// src/api/valuationApi.ts

import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl;

export const addValuationApi = async (valuationData: any) => {
  const response = await axios.post(`${API_BASE_URL}/asset-valuation/addValuation`, valuationData);
  return response;
};

export const getValuationByAssetIDApi = async (assetID: any) => {
  const response = await axios.get(`${API_BASE_URL}/asset-valuation/${assetID}`);
  return response;
};

export const getCurrentTokenPriceByAssetIDApi = async (assetID: string) => {
  const response = await axios.get(`${API_BASE_URL}/asset-valuation/current-token-price/${assetID}`);
  return response;
};

export const getTotalTokenCountByAssetIDApi = async (assetID: string) => {
  console.log('getTotalTokenCountByAssetIDApi assetID:', assetID);
  const response = await axios.get(`${API_BASE_URL}/asset-valuation/total-tokens/${assetID}`);
  console.log('getTotalTokenCountByAssetIDApi response:', response);
  return response;
};

export const getAssetCurrentPriceByAssetIDApi = async (assetID: string) => {
  const response = await axios.get(`${API_BASE_URL}/asset-valuation/current-price/${assetID}`);
  return response;
};

export const getInitialValuationApi = async (assetID: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/asset-valuation/initial-valuation/${assetID}`);
    return response;
  } catch (error) {
    throw new Error('Failed to fetch initial valuation');
  }
};