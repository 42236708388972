import { Table } from "react-bootstrap"
import "../../common.css"
export const DataTable = (props) => {
    return (
            <Table className="custom-table">
                <thead>
                    <tr>
                        {props?.Header?.map((event,i)=>{
                        return(
                            <th key={i}>{event}</th>
                        )
                    })}
                    </tr>
                </thead>
                <tbody>
                    {props?.children}
                </tbody>
            </Table>
    )
}