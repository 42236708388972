import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MonthlySpendingState {
  monthlySpendingsList: any[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  success: boolean;
  loading: boolean;
}

const initialState: MonthlySpendingState = {
    monthlySpendingsList: [],
    status: 'idle',
    error: null,
    success: false,
    loading: false
};

const assetMonthlyFinancialsSlice = createSlice({
  name: 'assetMonthlyFinancials',
  initialState,
  reducers: {
    // Fetch monthly spendings
    fetchMonthlySpendingsRequest(state, action: PayloadAction<{assetID: string, month: number, year: number}>) {
      state.status = 'loading';
      state.error = null;
    },
    fetchMonthlySpendingsSuccess(state, action: PayloadAction<any[]>) {
        console.log('fetchMonthlySpendingsSuccess: ', action.payload);
      state.status = 'succeeded';
      state.monthlySpendingsList = action.payload;
    },
    fetchMonthlySpendingsFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },
    
    // Update monthly spending
    updateMonthlySpendingRequest(state, action: PayloadAction<any>) {
      state.status = 'loading';
      state.error = null;
    },
    updateMonthlySpendingSuccess(state, action: PayloadAction<any>) {
      state.status = 'succeeded';
    //   const index = state.monthlySpendingsList.findIndex(item => item._id === action.payload._id);
    //   if (index !== -1) {
    //     state.monthlySpendingsList[index] = action.payload;
    //   }
    },
    updateMonthlySpendingFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },
    
    // Add new spending
    addMonthlySpendingRequest(state, action: PayloadAction<any>) {
      state.status = 'loading';
      state.error = null;
    },
    addMonthlySpendingSuccess(state, action: PayloadAction<any>) {
      state.status = 'succeeded';
    //   state.monthlySpendingsList.push(action.payload);
    },
    addMonthlySpendingFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const {
  fetchMonthlySpendingsRequest,
  fetchMonthlySpendingsSuccess,
  fetchMonthlySpendingsFailure,
  updateMonthlySpendingRequest,
  updateMonthlySpendingSuccess,
  updateMonthlySpendingFailure,
  addMonthlySpendingRequest,
  addMonthlySpendingSuccess,
  addMonthlySpendingFailure,
} = assetMonthlyFinancialsSlice.actions;

export default assetMonthlyFinancialsSlice.reducer;
