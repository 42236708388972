import React, { useEffect, useState } from "react";
import styles from "./MetricsCards.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faShoppingCart, faDollarSign, faUser, faWallet } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/rootReducer';
import { getTotalPrebookingTokensRequest } from '../../../../../features/assetPrebooking/assetPrebookingSlice';
import { useParams } from 'react-router-dom';
import { fetchTotalTokenPurchaseByAssetIDAndUserID } from "../../../../../features/tokenPurchase/tokenPurchaseSlice";

// Define a TypeScript interface for the card data
interface CardData {
  title: string;
  value: string;
  change?: string; // Optional field
  link: string;
  selection?: string;
  icon: IconDefinition;
  changeType?: "increase" | "decrease"; // Optional field
}

interface MetricsCardsProps {
  cards: CardData[];
  handleReportSelection: (report: string) => void;
}

const MetricsCards: React.FC<MetricsCardsProps> = ({ handleReportSelection }) => {
  console.log('MetricsCards');

  const cards: CardData[] = [
    {
      title: "Pre-Bookings",
      value: "0",
      link: "View Pre-bookings",
      selection: "Prebooking",
      icon: faShoppingCart,
      changeType: "decrease",
    },
    {
      title: "Purchases",
      value: "0",
      link: "View Purchases",
      selection: "Purchases",
      icon: faDollarSign,
      changeType: "increase",
    },
    {
      title: "Dividends",
      value: "$0",
      // change: "+5.16%",
      link: "View Dividends",
      selection: "Dividends",
      icon: faUser,
      changeType: "increase",
    },
    {
      title: "Sale Requests",
      value: "0",
      link: "View Sale Requests",
      selection: "SalesRequests",
      icon: faWallet,
    },
  ];

  const [cardDetails, setCardDetails] = useState(cards);
  

  const dispatch = useDispatch();

  const { totalTokensForAssetAndUser, loading, success, error } = useSelector((state: RootState) => state.prebooking);
  const { totalTokenPurchased } = useSelector((state: RootState) => state.tokenPurchase);
  const [valueArr, setValueArr] = useState<string[]>([]);

  const marketplaceLink = 'http://localhost:3002/me/marketplace';

  const { asset_id } = useParams(); // Extracts `id` from the URL

  const assetID = asset_id ?? ''; // Use the correct asset ID
  const userID = localStorage.getItem('userID') ?? '';

  useEffect(() => {
    dispatch(getTotalPrebookingTokensRequest({ userID, assetID }));
    dispatch(fetchTotalTokenPurchaseByAssetIDAndUserID({ userID, assetID }));    
  }, [dispatch]);

  useEffect(() => {
    const valArr = [`${totalTokensForAssetAndUser?.totalTokens} tokens`, 
      `${totalTokenPurchased} tokens`];
      setValueArr(valArr);
  }, [dispatch, totalTokensForAssetAndUser, totalTokenPurchased]);

  return (
    <div>
      <div className={styles.cardsContainer}>
        {cardDetails.map((card, index) => (
          <div className={styles.card} key={index}>
            <div className={styles.cardHeader}>
              <span>{card.title}</span>
              <FontAwesomeIcon icon={card.icon} className={styles.icon} />
            </div>
            <div className={styles.cardContent}>
              <div className={styles.valueChangeContainer}>
                <span>{valueArr[index]}</span>
                {card.change && (
                  <span
                    className={
                      card.changeType === "increase"
                        ? styles.increase
                        : styles.decrease
                    }
                  >
                    {card.change}
                  </span>
                )}
              </div>
            </div>
            <a
              href="#"
              className={styles.cardLink}
              onClick={(e) => {
                e.preventDefault(); // Prevent navigation
                if (card.selection) {
                  handleReportSelection(card.selection);
                }
              }}
            >
              {card.link}
            </a>
          </div>
        ))}
      </div>
      {/* Horizontal Line */}
      <hr className={styles.separator} />
    </div>
  );
};

export default MetricsCards;
