// src/api/adminUsersApi.ts
import axios from 'axios';
import config from '../../config/config';

export interface CreateAdminUserDto {
  name: string;
  email: string;
  password: string;
  role: string;
}

const { apiUrl: API_BASE_URL } = config;

export const createAdminUserApi = async (createDto: CreateAdminUserDto) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/admin-users`, createDto);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Error creating admin user');
  }
};

export interface LoginAdminUserDto {
    email: string;
    password: string;
  }
  
  export const loginAdminUserApi = async (loginDto: LoginAdminUserDto) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/admin-users/login`, loginDto);
      return response.data; // Should return { accessToken: '...' }
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Invalid credentials');
    }
  };

  export interface ChangeAdminTypeDto {
    userId: string;
    adminType: string; // New admin type
  }
  
  export const changeAdminTypeApi = async (changeDto: ChangeAdminTypeDto) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/admin-users/change-admin-type`, changeDto);
      return response.data; // Returns the updated AdminUser
    } catch (error: any) {
      throw new Error((error as any).response?.data?.message || 'Failed to change admin type');
    }
  };

  export interface ChangePasswordDto {
    userId: string;
    oldPassword: string;
    newPassword: string;
  }
  
  export const changePasswordApi = async (changeDto: ChangePasswordDto) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/admin-users/change-password`, changeDto);
      return response.data; // Returns the updated AdminUser
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to change password');
    }
  };  