// src/api/tokenSaleApi.ts

import axios from 'axios';

const API_BASE_PURCHASE_URL = 'http://localhost:3000/token-purchase';
const API_BASE_SALE_URL = 'http://localhost:3000/token-sale';

export const createTokenSale = async (tokenSaleDto: any) => {
  const response = await axios.post(`${API_BASE_SALE_URL}/create-token-sale-request`, tokenSaleDto);
  return response.data;
};

export const createTokenSaleRequest = async (tokenSaleDto: any) => {
  console.log('createTokenSaleRequest ', tokenSaleDto);
  const response = await axios.post(`${API_BASE_SALE_URL}/create-token-sale-request`, tokenSaleDto);
  return response.data;
};

export const getTokenSaleRequestByAssetIDAndUserID = async (assetID: string, userID: string) => {
  const response = await axios.get(`${API_BASE_SALE_URL}/get-requests/${assetID}/${userID}`);
  console.log('getTokenSaleRequestByAssetIDAndUserID ', response.data);
  return response;
};

export const updateSaleStatus = async (tokenSaleId: string, updateStatusDto: any) => {
  const response = await axios.patch(`/token-sale/${tokenSaleId}/status`, updateStatusDto);
  return response.data;
};

export const getAllSaleRequests = async () => {
  const response = await axios.get('/token-sale');
  return response.data;
};

export const fetchTokensAvailableToSell = async (assetID: string, userID: string) => {
  try {
      const response = await axios.get(`${API_BASE_PURCHASE_URL}/tokens-available-to-sell/${assetID}/${userID}`);
      return response;
  } catch (error) {
      throw error;
  }
};