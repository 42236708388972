import React, { useEffect } from 'react';
import styles from './PropertiesInvestedList.module.css';
import { FaBuilding } from 'react-icons/fa'; // Example icons from Font Awesome
import { useDispatch, useSelector } from 'react-redux';
import { requestAllProperty } from '../../../../features/asset/assetSlice';
import { DataTable } from '../../../common';
import { PROP_INVESTED_HEADERS } from '../../../common/constants';

const AllPropertyRates = () => {
  const dispatch = useDispatch();
  //const assetsInvested = useSelector((state: any) => state?.asset?.allProperty);
  const assetsInvested: any[] = [];

  useEffect(() => {
    // dispatch(requestAllProperty());
  }, [dispatch]);

  return (
    <>
      <div className={styles.metricsContainer}>
        {/* Total Properties Card */}
        <div className={styles.metricCard}>
          <div className={styles.metricHeader}>
            <FaBuilding className={styles.metricIcon} />
            <span>Properties Invested</span>
          </div>
          <div className={styles.top_2}>
            {assetsInvested?.length > 0 ? (
              <DataTable Header={PROP_INVESTED_HEADERS}>
                {assetsInvested.map((assetInvseted: any, index: any) => (
                  <tr key={index}>
                    <td width={'250px'}>{assetInvseted?.propertyTitle}</td>
                    <td width={'250px'}>{'$' + assetInvseted?.tokenPrice}</td>
                    <td width={'250px'}>
                      <span
                        className={styles.change}
                        style={{
                          color: assetInvseted?.differencePrice?.toString()?.startsWith('-')
                            ? 'red'
                            : 'green',
                        }}
                      >
                        {assetInvseted?.differencePrice + '%'}
                      </span>
                    </td>
                    <td width={'250px'}>{'$' + assetInvseted?.propertyValutaion}</td>
                  </tr>
                ))}
              </DataTable>
            ) : (
              <div className={styles.noData}>
                <p>
                  We will be launching slice purchases soon. We will let you know once we are ready.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllPropertyRates;
