import React, { useEffect } from 'react';
import KYCMessage from './KYCMessage';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSessionUrlRequest,
  getKycStatusRequest,
} from '../../../features/kycVeriff/kycVeriffSlice'; // Adjust paths based on your project structure
import { RootState } from '../../../store/rootReducer';
import styles from './KYCMessage.module.css'; // You can style this component using a CSS module

const KYCProcess: React.FC = () => {
  const dispatch = useDispatch();

  // Selectors to get session URL and status from the Redux store
  const sessionUrl = useSelector((state: RootState) => state.kycVeriff.sessionUrl);
  const sessionStatus = useSelector((state: RootState) => state.kycVeriff.sessionStatus);

  useEffect(() => {
     // Fetch KYC status on component load
     const userId = localStorage.getItem('userID') ?? '';
     if (userId) {
       dispatch(getKycStatusRequest({userId}));
     }

    // Open the session URL in a popup window if sessionUrl changes
    if (sessionUrl?.verification?.url && sessionStatus === 'created') {
      const url = sessionUrl.verification.url;
      console.log('Opening Session URL:', url);
      window.open(url, '_blank', 'width=600,height=700');
    }
  }, [dispatch, sessionUrl]);

  const handleStartKYC = () => {
    const userID = localStorage.getItem('userID') ?? '';
    const userDetails = {
      userId: userID, // Replace with dynamic user ID or relevant data
      firstName: 'Adam',
      lastName: 'Smith',
      idNumber: 'f04bdb47-d3be-4b28-b028-a652feb060b5',
      documentNumber: '',
      documentType: '',
      documentCountry: '',
      vendorData: 'ASTS121',
      endUserId: 'a1b2c35d-e8f7-6d5e-3cd2-a1b2c35db3d4',
      consents: [
        {
          type: 'ine',
          approved: true,
        },
      ],
    };

    // Dispatch the action to create a session
    dispatch(createSessionUrlRequest(userDetails));
  };

  return (
    <div>
      {sessionStatus !== 'created' && <div>   <KYCMessage onStartKYC={handleStartKYC} /></div>}
      {/* Display based on session status */}
      {sessionStatus === 'created' && <div className={styles.kycContainer}>
        <p className={styles.message}>
        KYC session has already been initiated and is awaiting approval. For further assistance, please reach out to the administrator.
      </p>
      </div>}

      {/* Display link if session URL exists */}
      {sessionStatus !== 'created' && sessionUrl?.verification?.url && (
        <p>
          <a
            href={sessionUrl.verification.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open KYC Session
          </a>
        </p>
      )}

      {/* Display message for ongoing or completed KYC */}
      {sessionStatus === 'completed' && <p>Your KYC is already completed.</p>}
      {sessionStatus === 'in-progress' && <p>Your KYC is in progress.</p>}
    </div>
  );
};

export default KYCProcess;
