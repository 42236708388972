import React, { useState } from 'react';
import TwoFactorSetup from './TwoFactorSetup';
import AuthenticatorSetup from './AuthenticatorSetup';

const TwoFactorAuthContainer = () => {
  const [step, setStep] = useState(0); // 0 - TwoFactorSetup, 1 - AuthStep1, 2 - AuthStep2

  return (
    <div>
      {step === 0 && <TwoFactorSetup onNext={() => setStep(1)} />}
      {step === 1 && <AuthenticatorSetup />}
    </div>
  );
};

export default TwoFactorAuthContainer;
