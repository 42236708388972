import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Register.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { registerRequest } from '../../../features/auth/authSlice';
import { RootState } from '../../../store/rootReducer';
import { recordReferralClickRequest } from '../../../features/userReferral/userReferralSlice';

const Register: React.FC = () => {

  const searchParams = new URLSearchParams(window.location.search);
  const referralCode = searchParams.get('referralCode');

  const { registrationSuccess, registrationError, registrationLoading } = useSelector(
    (state: RootState) => state.auth
  );

  const registrationSuccessMessage = 'Successfully Registered. You will be redirected to the login page shortly.';

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isChecked, setIsChecked] = useState(false); // State for the checkbox

  const [isMinLengthValid, setIsMinLengthValid] = useState(false);
  const [hasLetter, setHasLetter] = useState(false);
  const [hasNumberOrSymbol, setHasNumberOrSymbol] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const checkMark = '✔';

  useEffect(() => {
    let additionalInfo = {
      action: 'click',
    };
    if (referralCode) {
      dispatch(recordReferralClickRequest({ referralCode, additionalInfo }));
    }
  }, [referralCode, dispatch]);

  useEffect(() => {
    if (registrationSuccess) {
      let additionalInfo = {
        action: 'register',
      };
      if (referralCode) {
        dispatch(recordReferralClickRequest({ referralCode, additionalInfo }));
      }
      // Redirect to homepage after 3 seconds
      const timer = setTimeout(() => {
        navigate('/login');
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [registrationSuccess, navigate]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRegister = () => {
    let isValid = true;

    if (!validateEmail(email)) {
      setEmailError('Invalid email format');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (isValid && isChecked) {
      dispatch(registerRequest({ email, password }));
    } else if (!isChecked) {
      setEmailError('You must agree to the terms and conditions to register.');
    }
  };

  const handleEmailBlur = () => {
    if (!validateEmail(email)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);

    // Check for minimum length
    setIsMinLengthValid(value.length >= 8);

    // Check for letters
    setHasLetter(/[a-zA-Z]/.test(value));

    // Check for numbers or symbols
    setHasNumberOrSymbol(/[0-9!@#$%^&*(),.?":{}|<>]/.test(value));
  };

  return (
    <div>
      {/* Add the security notice section */}
      <div className={styles.securityNotice}>
        <span>Please make sure you are visiting </span>
        <div>
          <span className={styles.lockIcon}>🔒</span>
          <a href="https://assetslices.com" target="_blank" rel="noopener noreferrer" className={styles.secureLink}>
            https://assetslices.com
          </a>
        </div>
      </div>

      {/* Registration loading status */}
      {/* {registrationLoading && <div className={styles.loading}>Registering...</div>} */}

      {/* Registration success status */}
      {registrationSuccess && <div className={styles.success}>{registrationSuccessMessage}</div>}

      {/* Registration error status */}
      {registrationError && <div className={styles.error}>{registrationError}</div>}

      <div className={styles.inputField}>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className={emailError ? styles.invalid : ''}
          onBlur={handleEmailBlur}
        />
        {emailError && <span className={styles.errorMessage}>{emailError}</span>}
      </div>

      <div className={styles.inputField}>
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Password"
        />
        <ul className={styles.passwordRequirements}>
          <li className={styles.requirement}>
            <span className={`${styles.checkmark} ${isMinLengthValid ? styles.valid : ''}`}>
              {checkMark}
            </span>{' '}
            At least 8 characters
          </li>
          <li className={styles.requirement}>
            <span className={`${styles.checkmark} ${hasLetter ? styles.valid : ''}`}>
              {checkMark}
            </span>{' '}
            Lowercase or uppercase letter
          </li>
          <li className={styles.requirement}>
            <span className={`${styles.checkmark} ${hasNumberOrSymbol ? styles.valid : ''}`}>
              {checkMark}
            </span>{' '}
            Number or symbol
          </li>
        </ul>
      </div>

      {/* Terms and Conditions Section */}
      <div className={styles.checkboxContainer}>
        <input
          type="checkbox"
          id="terms"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <div>
          <span className={styles.checkboxLabel}>
            I hereby confirm that I have read and agree to the{' '}
            <a href="/policy/terms-and-services" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
            {/* ,{' '}
            <a href="/services-terms" target="_blank" rel="noopener noreferrer">
              Services Terms
            </a>
            ,{' '}
            <a href="/earn-terms" target="_blank" rel="noopener noreferrer">
              Earn Terms
            </a>
            ,{' '}
            <a href="/exchange-terms" target="_blank" rel="noopener noreferrer">
              Exchange Terms
            </a> */}
            ,{' '}
            <a href="/policy/cookie" target="_blank" rel="noopener noreferrer">
              Cookie Policy
            </a>
            , and{' '}
            <a href="/policy/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>{' '}
            of AssetSlices.
          </span>
        </div>
      </div>

      <button className={styles.button} onClick={handleRegister} disabled={registrationLoading}>
        {registrationLoading ? 'Registering...' : 'Register'}
      </button>

      <div className={styles.linksContainer}>
        <a className={styles.link} href="/login">
          Login
        </a>
      </div>
    </div>
  );
};

export default Register;
