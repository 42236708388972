// src/sagas/tokenSaleSaga.ts

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createTokenSaleRequest,
  createTokenSaleSuccess,
  createTokenSaleFailure,
  getTokenSaleRequestByAssetIDAndUserIDRequest,
  getTokenSaleRequestByAssetIDAndUserIDSuccess,
  getTokenSaleRequestByAssetIDAndUserIDFailure,
  updateSaleStatusRequest,
  updateSaleStatusSuccess,
  updateSaleStatusFailure,
  getAllSaleRequestsRequest,
  getAllSaleRequestsSuccess,
  getAllSaleRequestsFailure,
  fetchTokensAvailableToSellRequest,
  fetchTokensAvailableToSellSuccess,
  fetchTokensAvailableToSellFailure,  
} from './tokenSaleSlice';
import {
  createTokenSaleRequest as apiCreateTokenSaleRequest,
  getTokenSaleRequestByAssetIDAndUserID,
  updateSaleStatus,
  getAllSaleRequests,
  fetchTokensAvailableToSell,
} from './tokenSaleApi';
import { PayloadAction } from '@reduxjs/toolkit';


export interface ResponseGenerator{
    config?:any,
    data?:any,
    headers?:any,
    request?:any,
    status?:number,
    statusText?:string
  }


function* createTokenSaleSaga(action: ReturnType<typeof createTokenSaleRequest>) {
  console.log('createTokenSaleSaga ', action.payload);
  try {
    const response: ResponseGenerator = yield call(apiCreateTokenSaleRequest, action.payload);
    yield put(createTokenSaleSuccess(response));
  } catch (error) {
    yield put(createTokenSaleFailure(" An error occurred while creating token sale"));
  }
}

function* getTokenSaleRequestByAssetIDAndUserIDSaga(action: ReturnType<typeof getTokenSaleRequestByAssetIDAndUserIDRequest>) {
  try {
    const { assetID, userID } = action.payload;
    const response: ResponseGenerator = yield call(getTokenSaleRequestByAssetIDAndUserID, assetID, userID);
    yield put(getTokenSaleRequestByAssetIDAndUserIDSuccess(response?.data));
  } catch (error) {
    yield put(getTokenSaleRequestByAssetIDAndUserIDFailure("An error occurred while fetching token sale requests"));
  }
}

function* updateSaleStatusSaga(action: ReturnType<typeof updateSaleStatusRequest>) {
  try {
    const { tokenSaleId, updateStatusDto } = action.payload;
    const response: ResponseGenerator = yield call(updateSaleStatus, tokenSaleId, updateStatusDto);
    yield put(updateSaleStatusSuccess(response));
  } catch (error) {
    yield put(updateSaleStatusFailure("An error occurred while updating token sale status"));
  }
}

function* getAllSaleRequestsSaga() {
  try {
    const response: ResponseGenerator = yield call(getAllSaleRequests);
    yield put(getAllSaleRequestsSuccess(response?.data));
  } catch (error) {
    yield put(getAllSaleRequestsFailure("An error occurred while fetching all token sale requests"));
  }
}

function* handleFetchTokensAvailableToSell(action: PayloadAction<{assetID: string, userID: string}>) {
  console.log('fetchTokensAvailableToSellRequest ', action.payload);
  try {
      const { assetID, userID } = action.payload;
      const response: ResponseGenerator = yield call(fetchTokensAvailableToSell, assetID, userID);
      yield put(fetchTokensAvailableToSellSuccess(response?.data?.count));
  } catch (error: any) {
      yield put(fetchTokensAvailableToSellFailure(error.message));
  }
}
export function* tokenSaleSaga() {
  yield takeLatest(createTokenSaleRequest.type, createTokenSaleSaga);
  yield takeLatest(getTokenSaleRequestByAssetIDAndUserIDRequest.type, getTokenSaleRequestByAssetIDAndUserIDSaga);
  yield takeLatest(updateSaleStatusRequest.type, updateSaleStatusSaga);
  yield takeLatest(getAllSaleRequestsRequest.type, getAllSaleRequestsSaga);
  yield takeLatest(fetchTokensAvailableToSellRequest.type, handleFetchTokensAvailableToSell);
}
