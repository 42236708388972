import React from 'react';
import styles from './Card.module.css';
import img from './1.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faMapMarkerAlt, faTag } from '@fortawesome/free-solid-svg-icons';

interface CardProps {
    assetId: string;
    propertyId: string;
    status: string;
    location: string;
    title: string;
    investors: number;
    yearlyInvestmentReturn: string;
    fundedDate: string;
    purchaseCost: string;
    currentValuation: string;
    image: string;
    assetStage?: string;
    tokenPrice?: string;
    saleStartDate?: string;
    rentalStatus?: boolean;
    estimatedROI?: string;
    preBookingUsers?: number;
    totalTokensPreBooked?: number;
    totalTokensPurchased?: number;
}

const Card: React.FC<CardProps> = ({
      assetId,
      image,
      title,
      purchaseCost,
      investors,
      yearlyInvestmentReturn,
      fundedDate,
      currentValuation,
      status,
      assetStage,
      saleStartDate,
      tokenPrice,
      location,
      propertyId,
      rentalStatus,
      estimatedROI,
      preBookingUsers,
      totalTokensPreBooked,
      totalTokensPurchased,
  }) => {


      const getImageUrl = () => {
        const defaultImageUrl = "https://lenark-dev-assets-2.s3.us-east-1.amazonaws.com/default/default.webp";
        if (image === '') {
          return defaultImageUrl;
        }
        return image;
      }

      const getInvestorCountDesc = () => {
        const investorTitle = assetStage === 'Pre-Booking' ? 'Pre-booked' : 'Investors';
        const investorCount = assetStage === 'Pre-Booking' ? preBookingUsers : investors;

        if (investorCount && investorCount > 0) {
          return `${investorCount} ${investorTitle}`;
        }
        if (investorCount === 0) {
          return 'No investors';
        }
        return '';
      }

      const getTokenInvestedCountDesc = () => {
        const investorTitle = assetStage === 'Pre-Booking' ? 'Tokens Pre-booked' : 'Tokens purchased';
        let tokenCount = assetStage === 'Pre-Booking' ? totalTokensPreBooked : totalTokensPurchased;

        if (!tokenCount) {
          tokenCount = 0;
        }

        if (tokenCount >= 0) {
          return `${tokenCount} ${investorTitle}`;
        }
        return '';
      }

      return (
    <div className={styles.card}>
      <img
        src={getImageUrl()}
        alt="Property"
        className={styles.cardImage}
      />
      <div className={styles.cardHeader}>
      <div className={styles.cardHeaderText}>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={faBuilding} />
            <span className={styles.propertyId}>{ propertyId }</span>
          </span>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={faTag} />
            <span className={styles.status}>{rentalStatus ? 'Occupied' : 'Vacant'}</span>
          </span>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <span className={styles.location}>{location}</span>
          </span>
        </div>
                {/* <div className={styles.cardHeaderIcons}>
          <span className={styles.icon}>🚗</span>
          <span className={styles.icon}>🚪</span>
          <span className={styles.icon}>#308</span>
          <span className={styles.icon}>📋</span>
          <span className={styles.icon}>🇦🇪</span>
        </div> */}
        {/* <span className={styles.rented}>Rented</span> */}
      </div>
      <div className={styles.cardBody}>
        <h2>{ title }</h2>
        <span className={styles.investors}>{ getTokenInvestedCountDesc() }</span>
        <div className={styles.investmentDetails}>
        <div className={styles.investmentDetail}>
            <span>Asset Stage</span>
            <span>{assetStage}</span>
          </div>
          <div className={styles.investmentDetail}>
            <span>Estimated Yearly ROI</span>
            <span>{estimatedROI}</span>
          </div>
          <div className={styles.investmentDetail}>
            <span>Sale start date</span>
            <span>{saleStartDate}</span>
          </div>
          <div className={styles.investmentDetail}>
            <span>Token Price</span>
            <span>{tokenPrice}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
