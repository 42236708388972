import { Blocks } from "react-loader-spinner"

export const Loader = () => {
    return (
        <div className="loader-center">
            <Blocks
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                color="green"
                wrapperClass="blocks-wrapper"
                visible={true} />
        </div>

    )
}