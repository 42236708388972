// src/components/HorizontalTabs.tsx
import React, { useState } from 'react';
import styles from './HorizontalTabs.module.css';

interface Tab {
  label: string;
  content: React.ReactNode;
}

interface HorizontalTabsProps {
  tabs: Tab[];
}

const HorizontalTabs: React.FC<HorizontalTabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className={styles.horizontalTabs}> 
      <div className={styles.tabHeader}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`${styles.tabItem} ${index === activeTab ? styles.active : ''}`} 
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className={styles.tabContent}> {/* Use styles from CSS module */}
        {tabs[activeTab].content}
      </div>
    </div>
  );
};

export default HorizontalTabs;
