import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import styles from './DividendDistribution.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/rootReducer';
import { useParams } from 'react-router-dom';
import { getMonthlyRentDetailsByYearMonthRequest, getTokenDetailsRequest } from '../../../../../features/assetEditor/assetEditorSlice';

const DividendDistributions: React.FC = () => {
    const dispatch = useDispatch();
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const { success, error, loading, selectedMonthlyRentDetails } = useSelector((state: RootState) => state.assetEditor);
    const { tokenDetails } = useSelector((state: RootState) => state.assetEditor);

    console.log('selectedMonthlyRentDetails', selectedMonthlyRentDetails);

    const [showMonthlyCost, setShowMonthlyCost] = useState(true); // Default to maximized
    const [showDropdowns, setShowDropdowns] = useState(false); // State for dropdown visibility

    // Default to current year and month
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth()); // 0 = January, 11 = December

    const [showingYear, setShowingYear] = useState<number>(new Date().getFullYear());
    const [showingMonth, setShowingMonth] = useState<number>(new Date().getMonth()); // 0 = January, 11 = December
    const [totalTokens, setTotalTokens] = useState<number>(0);
    const [currentMonthDotSelected, setCurrentMonthDotSelected] = useState<number>(5);

    const { asset_id } = useParams(); // Extracts `id` from the URL
    console.log('AssetBasicDetailsHolderId ', asset_id);

    useEffect(() => {
        const year = selectedYear;
        const month = months[selectedMonth];
        dispatch(getMonthlyRentDetailsByYearMonthRequest({assetID: asset_id ?? '', year, month}));
    }, [dispatch, selectedYear, selectedMonth]);

    useEffect(() => {
        if (tokenDetails) {
          setTotalTokens(tokenDetails.totalNumberOfTokens);
        }
      }, [tokenDetails]);
    
    
    // const netIncomePerMonth = selectedMonthlyRentDetails?.grossIncomePerMonth ?? 0 - 
    //                           selectedMonthlyRentDetails?.propertyManagementFee ?? 0 - 
    //                           selectedMonthlyRentDetails?.assetSlicesFee ?? 0 - 
    //                           selectedMonthlyRentDetails?.maintenanceExpense ?? 0 - 
    //                           selectedMonthlyRentDetails?.propertyTax ?? 0 - 
    //                           selectedMonthlyRentDetails?.insuranceUtilities ?? 0;

    // const netIncomePerYear = netIncomePerMonth * 12;
    const totalInvestment = selectedMonthlyRentDetails?.totalInvestment;
    // const expectedIncomePercent = (netIncomePerYear / totalInvestment) * 100;
    // const incomePerTokenPerYear = incomePerTokenPerMonth * 12;
    const monthlyCostSum = (selectedMonthlyRentDetails?.propertyManagementFees ?? 0) +
                           (selectedMonthlyRentDetails?.assetSlicesFee ?? 0) +
                           (selectedMonthlyRentDetails?.maintenanceExpense ?? 0) +
                           (selectedMonthlyRentDetails?.propertyTax ?? 0) +
                           (selectedMonthlyRentDetails?.insuranceUtilities ?? 0);

    const netIncomePerMonth = (selectedMonthlyRentDetails?.grossIncomePerMonth ?? 0) - monthlyCostSum;
    const incomePerTokenPerMonth = netIncomePerMonth / totalTokens;
    const toggleMonthlyCost = () => {
        setShowMonthlyCost(!showMonthlyCost);
    };

    const toggleDropdowns = () => {
        setShowDropdowns(!showDropdowns);
    };

    const handleYearChange = (change: number) => {
        setSelectedYear(prevYear => prevYear + change);
    };

    const handleMonthChange = (index: number) => {
        setSelectedMonth(index);
        setCurrentMonthDotSelected(index);
    };

    const currentMonth = months[selectedMonth];
    const currentYear = selectedYear;

    const getVisibleMonths = () => {
        const monthsArray = [...months];
        const currentMonthIndex = new Date().getMonth(); // 0 = January, 11 = December
        const currentYear = new Date().getFullYear();
    
        // Calculate the start month and year
        const startMonthIndex = (currentMonthIndex - 5 + 12) % 12;
        const startYear = currentMonthIndex - 5 < 0 ? currentYear - 1 : currentYear;
    
        const visibleMonths = [];
        for (let i = 0; i < 6; i++) {
            const monthIndex = (startMonthIndex + i) % 12;
            const year = startYear + (startMonthIndex + i >= 12 ? 1 : 0);
            const month = monthsArray[monthIndex];
            visibleMonths.push({ month, year });
        }
        return visibleMonths;
    };

    const visibleMonths = getVisibleMonths();
    console.log('visibleMonths', visibleMonths);
    console.log('selectedMonth', selectedMonth);
    // const currentMonthIndex = months.indexOf(months[selectedMonth]);
    // const currentMonthIndex = selectedMonth;

    return (
        <div className={styles.assetIncomeDetailsContainer}>

            {/* Heading */}
            <div className={styles.header}>
                <h2>{`${visibleMonths[currentMonthDotSelected].month} ${visibleMonths[currentMonthDotSelected].year} Dividend`}</h2>
            </div>

            {/* Dots for month navigation */}
            <div className={styles.dotsContainer}>
                {visibleMonths.map((month, index) => (
                    <div
                        key={index}
                        className={`${styles.dot} ${currentMonthDotSelected === index ? styles.activeDot : ''}`}
                        onClick={() => handleMonthChange(index)}
                    />
                ))}
            </div>

            <div className={styles.assetIncomeDetails}>
                {selectedMonthlyRentDetails && (
                <div className={styles.rentalIncomeContent}>
                    <div className={`${styles.row} ${styles.bold}`}>
                        <div className={styles.key}>House Rented</div>
                        <div className={styles.value}>{selectedMonthlyRentDetails.houseCurrentlyRented ? 'Yes' : 'No'}</div>
                    </div>
                    {/* <div className={styles.row}>
                        <div className={styles.key}>Gross Income per Year</div>
                        <div className={styles.value}>{selectedMonthlyRentDetails.grossIncomePerYear}</div>
                    </div> */}
                    <div className={styles.row}>
                        <div className={styles.key}>Rent</div>
                        <div className={styles.value}>{selectedMonthlyRentDetails.grossIncomePerMonth}</div>
                    </div>
                    <div className={styles.monthlyCostHeader} onClick={toggleMonthlyCost}>
                        <div>Operating Costs
                        <FontAwesomeIcon icon={showMonthlyCost ? faChevronUp : faChevronDown} className={styles.collapseIcon} />
                        </div>
                        <div className={`${styles.monthlyCostTotal}`}>{monthlyCostSum}</div>
                    </div>
                    {showMonthlyCost && (
                        <div className={styles.monthlyCostContent}>
                            <div className={styles.row}>
                                <div className={styles.key}>Property Management Fee</div>
                                <div className={styles.value}>{selectedMonthlyRentDetails.propertyManagementFees}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.key}>AssetSlices Fee</div>
                                <div className={styles.value}>{selectedMonthlyRentDetails.assetSlicesFee}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.key}>Maintenance Expense</div>
                                <div className={styles.value}>{selectedMonthlyRentDetails.maintenanceExpense}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.key}>Property Tax</div>
                                <div className={styles.value}>{selectedMonthlyRentDetails.propertyTax}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.key}>Insurance Utilities</div>
                                <div className={styles.value}>{selectedMonthlyRentDetails.utilities}</div>
                            </div>
                        </div>
                    )}
                    <div className={styles.row}>
                        <div className={`${styles.key} ${styles.bold}`}>Net Income per Month (Dividends)</div>
                        <div className={styles.value}>{netIncomePerMonth}</div>
                    </div>
                    {/* <div className={styles.row}>
                        <div className={`${styles.key}`}>Net Income per Year</div>
                        <div className={styles.value}>{netIncomePerYear}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={`${styles.key}`}>Total Investment</div>
                        <div className={styles.value}>{totalInvestment}</div>
                    </div> */}
                    {/* <div className={styles.row}>
                        <div className={`${styles.key} ${styles.bold}`}>Expected Income Percentage</div>
                        <div className={`${styles.value} ${styles.bold}`}>{expectedIncomePercent}{`%`}</div>
                    </div> */}
                    {/* <div className={styles.row}>
                        <div className={styles.key}>Dividends</div>
                        <div className={styles.value}>$358</div>
                    </div> */}
                    <div className={styles.row}>
                        <div className={styles.key}>Total Number of Tokens</div>
                        <div className={styles.value}>{totalTokens}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={`${styles.key} ${styles.bold}`}>Per Token</div>
                        <div className={`${styles.value} ${styles.bold}`}>{selectedMonthlyRentDetails.incomePerTokenPerMonth}</div>
                    </div>
                    {/* <div className={styles.row}>
                        <div className={`${styles.key} ${styles.bold}`}>Income per Token per year</div>
                        <div className={`${styles.value} ${styles.bold}`}>{selectedMonthlyRentDetails.incomePerTokenPerYear}</div>
                    </div> */}
                </div>
                )}
                {!selectedMonthlyRentDetails && (
                <div>
                    <span>No data available</span>
                </div>
                )}
            </div>
        </div>
    );
};

export default DividendDistributions;
