import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl + '/users';

// API call to fetch email by userId
export const fetchEmailByUserIdApi = async (userId: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/email/${userId}`);
    return response.data?.email;
  } catch (error) {
    throw new Error('Failed to fetch email');
  }
};
