// referralCodeSaga.ts
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchReferralCodeApi, recordReferralClickApi, 
  fetchReferralStatsByCodeApi, fetchReferralUrlApi } from './userReferralApi';
import {
  fetchReferralCodeRequest,
  fetchReferralCodeSuccess,
  fetchReferralCodeFailure,
  recordReferralClickRequest,
  recordReferralClickSuccess,
  recordReferralClickFailure,
  fetchReferralStatsRequest,
  fetchReferralStatsSuccess,
  fetchReferralStatsFailure,
  fetchReferralUrlRequest,
  fetchReferralUrlSuccess,
  fetchReferralUrlFailure
} from './userReferralSlice';

// Worker saga: Fetch referral code
function* fetchReferralCodeSaga(action: any): any {
  try {
    const userId = action.payload; // userId from the dispatched action
    const response = yield call(fetchReferralCodeApi, userId);
    yield put(fetchReferralCodeSuccess(response.referralCode));
  } catch (error: any) {
    yield put(fetchReferralCodeFailure(error.message));
  }
}

function* fetchReferralUrlSaga(action: any): any {
  try {
    const userId = action.payload; // userId from the dispatched action
    const response = yield call(fetchReferralUrlApi, userId);
    yield put(fetchReferralUrlSuccess(response.referralUrl));
  } catch (error: any) {
    yield put(fetchReferralUrlFailure(error.message));
  }
}

// Worker saga: Record referral click
function* recordReferralClickSaga(action: any) {
  try {
    const { referralCode, additionalInfo } = action.payload;
    yield call(recordReferralClickApi, referralCode, additionalInfo);
    yield put(recordReferralClickSuccess());
  } catch (error: any) {
    yield put(recordReferralClickFailure(error.message));
  }
}

function* fetchReferralStatsSaga(action: any): any {
  try {
    const referralCode = action.payload; // Extract the referral code from the action
    const response = yield call(fetchReferralStatsByCodeApi, referralCode);
    yield put(fetchReferralStatsSuccess(response)); // Dispatch success action
  } catch (error: any) {
    yield put(fetchReferralStatsFailure(error.message)); // Dispatch failure action
  }
}

// Watcher saga: Watches for actions dispatched to the store
export function* watchReferralCodeSagas() {
  yield takeLatest(fetchReferralCodeRequest.type, fetchReferralCodeSaga);
  yield takeLatest(fetchReferralUrlRequest.type, fetchReferralUrlSaga);
  yield takeLatest(recordReferralClickRequest.type, recordReferralClickSaga);
  yield takeLatest(fetchReferralStatsRequest.type, fetchReferralStatsSaga);
}
