import React, { useState } from 'react';
import styles from './ActivityTimeline.module.css';

const activityList = [
  {
    type: 'tokenPurchase',
    label: 'Token Purchase',
    detailText: 'Purchased %1 token for property id %2 each costing %3'
  },
  {
    type: 'tokenSell',
    label: 'Token Sell',
    detailText: 'Sold %1 token for property id %2 each costing %3'
  },
  {
    type: 'revenueDistribution',
    label: 'Revenue Distribution',
    detailText: 'Distributed %1USDC for property id %2 For period %3'
  },
  {
    type: 'revenueWithdrawal',
    label: 'Revenue Withdrawal',
    detailText: 'Withdrawn %1USDC for property id %2'
  },
];
const ActivityTimeline: React.FC = () => {
  const allItems = [
    {
      type: 'tokenPurchase',
      label: 'Token Purchase',
      detailText: 'Purchased %1 token for property id %2 each costing %3',
      notes: '',
      time: '11 Jan 2024 12:00'
    },
    {
      type: 'tokenSell',
      label: 'Token Sell',
      detailText: 'Sold %1 token for property id %2 each costing %3',
      notes: '',
      time: '11 Nov 2024 12:00'
    },
    {
      type: 'revenueDistribution',
      label: 'Revenue Distribution',
      detailText: 'Distributed %1USDC for property id %2 For period %3',
      notes: '',
      time: '11 Jun 2024 12:00'
    },
    {
      type: 'revenueWithdrawal',
      label: 'Revenue Withdrawal',
      detailText: 'Withdrawn %1USDC for property id %2',
      notes: '',
      time: '11 Sep 2024 12:00'
    },
    {
      type: 'tokenPurchase',
      label: 'Token Purchase',
      detailText: 'Purchased %1 token for property id %2 each costing %3',
      notes: '',
      time: '11 Jan 2024 12:00'
    },
    {
      type: 'tokenSell',
      label: 'Token Sell',
      detailText: 'Sold %1 token for property id %2 each costing %3',
      notes: '',
      time: '11 Nov 2024 12:00'
    },
    {
      type: 'revenueDistribution',
      label: 'Revenue Distribution',
      detailText: 'Distributed %1USDC for property id %2 For period %3',
      notes: '',
      time: '11 Jun 2024 12:00'
    },
    {
      type: 'revenueWithdrawal',
      label: 'Revenue Withdrawal',
      detailText: 'Withdrawn %1USDC for property id %2',
      notes: '',
      time: '11 Sep 2024 12:00'
    },  
  ];

  const [visibleItemsCount, setVisibleItemsCount] = useState(3); // Show first 3 items initially

  const showMoreItems = () => {
    setVisibleItemsCount((prevCount) => prevCount + 3); // Load 3 more items on each click
  };

  const visibleItems = allItems.slice(0, visibleItemsCount);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Activity Timeline</h2>
        <div className={styles.filterContainer}>
          <div className={styles.customSelect}>
            <select className={styles.filter}>
              <option value="all">All Activities</option>
              {activityList.map((item, index ) => (
                <option value={item?.type}>{item?.label}</option>
              ))}
            </select>
          </div>
          <input type="text" className={styles.search} placeholder="Search..." />
        </div>
      </div>
      <div className={styles.timeline}>
        {visibleItems.map((item, index) => (
          <div key={index} className={styles.timelineItem}>
            <div className={styles.iconContainer}>
              <span className={styles.icon}>
                {/* {item.type === 'call' && '📞'}
                {item.type === 'mail' && '✉️'}
                {item.type === 'reminder' && '🔔'}
                {item.type === 'update' && '✔️'}
                {item.type === 'comment' && '💬'}
                {item.type === 'creation' && '🆕'} */}
                {item.type === 'revenueWithdrawal' && '💵'}
                {item.type === 'revenueDistribution' && '🔖'}
                {item.type === 'tokenPurchase' && '💲'}
                {item.type === 'tokenSell' && '💸'}
              </span>
            </div>
            <div className={styles.details}>
              <strong>{item.label}</strong>
              <div className={styles.detailsBox}>
                <p>{item.detailText}</p>
              </div>
              {item.notes && (
                <div className={styles.noteBox}>
                  <p>{item.notes}</p>
                </div>
              )}
              <span className={styles.time}>{item.time}</span>
            </div>
          </div>
        ))}
      </div>

      {visibleItemsCount < allItems.length && (
        <button className={styles.showMoreButton} onClick={showMoreItems}>
          Show More
        </button>
      )}
    </div>
  );
};

export default ActivityTimeline;
