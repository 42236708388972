
import { call, put, takeLatest } from 'redux-saga/effects';
import { getTotalTokensApi } from './assetTokenizationApi';
import {
  getTotalTokensRequest,
  getTotalTokensSuccess,
  getTotalTokensFailure,
} from './assetTokenizationSlice';
import { PayloadAction } from '@reduxjs/toolkit';

function* fetchTotalTokensSaga(action: any): Generator<any, void, any> {
  try {
    console.log("fetchTotalTokensSaga action:", action);
    console.log("fetchTotalTokensSaga action.payload:", action.payload);
    const assetID= action.payload;
    console.log("fetchTotalTokensSaga assetID:", assetID);
    const totalTokens = yield call(getTotalTokensApi, assetID);
    yield put(getTotalTokensSuccess(totalTokens?.data?.totalTokens));
  } catch (error) {
    yield put(getTotalTokensFailure("Failed to fetch total tokens"));
  }
}

export default function* assetTokenizationSaga() {
  yield takeLatest(getTotalTokensRequest.type, fetchTotalTokensSaga);
}
