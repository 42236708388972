// src/features/assetEditor/assetEditorApi.ts
import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl;


export const getTotalTokensApi = async (assetID: string) => {
  try {
    console.log('getTotalTokensApi assetID:', assetID);
    const response = await axios.get(`${API_BASE_URL}/asset-tokenization/asset/`+ assetID + `/total-tokens`);
    return response;
  } catch (error) {
    throw new Error(`Failed to fetch total tokens for assetID: ${assetID}`);
  }
};
