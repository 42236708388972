import React, { useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmailRequest } from '../../../features/auth/authSlice';

const EmailVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { emailVerificationLoading, emailVerificationSuccess, emailVerificationError } = useSelector(
    (state: any) => state.auth,
  );

  const { vt } = useParams<{ vt: string }>(); // Extract `vt` from the URL params

  useEffect(() => {
    console.log('Verification token from URL: ', vt);
    if (vt) {
      dispatch(verifyEmailRequest(vt));
    }
  }, [dispatch, vt]);

  useEffect(() => {
    if (emailVerificationSuccess) {
      // Redirect to homepage after 3 seconds
      const timer = setTimeout(() => {
        navigate('/');
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [emailVerificationSuccess]);

  return (
    <div>
      <h2>Email Verification</h2>
      {emailVerificationLoading && <p>Verifying your email...</p>}
      {emailVerificationError && <p style={{ color: 'red' }}>{emailVerificationError}</p>}
      {emailVerificationSuccess && (
        <div>
          <p style={{ color: 'green' }}>Email verified successfully!</p>
          <p>You will be redirected to the homepage shortly.</p>
          <p>
            If you are not redirected automatically, click <Link to="/">here</Link> to go to the homepage.
          </p>
        </div>
      )}
    </div>
  );
};

export default EmailVerification;
