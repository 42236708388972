import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import styles from "./DividendDistributionGraph.module.css";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const DemographicsChart: React.FC = () => {
  // Chart data
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Male",
        data: [30, 40, 50, 60, 70, 80],
        backgroundColor: "rgba(114, 124, 245, 0.8)", // Purple
      },
      {
        label: "Female",
        data: [60, 70, 80, 90, 100, 110],
        backgroundColor: "rgba(255, 165, 0, 0.8)", // Orange
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Disable legend
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove gridlines from X-axis
        },
      },
      y: {
        grid: {
          color: "rgba(200, 200, 200, 0.3)", // Light gray gridlines
        },
        beginAtZero: true,
        ticks: {
          stepSize: 30, // Step size for Y-axis
        },
      },
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>Distribution Ratio</h3>
        {/* <button className={styles.menuButton}>...</button> */}
      </div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default DemographicsChart;
