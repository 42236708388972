// src/components/AssetDetailEditor/AssetPage.tsx
import React, { useEffect, useState } from 'react';
import './AssetBasicDetailsHolder.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { getBasicDetailsRequest, getLocationDetailsRequest, getInternalStructureRequest,
  getExternalStructureRequest, getFeaturesRequest
 } from '../../../../features/assetEditor/assetEditorSlice';
import AssetBlockchain from '../AssetDetailViewer/AssetBlockchain/AssetBlockchain_old';
import AssetFeatures from '../AssetDetailViewer/AssetFeatures/AssetFeatures';
import AssetLocation from '../AssetDetailViewer/AssetLocation/AssetLocation';
import AssetCarousel from '../AssetDetailViewer/AssetCarousel/AssetCarousel';
import { useParams } from 'react-router-dom';
import AssetOverview from '../AssetDetailViewer/AssetOverview/AssetOverview';
import AssetDescription from '../AssetDetailViewer/AssetDescription/AssetDescription';
import AssetBlockchainDetails from '../AssetDetailViewer/AssetBlockchainDetails/AssetBlockchainDetails';
//import AssetCarousel from './AssetCarousel';

const AssetPage: React.FC = () => {
  const dispatch = useDispatch();
  const { basicDetails, addressDetails, 
    internalStructureDetails, externalStructureDetails, 
    assetFeatures } = useSelector((state: RootState) => state.assetEditor);

  const { asset_id } = useParams(); // Extracts `id` from the URL

  useEffect(() => {
    dispatch(getBasicDetailsRequest(asset_id ?? ''));
    dispatch(getLocationDetailsRequest(asset_id ?? ''));
    dispatch(getInternalStructureRequest(asset_id ?? ''));
    dispatch(getExternalStructureRequest(asset_id ?? ''));
    dispatch(getFeaturesRequest(asset_id ?? ''));
  }, [dispatch]);

  const sqft = internalStructureDetails?.interiorSizeInSqft + "sq ft";
  const bedroomCount = internalStructureDetails?.numberOfBedRoom + " Bedrooms";
  const bathroomCount = internalStructureDetails?.numberOfBathroom + " Bathrooms";
  const floorCount = externalStructureDetails?.stories + " Floors";
  const garageCount = externalStructureDetails?.parking + " Garages";
  const roomsCount = internalStructureDetails?.numberOfBedRoom + internalStructureDetails?.numberOfBathroom + " Rooms";
  const propertyType = externalStructureDetails?.propertyType;
  const builtYear = externalStructureDetails?.constructionYear;


  // State to store image URLs
  // const [imageUrls, setImageUrls] = useState<string[]>([]);

  // useEffect(() => {
  //   const urls: string[] = [];
  //   if (basicDetails?.images) {
  //     basicDetails?.images.map((fileName) => {
  //       const imageUrl = "https://lenark-dev-assets-2.s3.us-east-1.amazonaws.com/" + asset_id + "/" + fileName;
  //       urls.push(imageUrl);
  //       // urls.push(`http://localhost:3001/assets/image/${fileName}`);
  //     })
  //     setImageUrls(urls);
  //   }

  // }, [basicDetails]);
  
  // // Effect to convert File objects to URLs
  // useEffect(() => {
  //   if (basicDetails?.images) {
  //     // Convert File objects to URLs
  //     const urls: string[] = [];
  //     const promises = basicDetails.images.map((file) => {
  //       return new Promise<string>((resolve) => {
  //         const reader = new FileReader();
  //         reader.onload = (e) => {
  //           if (e.target && typeof e.target.result === 'string') {
  //             resolve(e.target.result);
  //           }
  //         };
  //         reader.readAsDataURL(file);
  //       });
  //     });

  //     Promise.all(promises).then((results) => {
  //       setImageUrls(results);
  //     });
  //   }
  // }, [basicDetails]);
  
  return (
    <div>
        <AssetCarousel images={basicDetails.imageUrls}/>
        <AssetLocation location={addressDetails} />
        <div className="assetDetails">
          <AssetOverview 
            area= { sqft }
            beds= { bedroomCount}
            baths= { bathroomCount}
            rooms= { roomsCount}
            floors={ floorCount }
            garage={ garageCount }
            propertyType={ propertyType }
            builtYear={ builtYear }
          />
          <AssetDescription 
            description={basicDetails.assetDescription} />
        </div>
        {/* Pass assetFeatures directly */}
        <AssetFeatures
          // _id={assetFeatures._id}
          // assetID={assetFeatures.assetID}
          cooling={assetFeatures.cooling}
          heating={assetFeatures.heating}
          fireAlarm={assetFeatures.fireAlarm}
          gym={assetFeatures.gym}
          balcony={assetFeatures.balcony}
          modernKitchen={assetFeatures.modernKitchen}
          pool={assetFeatures.pool}
          elevator={assetFeatures.elevator}
          petFriendly={assetFeatures.petFriendly}
          storage={assetFeatures.storage}
          laundry={assetFeatures.laundry}
          dishWasher={assetFeatures.dishWasher}
        />

        {/* <AssetFeatures features={assetFeatures} /> */}
        <AssetBlockchainDetails />
    </div>
  );
};

export default AssetPage;
