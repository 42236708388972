import React, { useEffect, useState } from 'react';
import styles from './Prebooking.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { createPreBookingRequest, getTotalPrebookingTokensForAssetRequest } from '../../../../../features/assetPrebooking/assetPrebookingSlice';
import { RootState } from '../../../../../store/rootReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { getTokenAvailabilityRequest } from '../../../../../features/assetEditor/assetEditorSlice';

const Prebooking = () => {
    const dispatch = useDispatch();

    const { loading, success, error, totalPrebookingTokensForAsset } = useSelector((state: RootState) => state.prebooking);
    const { tokenAvailability, tokenPurchaseData } = useSelector((state: RootState) => state.assetEditor);

    const { asset_id } = useParams(); // Extracts `id` from the URL
  
    const assetID = asset_id ?? ''; // Use the correct asset ID
    const userID = localStorage.getItem('userID') ?? '';
  
    const [costPerSlice, setCostPerSlice] = useState(0);
    const [slicesAvailable, setSlicesAvailable] = useState(0);
    const [slicesBooked, setSlicesBooked] = useState(0);
    const [totalSlices, setTotalSlices] = useState(0);

    const [sliceCount, setSliceCount] = useState(1);
    const [totalSliceCost, setTotalSliceCost] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    const handleIncrement = () => setSliceCount(sliceCount + 1);
    const handleDecrement = () => {
      if (sliceCount > 1) setSliceCount(sliceCount - 1);
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10);
      if (!isNaN(value) && value > 0) setSliceCount(value);
    };

    useEffect(() => {
      dispatch(getTotalPrebookingTokensForAssetRequest(assetID));
    }, [dispatch, assetID]);
  
    useEffect(() => {
      dispatch(getTokenAvailabilityRequest(asset_id ?? ''));
    }, [dispatch]);

    useEffect(() => {
      if (tokenAvailability) {
        setCostPerSlice(tokenAvailability.tokenPrice);
        setTotalSlices(tokenAvailability.totalTokens);
      }
    }, [tokenAvailability]);

    useEffect(() => {
      if (totalPrebookingTokensForAsset) {
        setSlicesBooked(totalPrebookingTokensForAsset);
        setSlicesAvailable(totalSlices - totalPrebookingTokensForAsset);
      }
    }, [totalPrebookingTokensForAsset]);

    React.useEffect(() => {
      setTotalSliceCost(sliceCount * 50);
      setTotalDiscount(sliceCount * 1);
      setTotalCost(sliceCount * 50 - sliceCount * 1);
    }, [sliceCount]);
  
    React.useEffect(() => {
      // Reset values after successful booking
      if (success) {
        setSliceCount(1);
        setTotalSliceCost(50);
        setTotalDiscount(1);
        setTotalCost(49);
      }
    }, [success]);
  
    const handleConfirmBooking = () => {
      const preBookingData = {
        assetID: assetID, // Replace with actual asset ID
        userID: userID, // Replace with actual user ID
        numberOfTokens: sliceCount
      };
  
      dispatch(createPreBookingRequest(preBookingData));
    };
  
  return (
    <div className={styles.container}>
      {/* Pre-Booking Section */}
      {/* <div className={styles.section}>
        <h3>Pre-Booking</h3>
        <div className={styles.sectionContent}>
          <div className={styles.weeklyEstimates}>
            <h4>Weekly estimates</h4>
            <p>
              The asset is in pre-booking stage. Book the slices now and we will notify when it's ready to purchase the slices,
              <strong> with $1 each slice</strong>.
            </p>
          </div>
        </div>
      </div> */}

      {/* Order Summary Section */}
      <div className={styles.orderSummary}>
        <div className={styles.orderHeader}>
          <h3>Pre-book Summary</h3>   
          {/* {!success ? (
            <span className={styles.paymentPending}>Booking Pending</span>
          ) : (
            <span className={styles.bookingSuccess}>
              <FontAwesomeIcon icon={faCheckCircle} className={styles.successIcon} />
              Booking Success
            </span>
          )}                  */}
          <span className={styles.paymentPending}>Pre-book</span>
        </div>

        {/* Notification Section */}
        <div className={styles.notification}>
          {success && (
            <div className={styles.successNotification}>
              <FontAwesomeIcon icon={faCheckCircle} className={styles.notificationIcon} />
              Pre-booking confirmed successfully!
            </div>
          )}
          {error && (
            <div className={styles.failureNotification}>
              <FontAwesomeIcon icon={faTimesCircle} className={styles.notificationIcon} />
              {error}
            </div>
          )}
        </div>


        <div className={styles.sectionContent}>
          <div className={styles.weeklyEstimates}>
            <p>
              The asset is in pre-booking stage. Book the slices now and get <strong>$1 off on each slices</strong>. 
              We will notify when it's ready to purchase.
            </p>
          </div>
        </div>

        {/* <p>Use this personalized guide to get your store up and running.</p> */}
        <div className={styles.orderDetails}>
          <div className={styles.orderRow}>
            <span>Slices Available</span>
            <span>Pre-booking</span>
            <span>{slicesAvailable}</span>
          </div>
          <div className={styles.orderRow}>
            <span>Cost per Slice</span>
            <span></span>
            <span>{costPerSlice} USDC</span>
          </div>
          {/* <div className={styles.orderRow}>
            <span>How many slices do you want to order ?</span>
            <span>Cost per Slice - 50 USDC</span>
            <span>$0.00</span>
          </div> */}
          <div className={`${styles.orderRow} ${styles.preBook}`}>
            <span>Pre-book Slices</span>
            <span></span>
            <span></span>
          </div>
          <div className={`${styles.orderRow} ${styles.selectSlices}`}>
            <span>Select number of Slices</span>
            <div className={styles.sliceSelector}>
              <button className={styles.decrementButton} onClick={handleDecrement}>
                -
              </button>
              <input
                type="number"
                className={styles.sliceInput}
                value={sliceCount}
                onChange={handleChange}
                min="1"
              />
              <button className={styles.incrementButton} onClick={handleIncrement}>
                +
              </button>
            </div>
            <span>{totalSliceCost} USDC</span>
          </div>
          <div className={styles.orderRow}>
            <span>Total Discount</span>
            <span>Early bird discount</span>
            <span>-{totalDiscount} USDC</span>
          </div>
          <div className={`${styles.orderRow} ${styles.orderTotal}`}>
            <span>Total</span>
            <span></span>
            <span>{totalCost} USDC</span>
          </div>
        </div>
        <div className={styles.actionButtons}>
        <button
            className={styles.collectPaymentButton}
            onClick={handleConfirmBooking}
            disabled={loading || success}
          >
            {loading ? (
              <>
                <FontAwesomeIcon icon={faSpinner} className={styles.loadingSpinner} spin />
                Processing...
              </>
            ) : (
              'Confirm Booking'
            )}
          </button>
        </div>
        <p className={styles.footerNote}>
          Review your order at a glance on the Order Summary page.
        </p>
      </div>
    </div>
  );
};

export default Prebooking;
