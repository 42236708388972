import React from "react";
import styles from "./NFTPurplePassView.module.css";

interface NFTPurplePassViewProps {
  imageUrl: string;
  alignImage: "left" | "right"; // Prop to control image alignment
}

const NFTPurplePassView: React.FC<NFTPurplePassViewProps> = ({
  imageUrl,
  alignImage,
}) => {
  const handlePurchaseClick = () => {
    // Open OpenSea in a new tab
    window.open("https://opensea.io/", "_blank");
  };

  return (
    <div
      className={`${styles.container} ${
        alignImage === "left" ? styles.leftAligned : styles.rightAligned
      }`}
    >
      <div className={styles.image}>
        <img src={imageUrl} alt="Purple Pass" />
      </div>
      <div className={styles.description}>
        <h2 className={styles.title}>Purple Pass</h2>
        <p>
          The Purple Pass is your ticket to a world of exclusive benefits,
          providing access to premium content and services that redefine the
          NFT experience.
        </p>
        <ul className={styles.extraDescription}>
          <li>Total available NFTs - 2000.</li>
          <li>Premium access to exclusive events.</li>
          <li>Valid for two years from the date of issuance.</li>
          <li>Special perks for collectors and investors.</li>
          <li>Unlocks VIP privileges across all platforms.</li>
        </ul>
        {/* Purchase Button */}
        <div className={styles.buttonContainer}>
          <button className={styles.purchaseButton} onClick={handlePurchaseClick}>
            Purchase Purple Pass
          </button>
        </div>
      </div>
    </div>
  );
};

export default NFTPurplePassView;
