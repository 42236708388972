import React from 'react';
import styles from './DividendDistributionsHolder.module.css';
import DividendDistributions from '../AssetDetailViewer/DividendDistribution/DividendDistribution';
import DividendDistributionGraph from './DividendDistributionGraph/DividendDistributionGraph';

const DividendDistributionsHolder: React.FC = () => {
  return (
    <div className={styles.container}>
      {/* Overlay */}
      <div className={styles.overlay}>
        <span className={styles.overlayText}>Slice Purchases will be launched soon</span>
      </div>

      <div className={styles.investProcess}>
        <DividendDistributions />
      </div>
      <div className={styles.assetInvestStats}>
        <DividendDistributionGraph />
      </div>
    </div>
  );
};

export default DividendDistributionsHolder;
