import React, { useEffect } from 'react';
import AssetValuation from '../AssetDetailViewer/AssetValuation/AssetValuation';
import styles from './InvestmentAppreciationHolder.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { getValuationDetailsRequest } from '../../../../features/assetEditor/assetEditorSlice';
import { useParams } from 'react-router-dom';
import InvestmentAppreciationGraph from './InvestmentAppreciationGraph/InvestmentAppreciationGraph';

const InvestmentAppreciationHolder: React.FC = () => {
  const dispatch = useDispatch();
  const { valuationDetails } = useSelector((state: RootState) => state.assetEditor);

  const { asset_id } = useParams(); // Extracts `id` from the URL

  useEffect(() => {
    dispatch(getValuationDetailsRequest(asset_id ?? '')); // Replace with your asset ID
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.investProcess}>
        <div className={styles.graphContainer}>
          <h2 className={styles.graphHeader}>Sqft value by year</h2>
          <InvestmentAppreciationGraph />
        </div>
      </div>
      <div className={styles.assetInvestStats}>
        <AssetValuation />
      </div>
    </div>
  );
};

export default InvestmentAppreciationHolder;
