import React from 'react';
import styles from './LoginWrapper.module.css';
import { Link, useLocation } from 'react-router-dom';
import Register from './Register';
import Login from './Login';
import logo from '../../../assets/logo12.png'; // Replace with the path to your logo
import ForgetPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import EmailVerification from './EmailVerification';

const LoginWrapper: React.FC = () => {
  const location = useLocation()
  console.log(location.pathname);


  const getContent = () => {
    const pathArr = location.pathname.split('/');

    if (pathArr[1] === '') {
      return <Login />
    }
    else if (pathArr[1] === 'login') {
      return <Login />
    }
    else if (pathArr[1] === 'register') {
      return <Register />
    }
    else if (pathArr[1] === 'signup') {
      return <Register />
    }
    else if (pathArr[1] === 'forgot-password') {
      return <ForgetPassword />
    }
    else if (pathArr[1] === 'reset-password') {
      return <ResetPassword />
    }
    else if (pathArr[1] === 'email-verification') {
      return <EmailVerification />
    }


  }

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [emailError, setEmailError] = useState('');
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const { isLoggedIn, user } = useSelector((state: RootState) => state.auth);

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate(user.redirectUrl);
  //   }
  // }, [isLoggedIn, navigate]);

  // const validateEmail = (email: string) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  // const handleLogin = () => {
  //   if (!validateEmail(email)) {
  //     setEmailError('Invalid email format');
  //     return;
  //   }
  //   setEmailError('');
  //   dispatch(loginRequest({ email, password }));
  // };

  // const handleKeyPress = (event: React.KeyboardEvent) => {
  //   if (event.key === 'Enter') {
  //     handleLogin();
  //   }
  // };

  return (
    // <div className={styles.loginContainer} onKeyPress={handleKeyPress}>
    <div className={styles.loginContainer}>
      <div className={styles.loginBox}>
        <Link to="/">
          <img src={logo} alt="Logo" className={styles.logo} />
        </Link>
        {getContent()}
      </div>
    </div>
  );
};

export default LoginWrapper;
