import React, { useEffect, useState } from 'react';
import styles from "../HomePage.module.css"
import logo from '../../../../assets/logo.png'; // Import your logo image
import { Link } from "react-router-dom";
import WaitingList from '../WaitingList/WaitingList';

export const Header = ()=>{
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    // Set a timer to open the modal after 5 seconds
    const timer = setTimeout(() => {
      setModalOpen(true);
    }, 5000);

    // Cleanup the timer if the component unmounts before 5 seconds
    return () => clearTimeout(timer);
  }, []);

  const handleLinkClick = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };


  return( <header className={styles.header}>
      <div className={styles.logoContainer}>
        <Link to="/">
          <img src={logo} alt="AssetSlices Logo" className={styles.logo} />
        </Link>
        {/* <h1 className={styles.logo}></h1> */}
      </div>
      <div className={styles.headerRight}>
        {/* <a href="https://docs.assetslices.com/assetslices/tokens/token-details" target='_blank' rel='noopener noreferrer' className={styles.headerLink}>Token</a> */}
        {/* <a href="/assetslice-token" rel='noopener noreferrer' className={styles.headerLink}>Token</a> */}
        {/* <a href="/assetslice-team" className={styles.headerLink}>Team</a> */}
        {/* <a href="https://docs.assetslices.com/assetslices/how-assetslices-work/assetslices-owned" target='_blank' rel='noopener noreferrer' className={styles.headerLink}>How it works</a> */}
        <a href="https://docs.assetslices.com/" target='_blank' rel='noopener noreferrer' className={styles.signInLink}>Docs</a>
        <a href="#" onClick={handleLinkClick} className={styles.headerLink}>Join Waitlist</a>
        <Link to="/login" className={styles.signInLink}>Sign In</Link>
      </div>
      <WaitingList isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </header>
    )
}