import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchAssetStagesRequest,
  fetchAssetStagesSuccess,
  fetchAssetStagesFailure,
  createOrUpdateAssetStagesRequest,
  createOrUpdateAssetStagesSuccess,
  createOrUpdateAssetStagesFailure,
} from './assetStagesSlice';

import {
  fetchAssetStagesApi,
  createOrUpdateAssetStagesApi,
} from './assetStagesApi';

// Worker saga: Fetch asset stages by assetID
function* fetchAssetStagesWorker(action: ReturnType<typeof fetchAssetStagesRequest>): any {
  try {
    const { assetID } = action.payload;
    const response = yield call(fetchAssetStagesApi, assetID);
    yield put(fetchAssetStagesSuccess(response));
  } catch (error: any) {
    yield put(fetchAssetStagesFailure(error.message));
  }
}

// Worker saga: Create or update asset stages
function* createOrUpdateAssetStagesWorker(
  action: ReturnType<typeof createOrUpdateAssetStagesRequest>
): any {
  try {
    const createDto = action.payload;
    const response = yield call(createOrUpdateAssetStagesApi, createDto);
    yield put(createOrUpdateAssetStagesSuccess(response));
  } catch (error: any) {
    yield put(createOrUpdateAssetStagesFailure(error.message));
  }
}

// Watcher saga: listens for dispatched actions
export function* watchAssetStagesSagas() {
  yield takeLatest(fetchAssetStagesRequest.type, fetchAssetStagesWorker);
  yield takeLatest(createOrUpdateAssetStagesRequest.type, createOrUpdateAssetStagesWorker);
}
