import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchEmailByUserIdApi } from './userApi';
import {
  fetchEmailByUserIdRequest,
  fetchEmailByUserIdSuccess,
  fetchEmailByUserIdFailure,
} from './userSlice';

// Worker saga: Fetch email by userId
function* fetchEmailByUserIdSaga(action: any): any {
  try {
    const userId = action.payload;
    const response = yield call(fetchEmailByUserIdApi, userId);
    yield put(fetchEmailByUserIdSuccess(response));
  } catch (error: any) {
    yield put(fetchEmailByUserIdFailure(error.message));
  }
}

// Watcher saga: Watches for actions dispatched to the store
export function* watchUserSagas() {
  yield takeLatest(fetchEmailByUserIdRequest.type, fetchEmailByUserIdSaga);
}
