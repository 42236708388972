// src/api/assetSettingsApi.ts

import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl;


export const fetchAssetSettingsApi = async (assetID: string) => {
  return axios.get(`${API_BASE_URL}/asset-settings/${assetID}`);
};

export const saveAssetSettingsApi = async (settingsData: any) => {
    console.log('saveAssetSettingsApi settingsData:', settingsData);
  return axios.post(`${API_BASE_URL}/asset-settings`, settingsData);
};

export const updateAssetSettingsApi = async (assetID: string, settingsData: any) => {
  return axios.put(`${API_BASE_URL}/asset-settings/${assetID}`, settingsData);
};
