import { call, put, takeLatest } from 'redux-saga/effects';
import { generateTotpApi, verifyTotpApi, get2FAStatusApi } from './userSecurityApi';
import {
  generateTotpRequest,
  generateTotpSuccess,
  generateTotpFailure,
  verifyTotpRequest,
  verifyTotpSuccess,
  verifyTotpFailure,
  get2FAStatusRequest,
  get2FAStatusSuccess,
  get2FAStatusFailure,  
} from './userSecuritySlice';

// Worker saga: Generate TOTP secret and QR code
function* generateTotpSaga(action: any): any {
  try {
    const { userId, userEmail } = action.payload;
    const response = yield call(generateTotpApi, userId, userEmail);
    yield put(generateTotpSuccess(response));
  } catch (error: any) {
    yield put(generateTotpFailure(error.message));
  }
}

// Worker saga: Verify TOTP code
function* verifyTotpSaga(action: any): any {
  try {
    const { userId, token } = action.payload;
    yield call(verifyTotpApi, userId, token);
    yield put(verifyTotpSuccess());
  } catch (error: any) {
    yield put(verifyTotpFailure(error.message));
  }
}

// Worker saga: Get 2FA status
function* get2FAStatusSaga(action: any): any {
  try {
    const { userId } = action.payload;
    const response = yield call(get2FAStatusApi, userId);
    console.log('get2FAStatusSaga response:', response);
    yield put(get2FAStatusSuccess(response));
  } catch (error: any) {
    yield put(get2FAStatusFailure(error.message));
  }
}

// Watcher saga: Watches for actions dispatched to the store
export function* watchUserSecuritySagas() {
  yield takeLatest(generateTotpRequest.type, generateTotpSaga);
  yield takeLatest(verifyTotpRequest.type, verifyTotpSaga);
  yield takeLatest(get2FAStatusRequest.type, get2FAStatusSaga);
}
