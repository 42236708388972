// src/features/asset/assetSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AssetState {
  loading: boolean;
  error: string | null;
  success: boolean;
  unpublishedAssets: any[];
  publishedAssets: any[];
  selectedAsset: { assetID: string; name: string; _id: string } | null;
  totalProperty: any;
  portfolioDetails :any;
  allProperty: any [];
}

const initialState: AssetState = {
  loading: false,
  error: null,
  success: false,
  unpublishedAssets: [],
  publishedAssets: [],
  selectedAsset: null,
  totalProperty: null,
  portfolioDetails : [],
  allProperty : []
};

const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    createAssetRequest(state) {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    createAssetSuccess(state) {
      state.loading = false;
      state.success = true;
    },
    createAssetFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    showUnpublishedAssetsRequest(state) {
      state.loading = true;
      state.error = null;
    },
    showUnpublishedAssetsSuccess(state, action: PayloadAction<any[]>) {
      state.loading = false;
      state.unpublishedAssets = action.payload;
      state.selectedAsset = null;
    },
    showUnpublishedAssetsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    showPublishedAssetsRequest(state) {
      state.loading = true;
      state.error = null;
    },
    showPublishedAssetsSuccess(state, action: PayloadAction<any[]>) {
      state.loading = false;
      state.publishedAssets = action.payload;
      state.selectedAsset = null;
    },
    showPublishedAssetsFailure(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action?.payload;
    },
    selectAsset: (state, action: PayloadAction<{ assetID: string; name: string; _id: string }>) => {
      state.selectedAsset = action.payload;
    },
    ShowReqestPropertycount(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null
    },
    ShowPropertycount(state, action: PayloadAction<any>) {
      console.log("ShowPropertycount:::",action.payload)
      state.loading = false;
      state.totalProperty = action.payload;
      state.error = null
    },
    requestPortfolioDetails(state, action: PayloadAction<any>){
      state.loading = true ;
    },
    responsePortfolioDetails(state, action:PayloadAction<any>){
      state.loading = false ;
      state.portfolioDetails = action?.payload;
    },
    requestAllProperty(state){
      state.loading = true ;
      state.allProperty = []
    },
    responseAllPropertyDetails(state, action:PayloadAction<any>){
      state.loading  = false ;
      state.allProperty = action.payload
    }
  },
});

export const { createAssetRequest, createAssetSuccess, createAssetFailure,
  showUnpublishedAssetsRequest, showUnpublishedAssetsSuccess, showUnpublishedAssetsFailure,
  showPublishedAssetsRequest, showPublishedAssetsSuccess, showPublishedAssetsFailure,responseAllPropertyDetails,requestAllProperty,
  selectAsset, ShowPropertycount, ShowReqestPropertycount, requestPortfolioDetails, responsePortfolioDetails} = assetSlice.actions;

export default assetSlice.reducer;
