// src/components/ForgetPassword.tsx

import React, { useState, useEffect } from 'react';
import styles from './ForgotPassword.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { requestPasswordResetRequest } from '../../../features/auth/authSlice';
import { RootState } from '../../../store/rootReducer';

const ForgetPassword: React.FC = () => {
  const dispatch = useDispatch();
  const { loading, passwordResetSuccess, passwordResetError } = useSelector((state: RootState) => state.auth);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  // Validate email format
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle the password reset request
  const handleForgetPassword = () => {
    if (!validateEmail(email)) {
      setEmailError('Invalid email format');
      return;
    }
    setEmailError('');
    dispatch(requestPasswordResetRequest(email)); // Dispatch the requestPasswordReset action
  };

  // Validate email on blur event
  const handleEmailBlur = () => {
    if (!validateEmail(email)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  // Clear email field upon successful password reset request
  useEffect(() => {
    if (passwordResetSuccess) {
      setEmail('');
    }
  }, [passwordResetSuccess]);

  return (
    <div>
      <h2>Forget Password</h2>
      <div className={styles.inputField}>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className={emailError ? styles.invalid : ''}
          onBlur={handleEmailBlur} // Validate on blur
        />
        {emailError && <span className={styles.errorMessage}>{emailError}</span>}
      </div>

      {/* Status messages */}
      {loading && <p className={styles.infoMessage}>Sending password reset request...</p>}
      {passwordResetError && <p className={styles.errorMessage}>{passwordResetError}</p>}
      {passwordResetSuccess && <p className={styles.successMessage}>Password reset link sent! Please check your email.</p>}

      <button className={styles.button} onClick={handleForgetPassword} disabled={loading}>
        {loading ? 'Sending Request...' : 'Reset Password'}
      </button>

      <div className={styles.linksContainer}>
        <a className={styles.link} href="/login">Login</a>
        <a className={styles.disabledLink} >Register</a>
        {/* <a className={styles.link} href="/register">Register</a> */}
      </div>
    </div>
  );
};

export default ForgetPassword;
