// src/components/AssetDetailViewer/AssetCarousel/LightboxModal.tsx
import React, { useEffect, useRef, useState } from 'react';
import styles from './LightboxModal.module.css';

interface LightboxModalProps {
  images: string[];
  currentIndex: number;
  onClose: () => void;
}

const LightboxModal: React.FC<LightboxModalProps> = ({ images, currentIndex, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(currentIndex);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentImageIndex(currentIndex);
    // Focus on the modal when it mounts
    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, [currentIndex]);

  const handleNext = () => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(nextIndex);
  };

  const handlePrev = () => {
    const prevIndex = (currentImageIndex - 1 + images.length) % images.length;
    setCurrentImageIndex(prevIndex);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'ArrowLeft':
        handlePrev();
        break;
      case 'ArrowRight':
        handleNext();
        break;
      case 'Escape':
        onClose();
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={styles.modalBackdrop}
      onClick={onClose}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      ref={modalRef} // Ref to manage focus
    >
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <img src={images[currentImageIndex]} alt="Asset" className={styles.modalImage} />
        <button className={styles.prevButton} onClick={handlePrev}>{'<'}</button>
        <button className={styles.nextButton} onClick={handleNext}>{'>'}</button>
      </div>
    </div>
  );
};

export default LightboxModal;
