import React, { useEffect, useState } from 'react';
import styles from './ReferralBoard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { fetchReferralCodeRequest, fetchReferralUrlRequest, 
    recordReferralClickRequest, fetchReferralStatsRequest } from '../../../features/userReferral/userReferralSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';

const ReferralBoard = () => {
    const dispatch = useDispatch();
    // const referralUrl ='http://localhost:3001/signup?referralCode=';
    const [link, setLink] = useState("");
    const userID = localStorage.getItem('userID') ?? '';

    const { referralCode, referralUrl, stats } = useSelector((state: RootState) => state.referralCode);
    

    // Fetch referral code
    useEffect(() => {
        // dispatch(fetchReferralCodeRequest(userID));
        dispatch(fetchReferralUrlRequest(userID));
    }, [dispatch]);

    useEffect(() => {
        if (referralCode) {
            setLink(`${referralUrl}${referralCode}`);
            dispatch(fetchReferralStatsRequest(referralCode));
        }
    }, [referralCode, dispatch]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(link);
        //TODO: Show a toast notification
        //alert("Link copied to clipboard!");
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2>Refer Friends & Earn Credit</h2>
                <p>Introduce a friend to AssetSlices and you'll be credited rewards to your account</p>
                {/* <div className={styles.creditBox}>$100 Credit</div> */}
            </div>
            <div className={styles.shareSection}>
                <h3>Share referral link with friends</h3>
                <div className={styles.linkBox}>
                    <input type="text" value={referralUrl? referralUrl : ''} readOnly />
                    <button onClick={copyToClipboard}>Copy Link</button>
                </div>
                <div className={styles.socialButtons}>
                    <button className={styles.facebookButton}><FontAwesomeIcon icon={faFacebookF} className={styles.socialIcons} /> Facebook</button>
                    <button className={styles.twitterButton}><FontAwesomeIcon icon={faTwitter} className={styles.socialIcons} /> Twitter</button>
                    <button className={styles.linkedinButton}><FontAwesomeIcon icon={faLinkedinIn} className={styles.socialIcons} /> LinkedIn</button>
                    <button className={styles.emailButton}><FontAwesomeIcon icon={faEnvelope} className={styles.socialIcons} /> Email</button>
                </div>
            </div>
            <div className={styles.stats}>
                <div>
                    <span>{stats?.click ?? 0}</span>
                    <p>Click count</p>
                </div>
                <div>
                    <span>{stats?.register ?? 0}</span>
                    <p>Referral Signups</p>
                </div>
                <div>
                    <span>{stats?.firstPurchase ?? 0}</span>
                    <p>Successful Purchase</p>
                </div>
                <div>
                    <span>{stats?.creditsEarned ?? 0}</span>
                    <p>Credits Earned</p>
                </div>
            </div>
        </div>
    );
};

export default ReferralBoard;
