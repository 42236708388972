import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserAddressRequest } from '../../../features/userProfile/userProfileSlice'; // Import the action from your slice
import styles from './AddressSection.module.css';
import { RootState } from '../../../store/rootReducer';

import {
    GetCountries,
    GetState,
    GetCity,
    GetLanguages,
    GetRegions,
    GetPhonecodes //async functions
  } from "react-country-state-city";
import { City, Language, Phonecodes, Region, State } from 'react-country-state-city/dist/esm/types';
import { Country } from 'react-country-state-city/dist/esm/types';

  
interface AddressSectionProps {
  address: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  country: string;
}

const AddressSection: React.FC<AddressSectionProps> = ({
  address,
  addressLine2,
  city,
  state,
  zip,
  county,
  country
}) => {
  const dispatch = useDispatch();
  
  const [region, setRegion] = useState("");
  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);
  const [language, setLanguage] = useState(0);
  const [phoneCode, setPhoneCode] = useState("");

  const [phonecodeList, setPhonecodeList] = useState<Phonecodes[]>([]);
  const [regionsList, setRegionsList] = useState<Region[]>([]);
  
  const [countriesList, setCountriesList] = useState<Country[]>([]);
  const [stateList, setStateList] = useState<State[]>([]);
  const [cityList, setCityList] = useState<City[]>([]);
  const [languageList, setLanguageList] = useState<Language[]>([]);

  console.log('countriesList:', countriesList); 
    console.log('stateList:', stateList);
    console.log('cityList:', cityList);

  // Local state to handle form inputs, initialized with the passed props
  const [localAddress, setLocalAddress] = useState(address);
  const [localAddressLine2, setLocalAddressLine2] = useState(addressLine2);
  const [localCity, setLocalCity] = useState(city);
  const [localState, setLocalState] = useState(state);
  const [localZip, setLocalZip] = useState(zip);
  const [localCounty, setLocalCounty] = useState(county);
  const [localCountry, setLocalCountry] = useState(country);
  
  const [localSuccessMessage, setLocalSuccessMessage] = useState<string | null>(null);
  const [localErrorMessage, setLocalErrorMessage] = useState<string | null>(null);
  
  const { addressSectionSuccessMessage, addressSectionError } = useSelector((state: RootState) => state.userProfile);
  
  const userID = localStorage.getItem('userID') ?? '';
  useEffect(() => {
    GetPhonecodes().then((result) => {
      setPhonecodeList(result);
    });
    GetRegions().then((result) => {
      setRegionsList(result);
    });
    GetCountries().then((result) => {
      setCountriesList(result);
    });

    GetLanguages().then((result) => {
      setLanguageList(result);
    });
  }, []);

  const handleSaveAddress = () => {
    // Validation check
    if (!localAddress || !localCity || !localState || !localZip || !localCountry) {
      setLocalErrorMessage('Please fill in all required fields.');
      return;
    }
    
    // Create the address object
    const addressData = {
      address1: localAddress,
      address2: localAddressLine2,
      city: localCity,
      state: localState,
      zip: localZip,
      county: localCounty,
      country: localCountry,
    };

    // Dispatch the updateUserAddressRequest action
    dispatch(updateUserAddressRequest({ userID, addressData }));
  };

  // Update local notification state when success or error occurs
  useEffect(() => {
    setLocalSuccessMessage(addressSectionSuccessMessage);
    setLocalErrorMessage(addressSectionError);

    // Clear notifications after 3 seconds
    const timer = setTimeout(() => {
      setLocalSuccessMessage(null);
      setLocalErrorMessage(null);
    }, 3000);

    return () => clearTimeout(timer);
  }, [addressSectionSuccessMessage, addressSectionError]);

  // To ensure the form updates when the props change, we can use useEffect
  useEffect(() => {
    setLocalAddress(address);
    setLocalAddressLine2(addressLine2);
    setLocalCity(city);
    setLocalState(state);
    setLocalZip(zip);
    setLocalCounty(county);
    setLocalCountry(country);
  }, [address, addressLine2, city, state, zip, county, country]);

  return (
    <div className={styles.container}>
      <h2>Address Information</h2>
      
      <div>
      <h6>Region</h6>
      <select
        onChange={(e) => {
          const _region = regionsList[Number(e.target.value)];
          setRegion(_region.name);
        }}
        value={region}
      >
        {regionsList.map((item, index) => (
          <option key={index} value={index}>
            {item.name}
          </option>
        ))}
      </select>
      <h6>Country</h6>
      <select
        onChange={(e) => {
            console.log('e.target.value:', e.target.value);
          const country = countriesList[Number(e.target.value)];
          console.log('country:', country);

          setCountryid(country.id);
          GetState(country.id).then((result) => {
            console.log('GetState');
            console.log('result:', result);
            setStateList(result);
          });
        }}
        value={countryid}
      >
        {countriesList.map((item, index) => (
          <option key={index} value={index}>
            {item.name}
          </option>
        ))}
      </select>

        {/*
      <h6>Phone Code</h6>
      <select
        onChange={(e) => {
          setRegion(e.target.value);
        }}
        value={phoneCode}
      >
        {regionsList.map((item, index) => (
          <option key={index} value={item.phone_code}>
            +{item.phone_code} - {item.name}
          </option>
        ))}
      </select>
      */}
      <h6>State</h6>
      <select
        onChange={(e) => {
          const state = stateList[Number(e.target.value)]; //here you will get full state object.
          setStateid(state.id);
          GetCity(countryid, state.id).then((result) => {
            setCityList(result);
          });
        }}
        value={stateid}
      >
        {stateList.map((item, index) => (
          <option key={index} value={index}>
            {item.name}
          </option>
        ))}
      </select>
      <h6>City</h6>
      <select
        onChange={(e) => {
          const city = cityList[Number(e.target.value)]; //here you will get full city object.
          setCityid(city.id);
        }}
        value={cityid}
      >
        {cityList.map((item, index) => (
          <option key={index} value={index}>
            {item.name}
          </option>
        ))}
      </select>
      {/*
      <h6>Language</h6>
      <select
        onChange={(e) => {
          setLanguage(e);
        }}
        value={language}
      >
        {languageList.map((item, index) => (
          <option key={index} value={item.id}>
            {item.name}
          </option>
        ))}
      </select> */}
    </div>

      {/* Success and Error Messages */}
      {/* {localSuccessMessage && <div className={styles.success}>{localSuccessMessage}</div>}
      {localErrorMessage && <div className={styles.error}>{localErrorMessage}</div>}

      <div className={styles.formGroup}>
        <label htmlFor="address">Address</label>
        <input
          type="text"
          id="address"
          value={localAddress}
          onChange={(e) => setLocalAddress(e.target.value)}
          placeholder="Address"
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="addressLine2">Address Line 2</label>
        <input
          type="text"
          id="addressLine2"
          value={localAddressLine2}
          onChange={(e) => setLocalAddressLine2(e.target.value)}
          placeholder="Address Line (if needed)"
        />
      </div>
      <div className={styles.grid}>
        <div className={styles.formGroup}>
          <label htmlFor="city">City</label>
          <input
            type="text"
            id="city"
            value={localCity}
            onChange={(e) => setLocalCity(e.target.value)}
            placeholder="City"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="state">State</label>
          <input
            type="text"
            id="state"
            value={localState}
            onChange={(e) => setLocalState(e.target.value)}
            placeholder="State"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="zip">ZIP</label>
          <input
            type="text"
            id="zip"
            value={localZip}
            onChange={(e) => setLocalZip(e.target.value)}
            placeholder="ZIP"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="county">County</label>
          <input
            type="text"
            id="county"
            value={localCounty}
            onChange={(e) => setLocalCounty(e.target.value)}
            placeholder="County"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="country">Country</label>
          <input
            type="text"
            id="country"
            value={localCountry}
            onChange={(e) => setLocalCountry(e.target.value)}
            placeholder="Country"
          />
        </div>
      </div>
      <button className={styles.saveButton} onClick={handleSaveAddress}>
        Save Address
      </button> */}
    </div>
  );
};

export default AddressSection;
