import React, { useState } from 'react';
import styles from './CryptoPurchaseHolder.module.css';
import TokenInteractorNew from '../../AssetDetailViewer/TokenInteractor/TokenInteractorNew';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/rootReducer';

const CryptoPurchaseHolder = () => {

    const { loading, success, error, tokenPurchaseDataDraft } = useSelector((state: RootState) => state.tokenPurchase);
    const [requiredUSDC, setRequiredUSDC] = useState(0);
    const [tokenCount, setTokenCount] = useState(0);

    console.log('CryptoPurchaseHolder tokenPurchaseDataDraft', tokenPurchaseDataDraft);

    React.useEffect(() => {
        if (tokenPurchaseDataDraft) {
            setRequiredUSDC(tokenPurchaseDataDraft.totalPurchaseCost);
            setTokenCount(tokenPurchaseDataDraft.numberOfTokens);
        }
    }, [tokenPurchaseDataDraft]);

    console.log('CryptoPurchaseHolder requiredUSDC', requiredUSDC);
    console.log('CryptoPurchaseHolder tokenCount', tokenCount);
    
  return (
    <div className={styles.container}>
      <div className={styles.chartContainer}>
        <h3 className={styles.title}>Purchase with Metamask</h3>

        <div className={styles.sectionContentWrapper}>
          <div className={styles.sectionContent}>
            <p>
              Please change to Sepholia - Ethereum test network. You can get test ETH and test USDC from the faucet.
            </p>
          </div>
        </div>

        <TokenInteractorNew requiredUSDC={requiredUSDC} tokenCount={tokenCount} />
      </div>
    </div>
  );
};

export default CryptoPurchaseHolder;
