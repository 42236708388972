// src/reducers/tokenSaleSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SaleHistory } from '../assetEditor/types';

interface TokenSaleState {
  tokenSales: SaleHistory[];
  loading: boolean;
  error: string | null;
  success: boolean | null;
  successMessage: string | null;
  tokensAvailableToSell: number | null;
}

const initialState: TokenSaleState = {
  tokenSales: [],
  tokensAvailableToSell: null,
  success: null,
  successMessage: null,
  loading: false,
  error: null,
  
};

const tokenSaleSlice = createSlice({
  name: 'tokenSale',
  initialState,
  reducers: {
    createTokenSaleRequest: (state, action: PayloadAction<any>) => {
      console.log('createTokenSaleRequest ', action.payload);
      state.loading = true;
    },
    createTokenSaleSuccess: (state, action: PayloadAction<any>) => {
      state.tokenSales.push(action.payload);
      state.loading = false;
      state.success = true;
      state.error = null;
      state.successMessage = 'Sale request submitted successfully';
    },
    createTokenSaleFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getTokenSaleRequestByAssetIDAndUserIDRequest: (state, action: PayloadAction<{ assetID: string; userID: string }>) => {
      state.loading = true;
    },
    getTokenSaleRequestByAssetIDAndUserIDSuccess: (state, action: PayloadAction<any[]>) => {
      console.log('getTokenSaleRequestByAssetIDAndUserIDSuccess ', action.payload);
      state.tokenSales = action.payload;
      state.loading = false;
      state.error = null;
    },
    getTokenSaleRequestByAssetIDAndUserIDFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateSaleStatusRequest: (state, action: PayloadAction<{ tokenSaleId: string; updateStatusDto: any }>) => {
      state.loading = true;
    },
    updateSaleStatusSuccess: (state, action: PayloadAction<any>) => {
      const index = state.tokenSales.findIndex((sale) => sale._id === action.payload._id);
      if (index !== -1) {
        state.tokenSales[index] = action.payload;
      }
      state.loading = false;
      state.error = null;
    },
    updateSaleStatusFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAllSaleRequestsRequest: (state) => {
      state.loading = true;
    },
    getAllSaleRequestsSuccess: (state, action: PayloadAction<any[]>) => {
      state.tokenSales = action.payload;
      state.loading = false;
      state.error = null;
    },
    getAllSaleRequestsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

    fetchTokensAvailableToSellRequest(state, action: PayloadAction<{assetID: string, userID: string}>) {
      console.log('fetchTokensAvailableToSellRequest ', action.payload);
      state.loading = true;
      state.error = null;
    },
    fetchTokensAvailableToSellSuccess(state, action: PayloadAction<number>) {
        state.loading = false;
        state.tokensAvailableToSell = action.payload;
    },
    fetchTokensAvailableToSellFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
    },

  },
});

export const {
  createTokenSaleRequest,
  createTokenSaleSuccess,
  createTokenSaleFailure,
  getTokenSaleRequestByAssetIDAndUserIDRequest,
  getTokenSaleRequestByAssetIDAndUserIDSuccess,
  getTokenSaleRequestByAssetIDAndUserIDFailure,
  updateSaleStatusRequest,
  updateSaleStatusSuccess,
  updateSaleStatusFailure,
  getAllSaleRequestsRequest,
  getAllSaleRequestsSuccess,
  getAllSaleRequestsFailure,
  fetchTokensAvailableToSellRequest,
  fetchTokensAvailableToSellSuccess,
  fetchTokensAvailableToSellFailure,  
} = tokenSaleSlice.actions;

export default tokenSaleSlice.reducer;
