import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getMonthlySpendingsApi,
  updateMonthlySpendingApi,
  addMonthlySpendingApi,
} from './assetMonthlyFinancialsApi';
import {
  fetchMonthlySpendingsRequest,
  fetchMonthlySpendingsSuccess,
  fetchMonthlySpendingsFailure,
  updateMonthlySpendingRequest,
  updateMonthlySpendingSuccess,
  updateMonthlySpendingFailure,
  addMonthlySpendingRequest,
  addMonthlySpendingSuccess,
  addMonthlySpendingFailure,
} from './assetMonthlyFinancialsSlice';
import { PayloadAction } from '@reduxjs/toolkit';

interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

// Worker saga to fetch monthly spendings
function* handleFetchMonthlySpendings(action: PayloadAction<string>) {
  try {
    const response: ResponseGenerator = yield call(getMonthlySpendingsApi, action.payload);
    yield put(fetchMonthlySpendingsSuccess(response.data));
  } catch (error) {
    yield put(fetchMonthlySpendingsFailure('Error fetching monthly spendings'));
  }
}

// Worker saga to update a monthly spending
function* handleUpdateMonthlySpending(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator = yield call(updateMonthlySpendingApi, action.payload.assetID, action.payload.monthlySpendingDetails);
    yield put(updateMonthlySpendingSuccess(response.data));
  } catch (error) {
    yield put(updateMonthlySpendingFailure('Error updating monthly spending'));
  }
}

// Worker saga to add a new monthly spending
function* handleAddMonthlySpending(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator = yield call(addMonthlySpendingApi, action.payload.monthlySpendingDetails);
    yield put(addMonthlySpendingSuccess(response.data));
  } catch (error) {
    yield put(addMonthlySpendingFailure('Error adding new monthly spending'));
  }
}

// Watcher saga
export default function* assetMonthlyFinancialsSaga() {
  yield takeLatest(fetchMonthlySpendingsRequest.type, handleFetchMonthlySpendings);
  yield takeLatest(updateMonthlySpendingRequest.type, handleUpdateMonthlySpending);
  yield takeLatest(addMonthlySpendingRequest.type, handleAddMonthlySpending);
}
