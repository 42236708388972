import axios from 'axios';
import imageCompression from 'browser-image-compression';

import {
  LocationDetails, BasicDetails, InternalStructureDetails,
  ExternalStructureDetails, AssetFeatures, AssetUpdate, AssetTokenization,
  ITokenAvailabilityDetails,
  PurchaseHistory
} from './types';
import config from '../../config/config';
import { compressImage } from '../../app/common/utils';

const { apiUrl } = config;


const API_BASE_URL = apiUrl;

export const saveLocationDetailsApi = async (locationDetails: any, _id: string) => {
  const response = await axios.put(`${API_BASE_URL}/asset/address/${_id}`, locationDetails);
  return response.data;
};

export const getLocationDetailsApi = async (_id: string) => {
  const response = await axios.get(`${API_BASE_URL}/asset/address/${_id}`);
  return response;
};

export const saveBasicDetailsApi = async (basicDetails: any, _id: string) => {
  const formData = new FormData();
  formData.append('assetDescription', basicDetails.assetDescription);
  basicDetails.images.forEach((image: any, index: number) => {
    formData.append(`image${index + 1}`, image);
  });
  const response = await axios.post(`${API_BASE_URL}/asset/description/${_id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const saveAssetDescriptionApi = async (assetTitle: string, assetDescription: string, _id: string) => {
  const response = await axios.post(`${API_BASE_URL}/asset/description/${_id}`, {
    description: assetDescription,
    assetTitle: assetTitle
  });
  return response.data;
};

export const getBasicDetailsApi = async (_id: string) => {
  const response = await axios.get(`${API_BASE_URL}/asset/basic-details/${_id}`, { withCredentials: true });
  return response;
};

// export const saveInternalStructureDetailsAPi = async (internalStructureDetails: InternalStructureDetails, assetID: string) => {
//     const response = await axios.post(`/api/save-internal-structure/${assetID}`, internalStructureDetails);
//     return response.data;
//   };

export const saveInternalStructureDetailsAPi = async (data: any) => {
  const response = await axios.post(`${API_BASE_URL}/asset/internal-structure/${data._id}`, data.internalStructureDetails);
  return response.data;
};

export const getInternalStructureDetailsApi = async (assetID: string) => {
  const response = await axios.get(`${API_BASE_URL}/asset/internal-structure/${assetID}`);
  return response;
};

export const saveRentDetailsApi = async (rentDetails: any, _id: string) => {
  console.log(rentDetails);
  const response = await axios.post(`${API_BASE_URL}/asset-rental`, rentDetails);
  console.log(response);
  return response.data;
};

export const getRentDetailsApi = async (assetID: string) => {
  const response = await axios.get(`${API_BASE_URL}/asset-rental/rentals/${assetID}`);
  console.log('getRentDetailsApi ', response);
  return response;
}

export const getTokenOfferingDetailsApi = async (assetID: string) => {
  const response = await axios.get(`${API_BASE_URL}/asset-offering/by-asset/${assetID}`);
  return response;
};

export const saveTokenOfferingDetailsApi = async (data: { tokenOfferingDetails: any; assetID: string; _id: string }) => {
  const response = await axios.post(`${API_BASE_URL}/asset-offering`, data.tokenOfferingDetails);
  return response.data;
};

export const getExternalStructureDetailsApi = async (assetId: string) => {
  const response = await axios.get(`${API_BASE_URL}/asset/external-structure/${assetId}`);
  return response;
};

export const saveExternalStructureDetailsApi = async (data: any) => {
  console.log('data.ExternalStructureDetails ', data);
  const response = await axios.post(`${API_BASE_URL}/asset/external-structure/${data._id}`, data.externalStructureDetails);
  return response.data;
};

export const saveExternalStructureDetailsApi2 = async (ex: ExternalStructureDetails, _id: string) => {
  console.log('data.ExternalStructureDetails ', ex);
  const response = await axios.put(`${API_BASE_URL}/asset/external-structure/${_id}`, ex);
  return response.data;
};

export const getPurchaseDetailsApi = async (assetId: string) => {
  const response = await axios.get(`${API_BASE_URL}/asset-purchase-details/${assetId}`);
  return response;
};

export const savePurchaseDetailsApi = async (data: { purchaseDetails: any; assetID: string; _id: string }) => {
  console.log(data);
  const response = await axios.post(`${API_BASE_URL}/asset-purchase-details`, data.purchaseDetails);
  return response.data;
};

export const getTokenDetailsApi = async (assetId: string) => {
  console.log('1984 getTokenDetailsApi:', assetId);
  const response = await axios.get(`${API_BASE_URL}/asset-token-details/${assetId}`);
  console.log('1984 getTokenDetailsApi:', response);
  return response;
};

export const saveTokenDetailsApi = async (data: { tokenDetails: any; assetID: string; _id: string }) => {
  console.log(data);
  const response = await axios.post(`${API_BASE_URL}/asset-token-details/createOrUpdate`, data.tokenDetails);
  return response.data;
};

export const getValuationDetailsApi = async (assetId: string) => {
  const response = await axios.get(`${API_BASE_URL}/asset-valuation/${assetId}`);
  return response;
};

export const saveValuationDetailsApi = async (details: any) => {
  /*
  const formData = new FormData();
  formData.append('valuationCost', details.valuationDetails.valuationCost);
  formData.append('valuationDate', details.valuationDetails.valuationDate);
  formData.append('valuationStatus', details.valuationDetails.valuationStatus);
  formData.append('valuationCompany', details.valuationDetails.valuationCompany);
  formData.append('assetID', details.valuationDetails.assetID);
  //formData.append('_id', details._id);

  console.log('Saving valuation details:', details);
  console.log('Saving valuation formData:', formData);
  */

  const response = await axios.post(`${API_BASE_URL}/asset-valuation/create`, details.valuationDetails);
  return response.data;
};

export const publishAssetApi = async (assetId: string) => {
  const response = await axios.post(`${API_BASE_URL}/asset/publish/${assetId}`);
  return response.data;
};

export const unpublishAssetApi = async (assetId: string) => {
  const response = await axios.post(`${API_BASE_URL}/asset/unpublish/${assetId}`);
  return response.data;
};

export const uploadImageApi = async ({ file, assetID, _id }: { file: File, assetID: string, _id: string }) => {
  console.log('uploadImageApi Uploading image:', file, assetID, _id);

  const formData = new FormData();
  const compressImg = await compressImage(file);

  const convertFileToBinary = (file: File): Promise<ArrayBuffer> => {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }

  let binaryData = await convertFileToBinary(compressImg)
  const blob = new Blob([binaryData], { type: file.type }); // file.type helps retain original MIME type

  formData.append('assetID', _id);
  formData.append('_id', _id);
  formData.append('file', blob, file.name)
  // let bodyObject = {
  //   fileName: file?.name?.replace(/\.[^/.]+$/, ""),
  //   file: binaryData,
  //   assetID: _id,
  //   _id: _id,
  // }

  // console.log('uploadImageApi formData:', bodyObject);
  const response = await axios.post(`${API_BASE_URL}/asset/upload/${_id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export const uploadImageApi2 = async (frmData: FormData, assetID: string) => {
  console.log('uploadImageApi Uploading image:', frmData);
  /*
  const formData = new FormData();
  formData.append('file', file);
  formData.append('assetID', _id);
  formData.append('_id', _id);

  console.log('uploadImageApi formData:', formData);
  */
  const response = await axios.post(`${API_BASE_URL}/asset/upload/${assetID}`, frmData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export const uploadValuationDocumentApi = async (data: { document: File, assetID: string, _id: string }) => {
  console.log('uploadValuationDocumentApi Uploading document:', data);


  const response = await axios.post(`${API_BASE_URL}/asset-valuation/doc-upload/${data.assetID}`, data.document, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export const saveFinancialDetailsApi = async (data: { financialDetails: any; assetID: string; _id: string }) => {
  console.log(data);
  const response = await axios.post(`${API_BASE_URL}/asset-financials/createOrUpdate`, data.financialDetails);
  return response.data;
};

export const getFinancialDetailsApi = async (assetId: string) => {
  const response = await axios.get(`${API_BASE_URL}/asset-financials/${assetId}`);
  return response;
};

export const saveAssetFeaturesApi = async (data: { assetFeatures: any; assetID: string; _id: string }) => {
  const response = await axios.post(`${API_BASE_URL}/asset-features/createOrUpdate`, data.assetFeatures);
  return response.data;
};

export const getAssetFeaturesApi = async (assetID: string) => {
  const response = await axios.get(`${API_BASE_URL}/asset-features/${assetID}`);
  return response;
};

export const updateMonthlyRentDetailsApi = async (monthlyRentDetails: any) => {
  const response = await axios.post(`${API_BASE_URL}/monthly-rent-details/${monthlyRentDetails._id}`,
    monthlyRentDetails.monthlyRentDetails);
  return response;
};

export const getAllMonthlyRentDetailsApi = async (assetID: string) => {
  const response = await axios.get(`${API_BASE_URL}/monthly-rent-details/${assetID}`);
  console.log('getAllMonthlyRentDetailsApi:', response);
  return response;
};

export const getMonthlyRentDetailsByYearMonthApi = async ({ assetID, year, month }: { assetID: string, year: number, month: string }) => {
  //TODO: try to use query params instead of object
  //const response = await axios.get(`${API_BASE_URL}/monthly-rent-details/search?assetID=${assetID}&year=${year}&month=${month}`);
  const response = await axios.get(`${API_BASE_URL}/monthly-rent-details/${assetID}/${year}/${month}`);
  return response;
};

export const getAssetUpdatesApi = async (assetId: string) => {
  const response = await axios.get(`${API_BASE_URL}/asset-updates/${assetId}`);
  console.log('getAssetUpdatesApi: response', response);
  return response;
};

export const saveAssetUpdateApi = async (data: any) => {
  console.log('saveAssetUpdateApi:', data);
  const response = await axios.post(`${API_BASE_URL}/asset-updates/`, { ...data });
  return response.data;
};

export const getPurchasePriceApi = async (assetID: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/asset-purchase-details/purchase-price/${assetID}`);
    return response;
  } catch (error) {
    //throw new Error(error?.response?.data?.message || 'Failed to fetch purchase price');
    throw new Error('Failed to fetch purchase price');
  }
};

export const getActiveTokenPriceApi = async (assetID: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/asset-tokenization/get-active-token-price/${assetID}`);
    console.log('getActiveTokenPriceApi:', response);
    return response;
  } catch (error) {
    //throw new Error(error?.response?.data?.message || 'Failed to fetch purchase price');
    throw new Error('Failed to fetch purchase price');
  }
};

export const getInitialTokenPriceApi = async (assetID: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/asset-tokenization/get-initial-token-price/${assetID}`);
    console.log('getInitialTokenPriceApi:', response);
    return response;
  } catch (error) {
    //throw new Error(error?.response?.data?.message || 'Failed to fetch purchase price');
    throw new Error('Failed to fetch purchase price');
  }
};

// Function to get token price history
export const getTokenPriceHistoryApi = async (assetID: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/asset-tokenization/token-price-history/${assetID}`);
    console.log('getTokenPriceHistoryApi:', response);
    return response;
  } catch (error) {
    throw new Error('Failed to fetch token price history');
  }
};

export const getTokenPriceApi = async (assetID: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/asset-purchase-details/purchase-price/${assetID}`);
    return response;
  } catch (error) {
    //throw new Error(error?.response?.data?.message || 'Failed to fetch purchase price');
    throw new Error('Failed to fetch purchase price');
  }
};

export const setTokenPriceApi = async ({ assetID, assetTokenDetails, purchasePrice, initialValuationId }: {
  assetID: string;
  assetTokenDetails: AssetTokenization, purchasePrice: number, initialValuationId: string
}) => {
  console.log('setTokenPriceApi:', assetID, assetTokenDetails, purchasePrice, initialValuationId);
  try {
    const response = await axios.post(`${API_BASE_URL}/asset-tokenization/set-token-price`,
      { assetID, assetTokenDetails, purchasePrice, initialValuationId }
    );
    return response;
  } catch (error) {
    //throw new Error(error?.response?.data?.message || 'Failed to fetch purchase price');
    throw new Error('Failed to fetch purchase price');
  }
};

export const updateTokenPriceApi = async ({ assetID, assetValuationId }: {
  assetID: string;
  assetValuationId: string
}) => {
  console.log('updateTokenPriceApi:', assetID, assetValuationId);
  try {
    const response = await axios.post(`${API_BASE_URL}/asset-tokenization/update-token-price`,
      { assetID, assetValuationId }
    );
    return response;
  } catch (error) {
    //throw new Error(error?.response?.data?.message || 'Failed to fetch purchase price');
    throw new Error('Failed to fetch purchase price');
  }
};

export const getTokenAvailabilityApi = async (assetID: string) => {
  console.log('getTokenAvailabilityApi:', assetID);
  try {
    const response = await axios.get(`${API_BASE_URL}/token-purchase/get-token-availability/${assetID}`);
    return response;
  } catch (error) {
    throw new Error('Failed to fetch token availability details');
  }
};

export const submitPurchaseRequestApi = async (data: {
  assetID: string;
  userId: string;
  numberOfTokens: number;
  purchasePricePerToken: number;
}) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/token-purchase/submit-purchase-request`, data);
    return response.data; // Adjust based on your API response structure
  } catch (error) {
    throw new Error('Failed to submit purchase request');
  }
};



export const getPurchaseHistoryByAssetIDApi = async (assetID: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/token-purchase/purchase-history/${assetID}`);
    return response;
  } catch (error) {
    throw new Error('Failed to fetch purchase history');
  }
};

export const getPurchaseHistoryByAssetIDAndUserIDApi = async (
  assetID: string,
  userID: string
) => {
  console.log('getPurchaseHistoryByAssetIDAndUserIDApi:', assetID, userID);
  try {
    const response = await axios.get(`${API_BASE_URL}/token-purchase/purchase-history/${assetID}/${userID}`);
    console.log('getPurchaseHistoryByAssetIDAndUserIDApi:', response);
    return response;
  } catch (error) {
    throw new Error('Failed to fetch purchase history');
  }
};

export const getTotalTokenPurchaseByAssetID = async (assetID: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/token-purchase/total-tokens-purchased/${assetID}`);
    return response;
  } catch (error) {
    throw new Error('Failed to fetch total token purchase count');
  }
};

