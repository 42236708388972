import React from 'react';
import styles from './AssetSlicesNFTHolder.module.css';
import AssetSlicesNFTHeader from './AssetSlicesNFTHeader';
import NFTGreenPassView from './NFTGreenPassView';
import NFTPurplePassView from './NFTPurplePassView';
import NFTGoldenPassView from './NFTGoldenPassView';
import NFTCardList from './NFTCardList';

const AssetSlicesNFTHolder: React.FC = () => {
  return (
    <div className={styles.container}>
      <AssetSlicesNFTHeader />
      <NFTGreenPassView
        imageUrl="https://lenark-homepage.s3.us-east-1.amazonaws.com/Connoisseur.webp"
        alignImage="right"
      />
      <NFTPurplePassView
        imageUrl="https://lenark-homepage.s3.us-east-1.amazonaws.com/Aficionado.webp"
        alignImage="left"
      />
      <NFTGoldenPassView
        imageUrl="https://lenark-homepage.s3.us-east-1.amazonaws.com/Novice.webp"
        alignImage="right"
      />

      {/* <NFTCardList /> */}
    </div>
  );
};

export default AssetSlicesNFTHolder;
