// src/components/UserPrivateRouteProps.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isTokenExpired, getStoredValue } from '../../utils/tokenUtils';


interface UserPrivateRouteProps {
  // You can define any additional props here if needed
}

const UserPrivateRoute: React.FC<UserPrivateRouteProps> = () => {
  console.log('UserPrivateRoute');
  // const accessToken = localStorage.getItem('accessToken');
  const accessToken = getStoredValue('accessToken');
  
  console.log('accessToken ', accessToken);

  // const isTokenExpired = (token: string): boolean => {
  //   // Implement your logic to check if the token is expired
  //   // For now, let's assume this function returns a boolean
  //   return false; // Replace with actual expiration check logic
  // };

  console.log('isTokenExpired(accessToken) ', isTokenExpired())

  return accessToken && !isTokenExpired() ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default UserPrivateRoute;