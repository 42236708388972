// src/components/PrivateRoute.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from '../components/LoginModule/Login';
import RegisterPage from '../components/LoginModule/Register';
import DashboardPage from '../components/Dashboard/Dashboard';
import Marketplace from '../components/Marketplace/Marketplace';
import HowItWorks from '../components/HowItWorks/HowItWorks';
import MyAccount from '../components/MyAccount/MyAccount';
import AccountDetails from '../components/AccountDetails/AccountDetails';
import Portfolio from '../components/Portfolio/Portfolio';
import Orders from '../components/Orders/Orders';
import DetailedView from '../components/DetailedView/DetailedView';
import CreateAsset from '../components/Admin/CreateAsset/CreateAsset';
import AdminPage from '../components/Admin/AdminPage/AdminPage';
import AdminDashboard from '../components/Admin/AdminDashboard/AdminDashboard';
import UnpublishedEditor from '../components/Admin/UnpublishedEditor/UnpublishedEditor';
import AssetPage from '../components/Admin/AssetDetailViewer/AssetPage/AssetPage';
import PublishedViewer from '../components/Admin/PublishedViewer/PublishedViewer';
import HomePage from '../components/HomePage/HomePage';
import UserPage from '../components/Users/UserPage/UserPage';
import UserDashboard from '../components/Users/UserContent/UserContent';
import AssetHolder from '../components/Users/AssetHolder/AssetHolder';
import AssetPageBase from '../components/AssetViewer/AssetDetailViewer/AssetPageNew/AssetPageBase';
import LoginWrapper from '../components/LoginModule/LoginWrapper';

import UserPrivateRoute from './UserPrivateRoute';
import AdminPrivateRoute from './AdminPrivateRoute';
import HomeRoute from './HomeRoute';
import AssetSliceToken from '../components/AssetSliceToken/AssetSliceToken';
import AssetManagementViewer from '../components/Admin/AssetManagement/AssetManagementViewer';
import NFTCardList from '../components/AssetSlicesNFT/NFTCardList';
import UserPortfolio from '../components/UserPortfolio/UserPortfolio';
import UserPageHolder from '../components/UserPage/UserPageHolder/UserPageHolder';
import UserDashboardHolder from '../components/UserDashboard/UserDashboard';
import AddressBook from '../components/UserWallet/AddressBook';
import UserWalletSecurity from '../components/UserWalletSecurity/UserWalletSecurity';
import MyProfile from '../components/UserAccount/MyProfile';
import PasswordReset from '../components/UserAccount/PasswordReset';
import KYCProcess from '../components/Kyc/KYCProcess';
import ReferralBoard from '../components/Referral/ReferralBoard';
import ActivityTimeline from '../components/UserAccount/ActivityTimeline';
import AssetSlicesNFTHolder from '../components/AssetSlicesNFT/AssetSlicesNFTHolder';
import AssetSliceTeam from '../components/AssetSliceTeam/AssetSliceTeam';
import PrivacyPolicy from '../components/HomePage/Policy/PrivacyPolicy/PrivacyPolicy';
import Policy from '../components/HomePage/Policy/Policy';
import CookiePolicy from '../components/HomePage/Policy/CookiePolicy/CookiePolicy';
import TermsAndService from '../components/HomePage/Policy/TermsAndConditions/TermsAndConditions';

const AppRoutes: React.FC = () => {
//   const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  return (
        <Router>
            <Routes>
                {/* <Route path="/home" element={<HomePage />} /> */}
                <Route path="/" element={<HomePage />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
                <Route path="/assetslice-token" element={<AssetSliceToken />} />
                {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}

                <Route path="policy" element={<Policy />} >
                    <Route path="cookie" element={<CookiePolicy />} />
                    <Route path="privacy" element={<PrivacyPolicy />} />
                    <Route path="terms-and-services" element={<TermsAndService />} />
                </Route>

                <Route path="/signup" element={<LoginWrapper />} />
                <Route path="/register" element={<LoginWrapper />} />
                <Route path="/forgot-password" element={<LoginWrapper />} />
                <Route path="/reset-password/:rt" element={<LoginWrapper />} />
                <Route path="/email-verification/:vt" element={<LoginWrapper />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
                <Route path="/assetslice-token" element={<AssetSliceToken />} />
                <Route path="/assetslice-team" element={<AssetSliceTeam />} />
                {/* <Route path="/dashboard" element={<DashboardPage />} >
                    <Route path="marketplace" element={<Marketplace />} />
                    <Route path="how-it-works" element={<HowItWorks />} />
                    <Route path="asset-view" element={<DetailedView />} />
                    <Route path="my-account" element={<MyAccount />} >
                        <Route path="account-details" element={<AccountDetails />} /> 
                        <Route path="portfolio" element={<Portfolio />} /> 
                        <Route path="Orders" element={<Orders />} /> 
                    </Route> 
                    <Route path="my-account-1" element={<MyAccount />} >
                        <Route path="account-details" element={<AccountDetails />} /> 
                        <Route path="portfolio" element={<Portfolio />} /> 
                        <Route path="Orders" element={<Orders />} /> 
                    </Route>            
                    <Route path="create-asset" element={<CreateAsset />} />
                </Route> */}

                <Route element={<UserPrivateRoute />}>
                    <Route path="/me" element={<UserPageHolder />} >
                        <Route path="dashboard" element={<UserDashboardHolder />} />
                        <Route path="nft" element={<AssetSlicesNFTHolder />} />
                        <Route path="marketplace" element={<Marketplace />} />
                        <Route path="portfolio" element={<UserPortfolio />} />
                        <Route path="wallerSecurity" element={<UserWalletSecurity />} />
                        <Route path="walletAddressBook" element={<AddressBook />} />
                        <Route path="accountMyProfile" element={<MyProfile />} />
                        <Route path="accountKyc" element={<KYCProcess />} />
                        <Route path="accountPasswordReset" element={<PasswordReset />} />
                        <Route path="accountActivityTimeline" element={<ActivityTimeline />} />
                        <Route path="accountReferralProgram" element={<ReferralBoard />} />
                        <Route path="assetv/:asset_id" element={<AssetPageBase />} />  
                    </Route>
                </Route>

            {/* <Route element={<UserPrivateRoute />}>
                <Route path="/me1" element={<UserPage />} >
                    <Route path="ms" element={<MainSection />} >
                        <Route path="dashboard" element={<UserDashboard />} />
                        <Route path="nft" element={<NFTCardList />} />
                        <Route path="marketplace" element={<Marketplace />} />
                        <Route path="portfolio" element={<UserPortfolio />} />
                    </Route>
                    <Route path="dashboard" element={<UserDashboard />} />
                    <Route path="assetv/:asset_id" element={<AssetPageBase />} />  
                </Route>
            </Route> */}

            {/* <Route element={<AdminPrivateRoute />}>
                <Route path="/admin" element={<AdminPage />} >
                    <Route path="dashboard" element={<AdminDashboard />} />
                    <Route path="unpublished-editor/:pid/:aid" element={<UnpublishedEditor />} />
                    <Route path="published-viewer" element={<PublishedViewer />} />
                    <Route path="asset-management/:pid/:aid" element={<AssetManagementViewer />} />
                </Route>
            </Route>     */}

                <Route element={<HomeRoute />}>
                    <Route path="/login" element={<LoginWrapper />} />
                </Route>

            </Routes>
        </Router>
  )
};

export default AppRoutes;
