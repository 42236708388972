// src/components/AssetDetailEditor/ProgressIndicator.tsx
import React, { useEffect, useState } from 'react';
import styles from './ProgressIndicator.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';

interface ProgressIndicatorProps {
  currentStepProp: string;
  completedStepProp: string[];
}

const TransactionSteps = {
  APPROVAL: 'Approval',
  PURCHASE: 'Purchasing token',
  // COMPLETED: 'Completed',
};

const TransactionStatus = {
  IDLE: 'IDLE',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
};

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({ currentStepProp, completedStepProp }) => {
  const [stepStatus, setStepStatus] = useState({
    APPROVAL: TransactionStatus.IDLE,
    PURCHASE: TransactionStatus.IDLE,
    COMPLETED: TransactionStatus.IDLE,
  });

  useEffect(() => {
    const updateStepStatus = () => {
      const updatedStatus: any = { ...stepStatus };

      Object.keys(TransactionSteps).forEach((step) => {
        if (completedStepProp.includes(TransactionSteps[step as keyof typeof TransactionSteps])) {
          updatedStatus[step] = TransactionStatus.COMPLETED;
        } else if (currentStepProp === TransactionSteps[step as keyof typeof TransactionSteps]) {
          updatedStatus[step] = TransactionStatus.PROCESSING;
        } else {
          if (updatedStatus[step] == TransactionStatus.COMPLETED) {
            updatedStatus[step] = TransactionStatus.COMPLETED;
          } else {
            updatedStatus[step] = TransactionStatus.IDLE;
          }
        }
      });
      setStepStatus(updatedStatus);
    };

    updateStepStatus();
  }, [currentStepProp, completedStepProp]);

  return (
    <div className={styles.progressContainer}>
      {Object.keys(TransactionSteps).map((stepKey) => {
        const step = TransactionSteps[stepKey as keyof typeof TransactionSteps];
        const status = stepStatus[stepKey as keyof typeof stepStatus];

        return (
          <div
            key={step}
            className={`${styles.progressStep} ${status === TransactionStatus.PROCESSING ? styles.activeStep : ''} ${status === TransactionStatus.COMPLETED ? styles.completedStep : ''}`}
          >
            {status === TransactionStatus.COMPLETED ? (
              <FontAwesomeIcon icon={faCheckCircle} className={styles.checkIcon} />
            ) : status === TransactionStatus.PROCESSING ? (
              <FontAwesomeIcon icon={faSpinner} className={`${styles.spinnerIcon} ${styles.processingIcon}`} spin />
            ) : ( '')}
            <span className={styles.stepName}>{step}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ProgressIndicator;
