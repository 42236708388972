import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  Title, // Import the Title plugin
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the datalabels plugin
import { Line } from 'react-chartjs-2';
import { useMediaQuery } from 'react-responsive';
import styles from './InvestmentAppreciationGraph.module.css';

// Register necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  Title,
  ChartDataLabels // Register the datalabels plugin
);

const InvestmentAppreciationGraph: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const data = {
    labels: ['2021', '2022', '2023', '2024'], // X-axis labels
    datasets: [
      {
        label: 'Data',
        data: [1.3, 1.3, 1.3, 1.5], // Data points
        borderColor: '#4CAF50', // Line color
        backgroundColor: 'rgba(76, 175, 80, 0.2)', // Fill color under the line
        fill: true, // Enable area fill
        pointRadius: 6, // Size of data points
        pointBackgroundColor: '#4CAF50', // Point color
        tension: 0.4, // Smooth curve
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allow the chart to take up the full container size
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context: any) => `${context.raw}K`, // Display "K" after the value
        },
      },
      title: {
        display: false, // Hide the title
      },
      datalabels: {
        anchor: 'end' as 'end', // Ensure the type matches `Align`
        align: 'top' as 'top', // Ensure the type matches `Align`
        color: '#000', // Label color
        font: {
          size: isMobile ? 8 : 12, // Label font size
          weight: 'bold' as 'bold',
        },
        formatter: (value: number) => `${value}K`, // Format the label with "K"
      },
      legend: {
        labels: {
          font: {
            size: isMobile ? 10 : 12, // Adjust font size for legend labels
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide vertical grid lines
        },
        ticks: {
          color: '#000',
          font: {
            size: isMobile ? 8 : 12, // Adjust font size for mobile
          },
        },
      },
      y: {
        ticks: {
          stepSize: 0.3, // Steps in Y-axis
          color: '#000',
          font: {
            size: isMobile ? 8 : 12, // Adjust font size for mobile
          },
        },
        min: 0, // Start from 0
        max: 3.0, // Y-axis maximum value
      },
    },
    layout: {
      padding: 0, // Remove padding around the chart
    },
  };

  return (
    <div className={styles.chartContainer} style={{ height: '100%', width: '100%' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default InvestmentAppreciationGraph;