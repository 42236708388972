// src/components/AssetDetailEditor/AssetFeatures.tsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './AssetFeatures.module.css';

export interface FeatureProps {
  name: string;
  value: boolean;
}

interface AssetFeaturesProps {
  // _id: string;
  // assetID: string;
  cooling: boolean;
  heating: boolean;
  fireAlarm: boolean;
  gym: boolean;
  balcony: boolean;
  modernKitchen: boolean;
  pool: boolean;
  elevator: boolean;
  petFriendly: boolean;
  storage: boolean;
  laundry: boolean;
  dishWasher: boolean;
}

const AssetFeatures: React.FC<AssetFeaturesProps> = ({
  // _id,
  // assetID,
  cooling,
  heating,
  fireAlarm,
  gym,
  balcony,
  modernKitchen,
  pool,
  elevator,
  petFriendly,
  storage,
  laundry,
  dishWasher,
}) => {
  const features = [
    { name: 'Cooling', value: cooling },
    { name: 'Heating', value: heating },
    { name: 'Fire Alarm', value: fireAlarm },
    { name: 'Gym', value: gym },
    { name: 'Balcony', value: balcony },
    { name: 'Modern Kitchen', value: modernKitchen },
    { name: 'Pool', value: pool },
    { name: 'Elevator', value: elevator },
    { name: 'Pet Friendly', value: petFriendly },
    { name: 'Storage', value: storage },
    { name: 'Laundry', value: laundry },
    { name: 'Dish Washer', value: dishWasher },
  ];

  return (
    <div className={styles.featuresContainer}>
      <h2 className={styles.featuresHeading}>Features</h2>
      <div className={styles.featuresGrid}>
        {features.map((feature, index) => (
          feature.value && (
            <div key={index} className={styles.featureItem}>
              <FontAwesomeIcon icon={faCheck} className={styles.featureIcon} />
              <span className={styles.featureText}>{feature.name}</span>
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default AssetFeatures;
