// src/features/messaging/messagingSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IMessage {
    id: string;
    userID: string;
    content: string;
}

interface MessagingState {
  messages: IMessage[];
  threads: any[];
  loading: boolean;
  error: string | null;
}

const initialState: MessagingState = {
  messages: [],
  threads: [],
  loading: false,
  error: null,
};

const messagingSlice = createSlice({
  name: 'messaging',
  initialState,
  reducers: {
    createMessageRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    createMessageSuccess: (state, action: PayloadAction<IMessage>) => {
      state.loading = false;
      state.messages.push(action.payload);
    },
    createMessageFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    markMessageAsReadRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    markMessageAsReadSuccess: (state, action: PayloadAction<IMessage>) => {
      state.loading = false;
      const index = state.messages.findIndex(m => m.id === action.payload.id);
      if (index !== -1) {
        state.messages[index] = action.payload;
      }
    },
    markMessageAsReadFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getMessagesRequest: (state, action: PayloadAction<{ userId: string, assetId: string }>) => {
      state.loading = true;
    },
    getMessagesSuccess: (state, action: PayloadAction<IMessage[]>) => {
      state.loading = false;
      state.messages = action.payload;
    },
    getMessagesFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getMessageThreadsRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    getMessageThreadsSuccess: (state, action: PayloadAction<any[]>) => {
      state.loading = false;
      state.threads = action.payload;
    },
    getMessageThreadsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  createMessageRequest,
  createMessageSuccess,
  createMessageFailure,
  markMessageAsReadRequest,
  markMessageAsReadSuccess,
  markMessageAsReadFailure,
  getMessagesRequest,
  getMessagesSuccess,
  getMessagesFailure,
  getMessageThreadsRequest,
  getMessageThreadsSuccess,
  getMessageThreadsFailure,
} = messagingSlice.actions;

export default messagingSlice.reducer;
