import React from 'react';
import styles from './CookiePolicy.module.css';

const CookiePolicy = () => {
  return (


    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <header className={styles.header}>
            <h1 className={styles.mainTitle}>AssetSlices Cookie Policy</h1>
            {/* <p className={styles.subTitle}> Cookie &amp; Conditions</p> */}
        </header>      
      </div>
      {/* <h1 className={styles.heading}>Cookie Policy for AssetSlices</h1> */}
      <p><strong>Effective Date:</strong> 24 Dec 2024</p>
      <p><strong>Last Updated:</strong> 24 Dec 2024</p>

      <h2 className={styles.subheading}>1. Introduction</h2>
      <p>Welcome to AssetSlices ("we," "our," or "us"). This Cookie Policy explains how we use cookies and similar technologies on our website, www.assetslices.com ("Website"). By using our Website, you consent to the use of cookies as described in this policy.</p>

      <h2 className={styles.subheading}>2. What Are Cookies?</h2>
      <p>Cookies are small text files placed on your device (computer, smartphone, or other devices) when you visit our Website. They help us recognize your device, store preferences, and enhance your browsing experience.</p>

      <h2 className={styles.subheading}>3. Types of Cookies We Use</h2>
      <ul className={styles.list}>
        <li><strong>Essential Cookies:</strong> These cookies are necessary for the Website's functionality and cannot be disabled. They allow basic features such as security and session management.</li>
        <li><strong>Performance Cookies:</strong> These cookies help us understand how visitors interact with our Website by collecting data such as pages visited and time spent on the site. This information helps us improve our services.</li>
        <li><strong>Functional Cookies:</strong> These cookies enable enhanced functionality and personalization, such as remembering your preferences and settings.</li>
        <li><strong>Targeting/Advertising Cookies:</strong> We use these cookies to deliver relevant advertisements based on your browsing history and interactions.</li>
      </ul>

      <h2 className={styles.subheading}>4. Cookies We Use</h2>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Cookie Name</th>
            <th>Purpose</th>
            <th>Expiration</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>SessionID</td>
            <td>Session management</td>
            <td>End of session</td>
          </tr>
          <tr>
            <td>AnalyticsID</td>
            <td>Site analytics tracking</td>
            <td>6 months</td>
          </tr>
          <tr>
            <td>Preferences</td>
            <td>User preferences</td>
            <td>1 year</td>
          </tr>
          <tr>
            <td>AdTracker</td>
            <td>Advertising tracking</td>
            <td>3 months</td>
          </tr>
        </tbody>
      </table>

      <h2 className={styles.subheading}>5. Managing Cookies</h2>
      <p>You can manage cookies through your browser settings. Most browsers allow you to refuse or delete cookies. Please note that disabling certain cookies may impact your experience on our Website.</p>

      <h2 className={styles.subheading}>6. How Can I Control Cookies?</h2>
      <p>You have the right to control cookies at any time. Here’s how:</p>
      <ul className={styles.list}>
        <li><strong>Browser Settings:</strong> Adjust your browser settings to accept, refuse, or delete cookies. Each browser has different cookie management settings, so consult your browser’s help section for detailed instructions.</li>
        <li><strong>Opt-Out Links:</strong> Some third-party services we use provide opt-out mechanisms. Visit their websites to learn more.</li>
        <li><strong>Do Not Track Settings:</strong> Enable "Do Not Track" in your browser if supported. However, not all websites respond to this signal.</li>
      </ul>

      <h2 className={styles.subheading}>7. Third-Party Cookies</h2>
      <p>We may use third-party service providers for analytics, advertising, and social media integration. These third parties may set their own cookies, governed by their privacy policies.</p>

      <h2 className={styles.subheading}>8. Changes to This Cookie Policy</h2>
      <p>We reserve the right to update this Cookie Policy at any time. Any changes will be posted on this page with an updated effective date.</p>

      <h2 className={styles.subheading}>9. Contact Us</h2>
      <p>If you have any questions about this Cookie Policy, please contact us at:</p>
      <address>
        <strong>AssetSlices</strong><br />
        Email: <a href="mailto:support@assetslices.com">support@assetslices.com</a><br />
        {/* Address: [Insert Company Address] */}
      </address>

      <p>By continuing to use our Website, you accept the terms of this Cookie Policy.</p>
    </div>
  );
};

export default CookiePolicy;
