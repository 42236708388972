
// export const _isTokenExpired = (token: string): boolean => {
//     // Implement your logic to check if the token is expired
//     // For now, let's assume this function returns a boolean
//     //return false; // Replace with actual expiration check logic

//     const payload = JSON.parse(atob(token.split('.')[1]));
//     const expirationTime = payload.exp * 1000;
//     const tokenExpired = Date.now() > expirationTime;
//     return tokenExpired;
  
//   };

  export const accessTokenExpired = (token: string): boolean => {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const expirationTime = payload.exp * 1000;
    const tokenExpired = Date.now() > expirationTime;
    return tokenExpired;
  };

  const clearTokenFromStorage = () => {
    // SessionStorage
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('userID');
    sessionStorage.removeItem('userRole');
    // LocalStorage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userID');
    localStorage.removeItem('userRole');
  }

//   export const _isTokenExpired2 = (token: string): boolean => {
//     // Implement your logic to check if the token is expired
//     // For now, let's assume this function returns a boolean
//     console.log('in fn _isTokenExpired2');
//     const payload = JSON.parse(atob(token.split('.')[1]));
//     const expirationTime = payload.exp * 1000;
//     const tokenExpired = Date.now() > expirationTime;
//     console.log('actual tokenExpired ', tokenExpired);
//     if (tokenExpired) {
//       // SessionStorage
//       sessionStorage.removeItem('accessToken');
//       sessionStorage.removeItem('userID');
//       sessionStorage.removeItem('userRole');
//       // LocalStorage
//       localStorage.removeItem('accessToken');
//       localStorage.removeItem('refreshToken');
//       localStorage.removeItem('userID');
//       localStorage.removeItem('userRole');
//     }


//     return false; // Replace with actual expiration check logic
//   };

export const getStoredValue = (key: string): string => {
    const value = localStorage.getItem(key) ?? '';
    return value;
}

export const isTokenExpired = () => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken') ?? '';

    if (accessToken) {
        // check if token is valid
        if (accessTokenExpired(accessToken)) {
            console.log('accessTokenExpired true');            
          // Attempt to refresh the token
          refreshAccessToken(refreshToken).then(newToken => {
            console.log('response from refreshAccessToken ', newToken);
            if (newToken) {
              // Store the new access token
              localStorage.setItem('accessToken', newToken);
              return false;
            } else {
              // Redirect to login page
              clearTokenFromStorage();
              redirectToLogin();
            }
          });
        }
        else {
            console.log('accessTokenExpired false');
            return false;
        }
    }
    else {
        // No access token found, redirect to login
        redirectToLogin();
    }


}


// export const isTokenExpired3 = (token: string) => {
//     const accessToken = localStorage.getItem('accessToken');
//     const refreshToken = localStorage.getItem('refreshToken') ?? '';

//     if (accessToken) {
//         // Check if the token is valid
//         if (_isTokenExpired(accessToken)) {
//           // Attempt to refresh the token
//           refreshAccessToken(refreshToken).then(newToken => {
//             console.log('response from refreshAccessToken ', newToken);
//             if (newToken) {
//               // Store the new access token
//               localStorage.setItem('accessToken', newToken);
//               return false;
//             } else {
//               // Redirect to login page
//               redirectToLogin();
//             }
//           });
//         } else {
//           // The token is valid, proceed with the session
//           return false;
//         }
//       } else {
//         // No access token found, redirect to login
//         redirectToLogin();
//       }
// }

const redirectToLogin = () => {
    window.location.href = '/login';
}

async function refreshAccessToken(refreshToken: string): Promise<string | null> {
    try {
      const url = "http://localhost:3000/users/refresh-token";
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refreshToken }),
      });
  
      if (response.ok) {
        const data = await response.json();
        return data.accessToken;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Failed to refresh access token:', error);
      return null;
    }
  }
  