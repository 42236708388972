import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserAddressRequest } from '../../../features/userProfile/userProfileSlice'; // Import the action from your slice
import styles from './AddressSection.module.css';
import { RootState } from '../../../store/rootReducer';

interface AddressSectionProps {
  address: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  country: string;
}

const AddressSection: React.FC<AddressSectionProps> = ({
  address,
  addressLine2,
  city,
  state,
  zip,
  county,
  country
}) => {
  const dispatch = useDispatch();
  
  // Local state to handle form inputs, initialized with the passed props
  const [localAddress, setLocalAddress] = useState(address);
  const [localAddressLine2, setLocalAddressLine2] = useState(addressLine2);
  const [localCity, setLocalCity] = useState(city);
  const [localState, setLocalState] = useState(state);
  const [localZip, setLocalZip] = useState(zip);
  const [localCounty, setLocalCounty] = useState(county);
  const [localCountry, setLocalCountry] = useState(country);
  
  const [localSuccessMessage, setLocalSuccessMessage] = useState<string | null>(null);
  const [localErrorMessage, setLocalErrorMessage] = useState<string | null>(null);
  
  const { addressSectionSuccessMessage, addressSectionError } = useSelector((state: RootState) => state.userProfile);
  
  const userID = localStorage.getItem('userID') ?? '';

  const handleSaveAddress = () => {
    // Validation check
    if (!localAddress || !localCity || !localState || !localZip || !localCountry) {
      setLocalErrorMessage('Please fill in all required fields.');
      return;
    }
    
    // Create the address object
    const addressData = {
      address1: localAddress,
      address2: localAddressLine2,
      city: localCity,
      state: localState,
      zip: localZip,
      county: localCounty,
      country: localCountry,
    };

    // Dispatch the updateUserAddressRequest action
    dispatch(updateUserAddressRequest({ userID, addressData }));
  };

  // Update local notification state when success or error occurs
  useEffect(() => {
    setLocalSuccessMessage(addressSectionSuccessMessage);
    setLocalErrorMessage(addressSectionError);

    // Clear notifications after 3 seconds
    const timer = setTimeout(() => {
      setLocalSuccessMessage(null);
      setLocalErrorMessage(null);
    }, 3000);

    return () => clearTimeout(timer);
  }, [addressSectionSuccessMessage, addressSectionError]);

  // To ensure the form updates when the props change, we can use useEffect
  useEffect(() => {
    setLocalAddress(address);
    setLocalAddressLine2(addressLine2);
    setLocalCity(city);
    setLocalState(state);
    setLocalZip(zip);
    setLocalCounty(county);
    setLocalCountry(country);
  }, [address, addressLine2, city, state, zip, county, country]);

  return (
    <div className={styles.container}>
      <h2>Address Information</h2>
      
      {/* Success and Error Messages */}
      {localSuccessMessage && <div className={styles.success}>{localSuccessMessage}</div>}
      {localErrorMessage && <div className={styles.error}>{localErrorMessage}</div>}

      <div className={styles.formGroup}>
        <label htmlFor="address">Address</label>
        <input
          type="text"
          id="address"
          value={localAddress}
          onChange={(e) => setLocalAddress(e.target.value)}
          placeholder="Address"
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="addressLine2">Address Line 2</label>
        <input
          type="text"
          id="addressLine2"
          value={localAddressLine2}
          onChange={(e) => setLocalAddressLine2(e.target.value)}
          placeholder="Address Line (if needed)"
        />
      </div>
      <div className={styles.grid}>
        <div className={styles.formGroup}>
          <label htmlFor="city">City</label>
          <input
            type="text"
            id="city"
            value={localCity}
            onChange={(e) => setLocalCity(e.target.value)}
            placeholder="City"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="state">State</label>
          <input
            type="text"
            id="state"
            value={localState}
            onChange={(e) => setLocalState(e.target.value)}
            placeholder="State"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="zip">ZIP</label>
          <input
            type="text"
            id="zip"
            value={localZip}
            onChange={(e) => setLocalZip(e.target.value)}
            placeholder="ZIP"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="county">County</label>
          <input
            type="text"
            id="county"
            value={localCounty}
            onChange={(e) => setLocalCounty(e.target.value)}
            placeholder="County"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="country">Country</label>
          <input
            type="text"
            id="country"
            value={localCountry}
            onChange={(e) => setLocalCountry(e.target.value)}
            placeholder="Country"
          />
        </div>
      </div>
      <button className={styles.saveButton} onClick={handleSaveAddress}>
        Save Address
      </button>
    </div>
  );
};

export default AddressSection;
