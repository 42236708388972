import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Distribution, IDistributionMapping } from '../assetEditor/types'

interface UserDistributionState {
  data: any[];
  totalDividend: number | null;
  distributionMapping: IDistributionMapping[];
  allDistributions: Distribution[];
  totalUnclaimedTokens: number;
  userDistributionDetails: any;
  loading: boolean;
  error: string | null;
}

const initialState: UserDistributionState = {
  data: [],
  totalDividend: null,
  distributionMapping: [],
  allDistributions: [],
  totalUnclaimedTokens: 0,
  userDistributionDetails: {},
  loading: false,
  error: null,
};

const userDistributionSlice = createSlice({
  name: 'userDistribution',
  initialState,
  reducers: {
    fetchTotalDividendRequest: (state, action: PayloadAction<{ assetID: string; userID: string }>) => {
      state.loading = true;
      state.error = null;
    },
    fetchTotalDividendSuccess: (state, action: PayloadAction<number>) => {
      state.loading = false;
      state.totalDividend = action.payload;
    },
    fetchTotalDividendFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchDistributionMappingRequest: (state, action: PayloadAction<{ assetID: string; userID: string }>) => {
      state.loading = true;
      state.error = null;
    },
    fetchDistributionMappingSuccess: (state, action: PayloadAction<IDistributionMapping[]>) =>{
      state.loading = false;
      state.distributionMapping = action.payload;
    },
    fetchDistributionMappingFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchAllDistributionsRequest: (state, action: PayloadAction<{ assetID: string; userID: string }>) => {
      state.loading = true;
      state.error = null;
    },
    fetchAllDistributionsSuccess: (state, action: PayloadAction<Distribution[]>) => {
      console.log('fetchAllDistributionsSuccess ', action.payload);
      state.loading = false;
      state.allDistributions = action.payload;
    },
    fetchAllDistributionsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateClaimStatusRequest: (state, action: PayloadAction<{ userDistributionID: string; claimedStatus: string; claimType: string }>) => {
      state.loading = true;
      state.error = null;
    },
    updateClaimStatusSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data = state.data.map((distribution) =>
        distribution._id === action.payload._id ? action.payload : distribution
      );
    },
    updateClaimStatusFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getTokensToBeClaimedRequest: (state, action: PayloadAction<{ userID: string; assetID: string }>) => {
      state.loading = true;
      state.error = null;
    },
    getTokensToBeClaimedSuccess: (state, action: PayloadAction<number>) => {
      state.loading = false;
      state.totalUnclaimedTokens = action.payload;
    },
    getTokensToBeClaimedFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

    getUserDistributionDetailsRequest(state, action: PayloadAction<{ assetID: string; userDistributionID: string }>) {
      state.loading = true;
      state.error = null;
    },
    getUserDistributionDetailsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.userDistributionDetails = action.payload;
    },
    getUserDistributionDetailsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },    

  },
});

export const {
  fetchTotalDividendRequest,
  fetchTotalDividendSuccess,
  fetchTotalDividendFailure,
  fetchDistributionMappingRequest,
  fetchDistributionMappingSuccess,
  fetchDistributionMappingFailure,
  fetchAllDistributionsRequest,
  fetchAllDistributionsSuccess,
  fetchAllDistributionsFailure,  
  updateClaimStatusRequest, 
  updateClaimStatusSuccess, 
  updateClaimStatusFailure,
  getTokensToBeClaimedRequest,
  getTokensToBeClaimedSuccess,
  getTokensToBeClaimedFailure,
  getUserDistributionDetailsRequest,
  getUserDistributionDetailsSuccess,
  getUserDistributionDetailsFailure,
} = userDistributionSlice.actions;

export default userDistributionSlice.reducer;

