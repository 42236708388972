// src/components/Marketplace.tsx

import React, { useEffect, useState } from 'react';
import styles from './Marketplace.module.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showPublishedAssetsRequest } from '../../../features/asset/assetSlice';
import { RootState } from '../../../store/rootReducer';
import defaultImage from '../../../assets/default.webp';
import Card from './Card';
import { Loader } from '../../common';
import { fetchMarketplaceItemsRequest } from '../../../features/marketplace/marketplaceSlice';

const Marketplace: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { marketplaceItems, loading } = useSelector((state: RootState) => state.marketplace);

  console.log('Marketplace Items:', marketplaceItems);

  useEffect(() => {
    dispatch(fetchMarketplaceItemsRequest());
  }, [dispatch]);

  const maxItemsAtLaunch = 3;

  const [scrollPosition, setScrollPosition] = useState(0);
  const [focusedItem, setFocusedItem] = useState<string | null>(null);

  const handleNextClick = () => {
    console.log('handleNextClick');
    const nextScrollPosition = scrollPosition + 1;
    console.log('nextScrollPosition:', nextScrollPosition);
    setScrollPosition(nextScrollPosition);
    // document.getElementById(`item-${nextScrollPosition}`)?.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start',
    // });
  };

  const handleItemClick = (itemId: string) => {
    navigate(`/me/assetv/${itemId}`);
  };

  return (
    <div className={styles.marketplace}>
      {/* <h2>Marketplace</h2> */}
      <div className={styles.itemsContainer}>
        {loading ? <Loader /> :
          <>
            <div className={styles.itemsGrid}>
              {marketplaceItems?.slice(0, (scrollPosition + 1) * maxItemsAtLaunch).map((item, index) => (
                <div
                  key={item._id}
                  id={`item-${scrollPosition * 3 + index}`}
                  onClick={() => handleItemClick(item._id)}
                  onFocus={() => setFocusedItem(item._id)}
                  onBlur={() => setFocusedItem(null)}
                  tabIndex={0}
                  className={styles.item}
                >

                  <Card
                    assetId={item._id}
                    image={item.images[0] || ''}
                    title={item.assetTitle}
                    purchaseCost={`${item.purchaseCost} ${item.currency}`}
                    investors={item.investors}
                    yearlyInvestmentReturn='NA' // Example value; replace with actual data
                    fundedDate='NA' // Example value; replace with actual data
                    currentValuation='NA' // Example value; replace with actual data
                    status='NA' // Example value; replace with actual data
                    assetStage={item.assetStage}
                    propertyId={item.propertyID} 
                    location={item.city}
                    tokenPrice={item.tokenPrice}
                    saleStartDate={item.saleStartDate}
                    rentalStatus={item.rentalStatus}
                    estimatedROI={item.estimatedROI}
                    preBookingUsers={item.uniquePrebookingUsers}
                    totalTokensPreBooked={item.totalTokensPreBooked}
                  />

                  {/* <img src={item?.image?.length > 0 ? item?.image[0] : defaultImage } alt={item.name} className={styles.image} />
              <div className={styles.itemDetails}>
                <h3 className={styles.itemTitle}>{item.propertyID}</h3>
                <h4 className={styles.assetTitle}>1 Bed in Bloom Tower</h4> 
                <p className={styles.assetCost}>Asset Cost: ${item.assetCost}</p>
                <p className={styles.numInvestors}>Number of Investors: {item.numInvestors}</p>
                <p>{item.streetAddress1} {item.streetAddress2}</p>
                <p>{item.city}</p>
                <p>{item.country}</p>
              </div> */}

                </div>
              ))}
            </div>
            <div className={styles.scrollButtonContainer}>
              {scrollPosition < Math.ceil(marketplaceItems.length / 3) - 1 && (
                <button onClick={handleNextClick} className={styles.scrollButton}>
                  Scroll for more
                </button>
              )}
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default Marketplace;
