import React from 'react';
import styles from './Header.module.css'; // Assuming you are using CSS modules
import { FaBookOpen } from 'react-icons/fa'; // Example Font Awesome icon (for book)

interface HeaderProps {
  title: string;
  icon: React.ReactNode; // Icon passed as a JSX element
  subTitle: string;
}

const Header: React.FC<HeaderProps> = ({ title, icon, subTitle }) => {
  return (
    <div className={styles.headerContainer}>
      {/* Left: Dots or logo */}
      <div className={styles.selectorIcon}>
        {icon}
      </div>

      {/* Left: Title */}
      <div className={styles.title}>
        {title}
      </div>

    { subTitle && 
        <>
            {/* Left: Seperator */}
            <div className={styles.Seperator}>
                {'/'}
            </div>

            {/* Left: SubTitle */}
            <div className={styles.title}>
                {subTitle}
            </div>
        </>
    }


      {/* Right: Book icon */}
      {/* <div className={styles.icon}>
        <FaBookOpen />
      </div> */}
    </div>
  );
};

export default Header;
