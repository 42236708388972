import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WaitingListEntry {
  statusCode: any;
  email: string;
  // Add other fields if your WaitingList object has them
}

interface WaitingListState {
  entries: WaitingListEntry[];
  loading: boolean;
  error: string | null;
  successMessage: string | null;
  statusCode: number;
}

const initialState: WaitingListState = {
  entries: [],
  loading: false,
  error: null,
  successMessage: null,
  statusCode: 0,
};

const waitingListSlice = createSlice({
  name: 'waitingList',
  initialState,
  reducers: {
    // Join waiting list actions
    joinWaitingListRequest: (state, action: PayloadAction<{ emailId: string }>) => {
      state.loading = true;
      state.error = null;
      state.successMessage = null;
    },
    joinWaitingListSuccess: (state, action: PayloadAction<WaitingListEntry>) => {
      const statusCode = action.payload?.statusCode;
      state.loading = false;
      state.entries.push(action.payload); // Add the new entry to the list
      state.successMessage = 'Successfully joined the waiting list!';
      state.statusCode = statusCode;
    },
    joinWaitingListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.statusCode = 500;
    },

    // Fetch all waiting list entries actions
    fetchWaitingListRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.successMessage = null;
    },
    fetchWaitingListSuccess: (state, action: PayloadAction<WaitingListEntry[]>) => {
      state.loading = false;
      state.entries = action.payload;
    },
    fetchWaitingListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Reset messages if needed
    resetWaitingListMessages: (state) => {
      state.successMessage = null;
      state.error = null;
    },
  },
});

export const {
  joinWaitingListRequest,
  joinWaitingListSuccess,
  joinWaitingListFailure,
  fetchWaitingListRequest,
  fetchWaitingListSuccess,
  fetchWaitingListFailure,
  resetWaitingListMessages,
} = waitingListSlice.actions;

export default waitingListSlice.reducer;
