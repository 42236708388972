import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserSecurityState {
  totpData: any;
  totpVerified: boolean;
  is2faEnabled: any;
  loading: boolean;
  error: string | null;
  successMessage: string | null;
  totpVerifiedSuccessMessage: string | null;
  totpVerifiedErrorMessage: string | null;
}

const initialState: UserSecurityState = {
  totpData: null,
  totpVerified: false,
  is2faEnabled: {},
  loading: false,
  error: null,
  successMessage: null,
  totpVerifiedSuccessMessage: null,
  totpVerifiedErrorMessage: null,
};

const userSecuritySlice = createSlice({
  name: 'userSecurity',
  initialState,
  reducers: {
    generateTotpRequest: (state, action: PayloadAction<{ userId: string, userEmail: string }>) => {
        console.log('generateTotpRequest action:', action);
      state.loading = true;
      state.error = null;
      state.successMessage = null;
    },
    generateTotpSuccess: (state, action: PayloadAction<any>) => {
        console.log('generateTotpSuccess action:', action);
      state.loading = false;
      state.totpData = action.payload;
      state.successMessage = 'TOTP generated successfully';
    },
    generateTotpFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Verify TOTP request
    verifyTotpRequest: (state, action: PayloadAction<{ userId: string, token: string }>) => {
        state.loading = true;
        state.error = null;
        state.totpVerified = false;
      },
      // Verify TOTP success
      verifyTotpSuccess: (state) => {
        state.loading = false;
        state.totpVerified = true;
        state.totpVerifiedSuccessMessage = 'AUTH verified successfully';
      },
      // Verify TOTP failure
      verifyTotpFailure: (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
        state.totpVerifiedErrorMessage = 'Unable to verify AUTH code';
      },
      // Get 2FA status request
      get2FAStatusRequest: (state, action: PayloadAction<{ userId: string }>) => {
        state.loading = true;
        state.error = null;
      },
      // Get 2FA status success
      get2FAStatusSuccess: (state, action: PayloadAction<any>) => {
        console.log('get2FAStatusSuccess action:', action);
        state.loading = false;
        state.is2faEnabled = action.payload;
      },
      // Get 2FA status failure
      get2FAStatusFailure: (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      },
    // Reset TOTP Verification Messages
    resetTotpVerificationMessages: (state) => {
      state.totpVerifiedSuccessMessage = null;
      state.totpVerifiedErrorMessage = null;
    },    
  },
});

export const {
  generateTotpRequest,
  generateTotpSuccess,
  generateTotpFailure,
  verifyTotpRequest,
  verifyTotpSuccess,
  verifyTotpFailure,
  get2FAStatusRequest,
  get2FAStatusSuccess,
  get2FAStatusFailure,
  resetTotpVerificationMessages,
} = userSecuritySlice.actions;

export default userSecuritySlice.reducer;
