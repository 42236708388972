
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { act } from 'react';
import { PurchaseHistory } from '../assetEditor/types';

interface TokenPurchaseState {
    totalTokensPurchased: number | null;
    loading: boolean;
    error: string | null;
    propertyInvested: any;
    monthlyWiseTokens: any;
    totalIncomeReceived: string;
    success: boolean;
    successMessage?: string;
    tokenPurchaseData?: any;
    tokenPurchaseDataDraft?: any;
    tokenPurchaseStatusSuccess?: boolean;
    tokenPurchaseStatusError?: string;
    tokenPurchaseStatusLoading?: boolean;
    tokenPurchaseStatusSuccessMessage?: string;
    purchaseHistory: PurchaseHistory[];
    totalTokenPurchased?: number;
}

const initialState: TokenPurchaseState = {
    totalTokensPurchased: null,
    loading: false,
    error: null,
    propertyInvested: null,
    monthlyWiseTokens: null,
    totalIncomeReceived: "0",
    success: false,
    successMessage: '',
    tokenPurchaseData: null,
    tokenPurchaseDataDraft: null,
    tokenPurchaseStatusSuccess: false,
    tokenPurchaseStatusError: '',
    tokenPurchaseStatusLoading: false,
    tokenPurchaseStatusSuccessMessage: '',
    purchaseHistory: [],
    totalTokenPurchased: 0,
};

const tokenPurchaseSlice = createSlice({
    name: 'tokenPurchase',
    initialState,
    reducers: {
        fetchTotalTokensPurchasedRequest(state, action: PayloadAction<{ assetID: string, userID: string }>) {
            console.log('fetchTotalTokensPurchasedRequest ', action.payload);
            state.loading = true;
            state.error = null;
        },
        fetchTotalTokensPurchasedSuccess(state, action: PayloadAction<number>) {
            console.log('fetchTotalTokensPurchasedSuccess ', action.payload);
            state.loading = false;
            state.totalTokensPurchased = action.payload;
        },
        fetchTotalTokensPurchasedFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        fetchPropertyInvestedResponse(state, action: PayloadAction<any>) {
            state.loading = false;
            state.propertyInvested = action.payload
        }, fetchPropertyInvestedRequest(state, action: PayloadAction<any>) {
            state.loading = true;
            state.propertyInvested = null
        }, fetchAllMonthlyTokensRequest(state, action: PayloadAction<any>) {
            state.loading = true;
            state.monthlyWiseTokens = null
        }, fetchAllMonthlyTokensResponse(state, action: PayloadAction<any>) {
            state.loading = true;
            state.monthlyWiseTokens = action?.payload
        }, fetchTotalIncomeReceived(state, action: PayloadAction<any>) {
            state.totalIncomeReceived = "0"
        }, fetchTotalIncomeReceivedResponse(state, action: PayloadAction<any>) {
            state.totalIncomeReceived = action?.payload
        },
        submitPurchaseRequest(state, action: PayloadAction<any>) {
            state.loading = true;
            state.error = null;
            state.success = false;
          },
          submitPurchaseRequestSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            state.success = true;
            state.tokenPurchaseData = action.payload;
            state.successMessage = 'Purchase request submitted successfully';
          },
          submitPurchaseRequestFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
          },
          resetTokenPurchaseData: (state) => {
            state.tokenPurchaseData = null;
            state.error = null;
            state.loading = false;
          },
          setTokenPurchaseDataDraft: (state, action: PayloadAction<any>) => {
            console.log('setTokenPurchaseDataDraft ', action.payload);
            const { tokenPurchaseDataDraft, tokenPurchaseStatusSuccessMessage } = action.payload;
            state.tokenPurchaseDataDraft = tokenPurchaseDataDraft;
            state.tokenPurchaseStatusSuccessMessage = tokenPurchaseStatusSuccessMessage;
          },
          fetchPurchaseHistoryByAssetIDAndUserID(state, action: PayloadAction<{ assetID: string; userID: string }>) {
            console.log('slice fetchPurchaseHistoryByAssetIDAndUserID:', action.payload);
            state.loading = true;
            state.error = null;
            state.success = false;
          },
          fetchPurchaseHistoryByAssetIDAndUserIDSuccess(state, action: PayloadAction<PurchaseHistory[]>) {
            console.log('slice fetchPurchaseHistoryByAssetIDAndUserIDSuccess:', action.payload);
            state.loading = false;
            state.success = true;
            state.purchaseHistory = action.payload ?? [];
          },
          fetchPurchaseHistoryByAssetIDAndUserIDFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
          },
          fetchTotalTokenPurchaseByAssetID(state, action: PayloadAction<string>) {
            state.loading = true;
            state.error = null;
            state.success = false;
          },
          fetchTotalTokenPurchaseByAssetIDSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            state.success = true;
            state.totalTokenPurchased = action.payload;
          },
          fetchTotalTokenPurchaseByAssetIDFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
          },
          fetchTotalTokenPurchaseByAssetIDAndUserID(state, action: PayloadAction<{assetID: string, userID: string}>) {
            state.loading = true;
            state.error = null;
            state.success = false;
          },
          fetchTotalTokenPurchaseByAssetIDAndUserIDSuccess(state, action: PayloadAction<number>) {
            state.loading = false;
            state.success = true;
            state.totalTokenPurchased = action.payload;
          },
          fetchTotalTokenPurchaseByAssetIDAndUserIDFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
          },        
    
    },
});

export const {
    fetchTotalTokensPurchasedRequest,
    fetchTotalTokensPurchasedSuccess,
    fetchTotalTokensPurchasedFailure,
    fetchPropertyInvestedResponse,
    fetchPropertyInvestedRequest,
    fetchAllMonthlyTokensRequest,
    fetchAllMonthlyTokensResponse,
    fetchTotalIncomeReceivedResponse,
    fetchTotalIncomeReceived,
    submitPurchaseRequest,
    submitPurchaseRequestSuccess,
    submitPurchaseRequestFailure,
    resetTokenPurchaseData,
    setTokenPurchaseDataDraft,
    fetchPurchaseHistoryByAssetIDAndUserID,
    fetchPurchaseHistoryByAssetIDAndUserIDSuccess,
    fetchPurchaseHistoryByAssetIDAndUserIDFailure,
    fetchTotalTokenPurchaseByAssetID,
    fetchTotalTokenPurchaseByAssetIDSuccess,
    fetchTotalTokenPurchaseByAssetIDFailure,
    fetchTotalTokenPurchaseByAssetIDAndUserID,
    fetchTotalTokenPurchaseByAssetIDAndUserIDSuccess,
    fetchTotalTokenPurchaseByAssetIDAndUserIDFailure,
} = tokenPurchaseSlice.actions;

export default tokenPurchaseSlice.reducer;
