import React, { useState } from 'react';
import styles from './RentWallet.module.css';
import { FaCopy } from 'react-icons/fa'; // Import the copy icon
import { ethers } from 'ethers'; // Ensure ethers.js is installed

const RentWallet: React.FC = () => {
  const [rentWalletAddress, setRentWalletAddress] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const connectToMetamask = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setRentWalletAddress(accounts[0]);
      } catch (error) {
        setErrorMessage('Failed to connect to Metamask.');
      }
    } else {
      setErrorMessage('Please install Metamask to connect.');
    }
  };

  const copyAddressToClipboard = () => {
    if (rentWalletAddress) {
      navigator.clipboard.writeText(rentWalletAddress);
      alert('Address copied to clipboard!');
    }
  };

  const formatAddress = (address: string) => {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  return (
    <div className={styles.rentWalletContainer}>
      <h2 className={styles.rentWalletTitle}>Rent Wallet</h2>
      <p className={styles.infoText}>
        All the revenue distribution tokens will be sent to this address.
      </p>
      {!rentWalletAddress ? (
        <button className={styles.connectButton} onClick={connectToMetamask}>
          Connect Metamask
        </button>
      ) : (
        <>
          <p className={styles.connectedAddressTitle}>Your connected Ethereum address</p>
          <div className={styles.walletDetails}>
            <a
              href={`https://etherscan.io/address/${rentWalletAddress}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.etherscanLink}
            >
              {formatAddress(rentWalletAddress)}
            </a>
            <FaCopy className={styles.copyIcon} onClick={copyAddressToClipboard} />
          </div>
        </>
      )}

      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

      {rentWalletAddress && (
        <p className={styles.changeWalletText}>
          To change the wallet address, contact us via chat.
        </p>
      )}
    </div>
  );
};

export default RentWallet;
