// src/api/messagingApi.ts

import axios from 'axios';

export const createMessageApi = async (messageData: any) => {
  const response = await axios.post('/api/messages', messageData);
  return response.data;
};

export const markMessageAsReadApi = async (messageId: string) => {
  const response = await axios.patch(`/api/messages/mark-read/${messageId}`);
  return response.data;
};

export const getMessagesApi = async (userId: string, assetId: string) => {
  const response = await axios.get(`/api/messages/${userId}/${assetId}`);
  return response.data;
};

export const getMessageThreadsApi = async (userId: string) => {
  const response = await axios.get(`/api/messages/threads/${userId}`);
  return response.data;
};
