import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserProfileState {
  profile: any;
  loading: boolean;
  error: string | null;
  profileInformationError: string | null;
  profileInformationSuccessMessage: string | null;
  addressSectionError: string | null;
  addressSectionSuccessMessage: string | null;
  displayName: any | null;
}

const initialState: UserProfileState = {
  profile: null,
  loading: false,
  error: null,
  profileInformationError: null,
  profileInformationSuccessMessage: null,
  addressSectionError: null,
  addressSectionSuccessMessage: null,
  displayName: null,
};

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {

    // Fetch user profile
    fetchUserProfileRequest: (state, action: PayloadAction<any>) => {
        state.loading = true;
        state.error = null;
      },
      fetchUserProfileSuccess: (state, action: PayloadAction<any>) => {
        console.log('fetchUserProfileSuccess')
        console.log(action)
        state.loading = false;
        state.profile = action.payload;
      },
      fetchUserProfileFailure: (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      },
  
    createUserProfileRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.error = null;
    },
    createUserProfileSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.profile = action.payload;
      state.profileInformationSuccessMessage = 'Profile information updated successfully';
      state.addressSectionSuccessMessage = null;
    },
    createUserProfileFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.profileInformationError = action.payload;
      state.addressSectionError = null;
    },

    updateUserAddressRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.error = null;
    },
    updateUserAddressSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.profile = action.payload;
      state.addressSectionSuccessMessage = 'Profile address updated successfully';
      state.profileInformationSuccessMessage = null;    
    },
    updateUserAddressFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.addressSectionError = action.payload;
      state.profileInformationError = null;    
    },

    // Update Profile Picture
    updateUserProfilePicRequest: (state, action: PayloadAction<{userID: string, profilePic: number}>) => {
        console.log('updateUserProfilePicRequest ');
        console.log(action);

        state.loading = true;
        state.error = null;
      },
      updateUserProfilePicSuccess: (state, action: PayloadAction<any>) => {
        console.log('updateUserProfilePicSuccess');
        console.log(action)
        state.loading = false;
        state.profile = action.payload;
      },
      updateUserProfilePicFailure: (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      },
      getDisplayNameRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
      },
      getDisplayNameSuccess(state, action: PayloadAction<any>) {
        state.loading = false;
        state.displayName = action.payload;
      },
      getDisplayNameFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
  },
});

export const {
  createUserProfileRequest,
  createUserProfileSuccess,
  createUserProfileFailure,
  updateUserAddressRequest,
  updateUserAddressSuccess,
  updateUserAddressFailure,
  updateUserProfilePicRequest,
  updateUserProfilePicSuccess,
  updateUserProfilePicFailure,
  fetchUserProfileRequest,
  fetchUserProfileSuccess,
  fetchUserProfileFailure,  
  getDisplayNameRequest,
  getDisplayNameSuccess,
  getDisplayNameFailure,  
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
