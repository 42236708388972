// src/features/valuation/valuationSaga.ts

import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { addValuationApi, getValuationByAssetIDApi, 
  getCurrentTokenPriceByAssetIDApi, getTotalTokenCountByAssetIDApi, getAssetCurrentPriceByAssetIDApi,
  getInitialValuationApi } from './assetValuationApi';
import { addValuationRequest, addValuationSuccess, addValuationFailure,
  getValuationByAssetIDRequest, getValuationByAssetIDSuccess, getValuationByAssetIDFailure,
  fetchTokenPriceRequest, fetchTokenPriceSuccess, fetchTokenPriceFailure,
  fetchTotalTokensRequest, fetchTotalTokensSuccess, fetchTotalTokensFailure,
  fetchAssetPriceRequest, fetchAssetPriceSuccess, fetchAssetPriceFailure,
  fetchInitialValuationRequest, fetchInitialValuationSuccess, fetchInitialValuationFailure,
 } from './assetValuationSlice';
import { ResponseGenerator } from './types';
import { PayloadAction } from '@reduxjs/toolkit';

function* handleAddValuation(action: PayloadAction<string>) {
  console.log('handleAddValuation Payload:', action.payload);
  try {
    const response: ResponseGenerator = yield call(addValuationApi, action.payload);
    console.log('Response:', response);
    yield put(addValuationSuccess(response));
  } catch (error) {
    yield put(addValuationFailure('Something went wrong'));
  }
}

function* handleGetValuationByAssetID(action: PayloadAction<string>) {
  console.log('handleGetValuationByAssetID Payload:', action.payload);
  try {
    const response: ResponseGenerator = yield call(getValuationByAssetIDApi, action.payload);
    console.log('Response:', response);
    yield put(getValuationByAssetIDSuccess(response?.data?.data));
  } catch (error) {
    yield put(getValuationByAssetIDFailure('Something went wrong'));
  }
}

function* fetchTokenPrice(action: { type: string; payload: string }) {
  try {
    const response: ResponseGenerator = yield call(getCurrentTokenPriceByAssetIDApi, action.payload);
    yield put(fetchTokenPriceSuccess(response?.data));
  } catch (error) {
    yield put(fetchTokenPriceFailure('Failed to fetch token price.'));
  }
}

function* fetchTotalTokens(action: { type: string; payload: string }) {
  try {
    console.log('fetchTotalTokens action:', action);
    const response: ResponseGenerator = yield call(getTotalTokenCountByAssetIDApi, action.payload);
    yield put(fetchTotalTokensSuccess(response?.data));
  } catch (error) {
    yield put(fetchTotalTokensFailure('Failed to fetch total tokens.'));
  }
}

function* fetchAssetPrice(action: { type: string; payload: string }) {
  try {
    const response: ResponseGenerator = yield call(getAssetCurrentPriceByAssetIDApi, action.payload);
    yield put(fetchAssetPriceSuccess(response?.data));
  } catch (error) {
    yield put(fetchAssetPriceFailure('Failed to fetch asset price.'));
  }
}

function* handleFetchInitialValuation(action: PayloadAction<string>) {
  try {
    const response: ResponseGenerator = yield call(getInitialValuationApi, action.payload);
    yield put(fetchInitialValuationSuccess(response?.data));
  } catch (error: any) {
    yield put(fetchInitialValuationFailure('An error occurred while fetching the initial valuation'));
  }
}

export default function* valuationSaga() {
  yield takeEvery(addValuationRequest.type, handleAddValuation);
  yield takeEvery(getValuationByAssetIDRequest.type, handleGetValuationByAssetID);
  yield takeEvery(fetchTokenPriceRequest.type, fetchTokenPrice);
  yield takeEvery(fetchTotalTokensRequest.type, fetchTotalTokens);
  yield takeEvery(fetchAssetPriceRequest.type, fetchAssetPrice); 
  yield takeLatest(fetchInitialValuationRequest.type, handleFetchInitialValuation);
}
