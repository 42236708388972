// src/features/distribution/distributionSagas.ts
import { call, put, takeLatest } from 'redux-saga/effects';
import { addDistributionApi, getDistributionsByAssetIDApi, updateDistributionStatusApi, getDistributionByIDApi } from './distributionApi';
import { fetchDistributionByIDRequest, fetchDistributionByIDFailure, fetchDistributionByIDSuccess,
    fetchDistributionsByAssetIDRequest, fetchDistributionsByAssetIDFailure, fetchDistributionsByAssetIDSuccess,
    changeDistributionStatusRequest, changeDistributionStatusFailure, changeDistributionStatusSuccess,
    createDistributionRequest, createDistributionFailure, createDistributionSuccess
 } from './distributionSlice';
import { PayloadAction } from '@reduxjs/toolkit';

export interface ResponseGenerator{
    config?:any,
    data?:any,
    headers?:any,
    request?:any,
    status?:number,
    statusText?:string
  }

  
function* handleFetchDistributionsByAssetID(action: PayloadAction<string>) {
    console.log('handleFetchDistributionsByAssetID');
  try {
    const response: ResponseGenerator = yield call(getDistributionsByAssetIDApi, action.payload);
    console.log('handleFetchDistributionsByAssetID response', response);
    yield put(fetchDistributionsByAssetIDSuccess(response.data));
  } catch (error) {
    yield put(fetchDistributionsByAssetIDFailure('An error occurred while fetching distributions'));
  }
}

function* handleFetchDistributionByID(action: PayloadAction<string>) {
  try {
    const response: ResponseGenerator = yield call(getDistributionByIDApi, action.payload);
    yield put(fetchDistributionByIDSuccess(response));
  } catch (error) {
    yield put(fetchDistributionByIDFailure('An error occurred while fetching distribution'));
  }
}

function* handleCreateDistribution(action: PayloadAction<any>) {
    console.log('handleCreateDistribution');
    console.log('action', action);
  try {
    const response: ResponseGenerator = yield call(addDistributionApi, action.payload);
    yield put(createDistributionSuccess(response));
  } catch (error) {
    yield put(createDistributionFailure('An error occurred while creating distribution'));
  }
}

function* handleChangeDistributionStatus(action: PayloadAction<{ distributionID: string; status: string }>) {
  try {
    const response: ResponseGenerator = yield call(updateDistributionStatusApi, action.payload.distributionID, action.payload.status);
    yield put(changeDistributionStatusSuccess(response));
  } catch (error) {
    yield put(changeDistributionStatusFailure('An error occurred while changing distribution status'));
  }
}

export default function* distributionSaga() {
  yield takeLatest(fetchDistributionsByAssetIDRequest.type, handleFetchDistributionsByAssetID);
  yield takeLatest(fetchDistributionByIDRequest.type, handleFetchDistributionByID);
  yield takeLatest(createDistributionRequest.type, handleCreateDistribution);
  yield takeLatest(changeDistributionStatusRequest.type, handleChangeDistributionStatus);
}
