import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  email: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  email: null,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Fetch email by userId request
    fetchEmailByUserIdRequest: (state, action: PayloadAction<string>) => {
        console.log('fetchEmailByUserIdRequest action:', action);
      state.loading = true;
      state.error = null;
      state.email = null;
    },
    // Fetch email by userId success
    fetchEmailByUserIdSuccess: (state, action: PayloadAction<string>) => {
        console.log('fetchEmailByUserIdSuccess action:', action);
      state.loading = false;
      state.email = action.payload;
    },
    // Fetch email by userId failure
    fetchEmailByUserIdFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchEmailByUserIdRequest,
  fetchEmailByUserIdSuccess,
  fetchEmailByUserIdFailure,
} = userSlice.actions;

export default userSlice.reducer;
