import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchMarketplaceItemsRequest,
  fetchMarketplaceItemsSuccess,
  fetchMarketplaceItemsFailure,
} from './marketplaceSlice';
import { fetchMarketplaceItemsApi } from './marketplaceApi';

import { SagaIterator } from 'redux-saga';

function* handleFetchMarketplaceItems(): SagaIterator {
  try {
    const items = yield call(fetchMarketplaceItemsApi);
    yield put(fetchMarketplaceItemsSuccess(items));
  } catch (error: any) {
    yield put(fetchMarketplaceItemsFailure(error.message || 'Failed to fetch marketplace items.'));
  }
}

export default function* marketplaceSaga() {
  yield takeLatest(fetchMarketplaceItemsRequest.type, handleFetchMarketplaceItems);
}
