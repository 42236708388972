// src/features/distribution/distributionSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DistributionState {
  distributionDetailsList: any[];
  distributionDetails: any | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: DistributionState = {
  distributionDetailsList: [],
  distributionDetails: null,
  status: 'idle',
  error: null,
};

const distributionSlice = createSlice({
  name: 'distribution',
  initialState,
  reducers: {
    fetchDistributionsByAssetIDRequest(state, action: PayloadAction<string>) {
        console.log('fetchDistributionsByAssetIDRequest');
        console.log('state', state);
        console.log('action', action);
      state.status = 'loading';
      state.error = null;
    },
    fetchDistributionsByAssetIDSuccess(state, action: PayloadAction<any[]>) {
      state.status = 'succeeded';
      state.distributionDetailsList = action.payload;
    },
    fetchDistributionsByAssetIDFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },
    fetchDistributionByIDRequest(state) {
      state.status = 'loading';
      state.error = null;
    },
    fetchDistributionByIDSuccess(state, action: PayloadAction<any>) {
      state.status = 'succeeded';
      state.distributionDetails = action.payload;
    },
    fetchDistributionByIDFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },
    createDistributionRequest(state, action: PayloadAction<any>) {
        console.log('createDistributionRequest');
        console.log('state', state);
        console.log('action', action);
      state.status = 'loading';
      state.error = null;
    },
    createDistributionSuccess(state, action: PayloadAction<any>) {
      state.status = 'succeeded';
      state.distributionDetailsList.push(action.payload);
    },
    createDistributionFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },
    changeDistributionStatusRequest(state, action: PayloadAction<any>) {
      state.status = 'loading';
      state.error = null;
    },
    changeDistributionStatusSuccess(state, action: PayloadAction<any>) {
      state.status = 'succeeded';
      const index = state.distributionDetailsList.findIndex((d) => d._id === action.payload._id);
      if (index !== -1) {
        state.distributionDetailsList[index] = action.payload;
      }
    },
    changeDistributionStatusFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const {
  fetchDistributionsByAssetIDRequest,
  fetchDistributionsByAssetIDSuccess,
  fetchDistributionsByAssetIDFailure,
  fetchDistributionByIDRequest,
  fetchDistributionByIDSuccess,
  fetchDistributionByIDFailure,
  createDistributionRequest,
  createDistributionSuccess,
  createDistributionFailure,
  changeDistributionStatusRequest,
  changeDistributionStatusSuccess,
  changeDistributionStatusFailure,
} = distributionSlice.actions;

export default distributionSlice.reducer;
