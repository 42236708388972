// App.tsx

import React from 'react';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { Provider } from 'react-redux';
import store from '../store/store';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import LoginPage from './components/LoginModule/Login';
// import RegisterPage from './components/LoginModule/Register';
// import DashboardPage from './components/Dashboard/Dashboard';
// import Marketplace from './components/Marketplace/Marketplace';
// import HowItWorks from './components/HowItWorks/HowItWorks';
// import MyAccount from './components/MyAccount/MyAccount';
// import AccountDetails from './components/AccountDetails/AccountDetails';
// import Portfolio from './components/Portfolio/Portfolio';
// import Orders from './components/Orders/Orders';
// import DetailedView from './components/DetailedView/DetailedView';
// import CreateAsset from './components/Admin/CreateAsset/CreateAsset';
// import AdminPage from './components/Admin/AdminPage/AdminPage';
// import AdminDashboard from './components/Admin/AdminDashboard/AdminDashboard';
// import UnpublishedEditor from './components/Admin/UnpublishedEditor/UnpublishedEditor';
// import AssetPage from './components/Admin/AssetDetailViewer/AssetPage/AssetPage';
// import PublishedViewer from './components/Admin/PublishedViewer/PublishedViewer';
// import HomePage from './components/HomePage/HomePage';
// import UserPage from './components/Users/UserPage/UserPage';
// import UserDashboard from './components/Users/UserDashboard/UserDashboard';
// import AssetHolder from './components/Users/AssetHolder/AssetHolder';
// import AssetPageBase from './components/AssetViewer/AssetDetailViewer/AssetPageNew/AssetPageBase';
// import LoginWrapper from './components/LoginModule/LoginWrapper';
import AppRoutes from './Routes/Routes';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  );
};

export default App;
