import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface HomeRouteProps {
  // You can define any additional props here if needed
}

const HomeRoute: React.FC<HomeRouteProps> = () => {
  const accessToken = localStorage.getItem('accessToken');

  const isTokenExpired = (token: string): boolean => {
    // Implement your logic to check if the token is expired
    // For now, let's assume this function returns a boolean
    return false; // Replace with actual expiration check logic
  };

  return accessToken && !isTokenExpired(accessToken) ? (
    <Navigate to="/me/dashboard" replace />
  ) : (
    <Outlet />
  );
};

export default HomeRoute;