import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl + '/backend-partners';

// API to add a new company
export const addCompanyApi = async (companyData: any) => {
  const response = await axios.post(`${API_BASE_URL}/add-company`, companyData);
  return response.data;
};

// API to fetch all companies
export const getAllCompaniesApi = async () => {
    console.log('getAllCompaniesApi');
  const response = await axios.get(`${API_BASE_URL}/all-companies`);
  console.log('response', response);
  return response.data;
};

// API to fetch companies by type
export const getCompaniesByTypeApi = async (companyType: string) => {
    console.log('getCompaniesByTypeApi');
    console.log('companyType', companyType);
  const response = await axios.get(`${API_BASE_URL}/companies-by-type`, {
    params: { companyType },
  });
  console.log('response', response);
  return response;
};
