// src/components/AssetDetailEditor/AssetBlockchain.tsx
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faTag, faWallet, faDollarSign, faFileContract, faUser, faBarcode } from '@fortawesome/free-solid-svg-icons';
import styles from './AssetBlockchainDetails.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchTokenPriceRequest } from '../../../../../features/assetValuation/assetValuationSlice';
import { RootState } from '../../../../../store/rootReducer';
// import {
//     getTokenDetailsRequest, getTokenOfferingDetailsRequest 
//   } from '../../../../features/assetEditor/assetEditorSlice';
  
  import {
    getTokenDetailsRequest, getTokenOfferingDetailsRequest 
  } from '../../../../../../src/features/assetEditor/assetEditorSlice';
  
const AssetBlockchainDetails = () => {
    const dispatch = useDispatch();
  
    const { tokenPrice } = useSelector((state: RootState) => state.valuation);
    const { tokenDetails, tokenOfferingDetailsList } = useSelector((state: RootState) => state.assetEditor);

    console.log('tokenDetails*** ', tokenDetails);
    console.log('tokenOfferingDetailsList ', tokenOfferingDetailsList);
    console.log('tokenPrice ', tokenPrice);


    const { asset_id } = useParams(); // Extracts `id` from the URL
    console.log('AssetBlockchainDetails ', asset_id);
  
  
    useEffect(() => {
      dispatch(fetchTokenPriceRequest(asset_id ?? ''));
    }, [dispatch]);
  
    useEffect(() => {
        dispatch(getTokenDetailsRequest(asset_id ?? ''));
        // dispatch(getTokenOfferingDetailsRequest(asset_id ??''));
      }, [dispatch]);
    
    return (
            <div className={styles.blockchainContainer}>
      <h2 className={styles.blockchainHeading}>Asset in Blockchain</h2>
      <div className={styles.blockchainGrid}>
        <div className={styles.blockchainItem}>
          <FontAwesomeIcon icon={faCoins} className={styles.blockchainIcon} />
          <div className={styles.blockchainText}>
            Token Name: <span className={styles.blockchainValue}>{tokenDetails.tokenName}</span>
          </div>
        </div>
        <div className={styles.blockchainItem}>
          <FontAwesomeIcon icon={faTag} className={styles.blockchainIcon} />
          <div className={styles.blockchainText}>
            Token Symbol: <span className={styles.blockchainValue}>{tokenDetails.tokenId}</span>
          </div>
        </div>
        <div className={styles.blockchainItem}>
          <FontAwesomeIcon icon={faBarcode} className={styles.blockchainIcon} />
          <div className={styles.blockchainText}>
            Token Supply: <span className={styles.blockchainValue}>{tokenDetails.totalNumberOfTokens}</span>
          </div>
        </div>
        <div className={styles.blockchainItem}>
          <FontAwesomeIcon icon={faDollarSign} className={styles.blockchainIcon} />
          <div className={styles.blockchainText}>
            Token Price: <span className={styles.blockchainValue}>${tokenPrice}</span>
          </div>
        </div>
        <div className={styles.blockchainItem}>
          <FontAwesomeIcon icon={faFileContract} className={styles.blockchainIcon} />
          <div className={styles.blockchainText}>
            Token Type: <span className={styles.blockchainValue}>{tokenDetails.tokenType}</span>
          </div>
        </div>
        <div className={styles.blockchainItem}>
          <FontAwesomeIcon icon={faWallet} className={styles.blockchainIcon} />
          <div className={styles.blockchainText}>
            Token Contract: <span className={styles.blockchainValue}>{tokenDetails.contractAddress}</span>
          </div>
        </div>
        <div className={styles.blockchainItem}>
          <FontAwesomeIcon icon={faUser} className={styles.blockchainIcon} />
          <div className={styles.blockchainText}>
            Owner Wallet Address: <span className={styles.blockchainValue}>{tokenDetails.ownerWalletAddress}</span>
          </div>
        </div>
      </div>
    </div>

    );
}

export default AssetBlockchainDetails;
