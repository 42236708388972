import React from 'react';
import styles from './AssetSlicesNFTHeader.module.css'; // Import the CSS module

const AssetSlicesNFTHeader = () => {
  const handleOpenSeaPurchase = () => {
    window.open('https://opensea.io', '_blank');
  };

  return (
    <>
      <div className={styles.bannerContainer}>
        <div className={styles.bannerContent}>
          <h1 className={styles.bannerTitle}>Purchase NFTs and avail offer while buying AssetSlices!</h1>
          <div className={styles.bannerButtons}>
            <button className={styles.seeArtistsButton} onClick={handleOpenSeaPurchase}>
              Purchase in Opensea
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetSlicesNFTHeader;
