// src/components/KYCMessage.tsx
import React from 'react';
import styles from './KYCMessage.module.css'; // You can style this component using a CSS module

interface KYCMessageProps {
  onStartKYC: () => void;
}

const KYCMessage: React.FC<KYCMessageProps> = ({ onStartKYC }) => {
  return (
    <div className={styles.kycContainer}>
      <p className={styles.message}>
        Finish the KYC process and get ready to make your first investment
      </p>
      <button className={styles.kycButton} onClick={onStartKYC}>
        Start KYC Process
      </button>
    </div>
  );
};

export default KYCMessage;
