import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl + '/user-security';

// API call to generate TOTP secret and QR code
export const generateTotpApi = async (userId: string, userEmail: string) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/generate-totp`, {
      userId,
      userEmail
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to generate TOTP');
  }
};


// API call to verify TOTP code
export const verifyTotpApi = async (userId: string, token: string) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/verify-totp`, {
      userId,
      token
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to verify TOTP');
  }
};

// API call to get the 2FA status for a user
export const get2FAStatusApi = async (userId: string) => {
  console.log('get2FAStatusApi userId:', userId);
  try {
    const response = await axios.get(`${API_BASE_URL}/2fa-status/${userId}`);
    console.log('get2FAStatusApi response:', response);
    return response.data?.is2FAEnabled;
  } catch (error) {
    throw new Error('Failed to retrieve 2FA status');
  }
};