import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './PersonalInformation.module.css';
import { createUserProfileRequest } from '../../../features/userProfile/userProfileSlice';
import { RootState } from '../../../store/rootReducer';

interface PersonalInformationProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  areaCode: string;
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({ firstName, lastName, email, phoneNumber, areaCode }) => {
  const [localFirstName, setLocalFirstName] = useState(firstName);
  const [localLastName, setLocalLastName] = useState(lastName);
  const [localEmail, setLocalEmail] = useState(email);
  const [localPhoneNumber, setLocalPhoneNumber] = useState(phoneNumber);
  const [localAreaCode, setLocalAreaCode] = useState(areaCode);
  const [localSuccessMessage, setLocalSuccessMessage] = useState<string | null>(null);
  const [localErrorMessage, setLocalErrorMessage] = useState<string | null>(null);

  const { profileInformationSuccessMessage, profileInformationError, loading } = useSelector((state: RootState) => state.userProfile);
  
  const userID = localStorage.getItem('userID') ?? '';

  const dispatch = useDispatch();

  useEffect(() => {
    setLocalFirstName(firstName);
    setLocalLastName(lastName);
    setLocalEmail(email);
    setLocalPhoneNumber(phoneNumber);
    setLocalAreaCode(areaCode);
  }, [firstName, lastName, email, phoneNumber, areaCode]);

  useEffect(() => {
    setLocalSuccessMessage(profileInformationSuccessMessage);
    setLocalErrorMessage(profileInformationError);

    // Clear notifications after 3 seconds
    const timer = setTimeout(() => {
      setLocalSuccessMessage(null);
      setLocalErrorMessage(null);
    }, 3000);

    return () => clearTimeout(timer);
  }, [profileInformationSuccessMessage, profileInformationError]);

  const handleSave = () => {
    if (!localFirstName || !localLastName || !localEmail || !localPhoneNumber || !localAreaCode) {
      setLocalErrorMessage('All fields are required!');
      // setSuccessMessage(null);
      return;
    }

    // Dispatch the createUserProfileRequest action with the form data
    dispatch(createUserProfileRequest({
      userID,
      firstName: localFirstName,
      lastName: localLastName,
      email: localEmail,
      phoneNumber: localPhoneNumber,
      areaCode: localAreaCode,
    }));

    // Simulating success notification after dispatch (should be based on response in real app)
    // setSuccessMessage('Personal Information updated successfully!');
    // setErrorMessage(null);

    // Clear notification after 3 seconds
    // setTimeout(() => {
    //   setSuccessMessage(null);
    //   setErrorMessage(null);
    // }, 30000);
  };

  return (
    <div className={styles.container}>
      <h2>Personal Information</h2>
      
      {/* Success and Error Messages */}
      {localSuccessMessage && <div className={styles.success}>{localSuccessMessage}</div>}
      {localErrorMessage && <div className={styles.error}>{localErrorMessage}</div>}
      
      <div className={styles.grid}>
        <div className={styles.formGroup}>
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            placeholder="First Name"
            value={localFirstName}
            onChange={(e) => setLocalFirstName(e.target.value)}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            placeholder="Last Name"
            value={localLastName}
            onChange={(e) => setLocalLastName(e.target.value)}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            placeholder="Email"
            value={localEmail}
            readOnly
            onChange={(e) => setLocalEmail(e.target.value)}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="tel"
            id="phoneNumber"
            placeholder="Phone Number"
            value={localPhoneNumber}
            onChange={(e) => setLocalPhoneNumber(e.target.value)}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="areaCode">Area Code</label>
          <input
            type="text"
            id="areaCode"
            placeholder="Area Code"
            value={localAreaCode}
            onChange={(e) => setLocalAreaCode(e.target.value)}
          />
        </div>
      </div>
      <button className={styles.saveButton} onClick={handleSave}>
        Save Information
      </button>
    </div>
  );
};

export default PersonalInformation;
