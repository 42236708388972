import React from "react";
import styles from "./DividentReport.module.css";

// Define the structure of the table data
interface Order {
  id: string;
  customer: {
    name: string;
    avatar: string | null; // URL or null for initials
    initials?: string;
  };
  product: string;
  amount: string;
  vendor: string;
  status: "Paid" | "Pending" | "Cancel";
}

const orders: Order[] = []; // Empty orders array to test the fallback message

const Reports: React.FC = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Dividend Report</h2>
      {orders.length === 0 ? (
        <div className={styles.noDataMessage}>You do not have any Dividends</div>
      ) : (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Customer</th>
              <th>Product</th>
              <th>Amount</th>
              <th>Vendor</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td className={styles.customerCell}>
                  {order.customer.avatar ? (
                    <img
                      src={order.customer.avatar}
                      alt={order.customer.name}
                      className={styles.avatar}
                    />
                  ) : (
                    <span className={styles.initials}>
                      {order.customer.initials}
                    </span>
                  )}
                  {order.customer.name}
                </td>
                <td>{order.product}</td>
                <td className={styles.amount}>{order.amount}</td>
                <td>{order.vendor}</td>
                <td>
                  <span
                    className={`${styles.status} ${
                      styles[order.status.toLowerCase()]
                    }`}
                  >
                    {order.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Reports;
