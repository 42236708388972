// src/components/AssetDetailEditor/TokenInteractor.tsx
import React, { useEffect, useState } from 'react';
import styles from './TokenInteractorNew.module.css';
import metaMaskIcon from '../../../../../assets/2261817.webp'; // Ensure the path to your MetaMask icon is correct
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import PropertyTokenArtifact from './AssetSlices1002.json';
// import PropertyTokenArtifact from './AssetSlices100.json';
import ERC20_ABI from './ERC20_ABI.json';
import ProgressIndicator from './ProgressIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import PurchaseDetails from './PurchaseDetails';
import { set } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/rootReducer';
import { submitPurchaseRequest } from '../../../../../features/tokenPurchase/tokenPurchaseSlice';

//const usdcContractAddress = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'; // Mainnet USDC contract
const usdcContractAddress = '0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238'; // USDC contract address on Sepolia

const SEPOLIA_URL = "https://sepolia.infura.io/v3/7df33d45fff14e4bb73eaf9836d5c3cb";
const SEPOLIA_WS_URL = 'wss://sepolia.infura.io/ws/v3/7df33d45fff14e4bb73eaf9836d5c3cb';

// const usdcABI = [
//   {
//     "constant": true,
//     "inputs": [{"name":"_owner", "type":"address"}],
//     "name": "balanceOf",
//     "outputs": [{"name": "balance", "type":"uint256"}],
//     "type": "function"
//   }
// ]; // Minimal ABI to get ERC20 Token balance

interface TokenInteractorProps {
  requiredUSDC: number;
  tokenCount: number;
}

const TokenInteractor: React.FC<TokenInteractorProps> = ({ requiredUSDC, tokenCount }) => {
  const dispatch = useDispatch();

  const { tokenPurchaseDataDraft, tokenPurchaseStatusSuccessMessage } = useSelector((state: RootState) => state.tokenPurchase);

  console.log('tokenPurchaseDataDraft', tokenPurchaseDataDraft);

  const TransactionSteps = {
    APPROVAL: 'Approval',
    PURCHASE: 'Purchasing token',
    COMPLETED: 'Completed'
  };
  
  
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [account, setAccount] = useState<string>('');
  const [ethBalance, setEthBalance] = useState<string>('');
  const [usdcBalance, setUsdcBalance] = useState<string>('');
  const [usdcContract, setUsdcContract] = useState<any>(null);
  const [propertyContract, setPropertyContract] = useState<any>(null);
  const [tokenName, setTokenName] = useState<string>('');
  const [tokenSymbol, setTokenSymbol] = useState<string>('');
  const [tokenBalance, setTokenBalance] = useState<string>('0');
  const [tokenPrice, setTokenPrice] = useState<string>('0');
  const [loading, setLoading] = useState<boolean>(false);
  const [buyAmount, setBuyAmount] = useState<number>(0);
  const [showProgressIndicator, setShowProgressIndicator] = useState<boolean>(false);
  const [notification, setNotification] = useState({
    message: '',
    type: '' // 'success' or 'error'
  });
  const [showNotification, setShowNotification] = useState(false);
  const [currentStep, setCurrentStep] = useState('');
  const [completedSteps, setCompletedSteps] = useState([] as any);
  const [numTokens, setNumTokens] = useState(0);
  const [usdcToSpend, setUsdcToSpend] = useState<number>(0); // Amount of USDC to spend
  const [propTokenYouWillGet, setPropTokenYouWillGet] = useState<number>(0); // Calculated property tokens

  const [numTokensToPurchase, setNumTokensToPurchase] = useState<number>(1); // Number of Tokens to purchase
  // const [usdcToPay, setUsdcToPay] = useState<number>(0); // Calculated usdc spend
  const [totalUsdcToPay, setTotalUsdcToPay] = useState<number>(0); // Calculated usdc spend
  const [insufficientBalance, setInsufficientBalance] = useState(false);

  const [metamaskButtonDisabled, setMetamaskButtonDisabled] = useState(true);
  const [approvalTxHash, setApprovalTxHash] = useState('');
  const [purchaseTxHash, setPurchaseTxHash] = useState('');
  // const [approvalInProgress, setApprovalInProgress] = useState(false);
  // const [approvalCompleted, setApprovalCompleted] = useState(false);
  // const [purchaseInProgress, setPurchaseInProgress] = useState(false);
  // const [purchaseCompleted, setPurchaseCompleted] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState('idle');
  const [purchaseStatus, setPurchaseStatus] = useState('idle');



  const propertyTokenContractAddress = '0x04Ca1bfb281fF855232dcE0937F46D6dbBF5F5Fd'; // AssetSlices1002 contract address on Sepolia
  // const propertyTokenContractAddress = '0x61234E9A3Ae323D9b3255b9d61F498B6a0a8230a'; // AssetSlices100 contract address on Sepolia
  
  useEffect(() => {
    console.log('Required USDC:', requiredUSDC);
    setBuyAmount(requiredUSDC);
  }, [requiredUSDC]);

  useEffect(() => {
    console.log('submitPurchaseRequest');
    console.log('approvalStatus:', approvalStatus);
    console.log('purchaseStatus:', purchaseStatus);
    if (approvalStatus === 'completed' && purchaseStatus === 'completed') {
      console.log('Submitting purchase request...');
      const purchaseData = {
        ...tokenPurchaseDataDraft,
        purchaseTxHash: purchaseTxHash,
      }
      console.log('purchaseData:', purchaseData);
      dispatch(submitPurchaseRequest(purchaseData));
    }
  }, [approvalStatus, purchaseStatus]);

  useEffect(() => {
    console.log('USDC to pay:', requiredUSDC);
    if (requiredUSDC > parseFloat(usdcBalance)) {
      setInsufficientBalance(true);
    } else {
      setInsufficientBalance(false);
    }
  }, [requiredUSDC, usdcBalance]);


  const shortenAddress = (address: string) => {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const unlockMetaMask = async () => {
    console.log("Unlocking MetaMask...");
    
    const provider = await detectEthereumProvider();
    if (provider) {
      console.log('Ethereum successfully detected!');

      // Initialize web3 instance with the provider
      const web3Instance = new Web3(provider as any);
      setWeb3(web3Instance);

      try {
        await (provider as any).request({ method: 'eth_requestAccounts' });
        const accounts = await web3Instance.eth.getAccounts();
        if (accounts.length === 0) {
          console.error('No accounts found.');
          return;
        }

        setAccount(accounts[0]);
        console.log('Connected account:', accounts[0]);

        const ethBalance = await web3Instance.eth.getBalance(accounts[0]);
        setEthBalance(web3Instance.utils.fromWei(ethBalance, 'ether'));

        // USDC
        const usdcContractInstance = new web3Instance.eth.Contract(
          ERC20_ABI as any,
          usdcContractAddress
        );
        setUsdcContract(usdcContractInstance);

        // Get USDC balance
        const usdcBal = await usdcContractInstance.methods.balanceOf(accounts[0]).call() as string;
        //setUsdcBalance(usdcBal);
        setUsdcBalance(web3Instance.utils.fromWei(usdcBal, 'mwei')); // USDC has 6 decimals


        // Property Token Contract
        const propertyTokenABI = PropertyTokenArtifact.abi;
        const propertyTokenContractInstance = new web3Instance.eth.Contract(propertyTokenABI, propertyTokenContractAddress);
        setPropertyContract(propertyTokenContractInstance);

        // Fetch and set token details
        const name = await propertyTokenContractInstance.methods.name().call() as string;
        const symbol = await propertyTokenContractInstance.methods.symbol().call() as string;
        setTokenName(name);
        setTokenSymbol(symbol);

        // Fetch and set token price
        const price = await propertyTokenContractInstance.methods.getTokenPrice().call() as string;
        setTokenPrice(web3Instance.utils.fromWei(price, 'mwei'));

        // Fetch and set user balances
        const tokenBal = await propertyTokenContractInstance.methods.balanceOf(accounts[0]).call() as string;
        setTokenBalance(web3Instance.utils.fromWei(tokenBal, 'ether'));
                
      } catch (error) {
        console.error('Error:', error);
        alert('Error interacting with MetaMask.');
      }
    } else {
      console.error('Please install MetaMask!');
    }
  };

  // When calling buyTokens, update the current step accordingly
  const approvePurchaseTokens = async () => {
    console.log('Buying tokens...');
    setApprovalStatus('in-progress');
    setShowProgressIndicator(true);
    setCurrentStep(TransactionSteps.APPROVAL);
    if (web3 && propertyContract && usdcContract && account) {
      setLoading(true);
      try {
        const usdcAmountInWei = web3.utils.toWei(buyAmount.toString(), 'mwei');
        console.log('USDC amount in Wei:', usdcAmountInWei);

        // Approve the AssetSlices1002 contract to spend USDC on behalf of the user
        usdcContract.methods.approve(propertyTokenContractAddress, usdcAmountInWei)
        .send({ from: account })
        .on('transactionHash', (hash: string) => {
          console.log('Transaction hash for approval:', hash);
          // You can store the hash or use it as needed
        })
        // .on('receipt', (receipt: any) => {
        //   console.log('Approval transaction receipt:', receipt);
        //   // Optionally, you can also mark the step completed here based on the receipt
        // })
        // .on('error', (error: Error) => {
        //   console.error('Error during approval transaction:', error);
        // });
      
        const infuraWeb3Instance = new Web3(new Web3.providers.WebsocketProvider(SEPOLIA_WS_URL));
        const usdcContractInstanceINF = new infuraWeb3Instance.eth.Contract(
          ERC20_ABI as any,
          usdcContractAddress
        );

        // Listen for Approval events via Infura WebSocket provider
        usdcContractInstanceINF.events.Approval({
          filter: { owner: account, spender: propertyTokenContractAddress },  // Filter by owner and spender
          fromBlock: 'latest'
        })
        .on('data', (event: any) => {
          console.log('Filtered Approval event for owner and spender:', event);
          if (event.returnValues.owner === account && event.returnValues.spender === propertyTokenContractAddress) {
            console.log('Approval event found:', event);
            const transactionHash = event?.transactionHash;
            setApprovalTxHash(transactionHash);
            setTimeout(() => {
              markStepAsCompleted(TransactionSteps.APPROVAL);
              purchaseTokens(usdcAmountInWei);
              setApprovalStatus('completed');
            }, 7000);
            // markStepAsCompleted(TransactionSteps.APPROVAL);
            // purchaseTokens(usdcAmountInWei);
            // setApprovalStatus('completed');
          }
        })
              // .on('error', (error: any) => {
        //   console.error('Error listening to Approval events:', error);
        // });


        // await usdcContract.methods.approve(usdcContractAddress, usdcAmountInWei).send({ from: account });
        // purchaseTokens(usdcAmountInWei);

        /*
        const approveTx = usdcContract.methods.approve(propertyTokenContractAddress, usdcAmountInWei).send({ from: account });

        const approveReceipt = await approveTx;
        const events = await usdcContract.getPastEvents('Approval', {
          filter: { owner: account, spender: propertyTokenContractAddress },
          fromBlock: approveReceipt.blockNumber,
          toBlock: approveReceipt.blockNumber,
        });
        
        if (events.length > 0) {
          console.log('Approval event(s) found:', events);
        } else {
          console.error('No Approval events found in the specified block range');
        }
        // Listen for the Approval event
        approveTx.on('transactionHash', (hash: any) => {
            console.log(`Transaction hash: ${hash}`);
            // setCurrentStep(TransactionSteps.AWAITING_DEPOSIT);
        });

        approveTx.on('receipt', (receipt: { events: { Approval: any; }; }) => {
            console.log('Approval transaction receipt:', receipt);
            if (receipt.events.Approval) {
                console.log('Approval event fired', receipt.events.Approval);
                //TODO Update contract to send proper event then enable this line
                markStepAsCompleted(TransactionSteps.APPROVAL);
                // Continue with buying tokens since the approval was successful
                purchaseTokens(usdcAmountInWei);
            }
            console.log('Approval event fired', receipt.events.Approval);
            markStepAsCompleted(TransactionSteps.APPROVAL);
            // Continue with buying tokens since the approval was successful
            purchaseTokens(usdcAmountInWei);
        });

        approveTx.on('error', (error: any) => {
            console.error('Approval transaction failed:', error);
            displayNotification('Approval failed! Please try again.', 'error');
            setLoading(false);
        });
        */
      

      } catch (error) {
        console.error('Error purchasing tokens:', error);
        displayNotification('Transaction failed!', 'error');
        setLoading(false);
      }
    }
  };

  const purchaseTokens = async (usdcAmountInWei: string) => {
    console.log('Purchasing tokens...');
    setPurchaseStatus('in-progress');
    try {
      propertyContract.methods.buyTokens(usdcAmountInWei).send({ from: account });
      setCurrentStep(TransactionSteps.PURCHASE);


      const infuraWeb3Instance = new Web3(new Web3.providers.WebsocketProvider(SEPOLIA_WS_URL));
      const propertyTokenABI = PropertyTokenArtifact.abi;
      const propertyTokenContractInstanceINF = new infuraWeb3Instance.eth.Contract(propertyTokenABI, 
        propertyTokenContractAddress);


      // Listen for Approval events via Infura WebSocket provider
      propertyTokenContractInstanceINF.events.Transfer({
        // filter: { owner: account, spender: propertyTokenContractAddress },  // Filter by owner and spender
        fromBlock: 'latest'
      })
      .on('data', (event: any) => {
        console.log('Transfer event :', event);
      })

      propertyTokenContractInstanceINF.events.TokensPurchased({
        // filter: { owner: account, spender: propertyTokenContractAddress },  // Filter by owner and spender
        fromBlock: 'latest'
      })
      .on('data', (event: any) => {
        console.log('TokensPurchased event :', event);
        markStepAsCompleted(TransactionSteps.PURCHASE);
        setPurchaseTxHash(event.transactionHash);
        setPurchaseStatus('completed');
        // setCurrentStep(TransactionSteps.COMPLETED);
        // markStepAsCompleted(TransactionSteps.COMPLETED);
      })



      // transaction.on('transactionHash', (hash: any) => {
      //   console.log(`Transaction hash: ${hash}`);
      //   setCurrentStep(TransactionSteps.PURCHASE);
      // });

      // transaction.on('receipt', (receipt: any) => {
      //   console.log('Transaction receipt:', receipt);
      //   // setCurrentStep(TransactionSteps.SENDING);
      //   if (receipt.events.TokensPurchased) {
      //     console.log('TokensPurchased event fired', receipt.events.TokensPurchased);
      //     markStepAsCompleted(TransactionSteps.PURCHASE);
      //     markStepAsCompleted(TransactionSteps.COMPLETED);
      //     // Continue with buying tokens since the approval was successful
      //     displayNotification('Purchase successful!', 'success');
      // }

      // });

      // transaction.on('confirmation', (_: any, receipt: { status: any; }) => {
      //   console.log('Transaction confirmed:', receipt);
      //   if (receipt.status) {
      //     // setCompletedSteps([...completedSteps, TransactionSteps.PURCHASE]);
      //     // setCompletedSteps([...completedSteps, TransactionSteps.COMPLETED]);
      //     // setCurrentStep(TransactionSteps.COMPLETED);
      //     displayNotification('Purchase successful!', 'success');
      //   }
      // });

      // transaction.on('error', (error: any) => {
      //   console.error('Transaction error:', error);
      //   displayNotification('Transaction failed!', 'error');
      //   setLoading(false);
      // });
    } catch (error) {
      console.error('Error purchasing tokens:', error);
      displayNotification('Transaction failed!', 'error');
      setLoading(false);
    }

  };

  const buyPropTokens = async () => {
    // if (web3 && propertyContract && usdcContract && account) {
      // setLoading(true);
      // setMetamaskButtonDisabled(true);
      approvePurchaseTokens();
    // } else {  
    //   alert('Please unlock MetaMask to proceed with the purchase.');
    // }
  }

  const displayNotification = (message: string, type: string) => {
    setNotification({ message, type });
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 5000); // Hide after 5 seconds
  };
  
  const markStepAsCompleted = (stepName:any) => {
    const completedSteps1 = [...completedSteps, stepName]; 
    // setCompletedSteps(prev => [...prev, stepName]);
    console.log('Completed Steps:', completedSteps1);
    setCompletedSteps(completedSteps1);
  };

  const checkBuyTokenDisabled = () => {
    if (approvalStatus === 'in-progress' || purchaseStatus === 'in-progress') {
      return true;
    }
    if (approvalStatus === 'completed' && purchaseStatus === 'completed') {
      return true;
    }
    if (requiredUSDC <= 0 || insufficientBalance) {
      return true;
    }
    return false;
  }

  const getLabelForBuyButton = () => {
    if (approvalStatus === 'in-progress' || purchaseStatus === 'in-progress') {
      return 'Processing...';
    }
    if (approvalStatus === 'completed' && purchaseStatus === 'completed') {
      return 'Completed';
    }
    return 'Buy Tokens';

  }

  return (
    <div className={styles.interactorContainer}>

      <div className={styles.orderDetails}>
        {/* <div className={styles.orderRow}>
          <span>Slices Available</span>
          <span></span>
          <span>1,500</span>
        </div>
        <div className={styles.orderRow}>
          <span>Selected number of Slices</span>
          <span></span>
          <span>50 USDC</span>
        </div> */}
        <div className={styles.orderRow}>
          <span>Final USDC Cost</span>
          <span></span>
          <span>{requiredUSDC} USDC</span>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <button className={styles.unlockButton} onClick={unlockMetaMask} disabled={metamaskButtonDisabled}>
          <img src={metaMaskIcon} alt="MetaMask" className={styles.metaMaskIcon} />
          <span className={styles.unlockButtonText}>Unlock MetaMask to Purchase</span>
        </button>
      </div>
      {account && (
        <div className={styles.accountInfo}>
          <div className={styles.orderDetails}>
            <div className={styles.orderRow}>
              <span>ETH account Connected</span>
              <span></span>
              <span>
                <a
                    href={`https://sepolia.etherscan.io/address/${account}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {shortenAddress(account)}
                </a>
              </span>
            </div>
            <div className={styles.orderRow}>
              <span>ETH Balance</span>
              <span></span>
              <span>{Number(ethBalance).toFixed(2)} ETH</span>
            </div>
            <div className={styles.orderRow}>
              <span>USDC Balance</span>
              <span></span>
              <span>{usdcBalance} USDC</span>
            </div>
            <div className={styles.orderRow}>
              <span>Required USDC</span>
              <span></span>
              <span>{requiredUSDC} USDC</span>
            </div>
          </div>
          {/* <table className={styles.detailsTable}>
            <tbody>
              <tr>
                <td><strong>Connected account:</strong></td>
                <td>
                  <a
                    href={`https://sepolia.etherscan.io/address/${account}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {shortenAddress(account)}
                  </a>
                </td>
              </tr>
              <tr>
                <td><strong>ETH Balance:</strong></td>
                <td>{ethBalance} ETH</td>
              </tr>
              <tr>
                <td><strong>USDC Balance:</strong></td>
                <td>{usdcBalance} USDC</td>
              </tr>
              <tr>
                <td><strong>Required USDC:</strong></td>
                <td>{totalUsdcToPay} USDC</td>
              </tr>
            </tbody>
          </table> */}
          <button
            onClick={buyPropTokens}
            disabled={checkBuyTokenDisabled()}
            className={styles.buyTokenButton} // Added margin here
          >
            {/* {loading ? 'Processing...' : `Buy Tokens for (${requiredUSDC} USDC)`} */}
            {getLabelForBuyButton()}
          </button>

          {/* Only show ProgressIndicator after clicking Buy Tokens */}
          {showProgressIndicator && (
            <>
              <ProgressIndicator currentStepProp={currentStep} completedStepProp={completedSteps} />
              <div className={styles.orderDetails}>
                <div className={styles.hashRow}>
                  <span>Approval Transaction Hash</span>
                  <span></span>
                  <span>
                    <a
                      href={`https://sepolia.etherscan.io/tx/${approvalTxHash}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {shortenAddress(approvalTxHash)}
                    </a>
                  </span>
                </div>
                <div className={styles.hashRow}>
                  <span>Purchase Transaction Hash</span>
                  <span></span>
                  <span>
                    <a
                      href={`https://sepolia.etherscan.io/tx/${purchaseTxHash}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {shortenAddress(purchaseTxHash)}
                    </a>
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TokenInteractor;
