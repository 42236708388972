// store/rootReducer.ts

import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import assetReducer from '../features/asset/assetSlice';
import assetEditorReducer from '../features/assetEditor/assetEditorSlice';
import distributionSlice from '../features/distribution/distributionSlice';
import valuationSlice from '../features/assetValuation/assetValuationSlice';
import tokenSaleReducer from '../features/tokenSale/tokenSaleSlice';
import tokenPurchaseReducer from '../features/tokenPurchase/tokenPurchaseSlice';
import UserDistributionReducer from '../features/userDistribution/userDistributionSlice';
import MessagingReducer from '../features/messaging/messagingSlice';
import UserProfileReducer from '../features/userProfile/userProfileSlice';
import userSecurityReducer from '../features/userSecurity/userSecuritySlice';
import userReducer from '../features/user/userSlice';
import referralCodeReducer from '../features/userReferral/userReferralSlice';
import BackendPartnersReducer from '../features/backendPartners/backendPartnerSlice';
import assetMonthlyFinancialsReducer from '../features/assetMonthlyFinancials/assetMonthlyFinancialsSlice';
import assetTokenizationReducer from '../features/assetTokenization/assetTokenizationSlice';
import assetSettingsReducer from '../features/assetSettings/assetSettingsSlice';
import adminUsersReducer from '../features/adminUsers/adminUsersSlice';
import assetPrebookingReducer from '../features/assetPrebooking/assetPrebookingSlice';
import marketplaceReducer from '../features/marketplace/marketplaceSlice';
import waitingListReducer from '../features/waitinglist/waitingListSlice';
import assetStagesReducer from '../features/assetStages/assetStagesSlice';
import kycVeriffReducer from '../features/kycVeriff/kycVeriffSlice';
// Import other reducers as needed

const rootReducer = combineReducers({
  auth: authReducer,
  asset: assetReducer,
  assetEditor: assetEditorReducer,
  distribution: distributionSlice,
  valuation: valuationSlice,
  tokenSale: tokenSaleReducer,
  tokenPurchase: tokenPurchaseReducer,
  userDistribution: UserDistributionReducer,
  messaging: MessagingReducer,
  userProfile: UserProfileReducer,
  userSecurity: userSecurityReducer,
  user: userReducer,
  referralCode: referralCodeReducer,
  BackendPartners: BackendPartnersReducer,
  assetMonthlyFinancials: assetMonthlyFinancialsReducer,
  assetTokenization: assetTokenizationReducer,
  assetSettings: assetSettingsReducer,
  adminUsers: adminUsersReducer,
  prebooking: assetPrebookingReducer,
  marketplace: marketplaceReducer,
  waitingList: waitingListReducer,
  assetStages: assetStagesReducer,
  kycVeriff: kycVeriffReducer,

  // Add other reducers here
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
