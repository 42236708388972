import React, { useState, FormEvent, MouseEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './WaitingList.module.css';
import { joinWaitingListRequest } from '../../../../features/waitinglist/waitingListSlice';
import { RootState } from '../../../../store/rootReducer';

interface WaitListModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const WaitListModal: React.FC<WaitListModalProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  const { statusCode } = useSelector((state: RootState) => state.waitingList);

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (statusCode === 409) {
      // setSubmitted(true);
      setEmail('');
        setError('Email already exists in the waiting list');
    }
    else if (statusCode === 500) {
      // setSubmitted(true);
      setEmail('');
        setError('Failed to join waiting list');
    }
    else if (statusCode === 201) {
      setSubmitted(true);
      setEmail('');
        setError(null);
    } 
  }, [statusCode]);

  // Close modal on Escape key press
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }
    // Cleanup when component unmounts or modal closes
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);


  if (!isOpen) return null;

  const validateEmail = (emailToValidate: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(emailToValidate);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    setError(null);
    console.log('Joining waiting list with email:', email);
    // Dispatch the action to join the waiting list
    dispatch(joinWaitingListRequest({ emailId: email }));

    // setEmail('');
    // setSubmitted(true);
  };

  const handleJoinCommunity = () => {
    window.open('https://t.me/assetslices', '_blank');
  };

  const handleOverlayClick = () => {
    onClose();
  };

  const handleModalContainerClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <section className={styles.modalContainer} onClick={handleModalContainerClick}>
        <button
          className={styles.closeButton}
          onClick={onClose}
          aria-label="Close modal"
        >
          &times;
        </button>
        {!submitted ? (
          <>
            <h2 className={styles.title}>Join the Waitlist</h2>
            <p className={styles.description}>
              Be among the first to get access when we launch.
            </p>

            <form onSubmit={handleSubmit} className={styles.form}>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (error) setError(null);
                }}
                required
                className={`${styles.emailInput} ${error ? styles.errorInput : ''}`}
              />
              {error && <span className={styles.errorMessage}>{error}</span>}
              <button type="submit" className={styles.submitButton}>
                Submit
              </button>
            </form>
            <button type="button" className={styles.submitButton} onClick={handleJoinCommunity}>
                Join our Community
            </button>
            <button className={styles.noThanksButton} onClick={onClose}>
              No, thank you.
            </button>
          </>
        ) : (
          <>
            <h2 className={styles.title}>Thank you!</h2>
            <p className={styles.description}>
              We will send you the registration link soon.
            </p>
            <button className={styles.submitButton} onClick={onClose}>
              Close
            </button>
            <button type="button" className={styles.submitButton} onClick={handleJoinCommunity}>
                Join our Community
            </button>
          </>
        )}
      </section>
    </div>
  );
};

export default WaitListModal;
