import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface VeriffState {
  loading: boolean;
  error: string | null;
  sessionUrl: { verification: { url: string } } | null; // Change from string to object
  sessionStatus: string | null;
  kycStatus: any; // Adjust to your actual KYC data structure
  userId: string | null;  
  userData: { userId: string } | null;
}

const initialState: VeriffState = {
  loading: false,
  error: null,
  sessionUrl: null,
  sessionStatus:  null,
  kycStatus: null,
  userId: null,
  userData: null
};

const veriffSlice = createSlice({
  name: 'veriff',
  initialState,
  reducers: {
    // 1) Create Session URL
    createSessionUrlRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.error = null;
      state.sessionUrl = null;
      state.sessionStatus = null;
    },
    createSessionUrlSuccess: (state, action: PayloadAction<{ verification: { url: string } }>)=> {
      state.loading = false;
      state.sessionUrl = action.payload; // The session URL
      state.sessionStatus = 'created'
    },
    createSessionUrlFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

    // 2) Get KYC Status
    getKycStatusRequest: (state, action: PayloadAction<{ userId: string }>) => {
      state.loading = true;
      state.userData = action.payload; // The session URL
      state.error = null;
    },
    getKycStatusSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.kycStatus = action.payload;
    },
    getKycStatusFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  createSessionUrlRequest,
  createSessionUrlSuccess,
  createSessionUrlFailure,
  getKycStatusRequest,
  getKycStatusSuccess,
  getKycStatusFailure,
} = veriffSlice.actions;

export default veriffSlice.reducer;
