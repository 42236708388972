import React, { useEffect, useMemo } from 'react';
import styles from './UserPortfolio.module.css';
import { FaStar } from 'react-icons/fa'; // Make sure to install react-icons
import { useDispatch, useSelector } from 'react-redux';
import { requestPortfolioDetails } from '../../../features/asset/assetSlice';
import { DataTable } from '../../common';
import { PORTFOLIO_HEADERS } from '../../common/constants';

const UserPortfolio = () => {
 
  const userID = localStorage.getItem('userID') ?? '';
  let portfolioDetails = useSelector((state:any) => state?.asset)
  let dispatch = useDispatch()
  useMemo(() => {
    dispatch(requestPortfolioDetails(userID))
  }, [])
  return (
    <div className={styles.container}>
      <div className={styles.header}>Portfolio is up 0.80%</div>
      <DataTable Header={PORTFOLIO_HEADERS}>


        {portfolioDetails?.portfolioDetails?.data?.map((asset:any, index:any) => (
         <tr  key={index}>
          <td width={'250px'}>{asset?.assetTitle}</td>
          <td width={'250px'}>{'$'+asset?.currentPrice}</td>
          <td width={'250px'}>
           <span className={styles.change} style={{ color: asset?.profitLoss?.toString()?.startsWith('-') ? 'red' : 'green' }}>
               {Math.round(asset?.profitLoss) + '%'}
           </span>
          </td>
          <td width={'250px'}>{'$'+asset?.moneyInvested}</td>
          <td width={'250px'}>{asset?.NoOfTokens}</td>
         </tr>
        ))}
        </DataTable>
    </div>
  );
};

export default UserPortfolio;
