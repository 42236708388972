// src/sagas/assetSettingsSaga.ts

import { call, put, takeLatest } from 'redux-saga/effects';
import { 
  fetchAssetSettingsApi, 
  saveAssetSettingsApi, 
  updateAssetSettingsApi 
} from './assetSettingsApi';
import { 
  fetchAssetSettingsRequest, fetchAssetSettingsSuccess, fetchAssetSettingsFailure,
  saveAssetSettingsRequest, saveAssetSettingsSuccess, saveAssetSettingsFailure,
  updateAssetSettingsRequest, updateAssetSettingsSuccess, updateAssetSettingsFailure 
} from './assetSettingsSlice';

// Worker Saga: Fetch Asset Settings
function* fetchAssetSettingsSaga(action: ReturnType<typeof fetchAssetSettingsRequest>): Generator<any, void, any> {
  try {
    const response = yield call(fetchAssetSettingsApi, action.payload);
    yield put(fetchAssetSettingsSuccess(response.data));
  } catch (error: any) {
    yield put(fetchAssetSettingsFailure(error.message));
  }
}

// Worker Saga: Save Asset Settings
function* saveAssetSettingsSaga(action: ReturnType<typeof saveAssetSettingsRequest>) {
  try {
    yield call(saveAssetSettingsApi, action.payload);
    yield put(saveAssetSettingsSuccess(action.payload));
  } catch (error: any) {
    yield put(saveAssetSettingsFailure(error.message));
  }
}

// Worker Saga: Update Asset Settings
function* updateAssetSettingsSaga(action: ReturnType<typeof updateAssetSettingsRequest>) {
  try {
    yield call(updateAssetSettingsApi, action.payload.assetID, action.payload.data);
    yield put(updateAssetSettingsSuccess());
  } catch (error: any) {
    yield put(updateAssetSettingsFailure(error.message));
  }
}

// Watcher Saga: Watch for actions dispatched to the store
export function* watchAssetSettingsSaga() {
  yield takeLatest(fetchAssetSettingsRequest.type, fetchAssetSettingsSaga);
  yield takeLatest(saveAssetSettingsRequest.type, saveAssetSettingsSaga);
  yield takeLatest(updateAssetSettingsRequest.type, updateAssetSettingsSaga);
}
