import { call, put, takeLatest } from 'redux-saga/effects';
import { 
  fetchTotalDividendRequest, fetchTotalDividendSuccess, fetchTotalDividendFailure,
  fetchDistributionMappingRequest, fetchDistributionMappingSuccess, fetchDistributionMappingFailure,
  fetchAllDistributionsRequest, fetchAllDistributionsSuccess, fetchAllDistributionsFailure,
  updateClaimStatusRequest, updateClaimStatusSuccess, updateClaimStatusFailure, 
  getTokensToBeClaimedRequest, getTokensToBeClaimedSuccess, getTokensToBeClaimedFailure,
  getUserDistributionDetailsRequest, getUserDistributionDetailsSuccess, getUserDistributionDetailsFailure,
} from './userDistributionSlice';
import { getDistributionTotalForUserAndAsset, getDistributionMappingForUserAndAsset, 
  getAllDistributionsForUserAndAsset, updateClaimStatusApi, getTokensToBeClaimedApi, getUserDistributionDetailsApi } from './userDistributionApi';
import { PayloadAction } from '@reduxjs/toolkit';

export interface ResponseGenerator{
    config?:any,
    data?:any,
    headers?:any,
    request?:any,
    status?:number,
    statusText?:string
  }

// Worker saga: Will be fired on fetchTotalDividendRequest actions
function* fetchTotalDividendSaga(action: PayloadAction<{assetID: string, userID: string}>) {
  try {
    const { userID, assetID } = action.payload;
    const response: ResponseGenerator = yield call(getDistributionTotalForUserAndAsset, userID, assetID);
    console.log(' fetchTotalDividendSaga ', response?.data);
    yield put(fetchTotalDividendSuccess(response.data?.totalDividend));
  } catch (error: any) {
    yield put(fetchTotalDividendFailure(error.message));
  }
}

// Worker saga: Will be fired on fetchDistributionMappingRequest actions
function* fetchDistributionMappingSaga(action: PayloadAction<{assetID: string, userID: string}>) {
  try {
    const { userID, assetID } = action.payload;
    const response: ResponseGenerator = yield call(getDistributionMappingForUserAndAsset, userID, assetID);
    yield put(fetchDistributionMappingSuccess(response?.data));
  } catch (error: any) {
    yield put(fetchDistributionMappingFailure(error.message));
  }
}

// Worker saga: Will be fired on fetchAllDistributionsRequest actions
function* fetchAllDistributionsSaga(action: PayloadAction<{assetID: string, userID: string}>) {
  try {
    const { assetID, userID } = action.payload;
    const response: ResponseGenerator = yield call(getAllDistributionsForUserAndAsset, assetID, userID);
    console.log('fetchAllDistributionsSaga ', response);
    yield put(fetchAllDistributionsSuccess(response?.data));
  } catch (error: any) {
    yield put(fetchAllDistributionsFailure(error.message));
  }
}

function* updateClaimStatusSaga(action: ReturnType<typeof updateClaimStatusRequest>) {
  try {
    const { userDistributionID, claimedStatus, claimType } = action.payload;
    const response: ResponseGenerator = yield call(updateClaimStatusApi, userDistributionID, claimedStatus, claimType);
    yield put(updateClaimStatusSuccess(response?.data));
  } catch (error: any) {
    yield put(updateClaimStatusFailure(error.message));
  }
}

function* getTokensToBeClaimedSaga(action: PayloadAction<{assetID: string, userID: string}>) {
  try {
    const { userID, assetID } = action.payload;
    const response: ResponseGenerator = yield call(getTokensToBeClaimedApi, userID, assetID);
    yield put(getTokensToBeClaimedSuccess(response?.data?.totalUnclaimedTokens));
  } catch (error: any) {
    yield put(getTokensToBeClaimedFailure(error.message));
  }
}

function* getUserDistributionDetailsSaga(action: any): Generator<any, void, any> {
  try {
    const { assetID, userDistributionID } = action.payload;
    const data = yield call(getUserDistributionDetailsApi, assetID, userDistributionID);
    yield put(getUserDistributionDetailsSuccess(data));
  } catch (error) {
    yield put(getUserDistributionDetailsFailure("Failed to get user distribution details"));
  }
}


// Watcher saga: Spawns a new fetchTotalDividendSaga task on each fetchTotalDividendRequest
export function* watchUserDistributionSagas() {
  yield takeLatest(fetchTotalDividendRequest.type, fetchTotalDividendSaga);
  yield takeLatest(fetchDistributionMappingRequest.type, fetchDistributionMappingSaga);
  yield takeLatest(fetchAllDistributionsRequest.type, fetchAllDistributionsSaga);
  yield takeLatest(updateClaimStatusRequest.type, updateClaimStatusSaga);
  yield takeLatest(getTokensToBeClaimedRequest.type, getTokensToBeClaimedSaga);
  yield takeLatest(getUserDistributionDetailsRequest.type, getUserDistributionDetailsSaga);

}
