import React from 'react';
import styles from './PrivacyPolicy.module.css';

const PolicyDraft = () => {
  return (
    <div className={styles.policyDraftContainer}>
      <div className={styles.headerContainer}>
        <header className={styles.header}>
            <h1 className={styles.mainTitle}>AssetSlices Privacy Policy and Disclosure</h1>
            {/* <p className={styles.subTitle}> Cookie &amp; Conditions</p> */}
        </header>      
      </div>

      {/* <h1 className={styles.policyTitle}>Privacy Policy and Disclosure</h1> */}
      <p className={styles.policyDate}>
        <strong>Effective Date:</strong> 24 Dec 2024
      </p>
      <p className={styles.policyDate}>
        <strong>Last Updated:</strong> 24 Dec 2024
      </p>

      <div className={styles.policySection}>
        <h2>1. Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul>
          <li>
            <strong>Personal Information:</strong> Name, email address, phone number, mailing
            address, payment details, and identification documents for verification purposes.
          </li>
          <li>
            <strong>Non-Personal Information:</strong> IP address, browser type, and device
            information.
          </li>
          <li>
            <strong>Cookies and Tracking Technologies:</strong> To enhance your user experience
            and analyze website traffic.
          </li>
        </ul>
      </div>

      <div className={styles.policySection}>
        <h2>2. How We Use Your Information</h2>
        <p>We use the information collected for purposes including but not limited to:</p>
        <ul>
          <li>Providing, operating, and maintaining our services.</li>
          <li>Processing transactions securely.</li>
          <li>Sending updates, notifications, and promotional content.</li>
          <li>Enhancing website functionality and user experience.</li>
          <li>Compliance with legal obligations.</li>
        </ul>
      </div>

      <div className={styles.policySection}>
        <h2>3. Disclosure of Your Information</h2>
        <p>We may disclose your information under the following circumstances:</p>
        <ul>
          <li>
            <strong>Service Providers:</strong> Third-party service providers assisting in payment
            processing, analytics, marketing, and customer support.
          </li>
          <li>
            <strong>Legal Requirements:</strong> When required by law or court order.
          </li>
          <li>
            <strong>Business Transfers:</strong> In case of mergers, acquisitions, or asset
            sales.
          </li>
        </ul>
      </div>

      <div className={styles.policySection}>
        <h2>4. Data Security</h2>
        <p>
          We implement security measures to protect your personal information from unauthorized
          access, disclosure, or alteration. However, no method of online transmission is 100%
          secure.
        </p>
      </div>

      <div className={styles.policySection}>
        <h2>5. Your Choices and Rights</h2>
        <ul>
          <li>
            <strong>Access and Correction:</strong> You can access and update your personal
            information.
          </li>
          <li>
            <strong>Opt-Out:</strong> You can opt-out of receiving marketing communications at any
            time.
          </li>
          <li>
            <strong>Cookies Control:</strong> Adjust your browser settings to manage cookies.
          </li>
        </ul>
      </div>

      <div className={styles.policySection}>
        <h2>6. Children’s Privacy</h2>
        <p>
          Our services are not intended for children under 18. We do not knowingly collect
          information from minors.
        </p>
      </div>

      <div className={styles.policySection}>
        <h2>7. Third-Party Links</h2>
        <p>
          Our website may contain links to third-party websites. We are not responsible for their
          privacy practices.
        </p>
      </div>

      <div className={styles.policySection}>
        <h2>8. Changes to This Privacy Policy</h2>
        <p>
          We reserve the right to modify this policy at any time. Significant changes will be
          notified through our website.
        </p>
      </div>

      <div className={styles.policySection}>
        <h2>9. Contact Us</h2>
        <p>
          If you have questions about this Privacy Policy or our data practices, please contact
          us at:
        </p>
        <address>
          <p><strong>AssetSlices.com Support Team</strong></p>
          <p>Email: support@assetslices.com</p>
          {/* <p>Phone: [Insert Contact Number]</p> */}
          {/* <p>Mailing Address: [Insert Business Address]</p> */}
        </address>
      </div>

      <p>
        By using our website, you consent to this Privacy Policy and the processing of your
        personal information as described above.
      </p>
    </div>
  );
};

export default PolicyDraft;
