// app/pages/RightSection.tsx

import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import styles from './LeftSection.module.css';
import LeftMenu from '../LeftMenu/LeftMenu';

const LeftSection: React.FC = () => {
//   const { id } = useParams(); // Extracts `id` from the URL
//   console.log('UserPageId ', id);

  return (
    <LeftMenu />
  );
};

export default LeftSection;
