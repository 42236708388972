import React, { useEffect } from "react";
import styles from "./PurchaseReport.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../store/rootReducer";
import { useParams } from "react-router-dom";
import { fetchPurchaseHistoryByAssetIDAndUserID } from "../../../../../../features/tokenPurchase/tokenPurchaseSlice";

// Define the structure of the table data
interface PurchaseHistory {
  id: string;
  purchaseDate: string;
  numberOfTokens: number;
  purchasePricePerToken: number;
  purchaseStatus: string;
  availableToSell: number;
  purchaseTxHash: string;
}

const Reports: React.FC = () => {
  console.log('Reports component rendered');
  const dispatch = useDispatch();

  const { loading = false, success = false, error, purchaseHistory } = useSelector((state: RootState) => state.tokenPurchase);

  console.log('purchaseHistory:', purchaseHistory);

  const { asset_id } = useParams(); // Extracts `id` from the URL
  
  const assetID = asset_id ?? ''; // Use the correct asset ID
  const userID = localStorage.getItem('userID') ?? '';

  useEffect(() => {
    dispatch(fetchPurchaseHistoryByAssetIDAndUserID({ assetID, userID }));
  }, [dispatch, assetID, userID]);

  const shortenAddress = (address: string) => {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const formatReadableDate = (isoDate: string): string => {
    const date = new Date(isoDate);
  
    // Options for date formatting
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      // hour: '2-digit',
      // minute: '2-digit',
      // second: '2-digit',
      // timeZoneName: 'short',
    };
  
    return date.toLocaleDateString('en-US', options);
  };
  
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Purchase Orders</h2>
      {purchaseHistory.length === 0 ? (
        <div className={styles.noDataMessage}>You do not have any Purchases</div>
      ) : (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Purchase Date</th>
              <th>Number of Tokens</th>
              <th>Purchase Price per Token</th>
              <th>Purchase Status</th>
              <th>Available to Sell</th>
              <th>Transaction Hash</th>
            </tr>
          </thead>
          <tbody>
            {purchaseHistory.map((order) => (
              <tr key={order?.id}>
                <td>{order?.id}</td>
                <td>{formatReadableDate(order.purchaseDate)}</td>
                <td>{order.numberOfTokens}</td>
                <td>{`${order.purchasePricePerToken} USDC`}</td>
                <td>{order.purchaseStatus}</td>
                <td>{order.availableToSell}</td>
                <td>
                    <a
                      href={`https://sepolia.etherscan.io/tx/${order.purchaseTxHash}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {shortenAddress(order.purchaseTxHash)}
                    </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Reports;
