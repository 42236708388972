// src/features/messaging/messagingSaga.ts

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createMessageApi,
  markMessageAsReadApi,
  getMessagesApi,
  getMessageThreadsApi,
} from './messagingApi';
import {
  createMessageRequest,
  createMessageSuccess,
  createMessageFailure,
  markMessageAsReadRequest,
  markMessageAsReadSuccess,
  markMessageAsReadFailure,
  getMessagesRequest,
  getMessagesSuccess,
  getMessagesFailure,
  getMessageThreadsRequest,
  getMessageThreadsSuccess,
  getMessageThreadsFailure,
} from './messagingSlice';

export interface ResponseGenerator{
    config?:any,
    data?:any,
    headers?:any,
    request?:any,
    status?:number,
    statusText?:string
  }



function* createMessageSaga(action: ReturnType<typeof createMessageRequest>) {
  try {
    const response: ResponseGenerator = yield call(createMessageApi, action.payload);
    yield put(createMessageSuccess(response?.data));
  } catch (error: any) {
    yield put(createMessageFailure(error.message));
  }
}

function* markMessageAsReadSaga(action: ReturnType<typeof markMessageAsReadRequest>) {
  try {
    const response: ResponseGenerator = yield call(markMessageAsReadApi, action.payload);
    yield put(markMessageAsReadSuccess(response?.data));
  } catch (error: any) {
    yield put(markMessageAsReadFailure(error.message));
  }
}

function* getMessagesSaga(action: ReturnType<typeof getMessagesRequest>) {
  try {
    const response: ResponseGenerator = yield call(getMessagesApi, action.payload.userId, action.payload.assetId);
    yield put(getMessagesSuccess(response?.data));
  } catch (error: any) {
    yield put(getMessagesFailure(error.message));
  }
}

function* getMessageThreadsSaga(action: ReturnType<typeof getMessageThreadsRequest>) {
  try {
    const response: ResponseGenerator = yield call(getMessageThreadsApi, action.payload);
    yield put(getMessageThreadsSuccess(response?.data));
  } catch (error: any) {
    yield put(getMessageThreadsFailure(error.message));
  }
}

export default function* messagingSaga() {
  yield takeLatest(createMessageRequest.type, createMessageSaga);
  yield takeLatest(markMessageAsReadRequest.type, markMessageAsReadSaga);
  yield takeLatest(getMessagesRequest.type, getMessagesSaga);
  yield takeLatest(getMessageThreadsRequest.type, getMessageThreadsSaga);
}
