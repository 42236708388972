import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AssetStage {
  id: string;
  name: string;
  // Add other fields as needed
}

export interface AssetStages {
  assetID: string;
  assetStage: string;
  stagesDetails: AssetStage[];
}

interface AssetStagesState {
  data: AssetStages | null;
  loading: boolean;
  error: string | null;
  successMessage: string | null;
}

const initialState: AssetStagesState = {
  data: null,
  loading: false,
  error: null,
  successMessage: null,
};

const assetStagesSlice = createSlice({
  name: 'assetStages',
  initialState,
  reducers: {
    // -- Fetch asset stages --
    fetchAssetStagesRequest: (
      state,
      action: PayloadAction<{ assetID: string }>
    ) => {
      state.loading = true;
      state.error = null;
      state.successMessage = null;
    },
    fetchAssetStagesSuccess: (state, action: PayloadAction<AssetStages>) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchAssetStagesFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

    // -- Create or update asset stages --
    createOrUpdateAssetStagesRequest: (state, _action: PayloadAction<any>) => {
      state.loading = true;
      state.error = null;
      state.successMessage = null;
    },
    createOrUpdateAssetStagesSuccess: (state, action: PayloadAction<AssetStages>) => {
      state.loading = false;
      // We assume the response is an updated or newly created AssetStages object
      state.data = action.payload;
      state.successMessage = 'Asset stages created/updated successfully';
    },
    createOrUpdateAssetStagesFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

    // -- Optionally reset messages or data if needed --
    resetAssetStagesMessages: (state) => {
      state.error = null;
      state.successMessage = null;
    },
  },
});

export const {
  fetchAssetStagesRequest,
  fetchAssetStagesSuccess,
  fetchAssetStagesFailure,
  createOrUpdateAssetStagesRequest,
  createOrUpdateAssetStagesSuccess,
  createOrUpdateAssetStagesFailure,
  resetAssetStagesMessages,
} = assetStagesSlice.actions;

export default assetStagesSlice.reducer;
