import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl + '/user-profile';

// API call to fetch a user profile by user ID
export const fetchUserProfileApi = async (userId: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch user profile');
  }
};

// API call to create a user profile
export const createUserProfileApi = async (profileData: any) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/create`, profileData);
    return response.data;
  } catch (error) {
    //throw new Error(error.response?.data?.message || 'Failed to create profile');
    throw new Error('Failed to create profile');
  }
};

// API call to update user address
export const updateUserAddressApi = async (userID: string, addressData: any) => {
  console.log('updateUserAddressApi');
  console.log(userID);
  console.log(addressData);
  try {
    const response = await axios.put(`${API_BASE_URL}/update-address/${userID}`, addressData);
    return response.data;
  } catch (error) {
    //throw new Error(error.response?.data?.message || 'Failed to update address');
    throw new Error('Failed to update address');
  }
};

export const updateUserProfilePicApi = async (userId: string, profilePicNumber: number) => {
    try {
    //   const formData = new FormData();
    //   formData.append('profilePic', profilePic);
  
    //   const response = await axios.put(`${API_BASE_URL}/update-profile-pic/${userId}`, formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   });

      const response = await axios.patch(`${API_BASE_URL}/update-profile-pic-number/${userId}`, {
        profilePicNumber: profilePicNumber });
      return response.data;
    } catch (error) {
      throw new Error('Failed to update profile picture');
    }
  };

  export const getDisplayNameByUserID = async (userID: string) => {
    const response = await axios.get(`${API_BASE_URL}/display-name/${userID}`);
    console.log('getDisplayNameByUserID');
    console.log(response.data);
    return response.data;
  };