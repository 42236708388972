// src/components/AssetDetailEditor/AssetBasicDetails.tsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRulerCombined, faBed, faBath, faDoorClosed, faBuilding, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import styles from './AssetOverview.module.css';

interface AssetOverviewProps {
  area: string;
  beds: string;
  baths: string;
  rooms: string;
  floors: string;
  garage: string;
  propertyType: string;
  builtYear: number;
}

const AssetOverview: React.FC<AssetOverviewProps> = ({ area, beds, baths, rooms, floors, garage, propertyType, builtYear }) => {
  return (
    <div className={styles.basicDetailsContainer}>
      <h2 className={styles.basicDetailsHeading}>Overview</h2>
      <div className={styles.detailsGrid}>
        <div className={styles.detailItem}>
          <FontAwesomeIcon icon={faRulerCombined} className={styles.overviewIcon} />
          <div className={styles.detailText}>
            Property Type: <span className={styles.detailValue}>{propertyType}</span>
          </div>
        </div>
        <div className={styles.detailItem}>
          <FontAwesomeIcon icon={faBed} className={styles.overviewIcon} />
          <div className={styles.detailText}>
            Built Year: <span className={styles.detailValue}>{builtYear}</span>
          </div>
        </div>
        <div className={styles.detailItem}>
          <FontAwesomeIcon icon={faBed} className={styles.overviewIcon} />
          <div className={styles.detailText}>
            Area: <span className={styles.detailValue}>{area}</span>
          </div>
        </div>
        <div className={styles.detailItem}>
          <FontAwesomeIcon icon={faBed} className={styles.overviewIcon} />
          <div className={styles.detailText}>
            Beds: <span className={styles.detailValue}>{beds}</span>
          </div>
        </div>
        <div className={styles.detailItem}>
          <FontAwesomeIcon icon={faBath} className={styles.overviewIcon} />
          <div className={styles.detailText}>
            Baths: <span className={styles.detailValue}>{baths}</span>
          </div>
        </div>
        <div className={styles.detailItem}>
          <FontAwesomeIcon icon={faDoorClosed} className={styles.overviewIcon} />
          <div className={styles.detailText}>
            Rooms: <span className={styles.detailValue}>{rooms}</span>
          </div>
        </div>
        <div className={styles.detailItem}>
          <FontAwesomeIcon icon={faBuilding} className={styles.overviewIcon} />
          <div className={styles.detailText}>
            Floors: <span className={styles.detailValue}>{floors}</span>
          </div>
        </div>
        <div className={styles.detailItem}>
          <FontAwesomeIcon icon={faWarehouse} className={styles.overviewIcon} />
          <div className={styles.detailText}>
            Garage: <span className={styles.detailValue}>{garage}</span>
          </div>
        </div>
      </div>
      {/* <p className={styles.descriptionText}>{description}</p> */}
    </div>
  );
};

export default AssetOverview;
