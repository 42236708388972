// features/auth/authSaga.ts

import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { loginRequest, loginSuccess, loginFailure } from './authSlice';
import { registerRequest, registerSuccess, registerFailure } from './authSlice';
import { logoutRequest, logoutSuccess, logoutFailure } from './authSlice';
import { validateTokenRequest,  validateTokenSuccess, validateTokenFailure,
  refreshTokenRefreshRequest, refreshTokenRefreshSuccess, refreshTokenRefreshFailure,
  changePasswordRequest, changePasswordSuccess, changePasswordFailure,
  verifyEmailRequest, verifyEmailSuccess, verifyEmailFailure,
  requestPasswordResetRequest, requestPasswordResetSuccess, requestPasswordResetFailure,
  resetPasswordRequest, resetPasswordSuccess, resetPasswordFailure,
  resendEmailVerificationRequest, resendEmailVerificationSuccess, resendEmailVerificationFailure,
  checkEmailVerifiedRequest, checkEmailVerifiedSuccess, checkEmailVerifiedFailure,
 } from './authSlice';
import { registerApi, loginApi, logoutApi, validateTokenApi, refreshTokenRefreshApi, changePasswordApi,
  verifyEmailApi, requestPasswordResetApi, resetPasswordApi, resendEmailVerificationApi, checkEmailVerifiedAPI
 } from './authApi';
import { PayloadAction } from '@reduxjs/toolkit';

export interface ResponseGenerator{
  config?:any,
  data?:any,
  headers?:any,
  request?:any,
  status?:number,
  statusText?:string
}
// Dummy login API function
const loginAPI = async (email: string, password: string) => {
  // Simulate API call
  return new Promise<boolean>((resolve, reject) => {
    setTimeout(() => {
      if (email === 'admin' && password === 'admin') {
        resolve(true); // Login successful
      } else {
        reject('Invalid email or password'); // Login failed
      }
    }, 1000); // Simulate delay of 1 second
  });
};

function* handleLogin(action: PayloadAction<{ email: string; password: string }>) {
  try {
    const response: ResponseGenerator =yield call(loginApi, action.payload);
    console.log('handleLogin ', response);
    const user = response?.data?.loggedInUser;
    console.log('handleLogin ', user);
    if (user) {
      yield put(loginSuccess(user));
    } else {
      yield put(loginFailure('Invalid username or password'));
    }
  } catch (error) {
    yield put(loginFailure('An error occurred while logging in'));
  }
}

function* handleRegister(action: PayloadAction<{ email: string; password: string }>) {
  try {
    const response: ResponseGenerator = yield call(registerApi, action.payload);
    yield put(registerSuccess());
  } catch (error: any) {
    console.log('Error:', error?.response?.data?.message);
    const errorMessage = error?.response?.data?.message || 'An error occurred while registering';
    yield put(registerFailure(errorMessage));
  }
}

function* handleLogout(action: PayloadAction) {
  try {
    const response: ResponseGenerator = yield call(logoutApi);
    yield put(logoutSuccess());
  } catch (error: any) {
    console.log('Error:', error?.response?.data?.message);
    const errorMessage = error?.response?.data?.message || 'An error occurred while logout';
    yield put(logoutFailure(errorMessage));
  }
}


function* handleValidateToken(action: PayloadAction) {
  try {
    const response: ResponseGenerator = yield call(validateTokenApi);
    yield put(validateTokenSuccess());
  } catch (error: any) {
    console.log('Error:', error?.response?.data?.message);
    const errorMessage = error?.response?.data?.message || 'An error occurred while logout';
    yield put(validateTokenFailure(errorMessage));
  }
}


function* handleRefreshTokenRefresh(action: PayloadAction) {
  try {
    const response: ResponseGenerator = yield call(refreshTokenRefreshApi);
    yield put(refreshTokenRefreshSuccess(response));
  } catch (error: any) {
    console.log('Error:', error?.response?.data?.message);
    const errorMessage = error?.response?.data?.message || 'An error occurred while token refresh';
    yield put(refreshTokenRefreshFailure(errorMessage));
  }
}

function* changePasswordSaga(action: ReturnType<typeof changePasswordRequest>) {
  try {
    const { userID, currentPassword, newPassword } = action.payload;
    const response: ResponseGenerator = yield call(changePasswordApi, userID, currentPassword, newPassword);
    yield put(changePasswordSuccess('Password changed successfully'));
  } catch (error: any) {
    yield put(changePasswordFailure(error.response?.data?.message || 'Failed to change password'));
  }
}

function* handleVerifyEmail(action: PayloadAction<string>) {
  try {
    const response: ResponseGenerator = yield call(verifyEmailApi, action.payload);
    yield put(verifyEmailSuccess());
  } catch (error: any) {
    console.log('Error:', error?.response?.data?.message);
    const errorMessage = error?.response?.data?.message || 'An error occurred while verifying email';
    yield put(verifyEmailFailure(errorMessage));
  }
}

function* requestPasswordResetSaga(action: PayloadAction<string>) {
  try {
    // const { email } = action.payload;
    const response: ResponseGenerator = yield call(requestPasswordResetApi, action.payload);
    yield put(requestPasswordResetSuccess());
  } catch (error) {
    yield put(requestPasswordResetFailure('Failed to reset password'));
  }
}

function* resetPasswordSaga(action: PayloadAction<{ token: string; newPassword: string }>) {
  try {
    const { token, newPassword } = action.payload;
    yield call(resetPasswordApi, token, newPassword);
    yield put(resetPasswordSuccess());
  } catch (error) {
    yield put(resetPasswordFailure('Failed to reset password'));
  }
}

function* handleResendEmailVerification(action: any): Generator<any, void, any> {
  try {
    const response: ResponseGenerator = yield call(resendEmailVerificationApi, action.payload?.userId);
    yield put(resendEmailVerificationSuccess(response));
  } catch (error) {
    yield put(resendEmailVerificationFailure('Failed to resend email verification'));
  }
}

function* handleCheckEmailVerified(action: PayloadAction<string>) {
  try {
    const response: ResponseGenerator = yield call(checkEmailVerifiedAPI, action.payload);
    yield put(checkEmailVerifiedSuccess(response));
  } catch (error) {
    yield put(checkEmailVerifiedFailure('Failed to check email verification'));
  }
}

function* authSaga() {
  yield takeEvery(loginRequest.type, handleLogin);
  yield takeLatest(registerRequest.type, handleRegister);
  yield takeEvery(logoutRequest.type, handleLogout);
  yield takeEvery(validateTokenRequest.type, handleValidateToken);
  yield takeEvery(refreshTokenRefreshRequest.type, handleRefreshTokenRefresh);
  yield takeLatest(changePasswordRequest.type, changePasswordSaga);
  yield takeLatest(verifyEmailRequest.type, handleVerifyEmail);
  yield takeLatest(requestPasswordResetRequest.type, requestPasswordResetSaga);
  yield takeLatest(resetPasswordRequest.type, resetPasswordSaga);
  yield takeLatest(resendEmailVerificationRequest.type, handleResendEmailVerification);
  yield takeLatest(checkEmailVerifiedRequest.type, handleCheckEmailVerified);

}

export default authSaga;
