// src/components/AssetDetailEditor/AssetPage.tsx
import React, { useEffect, useState } from 'react';
import './AssetPageBase.module.css';
//import AssetCarousel from './AssetCarousel';
import AssetHeader from '../AssetHeader/AssetHeader';
// import AssetBasicDetails from '../AssetBasicDetails/AssetBasicDetails';
// import AssetFeatures from '../AssetFeatures/AssetFeatures';
// import AssetLocation from '../AssetLocation/AssetLocation';
// import AssetBlockchain from '../AssetBlockchain/AssetBlockchain';
// import AssetValuationSample from '../AssetValuation/AssetValuation1';
// import AssetValuation from '../AssetValuation/AssetValuation';
// import MyTokenPurchases from '../MyTokenPurchases/MyTokenPurchases';
// import TokenOffering from '../TokenOffering/TokenOffering';
// import PurchaseToken from '../PurchaseToken/PurchaseToken';
// import UserPurchases from '../UserPurchases/UserPurchases';
import HorizontalTabs from '../../../HorizontalTabs/HorizontalTabs';
import AssetBasicDetailsHolder from '../../AssetBasicDetails/AssetBasicDetailsHolder';
import AssetInvestHolder from '../../AssetInvest/AssetInvestHolder';
import InvestmentAppreciationHolder from '../../InvestmentAppreciationHolder/InvestmentAppreciationHolder';
import AssetBlockchainDetailsHolder from '../../AssetBlockchainDetailsHolder/AssetBlockchainDetailsHolder';
import Breadcrumb from '../../../Breadcrumb/Breadcrumb';
import UserTokenPurchases from '../../UserTokenPurchases/UserTokenPurchasesHolder';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/rootReducer';
import { getBasicDetailsRequest, getLocationDetailsRequest } from '../../../../../features/assetEditor/assetEditorSlice';
import IncomeDetailsHolder from '../../AssetIncomeDetails/AssetIncomeDetailsHolder';
import AssetIncomeDetailsHolder from '../../AssetIncomeDetails/AssetIncomeDetailsHolder';
import { useParams } from 'react-router-dom';
import DividendDistributionsHolder from '../../DividendDistributionsHolder/DividendDistributionsHolder';
import UserHoldingHolder from '../../UserHoldingHolder/UserHoldingHolder';
import UserHoldingsHolder from '../../UserHoldingsHolder/UserHoldingsHolder';

const AssetPage: React.FC = () => {
  const dispatch = useDispatch();
  const { basicDetails, addressDetails } = useSelector((state: RootState) => state.assetEditor);
  console.log('AssetPage new addressDetails ', addressDetails);
  console.log('basicDetails ', basicDetails);
  const [fullAddress, setFullAddress] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');

  const { asset_id } = useParams(); // Extracts `id` from the URL
  console.log('AssetBasicDetailsHolderId asset_id ', asset_id);

  useEffect(() => {
    dispatch(getBasicDetailsRequest(asset_id ?? ''));
    dispatch(getLocationDetailsRequest(asset_id ?? ''));
  }, [dispatch]);

  // useEffect(() => {
  //   const addressLine1 = `${addressDetails?.streetAddress1}, ${addressDetails?.streetAddress2}`;
  //   setAddressLine1(addressLine1);
  //   const addressLine2 = `${addressDetails?.city}, ${addressDetails?.state} ${addressDetails?.zipcode}`;
  //   setAddressLine2(addressLine2);
  // }, [addressDetails]);

  const tabs = [
    { label: 'Asset Details', content: <AssetBasicDetailsHolder /> },
    // { label: 'Asset Income', content: <AssetIncomeDetailsHolder /> },
    { label: 'Invest in this asset', content: <AssetInvestHolder /> },
    { label: 'Dividend Distribution', content: <DividendDistributionsHolder /> },
    // { label: 'Asset in Blockchain', content: <AssetBlockchainDetailsHolder /> },
    { label: 'Investment Appreciation', content: <InvestmentAppreciationHolder /> },
    // { label: 'My Holdings', content: <UserHoldingHolder /> },
    { label: 'My Holdings', content: <UserHoldingsHolder /> },

  ];

  const breadcrumbProperties = {
        asset: {
            title: basicDetails.assetTitle,
        }
    }


  return (
    <div className="assetPageBaseContainer" style={{ 
        padding: '0px 5px', 
        backgroundColor: '#f9f9f9'         
      }}>

        {/* <Breadcrumb breadcrumbProperties={breadcrumbProperties} /> */}
        <AssetHeader title={basicDetails.assetTitle}
                      price={''} propertyID={basicDetails.propertyID}
                      province={basicDetails.province} country={basicDetails.country} 
                      addressLine1={''} addressLine2={''} />
        <HorizontalTabs tabs={tabs} />
    </div>
  );
};

export default AssetPage;
