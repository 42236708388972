
import { call, put, takeLatest } from 'redux-saga/effects';
import { fecthAllMonthlyTokens, fecthPropertInvested, 
    fetchTotalIncome, fetchTotalTokensPurchased, submitPurchaseRequestApi, 
    getPurchaseHistoryByAssetIDAndUserIDApi,
    getTotalTokenPurchaseByAssetID, getTotalTokenPurchaseByAssetIDAndUserID } from './tokenPurchaseApi';
import {
    fetchTotalTokensPurchasedRequest,
    fetchTotalTokensPurchasedSuccess,
    fetchTotalTokensPurchasedFailure,
    fetchPropertyInvestedResponse,
    fetchPropertyInvestedRequest,
    fetchAllMonthlyTokensRequest,
    fetchAllMonthlyTokensResponse,
    fetchTotalIncomeReceived,
    fetchTotalIncomeReceivedResponse,
    submitPurchaseRequest,
    submitPurchaseRequestSuccess,
    submitPurchaseRequestFailure,
    fetchPurchaseHistoryByAssetIDAndUserID,
    fetchPurchaseHistoryByAssetIDAndUserIDSuccess,
    fetchPurchaseHistoryByAssetIDAndUserIDFailure,
    fetchTotalTokenPurchaseByAssetID,
    fetchTotalTokenPurchaseByAssetIDSuccess,
    fetchTotalTokenPurchaseByAssetIDFailure,
    fetchTotalTokenPurchaseByAssetIDAndUserID,
    fetchTotalTokenPurchaseByAssetIDAndUserIDSuccess,
    fetchTotalTokenPurchaseByAssetIDAndUserIDFailure,
} from './tokenPurchaseSlice';
import { PayloadAction } from '@reduxjs/toolkit';


export interface ResponseGenerator{
    config?:any,
    data?:any,
    headers?:any,
    request?:any,
    status?:number,
    statusText?:string
  }


function* handleFetchTotalTokensPurchased(action: PayloadAction<{assetID: string, userID: string}>) {
    console.log('handleFetchTotalTokensPurchased ', action.payload);
    try {
        const { assetID, userID } = action.payload;
        const response: ResponseGenerator = yield call(fetchTotalTokensPurchased, assetID, userID);
        yield put(fetchTotalTokensPurchasedSuccess(response?.data?.count));
    } catch (error: any) {
        yield put(fetchTotalTokensPurchasedFailure(error.message));
    }
}

function* fetchPropertyInvested(action: PayloadAction<{userID: string}>){
    try{
        const response : ResponseGenerator = yield call(fecthPropertInvested,action.payload);
        yield put(fetchPropertyInvestedResponse(response?.data))
    }catch(error:any){
        yield put(fetchTotalTokensPurchasedFailure(error.message));
    }
}

function* fetchAllMonthlytoken(action: PayloadAction<{userID: string}>){
    try{
        const response : ResponseGenerator = yield call(fecthAllMonthlyTokens,action.payload);
        yield put(fetchAllMonthlyTokensResponse(response?.data))
    }catch(error:any){
        yield put(fetchTotalTokensPurchasedFailure(error.message));

    }
}

function* fetchIncomeReceived(action: PayloadAction<{userID : string}>){
    try{
        const response : ResponseGenerator = yield call(fetchTotalIncome,action.payload);
        yield put(fetchTotalIncomeReceivedResponse(response?.data))
    }catch(error:any){
        yield put(fetchTotalTokensPurchasedFailure(error.message));
    }
}

function* handleSubmitPurchaseRequest(action: ReturnType<typeof submitPurchaseRequest>) {
    try {
      const response: ResponseGenerator = yield call(submitPurchaseRequestApi, action.payload);
      yield put(submitPurchaseRequestSuccess(response));
    } catch (error) {
      yield put(submitPurchaseRequestFailure('An error occurred while submitting purchase request'));
    }
  }
  
  function* handleFetchPurchaseHistoryByAssetIDAndUserID(action: ReturnType<typeof fetchPurchaseHistoryByAssetIDAndUserID>) {
    try {
      console.log('saga fetchPurchaseHistoryByAssetIDAndUserID:', action.payload);
      const { assetID, userID } = action.payload;
      const response: ResponseGenerator = yield call(getPurchaseHistoryByAssetIDAndUserIDApi, assetID, userID);
      console.log('fetchPurchaseHistoryByAssetIDAndUserID response:', response);
      yield put(fetchPurchaseHistoryByAssetIDAndUserIDSuccess(response.data));
    } catch (error) {
      yield put(fetchPurchaseHistoryByAssetIDAndUserIDFailure('An error occurred while fetching purchase history'));
    }
  }

  function* handleFetchTotalTokenPurchaseByAssetID(action: ReturnType<typeof fetchTotalTokenPurchaseByAssetID>) {
    try {
      const response: ResponseGenerator = yield call(getTotalTokenPurchaseByAssetID, action.payload);
      yield put(fetchTotalTokenPurchaseByAssetIDSuccess(response.data?.totalTokens));
    } catch (error) {
      yield put(fetchTotalTokenPurchaseByAssetIDFailure('An error occurred while fetching total count of token purchase'));
    }
  }
  
  function* handleFetchTotalTokenPurchaseByAssetIDAndUserID(action: ReturnType<typeof fetchTotalTokenPurchaseByAssetIDAndUserID>) {
    try {
      const response: ResponseGenerator = yield call(getTotalTokenPurchaseByAssetIDAndUserID, action.payload.assetID, action.payload.userID);
      yield put(fetchTotalTokenPurchaseByAssetIDAndUserIDSuccess(response.data?.count));
    } catch (error) {
      yield put(fetchTotalTokenPurchaseByAssetIDAndUserIDFailure('An error occurred while fetching total count of token purchase'));
    }
  }
  

export function* watchTokenPurchase() {
    yield takeLatest(fetchTotalTokensPurchasedRequest.type, handleFetchTotalTokensPurchased);
    yield takeLatest(fetchPropertyInvestedRequest.type, fetchPropertyInvested);
    yield takeLatest(fetchAllMonthlyTokensRequest.type, fetchAllMonthlytoken);
    yield takeLatest(fetchTotalIncomeReceived.type, fetchIncomeReceived)
    yield takeLatest(submitPurchaseRequest.type, handleSubmitPurchaseRequest);
    yield takeLatest(fetchPurchaseHistoryByAssetIDAndUserID.type, handleFetchPurchaseHistoryByAssetIDAndUserID);
    yield takeLatest(fetchTotalTokenPurchaseByAssetID.type, handleFetchTotalTokenPurchaseByAssetID);
    yield takeLatest(fetchTotalTokenPurchaseByAssetIDAndUserID.type, handleFetchTotalTokenPurchaseByAssetIDAndUserID);

}
