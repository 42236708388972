import { Link } from "react-router-dom"
import styles from "../HomePage.module.css"
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';


export const Footer =()=>{
    return(
        <footer className={styles.footer}>
        <div className={styles.footerContent}>
          <div>
            <p>&copy; 2024 AssetSlices</p>
          </div>
          <div className={styles.footerLinks}>
            <Link to="/faq">FAQ</Link>
            <Link to="/policy/privacy">Privacy Policy</Link>
            <Link to="/policy/cookie">Cookie Policy</Link>
          </div>
          <div className={styles.socialMedia}>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          </div>
        </div>
      </footer>
    )
}