import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest } from '../../../features/auth/authSlice';
import { RootState } from '../../../store/rootReducer';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';


const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, user, error } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(user.redirectUrl);
    }
  }, [isLoggedIn, navigate]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = () => {
    if (!validateEmail(email)) {
      setEmailError('Invalid email format');
      return;
    }
    setEmailError('');
    dispatch(loginRequest({ email, password }));
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const handleEmailBlur = () => {
    if (!validateEmail(email)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };


  return (
    <div>
        {/* Display error notification if there's an error */}
        {error && <div className={styles.errorNotification}>{error}</div>}

        {/* <h2>Login</h2> */}
        <div className={styles.inputField}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className={emailError ? styles.invalid : ''}
            onBlur={handleEmailBlur} // Validate on blur
          />
          {emailError && <span className={styles.errorMessage}>{emailError}</span>}
        </div>
        <div className={styles.inputField}>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </div>
        <button className={styles.button} onClick={handleLogin}>
          Login
        </button>
        <div className={styles.linksContainer}>
          <a className={styles.disabledLink} >Register</a>
          {/* <a className={styles.link} href="/register">Register</a> */}
          <a className={styles.link} href="/forgot-password">Forgot Password?</a>
        </div>
    </div>
  );
};

export default Login;
