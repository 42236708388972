// src/features/assetSettings/assetSettingsSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AssetSettingsState {
  settings: any | null;
  loading: boolean;
  error: string | null;
}

const initialState: AssetSettingsState = {
  settings: null,
  loading: false,
  error: null,
};

const assetSettingsSlice = createSlice({
  name: 'assetSettings',
  initialState,
  reducers: {
    fetchAssetSettingsRequest(state, action: PayloadAction<string>) {
      state.loading = true;
      state.error = null;
    },
    fetchAssetSettingsSuccess(state, action: PayloadAction<any>) {
      state.settings = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchAssetSettingsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    saveAssetSettingsRequest(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    saveAssetSettingsSuccess(state, action: PayloadAction<any>) {
      console.log('saveAssetSettingsSuccess:', action.payload);
      state.loading = false;
      state.error = null;
    },
    saveAssetSettingsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    updateAssetSettingsRequest(state, action: PayloadAction<{ assetID: string, data: any }>) {
      state.loading = true;
      state.error = null;
    },
    updateAssetSettingsSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    updateAssetSettingsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchAssetSettingsRequest,
  fetchAssetSettingsSuccess,
  fetchAssetSettingsFailure,
  saveAssetSettingsRequest,
  saveAssetSettingsSuccess,
  saveAssetSettingsFailure,
  updateAssetSettingsRequest,
  updateAssetSettingsSuccess,
  updateAssetSettingsFailure,
} = assetSettingsSlice.actions;

export default assetSettingsSlice.reducer;
