import React from 'react';
import styles from './TermsAndConditions.module.css';


const TermsAndService = () => {
    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <header className={styles.header}>
                    <h1 className={styles.mainTitle}>AssetSlices Terms of Service</h1>
                    {/* <p className={styles.subTitle}> Cookie &amp; Conditions</p> */}
                </header>      
            </div>

            {/* <h1>Terms of Service</h1> */}
            <p className={styles.policyDate}>
                <strong>Effective Date:</strong> 24 Dec 2024
            </p>
            <p className={styles.policyDate}>
                <strong>Last Updated:</strong> 24 Dec 2024
            </p>


            <div className={styles.policySection}>
                <p>Welcome to AssetSlices! By accessing or using our website (www.assetslices.com), you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree, please discontinue your use of our services.</p>

                <h2>1. Definitions</h2>
                <ul>
                    <li><strong>“AssetSlices,” “we,” “our,” “us”:</strong> Refers to the website and its operators.</li>
                    <li><strong>“User,” “you,” “your”:</strong> Any individual accessing or using the website.</li>
                    <li><strong>“Services”:</strong> The services provided through www.assetslices.com, including asset investments, blockchain-based transactions, and related features.</li>
                </ul>

                <h2>2. Eligibility</h2>
                <p>To use our services, you must be at least 18 years old and legally capable of entering into binding contracts.</p>

                <h2>3. Account Registration</h2>
                <p>You must create an account to access certain features. Provide accurate and up-to-date information. You are responsible for maintaining the confidentiality of your account details.</p>

                <h2>4. Services Overview</h2>
                <p>AssetSlices facilitates real estate investment through blockchain technology. Users can purchase fractional investments in listed assets under specified terms.</p>

                <h2>5. Investment Disclaimer</h2>
                <p>Investments involve risks, including potential loss of funds. We do not guarantee returns. You are encouraged to conduct your due diligence before investing.</p>

                <h2>6. User Obligations</h2>
                <p>Use the website lawfully. Refrain from any fraudulent activities or security breaches. Respect intellectual property rights.</p>

                <h2>7. Fees and Payments</h2>
                <p>Applicable fees will be disclosed before transactions. All payments are processed securely. Non-payment or chargebacks may result in service suspension.</p>

                <h2>8. Intellectual Property</h2>
                <p>All website content, trademarks, and proprietary information are owned by AssetSlices or its licensors. You may not copy, modify, or distribute any content without our consent.</p>

                <h2>9. Data Privacy</h2>
                <p>Your personal information will be processed according to our <a href="#">Privacy Policy</a>.</p>

                <h2>10. Termination</h2>
                <p>We may terminate or suspend your access for violations of these Terms. You may delete your account at any time.</p>

                <h2>11. Limitation of Liability</h2>
                <p>We are not liable for indirect, incidental, or consequential damages arising from your use of the website.</p>

                <h2>12. Indemnification</h2>
                <p>You agree to indemnify and hold us harmless from any claims or liabilities resulting from your breach of these Terms.</p>

                <h2>13. Governing Law</h2>
                <p>These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction].</p>

                <h2>14. Changes to Terms</h2>
                <p>We may update these Terms from time to time. Any changes will be communicated through our website.</p>

                <h2>15. Contact Us</h2>
                <p>For questions about these Terms, please contact us at <a href="mailto:support@assetslices.com">support@assetslices.com</a>.</p>

                <p>By using www.assetslices.com, you acknowledge that you have read, understood, and agreed to these Terms of Service.</p>
            </div>
        </div>
    );
};

export default TermsAndService;
