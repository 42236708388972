// app/pages/UserPage.tsx

import React, { useEffect, useRef, useState } from 'react';
import TopBanner from '../TopBanner/TopBanner';
import { Outlet, useParams } from 'react-router-dom';
import styles from './MainSection.module.css';
import Footer from '../Footer/Footer';
import LeftSection from './LeftSection/LeftSection';
import RightSection from './RightSection/RightSection';
import LeftMenu from './LeftMenu/LeftMenu';
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosArrowDropleftCircle } from "react-icons/io";

const MainSection:  React.FC<any> = (props) => {
  const { id } = useParams(); // Extracts `id` from the URL
const rightsectionRef = useRef<HTMLDivElement>(null);
 
 const handleClick = ()=>{
    props?.evenListener(false)
 }


 useEffect(()=>{
  rightsectionRef.current?.addEventListener('click',handleClick)
 },[])
  return (
    <div className={styles.container}>
      <div className={styles.left_menu_desktop}><LeftSection /></div>
      {/* <div className={styles.menu_body_mobile}><GiHamburgerMenu size={30} color='white' onClick={() => sideBar('open')} /></div> */}
      { props?.type &&<div style={{ position: 'fixed',zIndex:100 }}><LeftSection /></div>}
      <div className={styles.conatiner_width} ref={rightsectionRef}>
      <RightSection />
      </div>
    </div>
  );
};

export default MainSection;
