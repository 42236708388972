import { call, put, takeLatest } from 'redux-saga/effects';
import { addCompanyApi, getAllCompaniesApi, getCompaniesByTypeApi } from './backendPartnerApi';
import {
  addCompanyRequest,
  addCompanySuccess,
  addCompanyFailure,
  getAllCompaniesRequest,
  getAllCompaniesSuccess,
  getAllCompaniesFailure,
  getCompaniesByTypeRequest,
  getCompaniesByTypeSuccess,
  getCompaniesByTypeFailure,
} from './backendPartnerSlice';
import { PayloadAction } from '@reduxjs/toolkit';

interface ResponseGenerator {
  data?: any;
}

// Handle add company saga
function* handleAddCompany(action: PayloadAction<any>) {
  try {
    const response: ResponseGenerator = yield call(addCompanyApi, action.payload);
    yield put(addCompanySuccess(response.data));
  } catch (error: any) {
    yield put(addCompanyFailure('An error occurred while adding company'));
  }
}

// Handle get all companies saga
function* handleGetAllCompanies() {
  try {
    const response: ResponseGenerator = yield call(getAllCompaniesApi);
    console.log('handleGetAllCompanies response', response);
    yield put(getAllCompaniesSuccess(response.data));
  } catch (error: any) {
    yield put(getAllCompaniesFailure('An error occurred while fetching companies'));
  }
}

// Handle get companies by type saga
function* handleGetCompaniesByType(action: PayloadAction<string>) {
  try {
    const response: ResponseGenerator = yield call(getCompaniesByTypeApi, action.payload);
    console.log('handleGetCompaniesByType response', response);
    yield put(getCompaniesByTypeSuccess(response.data));
  } catch (error: any) {
    yield put(getCompaniesByTypeFailure('An error occurred while fetching companies by type'));
  }
}

// Watcher saga
export default function* companySaga() {
  yield takeLatest(addCompanyRequest.type, handleAddCompany);
  yield takeLatest(getAllCompaniesRequest.type, handleGetAllCompanies);
  yield takeLatest(getCompaniesByTypeRequest.type, handleGetCompaniesByType);
}
