import React from "react";
import styles from "./NFTGoldenPassView.module.css";

interface NFTGoldenPassViewProps {
  imageUrl: string;
  alignImage: "left" | "right"; // Prop to control image alignment
}

const NFTGoldenPassView: React.FC<NFTGoldenPassViewProps> = ({
  imageUrl,
  alignImage,
}) => {
  const handlePurchaseClick = () => {
    // Open OpenSea in a new tab
    window.open("https://opensea.io/", "_blank");
  };

  return (
    <div
      className={`${styles.container} ${
        alignImage === "left" ? styles.leftAligned : styles.rightAligned
      }`}
    >
      <div className={styles.image}>
        <img src={imageUrl} alt="Golden Pass" />
      </div>
      <div className={styles.description}>
        <h2 className={styles.title}>Golden Pass</h2>
        <p>
          The Golden Pass unlocks an extraordinary world of luxury and exclusivity,
          offering unparalleled access to premium features and rewards.
        </p>
        <ul className={styles.extraDescription}>
          <li>Total available NFTs - 2000.</li>
          <li>Exclusive access to elite events and experiences.</li>
          <li>Valid for three years from the date of issuance.</li>
          <li>Priority benefits for top-tier collectors.</li>
          <li>Unlocks luxury privileges across all platforms.</li>
        </ul>
        {/* Purchase Button */}
        <div className={styles.buttonContainer}>
          <button className={styles.purchaseButton} onClick={handlePurchaseClick}>
            Purchase Golden Pass
          </button>
        </div>
      </div>
    </div>
  );
};

export default NFTGoldenPassView;
