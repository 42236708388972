// src/sagas/adminUsersSaga.ts
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createAdminUserRequest,
  createAdminUserSuccess,
  createAdminUserFailure,
  loginAdminUserRequest,
  loginAdminUserSuccess,
  loginAdminUserFailure,  
  changeAdminTypeRequest,
  changeAdminTypeSuccess,
  changeAdminTypeFailure,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,  
} from './adminUsersSlice';
import { createAdminUserApi, CreateAdminUserDto, loginAdminUserApi, LoginAdminUserDto,
    changeAdminTypeApi, ChangeAdminTypeDto, changePasswordApi, ChangePasswordDto 
 } from './adminUsersApi';

function* createAdminUserSaga(action: ReturnType<typeof createAdminUserRequest>) {
  try {
    yield call(createAdminUserApi, action.payload);
    yield put(createAdminUserSuccess());
  } catch (error: any) {
    yield put(createAdminUserFailure(error.message));
  }
}


function* loginAdminUserSaga(action: ReturnType<typeof loginAdminUserRequest>): Generator<any, void, { accessToken: string }> {
    try {
      const response = yield call(loginAdminUserApi, action.payload);
      yield put(loginAdminUserSuccess({ accessToken: response.accessToken }));
    } catch (error: any) {
      yield put(loginAdminUserFailure(error.message));
    }
  }

  function* changeAdminTypeSaga(action: ReturnType<typeof changeAdminTypeRequest>): Generator<any, void, { message: string }> {
    try {
      const response = yield call(changeAdminTypeApi, action.payload);
      yield put(changeAdminTypeSuccess('Admin type changed successfully'));
    } catch (error: any) {
      yield put(changeAdminTypeFailure(error.message));
    }
  }  

  function* changePasswordSaga(action: ReturnType<typeof changePasswordRequest>) {
    try {
      yield call(changePasswordApi, action.payload);
      yield put(changePasswordSuccess('Password changed successfully'));
    } catch (error: any) {
      yield put(changePasswordFailure(error.message));
    }
  }
  

export function* watchCreateAdminUser() {
  yield takeLatest(createAdminUserRequest.type, createAdminUserSaga);
  yield takeLatest(loginAdminUserRequest.type, loginAdminUserSaga);
  yield takeLatest(changeAdminTypeRequest.type, changeAdminTypeSaga);
  yield takeLatest(changePasswordRequest.type, changePasswordSaga);

}
