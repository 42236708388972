// app/pages/RightSection.tsx

import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import styles from './RightSection.module.css';
import { FaTachometerAlt, FaShoppingCart, FaEnvelope, FaWallet, FaUserShield, FaChartPie, FaUser, FaThLarge, FaImage } from 'react-icons/fa'; // Example icons from Font Awesome
import Header from '../SelectionHeader/Header';

const RightSection: React.FC = () => {
//   const { id } = useParams(); // Extracts `id` from the URL
//   console.log('UserPageId ', id);

  const selectedMenu = sessionStorage.getItem('selectedMenu');
  const selectedSubMenu = sessionStorage.getItem('selectedSubMenu');

  const loadSelectionHeader = () => {

    let selectionTitle = '';
    let selectionIcon = <></>;
    let subTitle = '';

  if (selectedSubMenu) {
    switch (selectedSubMenu) {
      case 'account-my-profile':
        selectionTitle = '';
        selectionIcon = <></>;
        subTitle = 'My Profile';
        break;
      case 'account-kyc':
        selectionTitle = '';
        selectionIcon = <></>;
        subTitle = 'KYC';
        break;
      case 'account-password-reset':
        selectionTitle = '';
        selectionIcon = <></>;
        subTitle = 'Password Reset';
        break;
      case 'account-notification':
        selectionTitle = '';
        selectionIcon = <></>;
        subTitle = 'Notification';
        break;
      case 'account-activity-timeline':
        selectionTitle = '';
        selectionIcon = <></>;
        subTitle = 'Activity Timeline';
        break;
      case 'wallet-address-book':
        selectionTitle = '';
        selectionIcon = <></>;
        subTitle = 'Address Book';
        break;
      case 'wallet-security':
        selectionTitle = '';
        selectionIcon = <></>;
        subTitle = 'Wallet Security';
        break;
      case 'account-referral-program':
        selectionTitle = '';
        selectionIcon = <></>;
        subTitle = 'Referral Program';
        break;  
      default:
        selectionTitle = '';
        selectionIcon = <></>;
        break;
    }
  }

  switch (selectedMenu) {
    case 'user-dashboard':
      selectionTitle = 'Dashboard';
      selectionIcon = <FaTachometerAlt className={styles.icon} />;
      break;
    case 'user-marketplace':
      selectionTitle = 'Marketplace';
      selectionIcon = <FaThLarge className={styles.icon} />;
      break;
    case 'user-messages':
      selectionTitle = 'Messages';
      selectionIcon = <FaEnvelope className={styles.icon} />;
      break;
    case 'user-nfts':
      selectionTitle = 'AssetSlices NFT';
      selectionIcon = <FaImage className={styles.icon} />;
      break;
    case 'user-wallet':
      selectionTitle = 'Wallet';
      selectionIcon = <FaWallet className={styles.icon} />;
      break;
    case 'user-portfolio':
      selectionTitle = 'Portfolio';
      selectionIcon = <FaChartPie className={styles.icon} />;
      break;
    case 'account-details':
      selectionTitle = 'Account Details';
      selectionIcon = <FaUser className={styles.icon} />;
      break;
    default:
      selectionTitle = '';
      selectionIcon = <></>;
      break;
  }

  return <Header  title={selectionTitle} icon={selectionIcon} subTitle={subTitle}/>


};



  
  return (

    <div className={styles.contentWrapper}>
      <div className={styles.content}>
        {loadSelectionHeader()}
          <Outlet />
      </div>
    </div>
  );
};

export default RightSection;
