import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;
const ASSET_STAGES_BASE_URL = `${apiUrl}/asset-stages`;

// 1) Fetch asset stages by assetID (GET /asset-stages/:assetID)
export const fetchAssetStagesApi = async (assetID: string) => {
  try {
    const response = await axios.get(`${ASSET_STAGES_BASE_URL}/${assetID}`);
    return response.data; // Expecting an AssetStages object
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || 'Failed to fetch asset stages'
    );
  }
};

// 2) Create or update asset stages (POST /asset-stages)
export const createOrUpdateAssetStagesApi = async (createDto: any) => {
  try {
    // The controller says: @Post() => createStage(@Body() createDto)
    // So we send a POST to /asset-stages with createDto in the request body
    const response = await axios.post(`${ASSET_STAGES_BASE_URL}`, createDto);
    return response.data; // Expecting an AssetStages object
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || 'Failed to create or update asset stages'
    );
  }
};
