import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchUserProfileApi, createUserProfileApi, 
  updateUserAddressApi, updateUserProfilePicApi, getDisplayNameByUserID } from './userProfileApi';
import {
  fetchUserProfileRequest,
  fetchUserProfileSuccess,
  fetchUserProfileFailure,
  createUserProfileRequest,
  createUserProfileSuccess,
  createUserProfileFailure,
  updateUserAddressRequest,
  updateUserAddressSuccess,
  updateUserAddressFailure,
  updateUserProfilePicRequest,
  updateUserProfilePicSuccess,
  updateUserProfilePicFailure,
  getDisplayNameRequest,
  getDisplayNameSuccess,
  getDisplayNameFailure,
} from './userProfileSlice';

// Worker saga: Create user profile
function* createUserProfileSaga(action: any): any {
  try {
    const profileData = action.payload;
    const response = yield call(createUserProfileApi, profileData);
    yield put(createUserProfileSuccess(response));
  } catch (error: any) {
    yield put(createUserProfileFailure(error.message));
  }
}

// Worker saga: Update user address
function* updateUserAddressSaga(action: any): any {
  try {
    const { userID, addressData } = action.payload;
    console.log('updateUserAddressSaga');
    console.log(userID);
    console.log(addressData);
    const response = yield call(updateUserAddressApi, userID, addressData);
    yield put(updateUserAddressSuccess(response));
  } catch (error: any) {
    yield put(updateUserAddressFailure(error.message));
  }
}

function* updateUserProfilePicSaga(action: any): any {
    console.log('updateUserProfilePicSaga');
    try {
      const { userID, profilePic } = action.payload;
      console.log(userID);
      console.log(profilePic);
      const response = yield call(updateUserProfilePicApi, userID, profilePic);
      yield put(updateUserProfilePicSuccess(response));
    } catch (error: any) {
      yield put(updateUserProfilePicFailure(error.message));
    }
  }

// Worker saga: Fetch user profile
function* fetchUserProfileSaga(action: any): any {
  try {
    const userId = action.payload; // userId from the dispatched action
    const response = yield call(fetchUserProfileApi, userId);
    yield put(fetchUserProfileSuccess(response)); // Dispatch success action
  } catch (error: any) {
    yield put(fetchUserProfileFailure(error.message)); // Dispatch failure action
  }
}

function* handleGetDisplayName(action: { type: string; payload: string }) {
  try {
    const displayName: { displayName: string } = yield call(getDisplayNameByUserID, action.payload);
    yield put(getDisplayNameSuccess(displayName.displayName));
  } catch (error: any) {
    yield put(getDisplayNameFailure(error.response?.data?.message || 'Failed to fetch display name'));
  }
}
// Watcher saga: Watches for actions dispatched to the store
export function* watchUserProfileSagas() {
  yield takeLatest(createUserProfileRequest.type, createUserProfileSaga);
  yield takeLatest(updateUserAddressRequest.type, updateUserAddressSaga);
  yield takeLatest(updateUserProfilePicRequest.type, updateUserProfilePicSaga);
  yield takeLatest(fetchUserProfileRequest.type, fetchUserProfileSaga);
  yield takeLatest(getDisplayNameRequest.type, handleGetDisplayName);
}
