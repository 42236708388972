import React, { useEffect, useState } from 'react';
import styles from './Purchase.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { createPreBookingRequest } from '../../../../../features/assetPrebooking/assetPrebookingSlice';
import { RootState } from '../../../../../store/rootReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { purchaserID } from '../../../../common';
import { getTokenAvailabilityRequest } from '../../../../../features/assetEditor/assetEditorSlice';
import TokenInteractorNew from '../../AssetDetailViewer/TokenInteractor/TokenInteractorNew';
import { submitPurchaseRequest, setTokenPurchaseDataDraft } from '../../../../../features/tokenPurchase/tokenPurchaseSlice';
import { set } from 'date-fns';

const Purchase = () => {
    const dispatch = useDispatch();

    const { loading = false, success = false, error, tokenPurchaseDataDraft, tokenPurchaseStatusSuccessMessage } = useSelector((state: RootState) => state.tokenPurchase);
    const { tokenAvailability, tokenPurchaseData } = useSelector((state: RootState) => state.assetEditor);
    // const { success = false, error, loading = false } = useSelector((state: RootState) => ({
    //   success: state.assetEditor.success ?? false,
    //   error: state.assetEditor.error,
    //   loading: state.assetEditor.loading ?? false
    // }));

    const loading1 = false;
    console.log('tokenAvailability', tokenAvailability);
    console.log('tokenPurchaseData', tokenPurchaseData);
    console.log('tokenPurchaseDataDraft', tokenPurchaseDataDraft);

    const { asset_id } = useParams(); // Extracts `id` from the URL
  
    const assetID = asset_id ?? ''; // Use the correct asset ID
    const userID = localStorage.getItem('userID') ?? '';
  
    const [availableSliceCount, setAvailableSliceCount] = useState(0);
    const [costPerSlice, setCostPerSlice] = useState(0);

    const [sliceCount, setSliceCount] = useState(1);
    const [totalSliceCost, setTotalSliceCost] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    const [tokenCountConfirmed, setTokenCountConfirmed] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [showNotif, setShowNotif] = useState(false);

    const handleIncrement = () => {
      setSliceCount(sliceCount + 1);
    }
    const handleDecrement = () => {
      if (sliceCount > 1) setSliceCount(sliceCount - 1);
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10);
      if (!isNaN(value) && value > 0) setSliceCount(value);
    };

    React.useEffect(() => {
      setTotalSliceCost(sliceCount * costPerSlice);
      setTotalDiscount(sliceCount * 1);
      setTotalCost(sliceCount * costPerSlice - sliceCount * 1);
    }, [sliceCount]);

    useEffect(() => {
      dispatch(getTokenAvailabilityRequest(asset_id ?? ''));
    }, [dispatch]);

    useEffect(() => {
      setTotalSliceCost(sliceCount * costPerSlice);
    });

    useEffect(() => {
      if (tokenAvailability) {
        setAvailableSliceCount(tokenAvailability?.availableToPurchase);
        setCostPerSlice(tokenAvailability.tokenPrice);
      }
    }, [dispatch, tokenAvailability]);

    // React.useEffect(() => {
    //   // Reset values after successful booking
    //   if (success) {
    //     setSliceCount(1);
    //     setTotalSliceCost(50);
    //     setTotalDiscount(1);
    //     setTotalCost(49);
    //   }
    // }, [success]);
  
    // const handleConfirmBooking = () => {
    //   const preBookingData = {
    //     assetID: assetID, // Replace with actual asset ID
    //     userID: userID, // Replace with actual user ID
    //     numberOfTokens: sliceCount
    //   };
  
    //   dispatch(createPreBookingRequest(preBookingData));
    // };
  
    const EditConfirmPurchase = (event: React.FormEvent) => {
      event.preventDefault();
      setTokenCountConfirmed(false);
    };

    const handleConfirmPurchase = (event: React.FormEvent) => {
      event.preventDefault();
      let userId = purchaserID()
      if (sliceCount > 0) {
        // Dispatch the action to submit the purchase request
        const transactionDetails = {
          assetID,
          purchaserID: userId,
          numberOfTokens: sliceCount,
          purchasePricePerToken: costPerSlice,
          totalPurchaseCost: totalCost,
          // totalPurchaseCost: 20,
          purchaseStatus: 'TokenConfirmed',
        };
        // const tokenPurchaseStatusSuccessMessage = 'Token purchase completed successfully';

        dispatch(setTokenPurchaseDataDraft({ 
          tokenPurchaseDataDraft: transactionDetails, 
          tokenPurchaseStatusSuccessMessage }));
          setTokenCountConfirmed(true);
        }
        setStatusMessage('Token count confirmed. Unlock Metamask and press Buy Button');
        setShowNotif(true);
    };
  
    // useEffect(() => {
    //   // Cleanup function to reset tokenPurchaseData
    //   return () => {
    //     dispatch(resetTokenPurchaseData());
    //   };
    // }, [dispatch]);

  return (
    <div className={styles.container}>
      {/* Pre-Booking Section */}
      {/* <div className={styles.section}>
        <h3>Pre-Booking</h3>
        <div className={styles.sectionContent}>
          <div className={styles.weeklyEstimates}>
            <h4>Weekly estimates</h4>
            <p>
              The asset is in pre-booking stage. Book the slices now and we will notify when it's ready to purchase the slices,
              <strong> with $1 each slice</strong>.
            </p>
          </div>
        </div>
      </div> */}

      {/* Order Summary Section */}
      <div className={styles.orderSummary}>
        <div className={styles.orderHeader}>
          <h3>Purchase Summary</h3>   
          {/* {!success ? (
            <span className={styles.paymentPending}>Purchase Pending</span>
          ) : (
            <span className={styles.bookingSuccess}>
              <FontAwesomeIcon icon={faCheckCircle} className={styles.successIcon} />
              Purchase Success
            </span>
          )}                  */}
          <span className={styles.paymentPending}>On Sale</span>
        </div>

        {/* Notification Section */}
        <div className={styles.notification}>
          {showNotif && (
            <div className={styles.successNotification}>
              <FontAwesomeIcon icon={faCheckCircle} className={styles.notificationIcon} />
                {statusMessage}
                {/* {tokenPurchaseStatusSuccessMessage} */}
            </div>
          )}
          {error && (
            <div className={styles.failureNotification}>
              <FontAwesomeIcon icon={faTimesCircle} className={styles.notificationIcon} />
              {error}
            </div>
          )}
        </div>


        <div className={styles.sectionContent}>
          <div className={styles.weeklyEstimates}>
            <p>
              The asset is in sale stage. Purchase your slices before it gets sold out.
            </p>
          </div>
        </div>

        {/* <p>Use this personalized guide to get your store up and running.</p> */}
        <div className={styles.orderDetails}>
          <div className={styles.orderRow}>
            <span className={styles.orderRowChildResponsive}>Slices Available</span>
            <span>On Sale</span>
            <span>{availableSliceCount}</span>
          </div>
          <div className={styles.orderRow}>
            <span>Cost per Slice</span>
            <span></span>
            <span>{costPerSlice} USDC</span>
          </div>
          {/* <div className={styles.orderRow}>
            <span>How many slices do you want to order ?</span>
            <span>Cost per Slice - 50 USDC</span>
            <span>$0.00</span>
          </div> */}
          <div className={`${styles.orderRow} ${styles.preBook}`}>
            <span className={`${styles.orderRowChildResponsive} ${styles.responsiveFont}`}>Pre-book Slices</span>
            <span></span>
            <span></span>
          </div>
          <div className={`${styles.orderRow} ${styles.selectSlices}`}>
            <span>Select number of Slices</span>
            <div className={styles.sliceSelector}>
              <button className={styles.decrementButton} disabled={tokenCountConfirmed} 
                onClick={handleDecrement}>
                -
              </button>
              <input
                type="number"
                className={styles.sliceInput}
                value={sliceCount}
                onChange={handleChange}
                disabled={tokenCountConfirmed} 
                min="1"
              />
              <button disabled={tokenCountConfirmed} className={styles.incrementButton} 
                onClick={handleIncrement}>
                +
              </button>
            </div>
            <span>{totalSliceCost} USDC</span>
          </div>
          <div className={styles.orderRow}>
            <span>Total Discount</span>
            <span>Early bird discount</span>
            <span>-{totalDiscount} USDC</span>
          </div>
          <div className={`${styles.orderRow} ${styles.orderTotal}`}>
            <span className={`${styles.orderRowChildResponsive} ${styles.responsiveFont}`}>Total</span>
            <span></span>
            <span>{totalCost < 0 ? 0 : totalCost} USDC</span>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.actionButtonsInnerWrap}>
            <button
              className={styles.collectPaymentButton}
              disabled={tokenCountConfirmed}
              onClick={handleConfirmPurchase} > Confirm Token Count
              {/* {tokenCountConfirmed ? (
                <>
                  <FontAwesomeIcon icon={faSpinner} className={styles.loadingSpinner} spin />
                  Processing...
                </>
              ) : (
                'Confirm Purchase'
              )} */}
            </button>
            {tokenCountConfirmed && (
                  <span onClick={EditConfirmPurchase} className={styles.editTokenCount}>
                     Edit Token Count 
                  </span>
                  // <button
                  // className={styles.collectPaymentButton}
                  // onClick={EditConfirmPurchase} >
                  //   Edit Token Count
                  // </button>
            )}
          </div>    
        </div>
        <p className={styles.footerNote}>
          Press Unlock Metamask to continue with the purchase.
        </p>

        {/* { tokenPurchaseData?.purchaseStatus === 'Completed' && 
          <TokenInteractorNew requiredUSDC={totalCost} tokenCount={sliceCount} /> }  */}

      </div>
    </div>
  );
};

export default Purchase;
