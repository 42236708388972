import { call, put, takeLatest } from 'redux-saga/effects';
import { joinWaitingListApi, findAllWaitingListApi } from './waitingListApi';
import {
  joinWaitingListRequest,
  joinWaitingListSuccess,
  joinWaitingListFailure,
  fetchWaitingListRequest,
  fetchWaitingListSuccess,
  fetchWaitingListFailure,
} from './waitingListSlice';

// Worker saga: Join waiting list
function* joinWaitingListSaga(action: any): any {
  try {
    const { emailId } = action.payload;
    const response = yield call(joinWaitingListApi, emailId);
    yield put(joinWaitingListSuccess(response));
  } catch (error: any) {
    yield put(joinWaitingListFailure(error.message));
  }
}

// Worker saga: Fetch all waiting list entries
function* fetchWaitingListSaga(): any {
  try {
    const response = yield call(findAllWaitingListApi);
    yield put(fetchWaitingListSuccess(response));
  } catch (error: any) {
    yield put(fetchWaitingListFailure(error.message));
  }
}

// Watcher saga: Watches for actions dispatched to the store
export function* watchWaitingListSagas() {
  yield takeLatest(joinWaitingListRequest.type, joinWaitingListSaga);
  yield takeLatest(fetchWaitingListRequest.type, fetchWaitingListSaga);
}
