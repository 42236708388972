import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;
const VERIFF_BASE_URL = `${apiUrl}/veriff-kyc`; 
// Adjust if your NestJS controller is actually at /veriff or something else

// 1) Create Session URL
export const createVeriffSessionApi = async (dto: any) => {
  try {
    const response = await axios.post(`${VERIFF_BASE_URL}/createSessionUrl`, dto);
    return response.data; // The endpoint returns a string (session URL)
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Failed to create Veriff session URL');
  }
};

// 2) Get KYC Status
export const getVeriffKycStatusApi = async (userId: string) => {
  try {
    const response = await axios.get(`${VERIFF_BASE_URL}/getKycStatus/${userId}`);
    return response.data; // The endpoint returns 'veriffData' or similar record
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Failed to fetch KYC status');
  }
};
