// src/components/AssetDetailViewer/AssetCarousel/AssetCarousel.tsx
import React, { useState } from 'react';
import styles from './AssetCarousel.module.css';
import defaultImage from '../../../../../assets/default.webp'; // Replace with your default image
import image1 from '../../../../../assets/1.jpg';
import image2 from '../../../../../assets/2.jpg';
import image3 from '../../../../../assets/3.jpg';
import image4 from '../../../../../assets/4.jpg';
import LightboxModal from './LightboxModal';

interface AssetCarouselProps {
  images: string[];
}

const AssetCarousel: React.FC<AssetCarouselProps> = ({ images }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  //TODO: Remove this line after integrating with backend
    // images = [image1, image2, image3, image4, defaultImage]

  const openModal = (index: number) => {
    setCurrentIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carousel}>
        <div className={styles.leftColumn}>
          {images.length > 0 && (
            <img
              src={images[0]}
              alt="Asset"
              className={styles.largeImage}
              onClick={() => openModal(0)}
            />
          )}
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.topRow}>
            {images.length > 1 && (
              <img
                src={images[1]}
                alt="Asset"
                className={styles.smallImage}
                onClick={() => openModal(1)}
              />
            )}
            {images.length > 2 && (
              <img
                src={images[2]}
                alt="Asset"
                className={styles.smallImage}
                onClick={() => openModal(2)}
              />
            )}
          </div>
          <div className={styles.bottomRow}>
            {images.length > 3 && (
              <img
                src={images[3]}
                alt="Asset"
                className={styles.smallImage}
                onClick={() => openModal(3)}
              />
            )}
            {images.length > 4 && (
              <img
                src={images[4]}
                alt="Asset"
                className={styles.smallImage}
                onClick={() => openModal(4)}
              />
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <LightboxModal
          images={images}
          currentIndex={currentIndex}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default AssetCarousel;
