import React, { useState } from 'react';
import styles from './LeftMenu.module.css';

import { FaTachometerAlt, FaShoppingCart, FaEnvelope, FaWallet, FaUserShield, FaChartPie, FaUser, FaThLarge, FaImage } from 'react-icons/fa'; // Example icons from Font Awesome
import { useNavigate } from 'react-router-dom';

const LeftMenu: React.FC = () => {
  const navigate = useNavigate();

  const [selectedMenu, setSelectedMenu] = useState<string>('user-dashboard');
  const [selectedSubMenu, setSelectedSubMenu] = useState<string | null>(null);
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(true);

  const menuStructure = [
    {
      id: 'userDashboard',
      label: 'Dashboard',
      menuName: 'user-dashboard',
      link: '/me/dashboard',
      icon: <FaTachometerAlt className={styles.icon} />,
      subMenu: null
    },
    {
      id: 'userMarketplace',
      label: 'Marketplace',
      menuName: 'user-marketplace',
      link: '/me/marketplace',
      icon: <FaThLarge className={styles.icon} />
    },
    // {
    //   id: 'userMessages',
    //   label: 'Messages',
    //   menuName: 'user-messages',
    //   icon: <FaEnvelope className={styles.icon} />
    // },
    {
      id: 'userNfts',
      label: 'NFTs',
      menuName: 'user-nfts',
      link: '/me/nft',
      icon: <FaImage className={styles.icon} />,
    },
    // {
    //     id: 'userWallet',
    //     label: 'Wallet',
    //     menuName: 'user-wallet',
    //     icon: <FaWallet className={styles.icon} />,
    //     subMenu: [
    //         {
    //           id: 'wallerSecurity',
    //           label: 'Security',
    //           menuName: 'wallet-security',
    //           link: '/me/wallerSecurity',
    //           icon: <FaUserShield className={styles.icon} />,
    //         },
    //         {
    //           id: 'walletAddressBook',
    //           label: 'Address Book',
    //           link: '/me/walletAddressBook',
    //           icon: <FaUser className={styles.icon} />,
    //         },
    //     ]
    // },
    // {
    //     id: 'userPortfolio',
    //     label: 'Portfolio',
    //     menuName: 'user-portfolio',
    //     link: '/me/portfolio',
    //     icon: <FaChartPie className={styles.icon} />
    // },
    {
      id: 'accountDetails',
      label: 'Account Details',
      menuName: 'account-details',
      icon: <FaUser className={styles.icon} />,
      subMenu: [
        // {
        //   id: 'accountMyProfile',
        //   label: 'My Profile',
        //   menuName: 'account-my-profile',
        //   link: '/me/accountMyProfile',
        //   icon: <FaUserShield className={styles.icon} />
        // },
        {
          id: 'accountKyc',
          label: 'KYC',
          menuName: 'account-kyc',
          link: '/me/accountKyc',
          icon: <FaUser className={styles.icon} />
        },
        {
          id: 'accountPasswordReset',
          label: 'Password Reset',
          menuName: 'account-password-reset',
          link: '/me/accountPasswordReset',
          icon: <FaUser className={styles.icon} />
        },
        // {
        //   id: 'accountNotification',
        //   label: 'Notification',
        //   menuName: 'account-notification',
        //   icon: <FaUser className={styles.icon} />
        // },
        // {
        //   id: 'accountActivityTimeline',
        //   label: 'Activity Timeline',
        //   menuName: 'account-activity-timeline',
        //   link: '/me/accountActivityTimeline',
        //   icon: <FaUser className={styles.icon} />
        // },
        {
          id: 'accountReferralProgram',
          label: 'Referral Program',
          menuName: 'account-referral-program',
          link: '/me/accountReferralProgram',
          icon: <FaUser className={styles.icon} />
        },
        // {
        //   id: 'accountActivityTimeline',
        //   label: 'Billing',
        //   menuName: 'account-activity-timeline',
        //   icon: <FaUser className={styles.icon} />
        // },
        // {
        //   id: 'accountActivityTimeline',
        //   label: 'Data Export',
        //   menuName: 'account-activity-timeline',
        //   icon: <FaUser className={styles.icon} />
        // },
        // {
        //   id: 'accountActivityTimeline',
        //   label: 'Delete Account',
        //   menuName: 'account-activity-timeline',
        //   icon: <FaUser className={styles.icon} />
        // },
      ]
    },
  ];

  const handleRedirect = (url: string) => {
    console.log('handleRedirect url ', url);
    if (url === '') return;
    navigate(url);
  };

  const saveSelectedMenu = (menuName: any) => {
    sessionStorage.setItem('selectedMenu', menuName);
  };

  const saveSelectedSubMenu = (menuName: any) => {
    sessionStorage.setItem('selectedSubMenu', menuName);
  };
  
  return (
    <div className={styles.container} onMouseEnter={() => setIsMenuVisible(true)}
>
      <div className={styles.leftContent}>
        <div className={styles.topSpacer}></div>
        <div className={styles.menu}>
          <ul>
            {menuStructure.map((item, index) => (
              <li key={item.id}>
                <button
                  onClick={() => {
                    setSelectedMenu(item.menuName);
                    saveSelectedMenu(item.menuName);
                    saveSelectedSubMenu(null);
                    setSelectedSubMenu(null);
                    handleRedirect(item?.link || '')
                  }}
                  className={selectedMenu === item.menuName ? styles.activeMenuItem : ''}
                >
                  {item.icon} {item.label}
                </button>
                {item.subMenu && selectedMenu === item.menuName && (
                  <ul className={styles.subMenu}>
                    {item.subMenu?.map((subItem, index) => (
                      <li key={index}>
                        <button
                          onClick={() => {
                            setSelectedSubMenu(subItem?.menuName || null);
                            saveSelectedSubMenu(subItem?.menuName || null);
                            handleRedirect(subItem?.link || '')
                          }
                          }
                          className={selectedSubMenu === subItem.menuName ? styles.activeSubMenuItem : ''}
                        >
                          {subItem.label}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.bottomSpacer}></div>
      </div>
    </div>

  );
};

export default LeftMenu;
