// referralCodeApi.ts
import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl + '/referral';

// API call to fetch referral code
export const fetchReferralCodeApi = async (userId: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/referral-code/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch referral code');
  }
};

export const fetchReferralUrlApi = async (userId: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/referral-url/${userId}`);
    console.log('response.data', response.data);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch referral url');
  }
};

// API call to register referral click
export const recordReferralClickApi = async (referralCode: string, additionalInfo: any) => {

  try {
    console.log('in fn recordReferralClickApi')
    console.log(referralCode, additionalInfo);
    const response = await axios.post(`${API_BASE_URL}/click`, { referralCode, additionalInfo });
    return response.data;
  } catch (error) {
    throw new Error('Failed to record referral click');
  }
};

export const fetchReferralStatsByCodeApi = async (referralCode: string) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/stats/${referralCode}`);
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch referral stats');
    }
  };

  
