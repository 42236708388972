// src/components/ResetPassword.tsx

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordRequest } from '../../../features/auth/authSlice'; // Import your action
import { RootState } from '../../../store/rootReducer'; // Root state
import styles from './ResetPassword.module.css';
import { useParams, useNavigate } from 'react-router-dom';

const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const { rt } = useParams<{ rt: string }>(); // Get token from URL
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux state for tracking status
  const { loading, resetPasswordSuccess, resetPasswordError } = useSelector((state: RootState) => state.auth);

  // Handle password reset
  const handleResetPassword = () => {
    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }
    setPasswordError('');
    // Dispatch reset password action with token and new password
    if (rt) {
      dispatch(resetPasswordRequest({ token: rt, newPassword }));
    } else {
      setPasswordError('Invalid token');
    }
  };

  // Check if passwords match
  const checkSamePassword = () => {
    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError('');
    }
  };

  // Redirect after successful password reset
  useEffect(() => {
    if (resetPasswordSuccess) {
      navigate('/login'); // Redirect to login after successful reset
    }
  }, [resetPasswordSuccess, navigate]);

  return (
    <div>
      <h2>Reset Password</h2>
      <div className={styles.inputField}>
        <label htmlFor="newPassword">New Password</label>
        <input
          type="password"
          id="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
          className={passwordError ? styles.invalid : ''}
        />
      </div>
      <div className={styles.inputField}>
        <label htmlFor="confirmPassword">Confirm Password</label>
        <input
          type="password"
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm Password"
          className={passwordError ? styles.invalid : ''}
          onBlur={checkSamePassword} // Validate on blur
        />
        {passwordError && <span className={styles.errorMessage}>{passwordError}</span>}
      </div>

      {/* Show error or success status */}
      {resetPasswordError && <p className={styles.errorMessage}>{resetPasswordError}</p>}
      {resetPasswordSuccess && <p className={styles.successMessage}>Password reset successfully!</p>}

      <button className={styles.button} onClick={handleResetPassword} disabled={loading}>
        {loading ? 'Resetting Password...' : 'Reset Password'}
      </button>
    </div>
  );
};

export default ResetPassword;
