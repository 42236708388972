import React from 'react';
import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useMediaQuery } from 'react-responsive';

// Define the interface for the data
interface PropertiesData {
  month: string;
  tokenPurchased: number;
}

// Sample data for the chart
const data: PropertiesData[] = [
  { month: 'January', tokenPurchased: 0 },
  { month: 'February', tokenPurchased: 0 },
  { month: 'March', tokenPurchased: 0 },
  { month: 'April', tokenPurchased: 0 },
  { month: 'May', tokenPurchased: 0 },
  { month: 'June', tokenPurchased: 0 },
  { month: 'July', tokenPurchased: 0 },
  { month: 'August', tokenPurchased: 0 },
  { month: 'September', tokenPurchased: 0 },
  { month: 'October', tokenPurchased: 0 },
  { month: 'November', tokenPurchased: 0 },
  { month: 'December', tokenPurchased: 0 },
];

const PropertiesInvestedChart: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const tokenDetails = useSelector((state: any) => state?.tokenPurchase?.monthlyWiseTokens?.data);

  // Custom tick formatter for XAxis to shorten month names for mobile
  const formatXAxisTick = (tickItem: string) => (isMobile ? tickItem.substring(0, 2) : tickItem);

  return (
    <div style={{ width: '100%', height: 400, padding: 0}}>
      <ResponsiveContainer width='100%' height="100%">
        <LineChart
          data={tokenDetails}
          margin={{
            top: isMobile ? 0 : 20,
            right: isMobile ? 0 : 0,
            left: isMobile ? -45 : 0,
            bottom: isMobile ? 0 : 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" tick={{ fontSize: isMobile ? 8 : 12 }} tickFormatter={formatXAxisTick} />
          <YAxis tick={{ fontSize: isMobile ? 8 : 12 }} />
          <Tooltip />
          <Legend wrapperStyle={{ fontSize: isMobile ? 8 : 12 }} />
          <Line type="monotone" dataKey="tokenPurchased" stroke="#388E3C" activeDot={{ r: isMobile ? 4 : 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PropertiesInvestedChart;