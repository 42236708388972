import React, { useEffect } from 'react';
import styles from './PropertiesPrebookedList.module.css';
import { FaBuilding } from 'react-icons/fa'; // Example icons from Font Awesome
import { useDispatch, useSelector } from 'react-redux';
import { DataTable, purchaserID } from '../../../common';
import { PROP_PRE_BOOKED_HEADERS } from '../../../common/constants';
import { getPrebookingForDashboardRequest } from '../../../../features/assetPrebooking/assetPrebookingSlice';
import config from '../../../../config/config';

const AllPropertyRates = () => {
  const dispatch = useDispatch();
  const userId = purchaserID();
  const marketplaceLink = config.uiUrl + '/me/marketplace';

  const { prebookingData, loading } = useSelector((state: any) => state.prebooking);

  useEffect(() => {
    dispatch(getPrebookingForDashboardRequest(userId));
  }, [dispatch, userId]);

  return (
    <>
      <div className={styles.metricsContainer}>
        {/* Total Properties Card */}
        <div className={styles.metricCard}>
          <div className={styles.metricHeader}>
            <FaBuilding className={styles.metricIcon} />
            <span>Properties Pre-booked</span>
          </div>
          <div className={styles.top_2}>
            {loading ? (
              <p>Loading...</p>
            ) : prebookingData && prebookingData.length > 0 ? (
              <DataTable Header={PROP_PRE_BOOKED_HEADERS}>
                {prebookingData.map((assetInvseted: any, index: any) => (
                  <tr key={index}>
                    <td width={'250px'}>{assetInvseted?.assetDetails?.assetTitle}</td>
                    <td width={'250px'}>{assetInvseted?.totalTokens}</td>
                    <td width={'250px'}>{assetInvseted?.prebookDate}</td>
                  </tr>
                ))}
              </DataTable>
            ) : (
              <div className={styles.noData}>
                <p>
                  You have not prebooked any slices yet. Please go to{' '}
                  <a
                    href={`${marketplaceLink}`}
                    rel="noopener noreferrer"
                    className={styles.marketplaceLink}
                  >
                    Marketplace
                  </a>{' '}
                  and prebook your slices.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllPropertyRates;
