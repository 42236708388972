// sagas/rootSaga.ts

import { all } from 'redux-saga/effects';
import authSaga from '../features/auth/authSaga';
import assetSaga from '../features/asset/assetSaga';
import assetEditorSaga from '../features/assetEditor/assetEditorSaga';
import distributionSaga from '../features/distribution/distributionSagas';
import assetValuationSaga from '../features/assetValuation/assetValuationSaga';
import { watchTokenPurchase } from '../features/tokenPurchase/tokenPurchaseSaga';
import { tokenSaleSaga } from '../features/tokenSale/tokenSaleSaga';
import { watchUserDistributionSagas } from '../features/userDistribution/userDistributionSaga';
import messagingSaga from '../features/messaging/messagingSaga';
import { watchUserProfileSagas } from '../features/userProfile/userProfileSaga';
import { watchUserSecuritySagas } from '../features/userSecurity/userSecuritySaga';
import { watchUserSagas } from '../features/user/userSaga';
import { watchReferralCodeSagas } from '../features/userReferral/userReferralSaga';
import companySaga from '../features/backendPartners/backendPartnerSaga';
import assetMonthlyFinancialsSaga from '../features/assetMonthlyFinancials/assetMonthlyFinancialsSaga';
import assetTokenizationSaga from '../features/assetTokenization/assetTokenizationSaga';
import { watchAssetSettingsSaga } from '../features/assetSettings/assetSettingsSaga';
import { watchCreateAdminUser } from '../features/adminUsers/adminUsersSaga';
import { watchCreatePreBooking } from '../features/assetPrebooking/assetPrebookingSaga';
import marketplaceSaga from '../features/marketplace/marketplaceSagas';
import { watchWaitingListSagas } from '../features/waitinglist/waitingListSaga';
import { watchAssetStagesSagas } from '../features/assetStages/assetStagesSaga';
import { watchVeriffSagas } from '../features/kycVeriff/kycVeriffSaga';
// Import other sagas as needed

function* rootSaga() {
  yield all([
    authSaga(),
    assetSaga(),
    assetEditorSaga(),
    distributionSaga(),
    assetValuationSaga(),
    tokenSaleSaga(),
    watchTokenPurchase(),
    watchUserDistributionSagas(),
    messagingSaga(),
    watchUserProfileSagas(),
    watchUserSecuritySagas(),
    watchUserSagas(),
    watchReferralCodeSagas(),
    companySaga(),
    assetMonthlyFinancialsSaga(),
    assetTokenizationSaga(),
    watchAssetSettingsSaga(),
    watchCreateAdminUser(),
    watchCreatePreBooking(),
    marketplaceSaga(),
    watchWaitingListSagas(),
    watchAssetStagesSagas(),
    watchVeriffSagas(),
    // Add other sagas here
  ]);
} 

export default rootSaga;
