// src/components/AssetDetailEditor/AssetHeader.tsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faTwitter, faFacebook, faGooglePlusG, faPinterest, faInstagram } from '@fortawesome/free-brands-svg-icons';
import styles from './AssetHeader.module.css';

interface AssetHeaderProps {
  title: string;
  addressLine1: string;
  addressLine2: string;
  price: string;
  propertyID: number;
  province: string;
  country: string;
}

const AssetHeader: React.FC<AssetHeaderProps> = ({ title, addressLine1, addressLine2, price, propertyID, province, country }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <h2 className={styles.addressTitle}>{title}</h2>
          {/* <p className={styles.address}>
            {addressLine1} {addressLine1? ',': ''} 
            {addressLine2} {addressLine2? ',': ''}  
            {province} {province? ',': ''} 
            {country}
          </p> */}
        </div>
        <div className={styles.priceContainer}>
          <span className={styles.forSale}>
            {/* <a href="#" className={styles.favorites}>
              Add to favorites <FontAwesomeIcon icon={faHeart} />
            </a> */}
          </span>
          {/* <span>
            <p className={styles.propertyId}>Property ID: {propertyID}</p>
          </span> */}
        </div>
      </div>
      <div className={styles.subHeader}>
        {/* <p className={styles.propertyId}>Property ID: {propertyID}</p> */}
        {/* <a href="#" className={styles.favorites}>
          Add to favorites <FontAwesomeIcon icon={faHeart} />
        </a> */}
        {/* <div className={styles.shareContainer}>
          <span>Share:</span>
          <a href="#"><FontAwesomeIcon icon={faTwitter} className={styles.icon} /></a>
          <a href="#"><FontAwesomeIcon icon={faFacebook} className={styles.icon} /></a>
          <a href="#"><FontAwesomeIcon icon={faInstagram} className={styles.icon} /></a>
        </div> */}
      </div>
    </div>
  );
};

export default AssetHeader;
