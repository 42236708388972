import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl + '/user-distributions';


// API function for getting total distribution
export const getDistributionTotalForUserAndAsset = async (userID: string, assetID: string) => {
    const response = await axios.get(`${API_BASE_URL}/distribution-total/${assetID}/${userID}`);
    console.log(response);
    return response;
};

// API function for getting distribution mapping by months
export const getDistributionMappingForUserAndAsset = async (userID: string, assetID: string) => {
    const response = await axios.get(`${API_BASE_URL}/distribution-mapping/${assetID}/${userID}`);
    console.log(response);
    return response;
};

export const getAllDistributionsForUserAndAsset = async (assetID: string, userID: string) => {
    const response = await axios.get(`${API_BASE_URL}/distribution-all/${assetID}/${userID}`);
    return response;
  };

  export const updateClaimStatusApi = async (userDistributionID: string, claimedStatus: string, claimType: string) => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/update-claim-status/${userDistributionID}`, {
        claimedStatus,
        claimType,
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to update claim status');
    }
}

export const getTokensToBeClaimedApi = async (userID: string, assetID: string) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/get-tokens-to-be-claimed/${assetID}/${userID}`);
      return response;
    } catch (error:any) {
      throw new Error(error.response?.data?.message || 'Failed to retrieve tokens to be claimed');
    }
  };

export const getUserDistributionDetailsApi = async (assetID: string, userDistributionID: string) => {
  const response = await axios.get(`${API_BASE_URL}/${assetID}/${userDistributionID}`);
  return response.data;
};