// src/components/AssetDetailEditor/AssetValuation.tsx
import React, { useEffect, useState } from 'react';
import styles from './AssetValuation.module.css';
import { ValuationDetails } from '../../../../../features/assetEditor/types';
import { getValuationByAssetIDRequest } from '../../../../../features/assetValuation/assetValuationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../../store/rootReducer';

// interface AssetValuationProps {
//   valuationData: ValuationDetails[];
// }

//const AssetValuation: React.FC<AssetValuationProps> = ({ valuationData }) => {
const AssetValuation: React.FC = () => {
  const dispatch = useDispatch();
  const { asset_id } = useParams(); // Extracts `id` from the URL
  console.log('AssetValuation ', asset_id);
  let assetValuationDetails = useSelector((state:any)=>state?.valuation)

  // const [activeIndex, setActiveIndex] = useState<number | null>(null);

  // const toggleAccordion = (index: number) => {
  //   setActiveIndex(activeIndex === index ? null : index);
  // };

  useEffect(() => {
    dispatch(getValuationByAssetIDRequest(asset_id as string));
  }, [dispatch, asset_id]);

  const { assetValuationList, status, error } = useSelector(
    (state: RootState) => state.valuation
  );

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };


  return (
    <div className={styles.valuationContainer}>
      <h2 className={styles.valuationHeading}>Asset Valuation</h2>
      {/* <div className={styles.accordion}>
        {valuationData.map((valuation, index) => (
          <div key={index} className={styles.accordionItem}>
            <div
              className={styles.accordionTitle}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{valuation.date}</h3>
              <span>{activeIndex === index ? '-' : '+'}</span>
            </div>
            {activeIndex === index && (
              <div className={styles.accordionContent}>
                <div className={styles.valuationDetails}>
                  <div className={styles.valuationItem}>
                    <div className={styles.valuationTitle}>VALUATION DATE</div>
                    <div>{valuation.date}</div>
                  </div>
                  <div className={styles.valuationItem}>
                    <div className={styles.valuationTitle}>VALUATION COST</div>
                    <div>{valuation.assetValuation}</div>
                  </div>
                  <div className={styles.valuationItem}>
                    <div className={styles.valuationTitle}>TOTAL INVESTMENT</div>
                    <div>{valuation.totalInvestment}</div>
                  </div>
                  <div className={styles.valuationItem}>
                    <div className={styles.valuationTitle}>TOKEN PRICE</div>
                    <div>{valuation.tokenPrice}</div>
                  </div>
                  {valuation.annualChange && (
                    <div className={styles.valuationItem}>
                      <div className={styles.valuationTitle}>ANNUAL CHANGE</div>
                      <div>{valuation.annualChange}</div>
                    </div>
                  )}
                  {valuation.capitalROI && (
                    <div className={styles.valuationItem}>
                      <div className={styles.valuationTitle}>CAPITAL ROI</div>
                      <div>{valuation.capitalROI}</div>
                    </div>
                  )}
                  {valuation.regDistributed && (
                    <div className={styles.valuationItem}>
                      <div className={styles.valuationTitle}>REG DISTRIBUTED</div>
                      <div>{valuation.regDistributed}</div>
                    </div>
                  )}
                  <div className={styles.valuationItem}>
                    <div className={styles.valuationTitle}>VALUATION DOCUMENT</div>
                    <div><a href="#">{valuation.documentLink}</a></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div> */}

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Valuation Title</th>
              <th>Date</th>
              <th>Valuation</th>
              <th>Total Investment</th>
              <th>Annual Change</th>
              <th>capitalROI</th>
              <th>Token Price</th>
              <th>Total Token</th>
              <th>Valuation Document</th>
            </tr>
          </thead>
          <tbody>
            {assetValuationDetails?.assetValuationList?.map((valuation:any, index:number) => (
              <tr key={index}>
                <td>{valuation.valuationTitle}</td>
                <td>{formatDate(valuation.valuationDate)}</td>
                <td>${valuation.valuationAmount}</td>
                <td>${valuation.totalInvestment}</td>
                <td>+{valuation.annualChange}%</td>
                <td>+{valuation.capitalRoi}%</td>
                <td>${valuation.tokenPrice}</td>
                <td>{valuation.totalTokens}</td>
                <td>
                {/* {valuation.valuationDocumentName} */}
                 {valuation.valuationDocumentLink && <div>
                    <a href={valuation.valuationDocumentLink} className={styles.docLink}>{valuation.valuationDocumentName}</a>
                  </div> 
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default AssetValuation;
