import { call, put, takeLatest } from 'redux-saga/effects';
import { createPreBooking, getPrebookingForUserForDashboard, 
  getPrebookingForUserPerAsset, getTotalPrebookingTokensAPI, 
  getPrebookingForAssetAPI, getTotalPrebookingTokensForAssetAPI } from './assetPrebookingApi';
import {
  createPreBookingRequest,
  createPreBookingSuccess,
  createPreBookingFailure,
  getPrebookingForDashboardRequest,
  getPrebookingForDashboardSuccess,
  getPrebookingForDashboardFailure,
  getPrebookingForUserPerAssetRequest,
  getPrebookingForUserPerAssetSuccess,
  getPrebookingForUserPerAssetFailure,
  getTotalPrebookingTokensRequest,
  getTotalPrebookingTokensSuccess,
  getTotalPrebookingTokensFailure,    
  getPrebookingForAssetRequest,
  getPrebookingForAssetSuccess,
  getPrebookingForAssetFailure,
  getTotalPrebookingTokensForAssetRequest,
  getTotalPrebookingTokensForAssetSuccess,
  getTotalPrebookingTokensForAssetFailure,
} from './assetPrebookingSlice';

function* handleCreatePreBooking(action: any): Generator {
  try {
    const preBookingData = action.payload;
    yield call(createPreBooking, preBookingData); // Call the API
    yield put(createPreBookingSuccess()); // Dispatch success action
  } catch (error: any) {
    yield put(createPreBookingFailure(error.message || 'Failed to create pre-booking'));
  }
}

function* fetchPrebookingForDashboard(action: { type: string; payload: string }): Generator<any, void, any> {
  try {
    const response = yield call(getPrebookingForUserForDashboard, action.payload);
    yield put(getPrebookingForDashboardSuccess(response));
  } catch (error: any) {
    yield put(getPrebookingForDashboardFailure(error.message));
  }
}

function* fetchPrebookingForUserPerAsset(action: { type: string; payload: { userID: string; assetID: string } }): Generator<any, void, any> {
  try {
    const { userID, assetID } = action.payload;
    const response = yield call(getPrebookingForUserPerAsset, userID, assetID);
    yield put(getPrebookingForUserPerAssetSuccess(response));
  } catch (error: any) {
    yield put(getPrebookingForUserPerAssetFailure(error.message));
  }
}

function* handleGetTotalPrebookingTokens(action: ReturnType<typeof getTotalPrebookingTokensRequest>): Generator<any, void, any> {
  try {
    const { userID, assetID } = action.payload;
    const data = yield call(getTotalPrebookingTokensAPI, userID, assetID);
    yield put(getTotalPrebookingTokensSuccess(data));
  } catch (error: any) {
    yield put(getTotalPrebookingTokensFailure(error.message || 'Failed to fetch data'));
  }
}

function* fetchPrebookingForAsset(action: { type: string; payload: string }): Generator<any, void, any> {
  try {
    const assetID = action.payload;
    const response = yield call(getPrebookingForAssetAPI, assetID);
    yield put(getPrebookingForAssetSuccess(response));
  } catch (error: any) {
    yield put(getPrebookingForAssetFailure(error.message));
  }
}

function* handleGetTotalPrebookingTokensForAsset(
  action: ReturnType<typeof getTotalPrebookingTokensForAssetRequest>
): Generator<any, void, any> {
  try {
    const assetID = action.payload;
    const data = yield call(getTotalPrebookingTokensForAssetAPI, assetID);
    // data should be { totalTokens: number }
    yield put(getTotalPrebookingTokensForAssetSuccess(data));
  } catch (error: any) {
    yield put(
      getTotalPrebookingTokensForAssetFailure(
        error.message || 'Failed to fetch total prebooking tokens for asset'
      )
    );
  }
}

export function* watchCreatePreBooking() {
  yield takeLatest(createPreBookingRequest.type, handleCreatePreBooking);
  yield takeLatest(getPrebookingForDashboardRequest.type, fetchPrebookingForDashboard);
  yield takeLatest(getPrebookingForUserPerAssetRequest.type, fetchPrebookingForUserPerAsset);
  yield takeLatest(getTotalPrebookingTokensRequest.type, handleGetTotalPrebookingTokens);
  yield takeLatest(getPrebookingForAssetRequest.type, fetchPrebookingForAsset);
  yield takeLatest(getTotalPrebookingTokensForAssetRequest.type, handleGetTotalPrebookingTokensForAsset);
}
