// app/pages/UserPage.tsx

import React, {useRef, useState } from 'react';
import TopBanner from '../TopBanner/TopBanner';
import { useParams } from 'react-router-dom';
import styles from './UserPageHolder.module.css';
import MainSection from '../MainSection/MainSection';
import { GiHamburgerMenu } from 'react-icons/gi';

const UserPageHolder: React.FC = () => {
  const { id } = useParams(); // Extracts `id` from the URL
  let [showLeftbar, setShowLeftbar] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null);
  const sideBar = (event: any) => {
    setShowLeftbar(event)
  }

  return (
    <div className={styles.container}>
      <div className={styles.topBanner} >
        <div className={styles.menu_body_mobile}><GiHamburgerMenu onMouseEnter={() => setShowLeftbar(true)} size={30} color='white' onClick={() => sideBar(true)} /></div>
        <TopBanner />
      </div>
      <div className={styles.content} ref={menuRef}>
        <div className={styles.mainContent} >
          <MainSection type={showLeftbar} evenListener={setShowLeftbar} />
          {/* <Outlet /> Render child routes */}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default UserPageHolder;
