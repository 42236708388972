import React, { useEffect, useState } from 'react';
import AssetInvestStats from './AssetInvestStats/AssetInvestStats';
import InvestProcess from './InvestProcess/InvestProcess';
import styles from './AssetInvestHolder.module.css';
import { useDispatch, useSelector } from 'react-redux';
import PreBooking from './PreBooking/Prebooking';
import PurchaseToken from '../AssetDetailViewer/PurchaseToken/PurchaseToken';
import PreBookingGraph from './PreBookingGraph/PreBookingGraph';
import Purchase from './Purchase/Purchase';
import PurchaseGraph from './PurchaseGraph/PurchaseGraph';
import TokenInteractorNew from '../AssetDetailViewer/TokenInteractor/TokenInteractorNew';
import {
  fetchAssetStagesRequest,
  AssetStages,
} from '../../../../features/assetStages/assetStagesSlice';
import { RootState } from '../../../../store/rootReducer';
import CryptoPurchaseHolder from './CryptoPurchaseHolder/CryptoPurchaseHolder';
import { useParams } from 'react-router-dom';

const AssetInvestHolder: React.FC = () => {
  const dispatch = useDispatch();
  const [assetStage, setAssetStage] = useState<string | null>(null);

  const { asset_id } = useParams();

  const assetID = asset_id ?? '';
  const userID = localStorage.getItem('userID') ?? '';
  
  // const { tokenAvailability, tokenPurchaseData } = useSelector((state: RootState) => state.assetEditor);
  const { data, loading, error } = useSelector((state: RootState) => state.assetStages);

  useEffect(() => {
    dispatch(fetchAssetStagesRequest({ assetID }));
  }, [dispatch, assetID]);

  useEffect(() => {
    if (data) {
      setAssetStage(data?.assetStage ?? null);
    }
  }, [data]);

  return (
    <div className={styles.container}>
      <div className={styles.investProcess}>
        {assetStage === 'Pre-Booking' ? <PreBooking /> : null}  
        {assetStage === 'Sale' ? <Purchase /> : null}
        {/* <PreBooking /> */}
        {/* <Purchase /> */}
        {/* {tokenPurchaseData?.purchaseStatus === 'Completed'? 
            <TokenInteractorNew requiredUSDC={150} tokenCount={3} />: <Purchase /> } */}
        
        {/* <TokenInteractorNew requiredUSDC={150} tokenCount={3} /> */}
      </div>
      <div className={styles.assetInvestStats}>
        {/* <PreBookingGraph /> */}
        {/* <PurchaseGraph /> */}
        {assetStage === 'Sale' ? <CryptoPurchaseHolder /> : null}

        {/* <CryptoPurchaseHolder /> */}
      </div>
    </div>
  );
};

export default AssetInvestHolder;
