
import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl + '/token-purchase';
const API_BASE_URL_ = apiUrl + '/user-distributions';
const API_BASE_URL2 = apiUrl;

export const fetchTotalTokensPurchased = async (assetID: string, userID: string) => {
    console.log('in fetchTotalTokensPurchased ', assetID, userID);
    try {
        const response = await axios.get(`${API_BASE_URL}/total-tokens-purchased/${assetID}/${userID}`);
        console.log('fetchTotalTokensPurchased response', response);
        return response;
    } catch (error) {
        throw error;
    }
};


export const fecthPropertInvested = async(userID: any)=> {
    try{
        const response = await axios.get(`${API_BASE_URL}/getTotalPropertyInveted/${userID}`)
        return response;
    }catch(error){
        console.log("=======fecthPropertInvested==========",error)
    }
}
export const fecthAllMonthlyTokens = async(userID: any)=> {
    try{
        const response = await axios.get(`${API_BASE_URL}/total-tokens-over-year/${userID}`)
        return response;
    }catch(error){
        console.log("=======fecthAllMonthlyTokens==========",error)
    }
}

export const fetchTotalIncome = async(userID :any)=>{
    try{
        const response = await axios.get(`${API_BASE_URL_}/totalIncomeReceived/${userID}`)
        return response;
    }catch(error){
        console.log("=======fecthAllMonthlyTokens==========",error)
    }
}

export const submitPurchaseRequestApi = async (data: any) => {
    try {
      const response = await axios.post(`${API_BASE_URL2}/token-purchase/submit-purchase-request`, data);
      console.log('submitPurchaseRequestApi response', response);
      return response.data; // Adjust based on your API response structure
    } catch (error) {
      throw new Error('Failed to submit purchase request');
    }
  };
  
  export const getPurchaseHistoryByAssetIDAndUserIDApi = async (
    assetID: string,
    userID: string
  ) => {
    console.log('getPurchaseHistoryByAssetIDAndUserIDApi:', assetID, userID);
    try {
      const response = await axios.get(`${API_BASE_URL}/purchase-history/${assetID}/${userID}`);
      console.log('getPurchaseHistoryByAssetIDAndUserIDApi:', response);
      return response;
    } catch (error) {
      throw new Error('Failed to fetch purchase history');
    }
  };
  
  export const getTotalTokenPurchaseByAssetID = async (assetID: string) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/token-purchase/total-tokens-purchased/${assetID}`);
      return response;
    } catch (error) {
      throw new Error('Failed to fetch total token purchase count');
    }
  };

  export const getTotalTokenPurchaseByAssetIDAndUserID = async (assetID: string, userID: string) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/total-tokens-purchased/${assetID}/${userID}`);
      return response;
    } catch (error) {
      throw new Error('Failed to fetch total token purchase count');
    }
  };
