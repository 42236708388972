// src/components/PropertyWallet/PropertyWallet.tsx

import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers'; // Import ethers.js
import styles from './PropertyWallet.module.css';

const PropertyWallet: React.FC = () => {
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [tokenBalances, setTokenBalances] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const generateAddress = async () => {
    try {
      // Generate a new wallet using ethers.js
      const wallet = ethers.Wallet.createRandom();
      const newAddress = wallet.address;

      // Update the wallet address in the state
      setWalletAddress(newAddress);
      setSuccessMessage('Address generated successfully!');
      setTokenBalances([]); // Reset token balances on new address generation
      setLoading(false);

      // Fetch token balances
      fetchTokenBalances(newAddress);

      // Clear the success message after 3 seconds
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (error) {
      setErrorMessage('Error generating address. Please try again.');
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  };

  const fetchTokenBalances = async (address: string) => {
    setLoading(true);
    try {
      // Here, you'll need to replace this with an actual API call to get the token balances for an address
      // For example, using Etherscan API: https://api.etherscan.io/api?module=account&action=tokenbalance&address=${address}&apikey=YourApiKey
      // This is just a mock example for now:
      const mockTokenData = [
        { token: 'DAI', balance: 100 },
        { token: 'USDT', balance: 50 },
        { token: 'ETH', balance: 0.5 }
      ];

      setTokenBalances(mockTokenData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching token balances:', error);
      setErrorMessage('Error fetching token balances.');
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h2>Property Wallet</h2>
      <p>All the property tokens will be stored in this address</p>

      {/* Success and Error Messages */}
      {successMessage && <div className={styles.success}>{successMessage}</div>}
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}

      <button className={styles.generateButton} onClick={generateAddress}>
        Generate Address
      </button>

      {walletAddress && (
        <div className={styles.walletInfo}>
          <p className={styles.address}>Wallet Address: {walletAddress}</p>
          <a 
            href={`https://etherscan.io/address/${walletAddress}`} 
            target="_blank" 
            rel="noopener noreferrer" 
            className={styles.etherscanLink}
          >
            View on Etherscan
          </a>

          {/* Show token balances */}
          <div className={styles.tokenBalances}>
            <h3>Token Balances</h3>
            {loading ? (
              <p>Loading tokens...</p>
            ) : (
              <ul>
                {tokenBalances.map((token, index) => (
                  <li key={index}>
                    {token.token}: {token.balance}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyWallet;
