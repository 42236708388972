import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CompanyState {
  companies: any[];
  companyDetails: any | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: CompanyState = {
  companies: [],
  companyDetails: null,
  status: 'idle',
  error: null,
};

const companySlice = createSlice({
  name: 'BackendPartners',
  initialState,
  reducers: {
    // Add a new company
    addCompanyRequest(state, action: PayloadAction<any>) {
      state.status = 'loading';
    },
    addCompanySuccess(state, action: PayloadAction<any>) {
      state.status = 'succeeded';
      state.companies.push(action.payload);
    },
    addCompanyFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },

    // Get all companies
    getAllCompaniesRequest(state) {
      state.status = 'loading';
    },
    getAllCompaniesSuccess(state, action: PayloadAction<any[]>) {
        console.log('getAllCompaniesSuccess');
        console.log('payload', action.payload);
      state.status = 'succeeded';
      state.companies = action.payload;
    },
    getAllCompaniesFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },

    // Get companies by type
    getCompaniesByTypeRequest(state, action: PayloadAction<string>) {
      state.status = 'loading';
    },
    getCompaniesByTypeSuccess(state, action: PayloadAction<any[]>) {
      state.status = 'succeeded';
      console.log('payload', action.payload);
      state.companies = action.payload;
    },
    getCompaniesByTypeFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const {
  addCompanyRequest,
  addCompanySuccess,
  addCompanyFailure,
  getAllCompaniesRequest,
  getAllCompaniesSuccess,
  getAllCompaniesFailure,
  getCompaniesByTypeRequest,
  getCompaniesByTypeSuccess,
  getCompaniesByTypeFailure,
} = companySlice.actions;

export default companySlice.reducer;
