import React from 'react';
import styles from './MetricsChart.module.css';
import { FaBuilding, FaDollarSign, FaShoppingBasket, FaCloud, FaClock } from 'react-icons/fa'; // Example icons from Font Awesome
import PropertiesInvestedChart from '../PropertiesInvestedChart/PropertiesInvestedChart';
import IncomeReceivedChart from '../IncomeReceivedChart/IncomeReceivedChart';

const MetricsChart = () => {
  return (
    <>
        <div className={styles.metricsContainer}>
            {/* Total Properties Card */}
            <div className={styles.metricCard}>
                <div className={styles.metricHeader}>
                <FaBuilding className={styles.metricIcon} />
                <span>Number of Tokens Purchased Over Time </span>
                </div>
                <PropertiesInvestedChart />
                {/* <div className={styles.metricComparison}>
                <span className={styles.comparisonBadge}>= 0.00%</span> vs previous 24 hours
                </div> */}
            </div>
        </div>

        <div className={styles.metricsContainer}>
            {/* Total Properties Card */}
            <div className={styles.metricCard}>
                <div className={styles.metricHeader}>
                <FaBuilding className={styles.metricIcon} />
                <span>Income received Over Time</span>
                </div>
                <IncomeReceivedChart />
                {/* <div className={styles.metricComparison}>
                <span className={styles.comparisonBadge}>= 0.00%</span> vs previous 24 hours
                </div> */}
            </div>
        </div>

    </>
  );
};

export default MetricsChart;
