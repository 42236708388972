// src/features/assetEditor/assetEditorSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const assetEditorSlice = createSlice({
  name: 'assetTokenization',
  initialState: {
    totalAssetTokens: 0,
    loadingTotalTokens: false,
    totalTokensError: null,
  },
  reducers: {
    getTotalTokensRequest: (state, action: PayloadAction<string>) => {
        console.log("getTotalTokensRequest action:", action);
      state.loadingTotalTokens = true;
      state.totalTokensError = null;
    },
    getTotalTokensSuccess: (state, action) => {
      state.loadingTotalTokens = false;
      state.totalAssetTokens = action.payload;
    },
    getTotalTokensFailure: (state, action) => {
      state.loadingTotalTokens = false;
      state.totalTokensError = action.payload;
    },
  },
});

export const {
  getTotalTokensRequest,
  getTotalTokensSuccess,
  getTotalTokensFailure,
} = assetEditorSlice.actions;

export default assetEditorSlice.reducer;
