import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordRequest } from '../../../features/auth/authSlice';
import styles from './PasswordReset.module.css';
import { RootState } from '../../../store/rootReducer';

const PasswordReset: React.FC = () => {
  const dispatch = useDispatch();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordConfirmPasswordAreSame, setNewPasswordConfirmPasswordAreSame] = useState(false);
  const [enableSaveChanges, setEnableSaveChanges] = useState(false);
  const [localSuccessMessage, setLocalSuccessMessage] = useState<string | null>(null);
  const [localErrorMessage, setLocalErrorMessage] = useState<string | null>(null);

  const [isMinLengthValid, setIsMinLengthValid] = useState(false);
  const [hasLetter, setHasLetter] = useState(false);
  const [hasNumberOrSymbol, setHasNumberOrSymbol] = useState(false);
  const checkMark = '✔';
  const userID = localStorage.getItem('userID') ?? '';

  const { successMessage, error, loading } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    setLocalSuccessMessage(successMessage);
    setLocalErrorMessage(error);

    // Clear notifications after 3 seconds
    const timer = setTimeout(() => {
      setLocalSuccessMessage(null);
      setLocalErrorMessage(null);
    }, 3000);

    return () => clearTimeout(timer);
  }, [successMessage, error]);

  const handleSaveChanges = () => {
    // Dispatch the changePasswordRequest action
    dispatch(
      changePasswordRequest({
        userID: userID,
        currentPassword: oldPassword,
        newPassword: newPassword,
      })
    );
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewPassword(value);

    // Check for minimum length
    setIsMinLengthValid(value.length >= 8);

    // Check for letters
    setHasLetter(/[a-zA-Z]/.test(value));

    // Check for numbers or symbols
    setHasNumberOrSymbol(/[0-9!@#$%^&*(),.?":{}|<>]/.test(value));

    // Re-validate confirm password if necessary
    setNewPasswordConfirmPasswordAreSame(value === confirmPassword);
    setEnableSaveChanges(
      value === confirmPassword && isMinLengthValid && hasLetter && hasNumberOrSymbol
    );
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setConfirmPassword(value);
    const passwordsMatch = newPassword === value;
    setNewPasswordConfirmPasswordAreSame(passwordsMatch);
    setEnableSaveChanges(
      passwordsMatch && isMinLengthValid && hasLetter && hasNumberOrSymbol
    );
  };

  const handleOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value);
  };

  return (
    <div className={styles.container}>
      <h2>Password Change</h2>

      {/* Success and Error Messages */}
      {localSuccessMessage && <div className={styles.success}>{localSuccessMessage}</div>}
      {localErrorMessage && <div className={styles.error}>{localErrorMessage}</div>}

      <form className={styles.form}>
        <div className={styles.inputGroup}>
          <input
            type="password"
            placeholder="Old Password"
            value={oldPassword}
            onChange={handleOldPasswordChange}
          />
        </div>
        <div className={styles.inputGroup}>
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={handleNewPasswordChange}
          />
        </div>
        <div className={styles.inputGroup}>
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
        </div>
        <div>
          <ul className={styles.passwordRequirements}>
            <li className={styles.requirement}>
              <span className={`${styles.checkmark} ${isMinLengthValid ? styles.valid : ''}`}>
                {checkMark}
              </span>{' '}
              At least 8 characters
            </li>
            <li className={styles.requirement}>
              <span className={`${styles.checkmark} ${hasLetter ? styles.valid : ''}`}>
                {checkMark}
              </span>{' '}
              Lowercase or uppercase letter
            </li>
            <li className={styles.requirement}>
              <span className={`${styles.checkmark} ${hasNumberOrSymbol ? styles.valid : ''}`}>
                {checkMark}
              </span>{' '}
              Number or symbol
            </li>
            <li className={styles.requirement}>
              <span
                className={`${styles.checkmark} ${
                  newPasswordConfirmPasswordAreSame ? styles.valid : ''
                }`}
              >
                {checkMark}
              </span>{' '}
              New password and Confirm password are the same
            </li>
          </ul>
        </div>
        <div className={styles.buttonContainer}>
          <button
            type="button"
            className={styles.saveButton}
            onClick={handleSaveChanges}
            disabled={!enableSaveChanges}
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default PasswordReset;
