import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl + '/asset-prebooking';

export const createPreBooking = async (preBookingData: any) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/createPreBooking`, preBookingData);
    return response.data;
  } catch (error) {
    const typedError = error as any;
    throw typedError.response ? typedError.response.data : typedError;
  }
};

export const getPrebookingForUserForDashboard = async (userID: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getPrebookingForUserForDashboard/${userID}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Failed to fetch prebooking data');
    } else {
      throw new Error('Failed to fetch prebooking data');
    }
  }
};

export const getPrebookingForUserPerAsset = async (userID: string, assetID: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getPrebookingForUserPerAsset/${userID}/${assetID}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Failed to fetch prebooking data for user and asset');
    } else {
      throw new Error('Failed to fetch prebooking data for user and asset');
    }
  }
};

export const getTotalPrebookingTokensAPI = async (userID: string, assetID: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getTotalPrebookingTokens/${userID}/${assetID}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Failed to fetch total prebooking tokens');
    } else {
      throw new Error('Failed to fetch total prebooking tokens');
    }
  }
};

/**
 * Fetch prebooking details for a specific asset.
 */
export const getPrebookingForAssetAPI = async (assetID: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getPrebookingForAsset/${assetID}`);
    return response.data; // Expecting AssetPrebooking[] in the response
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Failed to fetch prebooking data for asset');
    } else {
      throw new Error('Failed to fetch prebooking data for asset');
    }
  }
};

/**
 * Fetch total tokens prebooked for a given asset.
 */
export const getTotalPrebookingTokensForAssetAPI = async (assetID: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getTotalPrebookingTokensForAsset/${assetID}`);
    // The response should be { totalTokens: number }
    return response.data; 
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(
        error.response?.data?.message || 'Failed to fetch total prebooking tokens for asset'
      );
    } else {
      throw new Error('Failed to fetch total prebooking tokens for asset');
    }
  }
};