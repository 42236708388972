import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MarketplaceState {
  marketplaceItems: any[];
  loading: boolean;
  error: string | null;
}

const initialState: MarketplaceState = {
  marketplaceItems: [],
  loading: false,
  error: null,
};

const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    fetchMarketplaceItemsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchMarketplaceItemsSuccess: (state, action: PayloadAction<any[]>) => {
      state.loading = false;
      state.marketplaceItems = action.payload;
    },
    fetchMarketplaceItemsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchMarketplaceItemsRequest,
  fetchMarketplaceItemsSuccess,
  fetchMarketplaceItemsFailure,
} = marketplaceSlice.actions;

export default marketplaceSlice.reducer;
