// app/pages/Marketplace.tsx

import React from 'react';
import styles from "./AssetSliceTeam.module.css"
import profilePic2 from '../../../assets/profile_pic2.png';
import { IoChevronBackCircle } from "react-icons/io5";
import { Link } from 'react-router-dom';
import logo from '../../../assets/logo.png';
import { Header } from '../HomePage/Header/header';
import { Footer } from '../HomePage/Footer';

const AssetSliceTeam: React.FC = () => {
    const homePage = () => {
        console.log(">>")
    }
    return (
        <>
            {/* <div className={styles.logoContainer}>
                <Link to="/">
                    <img src={logo} alt="Assetslices Logo" className={styles.logo} />
                    <IoChevronBackCircle size="30" color="white" onClick={homePage} title='Back' />
                </Link>

            </div> */}
            <Header />
            {/* <button value="Back" className={styles.backButton}>Back</button> */}

            <div className={styles.meetourTeam}>
                <span className={styles.meetlabel}>  Meet Our Team </span>
            </div>
            <div className={styles.daocontainer}>

                <div className={styles.daoinvestore}><span>DAO Investors</span></div>
                <div className={styles.center}>
                <div className={styles.teammember}>
                    <img src={profilePic2} alt="Member 1" className={styles.memberphoto} />
                    <div className={styles.memberinfo}>
                        <div className={styles.membername}>Vino</div>
                        <div className={styles.memberrole}>CEO</div>
                        <div className={styles.memberdescription}>Passionate about leading projects and inspiring teams to achieve excellence.</div>
                    </div>
                </div></div>
            </div>


            <div className={styles.coreTeam}><span>Our Core Team</span></div>
            <div className={styles.teamcontainer}>
                <div className={styles.teammember}>
                    <img src={profilePic2} alt="Member 1" className={styles.memberphoto} />
                    <div className={styles.memberinfo}>
                        <div className={styles.membername}>Partha</div>
                        <div className={styles.memberrole}>Team Lead</div>
                        <div className={styles.memberdescription}>Passionate about leading projects and inspiring teams to achieve excellence.</div>
                    </div>
                </div>
                <div className={styles.teammember}>
                    <img src={profilePic2} alt="Member 2" className={styles.memberphoto} />
                    <div className={styles.memberinfo}>
                        <div className={styles.membername}>Jagan</div>
                        <div className={styles.memberrole}>Designer</div>
                        <div className={styles.memberdescription}>Creating stunning designs that bring ideas to life with a touch of elegance.</div>
                    </div>
                </div>
                <div className={styles.teammember}>
                    <img src={profilePic2} alt="Member 3" className={styles.memberphoto} />
                    <div className={styles.memberinfo}>
                        <div className={styles.membername}>Santa</div>
                        <div className={styles.memberrole}>Developer</div>
                        <div className={styles.memberdescription}>Turning concepts into reality with efficient and elegant code solutions.</div>
                    </div>
                </div>
                <div className={styles.teammember}>
                    <img src={profilePic2} alt="Member 4" className={styles.memberphoto} />
                    <div className={styles.memberinfo}>
                        <div className={styles.membername}>Ekanth</div>
                        <div className={styles.memberrole}>Tester</div>
                        <div className={styles.memberdescription}>Ensuring quality through rigorous testing and attention to detail.</div>
                    </div>
                </div>
                <div className={styles.teammember}>
                    <img src={profilePic2} alt="Member 5" className={styles.memberphoto} />
                    <div className={styles.memberinfo}>
                        <div className={styles.membername}>Jilani</div>
                        <div className={styles.memberrole}>Marketer</div>
                        <div className={styles.memberdescription}>Crafting strategies that drive engagement and amplify brand reach.</div>
                    </div>
                </div>
            </div>

            <div className={styles.coreTeam}><span>Our Advisor</span></div>
            <div className={styles.teamcontainer}>
                <div className={styles.teammember}>
                    <img src={profilePic2} alt="Member 1" className={styles.memberphoto} />
                    <div className={styles.memberinfo}>
                        <div className={styles.membername}>Partha</div>
                        <div className={styles.memberrole}>Team Lead</div>
                        <div className={styles.memberdescription}>Passionate about leading projects and inspiring teams to achieve excellence.</div>
                    </div>
                </div>
                <div className={styles.teammember}>
                    <img src={profilePic2} alt="Member 2" className={styles.memberphoto} />
                    <div className={styles.memberinfo}>
                        <div className={styles.membername}>Jagan</div>
                        <div className={styles.memberrole}>Designer</div>
                        <div className={styles.memberdescription}>Creating stunning designs that bring ideas to life with a touch of elegance.</div>
                    </div>
                </div>
                <div className={styles.teammember}>
                    <img src={profilePic2} alt="Member 3" className={styles.memberphoto} />
                    <div className={styles.memberinfo}>
                        <div className={styles.membername}>Santa</div>
                        <div className={styles.memberrole}>Developer</div>
                        <div className={styles.memberdescription}>Turning concepts into reality with efficient and elegant code solutions.</div>
                    </div>
                </div>
                <div className={styles.teammember}>
                    <img src={profilePic2} alt="Member 4" className={styles.memberphoto} />
                    <div className={styles.memberinfo}>
                        <div className={styles.membername}>Ekanth</div>
                        <div className={styles.memberrole}>Tester</div>
                        <div className={styles.memberdescription}>Ensuring quality through rigorous testing and attention to detail.</div>
                    </div>
                </div>
                <div className={styles.teammember}>
                    <img src={profilePic2} alt="Member 5" className={styles.memberphoto} />
                    <div className={styles.memberinfo}>
                        <div className={styles.membername}>Jilani</div>
                        <div className={styles.memberrole}>Marketer</div>
                        <div className={styles.memberdescription}>Crafting strategies that drive engagement and amplify brand reach.</div>
                    </div>
                </div>
            </div>

            <div className={styles.coreTeam}><span>Our Trusted Partners</span></div>
            <section className="partnerssection">
            <div className={styles.partnercontainer}>
                <div className={styles.partnerscontainer}>
                    <div className={styles.partneritem}>
                    <img src={profilePic2} alt="Member 5" className={styles.memberphoto} />
                            <h3>Partner 1</h3>
                            <a href="#">Visit Website</a>
                    </div>
                    <div className={styles.partneritem}>
                    <img src={profilePic2} alt="Member 5" className={styles.memberphoto} />
                            <h3>Partner 2</h3>
                            <a href="#">Visit Website</a>
                    </div>
                    <div className={styles.partneritem}>
                    <img src={profilePic2} alt="Member 5" className={styles.memberphoto} />
                            <h3>Partner 3</h3>
                            <a href="#">Visit Website</a>
                    </div>
                    <div className={styles.partneritem}>
                    <img src={profilePic2} alt="Member 5" className={styles.memberphoto} />
                            <h3>Partner 4</h3>
                            <a href="#">Visit Website</a>
                    </div>
                </div>
            </div>
            </section>
            <Footer />
        </>
    );
};

export default AssetSliceTeam;
