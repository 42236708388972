import React, { useEffect, useState } from 'react';
import styles from './TwoFactorSetup.module.css';
import { get2FAStatusRequest } from '../../../features/userSecurity/userSecuritySlice';
import { useDispatch, useSelector } from 'react-redux';

interface TwoFactorSetupProps {
  onNext: () => void;
}

const TwoFactorSetup: React.FC<TwoFactorSetupProps> = ({ onNext }) => {
  const dispatch = useDispatch();
  const { is2faEnabled } = useSelector((state: any) => state.userSecurity);

  const [smsEnabled, setSmsEnabled] = useState(false);
  const [appEnabled, setAppEnabled] = useState(false);
  const userId = localStorage.getItem('userID') ?? '';

  console.log('is2faEnabled:', is2faEnabled);

  useEffect(() => {
    dispatch(get2FAStatusRequest({ userId }));
  }, [dispatch, userId]);

  useEffect(() => {
    setSmsEnabled(is2faEnabled?.isSmsEnabled);
    setAppEnabled(is2faEnabled?.isTotpEnabled);
  }, [dispatch, is2faEnabled]);

  const handleAppEnabledChange = () => {
    setAppEnabled(!appEnabled);
    // if (!appEnabled) {
    //   onNext();
    // }
    console.log('is2faEnabled:', is2faEnabled);
    if (is2faEnabled?.isTotpEnabled) {
      disable2FA();
    }
    else {
      enable2FA();
    }
  };

  const enable2FA = () => {
    onNext();
  }

  const disable2FA = () => {
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Set up 2 Factor Authentication</h2>
      <p>Choose how you want the security codes sent to you.</p>

      <div className={styles.option}>
        <label className={styles.label}>
          <div className={styles.authenticationOption}>
            <strong>SMS authentication</strong><br />
            We will send the log in codes to the number you set up here.
          </div>
          <input
            type="checkbox"
            checked={smsEnabled}
            onChange={() => setSmsEnabled(!smsEnabled)}
            className={styles.checkbox}
          />
        </label>
      </div>

      <div className={styles.option}>
        <label className={styles.label}>
          <div className={styles.authenticationOption}>
            <strong>Authentication app (recommended)</strong><br />
            The app will provide unique codes for you to log in with anytime.
          </div>
          <input
            type="checkbox"
            checked={appEnabled}
            onChange={handleAppEnabledChange}
            className={styles.checkbox}
          />
        </label>
      </div>
    </div>
  );
};

export default TwoFactorSetup;
