import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;
const API_BASE_URL = `${apiUrl}/waiting-list`;

// API call to join waiting list
export const joinWaitingListApi = async (emailId: string) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/join/${emailId}`);
    return response.data; // Expecting a WaitingList object
  } catch (error: any) {
    // You can refine error handling as needed
    throw new Error(error.response?.data?.message || 'Failed to join waiting list');
  }
};

// API call to get all entries in the waiting list
export const findAllWaitingListApi = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/`);
    return response.data; // Expecting an array of WaitingList entries
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Failed to retrieve waiting list');
  }
};
