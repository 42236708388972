import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProfileRequest } from '../../../features/userProfile/userProfileSlice';
import { RootState } from '../../../store/rootReducer';
import AddressSection from './AddressSection';
import PersonalInformation from './PersonalInformation';
import ProfilePicSelector from './ProfilePicSelector';
import AddressSection2 from './AddressSection2';

const MyProfile: React.FC = () => {
  const dispatch = useDispatch();
  const userID = localStorage.getItem('userID') ?? '';

  const { profile, loading } = useSelector((state: RootState) => state.userProfile);

  useEffect(() => {
    dispatch(fetchUserProfileRequest(userID));
  }, [dispatch, userID]);

  if (loading) {
    return <div>Loading profile...</div>;
  }

  return (
    <>
      {profile && (
        <>
          {/* <ProfilePicSelector profilePicSelected={profile.profilePicSelected} /> */}
          <PersonalInformation 
            firstName={profile.firstName}
            lastName={profile.lastName}
            email={profile.email}
            phoneNumber={profile.phoneNumber}
            areaCode={profile.areaCode}
          />
          <AddressSection2 
            address={profile.address1}
            addressLine2={profile.address2}
            city={profile.city}
            state={profile.state}
            zip={profile.zip}
            county={profile.county}
            country={profile.country}
          />
          <AddressSection 
            address={profile.address1}
            addressLine2={profile.address2}
            city={profile.city}
            state={profile.state}
            zip={profile.zip}
            county={profile.county}
            country={profile.country}
          />
                  </>
      )}
    </>
  );
};

export default MyProfile;
