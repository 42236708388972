import React from 'react';
import styles from './FeaturedListings.module.css';
import house1 from '../../../../assets/11.jpg';
import house2 from '../../../../assets/490aa385.jpg';
import house3 from '../../../../assets/498693106.jpg';

const FeaturedListings = () => {
  const listings = [
    {
      image: house1,
      title: 'Awesome Family Home',
      description: '3 bed | 2 bath | 4200 sq ft',
      price: '51USDC / SLICE',
      badges: ['Available']
    },
    {
      image: house2,
      title: 'Contemporary Studio',
      description: '1 bed | 1 bath | 1300 sq ft',
      price: '50USDC / SLICE',
      badges: ['Available']
    },
    {
      image: house3,
      title: 'Studio Bay View',
      description: '1 bed | 1 bath | 4400 sq ft',
      price: '52USDC / SLICE',
      badges: ['Available']
    }
  ];

  return (
    <div className={styles.featuredListings}>
      <h2>Discover Our Listings</h2>
      <p>These listings contain exclusive real estate opportunities within the city</p>
      <div className={styles.carousel}>
        {listings.map((listing, index) => (
          <div key={index} className={styles.listingCard}>
            <div className={styles.imageContainer}>
              <img src={listing.image} alt={listing.title} />
              {listing.badges.map((badge, idx) => (
                <div key={idx} className={styles.badge}>{badge}</div>
              ))}
            </div>
            <div className={styles.listingInfo}>
              <h3>{listing.title}</h3>
              <p>{listing.description}</p>
              <p className={styles.price}>{listing.price}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedListings;
