import React from "react";
import styles from "./NFTGreenPassView.module.css";

interface NFTGreenPassViewProps {
  imageUrl: string;
  alignImage: "left" | "right"; // Prop to control image alignment
}

const NFTGreenPassView: React.FC<NFTGreenPassViewProps> = ({
  imageUrl,
  alignImage,
}) => {
  const handlePurchaseClick = () => {
    // Open OpenSea in a new tab
    window.open("https://opensea.io/", "_blank");
  };

  return (
    <div
      className={`${styles.container} ${
        alignImage === "left" ? styles.leftAligned : styles.rightAligned
      }`}
    >
      <div className={styles.image}>
        <img src={imageUrl} alt="Green Pass" />
      </div>
      <div className={styles.description}>
        <h2 className={styles.title}>Green Pass</h2>
        <p>
          The Green Pass provides eco-friendly privileges and rewards, ensuring
          a sustainable future for our valued members.
        </p>
        <ul className={styles.extraDescription}>
          <li>Total available NFTs - 2000.</li>
          <li>Exclusive access to sustainable events and communities.</li>
          <li>Valid for two years from the date of issuance.</li>
          <li>Carbon footprint offset initiatives.</li>
          <li>Supports green charities and organizations.</li>
        </ul>
        {/* Purchase Button */}
        <div className={styles.buttonContainer}>
          <button className={styles.purchaseButton} onClick={handlePurchaseClick}>
            Purchase Green Pass
          </button>
        </div>
      </div>
    </div>
  );
};

export default NFTGreenPassView;
