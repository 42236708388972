import React from 'react';
import styles from './UserHoldingsHolder.module.css';
import MetricsCards from './MetricsCards/MetricsCards';
import Reports from './Reports/Reports';
import ReportTable from './ReportTable/ReportTable';
import PrebookingReport from './Reports/PrebookingReport/PrebookingReport';
import SalesRequestReport from './Reports/SalesRequestReport/SalesRequestReport';
import DividendReport from './Reports/DividentReport/DividentReport';
import PurchaseReport from './Reports/PurchaseReport/PurchaseReport';


const UserHoldingsHolder: React.FC = () => {
  const [selectedReport, setSelectedReport] = React.useState<string | undefined>('Prebooking');

  const handleReportSelection = (report: string) => {
    setSelectedReport(report);
  }

  return (
    <div className={styles.container}>
      <MetricsCards handleReportSelection={handleReportSelection} cards={[]}/>
      {selectedReport == 'Prebooking' && <PrebookingReport />}
      {selectedReport == 'SalesRequests' && <SalesRequestReport />}
      {selectedReport == 'Dividends' && <DividendReport />}
      {selectedReport == 'Purchases' && <PurchaseReport />}
      {/* <div className={styles.investProcess}>
        <PreBooking />
      </div>
      <div className={styles.assetInvestStats}>
      </div> */}
    </div>
  );
};

export default UserHoldingsHolder;
