import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateTotpRequest, verifyTotpRequest, resetTotpVerificationMessages } from '../../../features/userSecurity/userSecuritySlice';
import { fetchEmailByUserIdRequest } from '../../../features/user/userSlice';
import styles from './AuthenticatorSetup.module.css';
import { FaCopy } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const AuthenticatorSetup = () => {
  const [code, setCode] = useState(Array(6).fill(''));
  const dispatch = useDispatch();
  const { totpData, loading, totpVerifiedSuccessMessage, totpVerifiedErrorMessage,  error, successMessage } = useSelector((state: any) => state.userSecurity);
  const { email: userEmail } = useSelector((state: any) => state.user);

  // Placeholder values for userId and userEmail
  const userId = localStorage.getItem('userID') ?? '';
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    console.log('AuthenticatorSetup component loaded');
    dispatch(fetchEmailByUserIdRequest(userId));
  }, [dispatch]);

  useEffect(() => {
    console.log('AuthenticatorSetup got email');
    // Dispatch the action to generate the TOTP secret and QR code on component load
    dispatch(generateTotpRequest({ userId, userEmail }));
  }, [dispatch, userId, userEmail]);

    // Cleanup notifications when component is unmounted or route changes
    useEffect(() => {
      return () => {
        dispatch(resetTotpVerificationMessages());
      };
    }, [dispatch, navigate]); // Triggers on route change

  console.log('totpData:', totpData);
  const qrCodeUrl = totpData?.qrCodeDataUrl;
  const secretKey = totpData?.secret;

  const onVerify = (code: string) => {
    console.log('Verifying code:', code);
  };

  const handleCodeInput = (value: string, index: number) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Automatically focus the next input field if the current one is filled
    if (value.length === 1 && index < 5) {
      const nextSibling = document.querySelector(`input[name="digit-${index + 2}"]`) as HTMLInputElement;
      if (nextSibling) {
        nextSibling.focus();
      }
    }

    // If the 6th digit is entered, automatically call verifyCode
    if (index === 5 && value.length === 1) {
      dispatch(verifyTotpRequest({ userId, token: code.join('')}));
    }
  };

  const handleCopyKey = () => {
    navigator.clipboard.writeText(secretKey).then(
      () => alert('Secret Key copied to clipboard!'),
      () => alert('Failed to copy Secret Key.')
    );
  };

  const handleVerify = () => {
    //onVerify(code.join(''));
    dispatch(verifyTotpRequest({ userId, token: code.join('')}));
  };

  return (
    <div className={styles.container}>
      <h2>Enable 2FA - Authenticator App</h2>

      {loading && <p>Loading...</p>}
      {totpVerifiedErrorMessage && <p className={styles.error}>{totpVerifiedErrorMessage}</p>}
      {totpVerifiedSuccessMessage && <p className={styles.success}>{totpVerifiedSuccessMessage}</p>}

      <ol>
        <li className={styles.listItem}>
          <div className={styles.listText}>
            You will need an authenticator mobile app to complete this process, such as one of the following:
            <ul>
              <li>Google Authenticator (recommended) </li>
              <li>Microsoft Authenticator</li>
              <li>Duo Mobile</li>
            </ul>
          </div>
          <div className={styles.qrCodeContainer}>
            <img src={qrCodeUrl} alt="QR Code" className={styles.qrCode} />
          </div>
        </li>
        <li className={styles.stepsListItem}>
          If you can't scan the code, you can enter this secret key into your authentication app:
          <div className={styles.secretKeyInputContainer}>
            <input type="text" value={secretKey} readOnly className={styles.secretKey} />
            <FaCopy className={styles.copyIcon} onClick={handleCopyKey} />
          </div>
        </li>
        <li className={styles.stepsListItem}>
          After scanning the QR code above, enter the six-digit code generated by your authenticator:
          <div className={styles.digits}>
            {code.map((digit, index) => (
              <input
                key={index}
                type="text"
                name={`digit-${index + 1}`}
                maxLength={1}
                value={digit}
                onChange={(e) => handleCodeInput(e.target.value, index)}
                className={styles.digitInput}
              />
            ))}
          </div>
        </li>
      </ol>
      <button onClick={handleVerify} className={styles.verifyButton}>Verify now</button>
    </div>
  );
};

export default AuthenticatorSetup;
