// referralCodeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ReferralCodeState {
  referralCode: string | null;
  referralUrl: string | null;
  clickRecorded: boolean;
  loading: boolean;
  error: string | null;
  stats: any;
}

const initialState: ReferralCodeState = {
  referralCode: null,
  referralUrl: null,
  clickRecorded: false,
  loading: false,
  error: null,
  stats: null,  
};

const referralCodeSlice = createSlice({
  name: 'referralCode',
  initialState,
  reducers: {
    // Fetch Referral Code
    fetchReferralCodeRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = null;
    },
    fetchReferralCodeSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.referralCode = action.payload;
    },
    fetchReferralCodeFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchReferralUrlRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = null;
    },
    fetchReferralUrlSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      console.log('action.payload ', action.payload);
      state.referralUrl = action.payload;
    },
    fetchReferralUrlFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Record Referral Click
    recordReferralClickRequest: (state, action: PayloadAction<{ referralCode: string, additionalInfo: any} >) => {
      console.log('in recordReferralClickRequest slice fn');
      console.log('payload ', action.payload);
      state.loading = true;
      state.error = null;
    },
    recordReferralClickSuccess: (state) => {
      state.loading = false;
      state.clickRecorded = true;
    },
    recordReferralClickFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

    fetchReferralStatsRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.error = null;
    },
    fetchReferralStatsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.stats = action.payload;
    },
    fetchReferralStatsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

  },
});

export const {
  fetchReferralCodeRequest,
  fetchReferralCodeSuccess,
  fetchReferralCodeFailure,
  fetchReferralUrlRequest,
  fetchReferralUrlSuccess,
  fetchReferralUrlFailure,
  recordReferralClickRequest,
  recordReferralClickSuccess,
  recordReferralClickFailure,
  fetchReferralStatsRequest,
  fetchReferralStatsSuccess,
  fetchReferralStatsFailure,
} = referralCodeSlice.actions;

export default referralCodeSlice.reducer;
