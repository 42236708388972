import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AssetPrebooking {
  id: string;
  numberOfTokens: number;
  prebookDate: number;
  tokenPurchaseCount: number;
  purchaseWindowOpen: number;
  purchasedStatus: 'Purchased' | 'Pending';
}

interface PreBookingState {
  loading: boolean;
  success: boolean;
  error: string | null;
  prebookingData: { assetID: string; totalTokens: number }[] | null;
  userAssetPrebookingData: AssetPrebooking[] | null;
  totalTokensForAssetAndUser: any;
  totalPrebookingTokensForAsset?: number | null;
}

const initialState: PreBookingState = {
  loading: false,
  success: false,
  error: null,
  prebookingData: null,
  userAssetPrebookingData: null,
  totalTokensForAssetAndUser: null,
  totalPrebookingTokensForAsset: null,
};

const prebookingSlice = createSlice({
  name: 'prebooking',
  initialState,
  reducers: {
    createPreBookingRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.success = false;
      state.error = null;
    },
    createPreBookingSuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    },
    createPreBookingFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    getPrebookingForDashboardRequest(state, action: PayloadAction<string>) {
      state.loading = true;
      state.error = null;
    },
    getPrebookingForDashboardSuccess(
      state,
      action: PayloadAction<{ assetID: string; totalTokens: number }[]>
    ) {
      state.loading = false;
      state.prebookingData = action.payload;
    },
    getPrebookingForDashboardFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    getPrebookingForUserPerAssetRequest(state, action: PayloadAction<{ userID: string; assetID: string }>) {
      state.loading = true;
      state.error = null;
    },
    getPrebookingForUserPerAssetSuccess(state, action: PayloadAction<AssetPrebooking[]>) {
      state.loading = false;
      state.userAssetPrebookingData = action.payload;
    },
    getPrebookingForUserPerAssetFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },    
    getTotalPrebookingTokensRequest: (
      state,
      action: PayloadAction<{ userID: string; assetID: string }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    getTotalPrebookingTokensSuccess: (
      state,
      action: PayloadAction<{ userID: string; assetID: string; totalTokens: number }>
    ) => {
      state.loading = false;
      state.totalTokensForAssetAndUser = action.payload;
    },
    getTotalPrebookingTokensFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getPrebookingForAssetRequest(state, action: PayloadAction<string>) {
      // assetID is a string
      state.loading = true;
      state.error = null;
    },
    getPrebookingForAssetSuccess(state, action: PayloadAction<AssetPrebooking[]>) {
      state.loading = false;
      // We can store this data in a new field or reuse existing fields if you want
      // For clarity, let's say we store it in userAssetPrebookingData or a new field
      state.userAssetPrebookingData = action.payload; 
    },
    getPrebookingForAssetFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },    
    getTotalPrebookingTokensForAssetRequest: (state, action: PayloadAction<string>) => {
      // assetID is a string
      state.loading = true;
      state.error = null;
    },
    getTotalPrebookingTokensForAssetSuccess: (
      state, 
      action: PayloadAction<{ totalTokens: number }>
    ) => {
      state.loading = false;
      state.totalPrebookingTokensForAsset = action.payload.totalTokens;
    },
    getTotalPrebookingTokensForAssetFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },    
  },
});

export const {
  createPreBookingRequest,
  createPreBookingSuccess,
  createPreBookingFailure,
  getPrebookingForDashboardRequest,
  getPrebookingForDashboardSuccess,
  getPrebookingForDashboardFailure,
  getPrebookingForUserPerAssetRequest,
  getPrebookingForUserPerAssetSuccess,
  getPrebookingForUserPerAssetFailure,  
  getTotalPrebookingTokensRequest,
  getTotalPrebookingTokensSuccess,
  getTotalPrebookingTokensFailure,
  getPrebookingForAssetRequest,
  getPrebookingForAssetSuccess,
  getPrebookingForAssetFailure,
  getTotalPrebookingTokensForAssetRequest,
  getTotalPrebookingTokensForAssetSuccess,
  getTotalPrebookingTokensForAssetFailure,
} = prebookingSlice.actions;

export default prebookingSlice.reducer;
