// app/pages/Marketplace.tsx

import React from 'react';
import styles from './AssetSliceToken.module.css';
import { Header } from '../HomePage/Header/header';
import { Footer } from '../HomePage/Footer';
import sampleImage from "../../../assets/sample image.png"

const AssetSliceToken: React.FC = () => {
  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.mainContent}>
            <h2>AssetSliceToken</h2>
            <p>Welcome to the AssetSliceToken!</p>
            <span>Please find the more details about the token <a  href="https://docs.assetslices.com/assetslices/tokens/token-details" target='_blank' rel='noopener noreferrer' className={styles.details}>Details</a> </span>
            <img src={sampleImage} alt="graph"/>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AssetSliceToken;
