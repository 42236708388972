// src/components/UserDashboard/UserDashboard.tsx
import React, { useEffect, useMemo, useState } from 'react';
import MetricsCard from './MetricsCard/MetricsCard';
import MetricsChart from './MetricsChart/MetricsChart';
import AllPropertyRates from './AllPropertyRates/AllPropertyRates';
import { useSelector, useDispatch } from 'react-redux';
import { ShowReqestPropertycount } from '../../../features/asset/assetSlice';
import { resendEmailVerificationRequest, checkEmailVerifiedRequest } from '../../../features/auth/authSlice';
import { fetchAllMonthlyTokensRequest, fetchPropertyInvestedRequest, fetchTotalIncomeReceived } from '../../../features/tokenPurchase/tokenPurchaseSlice';
import { purchaserID } from '../../common';
import styles from './UserDashboard.module.css';
import PropertiesInvestedList from './PropertiesInvestedList/PropertiesInvestedList';
import PropertiesPrebookedList from './PropertiesPrebookedList/PropertiesPrebookedList';

const UserDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const userId = purchaserID()
  let [dashBoardDetails, setDashBoardDetails] = useState({
    totalProperties: null,
    totalPropertiesInvested: null,
    totalTokens: null
  })

  // Fetching user verification status (assumed to be in auth slice)
  // TODO: Change isEmailVerified and Create a Pending task list and fetch the list
  const { isEmailVerified, user, emailVerificationSent, loading } = useSelector((state: any) => state.auth);


  useMemo(() => {
    if (userId) {
      dispatch(ShowReqestPropertycount(userId))
      dispatch(fetchPropertyInvestedRequest(userId))
      dispatch(checkEmailVerifiedRequest(userId));
      dispatch(fetchAllMonthlyTokensRequest(userId))
      dispatch(fetchTotalIncomeReceived(userId))
    }
  }, [userId])
  const { asset, tokenPurchase } = useSelector((state: any) => state)
  useEffect(() => {
    if (asset)
      setDashBoardDetails((prev: any) => ({
        ...prev,
        totalProperties: asset.totalProperty,
        totalPropertiesInvested: tokenPurchase?.propertyInvested?.data?.length,
        incomeReceived: tokenPurchase?.totalIncomeReceived?.data
      })
      )
  }, [asset, tokenPurchase]);

  const handleResendVerification = () => {
    dispatch(resendEmailVerificationRequest({ userId }));
  };

  return (
    <div>
      {/* Email verification pending banner */}
      {!isEmailVerified && (
        <div className={styles.banner}>
          <h3>Email Verification Pending</h3>
          <p>
            Hi {user?.email}, it looks like you haven't verified your email yet.
            Please check your inbox for a verification email and follow the steps to complete the verification process.
            If you didn't receive the email, you can {' '}
            <span className={styles.resendLink} onClick={handleResendVerification}>
              resend the verification email
            </span>.
            {emailVerificationSent && <span> Verification email sent! Please check your inbox.</span>}
          </p>
        </div>
      )}

      <MetricsCard data={dashBoardDetails} />
      <PropertiesPrebookedList />
      <PropertiesInvestedList />
      {/* <AllPropertyRates /> */}
      <MetricsChart />
    </div>
  );
};

export default UserDashboard;
