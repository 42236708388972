import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl + '/marketplace';

export const fetchMarketplaceItemsApi = async () => {
  const response = await axios.get(`${API_BASE_URL}/items`);
  return response.data;
};

