import React, { useEffect, useState } from 'react';
import styles from "./PrebookingReport.module.css";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/rootReducer';
import { getPrebookingForUserPerAssetRequest } from '../../../../../../features/assetPrebooking/assetPrebookingSlice';
import { useParams } from 'react-router-dom';
import config from '../../../../../../config/config';

// Define the structure of the table data
interface Order {
    id: string;
    prebookingDate: string;
    tokensPrebooked: string;
    purchaseWindowOpen: string;
    purchasedStatus: "Purchased" | "Pending";
}

const orders: Order[] = [
  {
    id: "#DU0007",
    prebookingDate: "2021-09-01",
    tokensPrebooked: "100",
    purchaseWindowOpen: "2021-09-02",
    purchasedStatus: "Purchased",
  },
  {
    id: "#DU0007",
    prebookingDate: "2021-09-01",
    tokensPrebooked: "100",
    purchaseWindowOpen: "2021-09-02",
    purchasedStatus: "Purchased",
  },
  {
    id: "#DU0007",
    prebookingDate: "2021-09-01",
    tokensPrebooked: "100",
    purchaseWindowOpen: "2021-09-02",
    purchasedStatus: "Pending",
  },
  {
    id: "#DU0007",
    prebookingDate: "2021-09-01",
    tokensPrebooked: "100",
    purchaseWindowOpen: "2021-09-02",
    purchasedStatus: "Pending",
  },
  {
    id: "#DU0007",
    prebookingDate: "2021-09-01",
    tokensPrebooked: "100",
    purchaseWindowOpen: "2021-09-02",
    purchasedStatus: "Purchased",
  },
  {
    id: "#DU0007",
    prebookingDate: "2021-09-01",
    tokensPrebooked: "100",
    purchaseWindowOpen: "2021-09-02",
    purchasedStatus: "Purchased",
  },
];

const Reports: React.FC = () => {
    const dispatch = useDispatch();

    const { userAssetPrebookingData, loading, success, error } = useSelector((state: RootState) => state.prebooking);
  const marketplaceLink = config.uiUrl + '/me/marketplace';

    console.log('userAssetPrebookingData ', userAssetPrebookingData);

    const { asset_id } = useParams(); // Extracts `id` from the URL
  
    const assetID = asset_id ?? ''; // Use the correct asset ID
    const userID = localStorage.getItem('userID') ?? '';

    useEffect(() => {
        dispatch(getPrebookingForUserPerAssetRequest({ userID: userID, assetID: assetID }));
      }, [dispatch]);
    
    

      const formatReadableDate = (isoDate: string): string => {
        const date = new Date(isoDate);
      
        // Options for date formatting
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          // hour: '2-digit',
          // minute: '2-digit',
          // second: '2-digit',
          // timeZoneName: 'short',
        };
      
        return date.toLocaleDateString('en-US', options);
      };
      
        


  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Prebooking Orders</h2>
      {userAssetPrebookingData?.length === 0 ? (
              <div className={styles.noData}>
              <p>
                You have not prebooked any slices yet. Please go to{' '}
                <a
                    href={`${marketplaceLink}`}
                    rel="noopener noreferrer"
                  className={styles.marketplaceLink}
                >
                  Invest in this asset
                </a>{' '}
                tab and prebook your slices.
              </p>
            </div>
      ) : (
            <table className={styles.table}>
                <thead>
                <tr>
                    <th>Order ID</th>
                    <th>Tokens prebooked</th>
                    <th>Prebooking Date</th>
                    <th>Purchase window open</th>
                    <th>Purchased Status</th>
                </tr>
                </thead>
                <tbody>
                {userAssetPrebookingData?.map((order) => (
                    <tr key={order?.id}>
                    <td>{order?.id}</td>
                    <td>{order?.numberOfTokens}</td>
                    <td>{formatReadableDate(order?.prebookDate.toString())}</td>
                    {/* <td className={styles.amount}>{order.amount}</td> */}
                    <td>{order?.purchaseWindowOpen ? order?.purchaseWindowOpen : 'NA'}</td>
                    <td>
                        <span
                        className={`${styles.status} ${
                            styles[order?.purchasedStatus?.toLowerCase() ? 'purchased' : 'pending']
                        }`}
                        >
                        {order?.purchasedStatus? 'Purchased' : 'Pending'}
                        </span>
                    </td>
                    </tr>
                ))}
                </tbody>
            </table>
          )}
    </div>
  );
};

export default Reports;
