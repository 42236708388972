// src/features/asset/assetEditorSaga.ts
import { call, put, takeLatest } from 'redux-saga/effects';
import { saveLocationDetailsApi, getLocationDetailsApi, 
  saveBasicDetailsApi, getBasicDetailsApi,
  saveRentDetailsApi, saveAssetDescriptionApi, saveTokenOfferingDetailsApi, getTokenOfferingDetailsApi,
  saveInternalStructureDetailsAPi, getInternalStructureDetailsApi,
  saveExternalStructureDetailsApi, savePurchaseDetailsApi, saveTokenDetailsApi, getTokenDetailsApi,
  saveValuationDetailsApi, getValuationDetailsApi, unpublishAssetApi, publishAssetApi,
  uploadImageApi, uploadValuationDocumentApi, saveFinancialDetailsApi,
  getExternalStructureDetailsApi, getPurchaseDetailsApi, getFinancialDetailsApi,
  getRentDetailsApi, saveAssetFeaturesApi, getAssetFeaturesApi, 
  updateMonthlyRentDetailsApi, getAllMonthlyRentDetailsApi, getMonthlyRentDetailsByYearMonthApi,
  getAssetUpdatesApi, saveAssetUpdateApi, getPurchasePriceApi,
  getActiveTokenPriceApi, getInitialTokenPriceApi, getTokenPriceHistoryApi, setTokenPriceApi, 
  getTokenAvailabilityApi, submitPurchaseRequestApi, updateTokenPriceApi,
  getPurchaseHistoryByAssetIDApi, getPurchaseHistoryByAssetIDAndUserIDApi, getTotalTokenPurchaseByAssetID
} from './assetEditorApi';
import {
  getLocationDetailsRequest, getLocationDetailsSuccess, getLocationDetailsFailure,
  saveLocationDetailsRequest, saveLocationDetailsSuccess, saveLocationDetailsFailure,
  saveBasicDetailsRequest, saveBasicDetailsSuccess, saveBasicDetailsFailure,
    getBasicDetailsRequest, getBasicDetailsSuccess, getBasicDetailsFailure,
    saveRentDetailsRequest, saveRentDetailsSuccess, saveRentDetailsFailure,
    getRentDetailsRequest, getRentDetailsSuccess, getRentDetailsFailure,
    saveAssetDescriptionRequest, saveAssetDescriptionSuccess, saveAssetDescriptionFailure,
    getTokenOfferingDetailsRequest, getTokenOfferingDetailsSuccess, getTokenOfferingDetailsFailure,
    saveTokenOfferingDetailsRequest, saveTokenOfferingDetailsSuccess, saveTokenOfferingDetailsFailure,
    saveInternalStructureRequest, saveInternalStructureSuccess, saveInternalStructureFailure,
    saveExternalStructureRequest, saveExternalStructureSuccess, saveExternalStructureFailure,
    getExternalStructureRequest, getExternalStructureSuccess, getExternalStructureFailure,
    savePurchaseDetailsRequest, savePurchaseDetailsSuccess, savePurchaseDetailsFailure,
    getTokenDetailsRequest, getTokenDetailsSuccess, getTokenDetailsFailure,
    saveTokenDetailsRequest, saveTokenDetailsSuccess, saveTokenDetailsFailure,      
    getValuationDetailsRequest, getValuationDetailsSuccess, getValuationDetailsFailure,
    saveValuationDetailsRequest, saveValuationDetailsSuccess, saveValuationDetailsFailure,  
    publishAssetRequest, publishAssetSuccess, publishAssetFailure,
    unpublishAssetRequest, unpublishAssetSuccess, unpublishAssetFailure,
    uploadImageRequest, uploadImageSuccess, uploadImageFailure,
    uploadValuationDocumentRequest, uploadValuationDocumentSuccess, uploadValuationDocumentFailure,
    saveFinancialDetailsRequest, saveFinancialDetailsSuccess, saveFinancialDetailsFailure,
    getInternalStructureRequest, getInternalStructureSuccess, getInternalStructureFailure,
    getPurchaseDetailsRequest, getPurchaseDetailsSuccess, getPurchaseDetailsFailure,
    getFinancialDetailsRequest, getFinancialDetailsSuccess, getFinancialDetailsFailure,
    saveFeaturesRequest, saveFeaturesSuccess, saveFeaturesFailure,
    getFeaturesRequest, getFeaturesSuccess, getFeaturesFailure,
    updateMonthlyRentDetailsRequest, updateMonthlyRentDetailsSuccess, updateMonthlyRentDetailsFailure,
    getAllMonthlyRentDetailsRequest, getAllMonthlyRentDetailsSuccess, getAllMonthlyRentDetailsFailure,
    getMonthlyRentDetailsByYearMonthRequest, getMonthlyRentDetailsByYearMonthSuccess, getMonthlyRentDetailsByYearMonthFailure,
    getAssetUpdatesRequest, getAssetUpdatesSuccess, getAssetUpdatesFailure,
    saveAssetUpdateRequest, saveAssetUpdateSuccess, saveAssetUpdateFailure,
    getPurchasePriceRequest, getPurchasePriceSuccess, getPurchasePriceFailure,
    getActiveTokenPriceRequest, getActiveTokenPriceSuccess, getActiveTokenPriceFailure,
    setTokenPriceRequest, setTokenPriceSuccess, setTokenPriceFailure,
    getInitialTokenPriceRequest, getInitialTokenPriceSuccess, getInitialTokenPriceFailure,
    getTokenPriceHistoryRequest, getTokenPriceHistorySuccess, getTokenPriceHistoryFailure,
    getTokenAvailabilityRequest, getTokenAvailabilitySuccess, getTokenAvailabilityFailure,
    submitPurchaseRequest, submitPurchaseRequestSuccess, submitPurchaseRequestFailure,
    fetchPurchaseHistoryByAssetID, fetchPurchaseHistoryByAssetIDSuccess, fetchPurchaseHistoryByAssetIDFailure, 
    fetchPurchaseHistoryByAssetIDAndUserID, fetchPurchaseHistoryByAssetIDAndUserIDSuccess, fetchPurchaseHistoryByAssetIDAndUserIDFailure,
    fetchTotalTokenPurchaseByAssetID, fetchTotalTokenPurchaseByAssetIDSuccess, fetchTotalTokenPurchaseByAssetIDFailure,
    updateTokenPriceRequest, updateTokenPriceSuccess, updateTokenPriceFailure,

} from './assetEditorSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { FinancialDetails, PurchaseDetails, TokenDetails, ValuationDetails, ResponseGenerator, AssetFeatures, MonthlyRentDetails, AssetUpdate, AssetTokenization } from './types';
  
function* getLocationDetailsSaga(action: PayloadAction<string>) {
  try {
      const response: ResponseGenerator = yield call(getLocationDetailsApi, action.payload);
      console.log('Response:', response);
      yield put(getLocationDetailsSuccess(response.data));
  } catch (error) {
      yield put(getLocationDetailsFailure('An error occurred while saving location details'));
  }
}

function* saveLocationDetailsSaga(action: PayloadAction<{ locationDetails: any; assetID: string; _id: string }>) { //action: ReturnType<typeof saveLocationDetailsRequest>
  try {
    const { locationDetails, assetID, _id } = action.payload;
    const response: ResponseGenerator = yield call(saveLocationDetailsApi, locationDetails, _id);
    yield put(saveLocationDetailsSuccess());
  } catch (error) {
    yield put(saveLocationDetailsFailure('An error occurred while saving location details'));
  }
}

function* handleSaveBasicDetailsRequest(action: PayloadAction<{ basicDetails: any; assetID: string; _id: string }>) {
    try {
      const { basicDetails, _id } = action.payload;
      yield call(saveBasicDetailsApi, basicDetails, _id);
      yield put(saveBasicDetailsSuccess());
    } catch (error) {
      yield put(saveBasicDetailsFailure('An error occurred while saving location details'));
    }
  }

    
function* getBasicDetailsSaga(action: PayloadAction<string>) {
    try {
        const response: ResponseGenerator = yield call(getBasicDetailsApi, action.payload);
        console.log('Response:', response);
        yield put(getBasicDetailsSuccess(response.data));
    } catch (error) {
        yield put(getBasicDetailsFailure('An error occurred while fetching basic details'));
    }
  }
  
  function* handleSaveRentDetailsRequest(action: PayloadAction<{ rentDetails: any; assetID: string; _id: string }>) {
    try {
      const { rentDetails, _id } = action.payload;
      yield call(saveRentDetailsApi, rentDetails, _id);
      yield put(saveRentDetailsSuccess());
    } catch (error) {
      yield put(saveRentDetailsFailure('An error occurred while saving location details'));
    }
  }
  
  function* handleGetRentDetailsRequest(action: PayloadAction<string>) {
    try {
      const response: ResponseGenerator = yield call(getRentDetailsApi, action.payload);
      yield put(getRentDetailsSuccess(response.data));
    } catch (error) {
        yield put(getRentDetailsFailure('An error occurred while getting rent details'));
    }
  }
  
  function* handleSaveAssetDescriptionRequest(action: PayloadAction<{ assetTitle: string, assetDescription: string; assetID: string; _id: string }>) {
    try {
      const { assetTitle, assetDescription, _id } = action.payload;
      yield call(saveAssetDescriptionApi, assetTitle, assetDescription, _id);
      yield put(saveAssetDescriptionSuccess());
    } catch (error) {
      yield put(saveAssetDescriptionFailure('An error occurred while saving asset description'));
    }
  }
  
  // function* fetchTokenOfferingDetails(action: ReturnType<typeof getTokenOfferingDetailsRequest>) {
  //   try {
  //     //const data = yield call(getTokenOfferingDetailsApi, action.payload);
  //     yield put(getTokenOfferingDetailsSuccess());
  //   } catch (error) {
  //     yield put(getTokenOfferingDetailsFailure("An error occurred while fetching token offering details"));
  //   }
  // }
  
  function* handleSaveTokenOfferingDetailsRequest(action: PayloadAction<{ tokenOfferingDetails: string; assetID: string; _id: string }>) {
    try {
      yield call(saveTokenOfferingDetailsApi, action.payload);
      yield put(saveTokenOfferingDetailsSuccess());
    } catch (error) {
      yield put(saveTokenOfferingDetailsFailure("An error occurred while updating token offering details"));
    }
  }

  function* handleSaveInternalStructureDetailsRequest(action: PayloadAction<{ InternalStructureDetails: string; assetID: string; _id: string }>) {
    try {
      const { InternalStructureDetails, _id } = action.payload;
      yield call(saveInternalStructureDetailsAPi, action.payload);
      yield put(saveInternalStructureSuccess());
    } catch (error) {
      yield put(saveInternalStructureFailure("An error occurred while saving internal structure details"));
    }
  }

  function* handleSaveExternalStructureDetailsRequest(action: PayloadAction<{ ExternalStructureDetails: string; assetID: string; _id: string }>) {
    try {
      console.log('ExternalStructureDetails:', action.payload)
      const { ExternalStructureDetails, _id } = action.payload;
      yield call(saveExternalStructureDetailsApi, action.payload);
      yield put(saveExternalStructureSuccess());
    } catch (error) {
      yield put(saveExternalStructureFailure("An error occurred while saving internal structure details"));
    }
  }

  function* handleSavePurchaseDetailsRequest(action: PayloadAction<{ purchaseDetails: PurchaseDetails; assetID: string; _id: string }>) {
    try {
      const { purchaseDetails, _id } = action.payload;
      yield call(savePurchaseDetailsApi, action.payload);
      yield put(savePurchaseDetailsSuccess());
    } catch (error) {
      yield put(savePurchaseDetailsFailure("An error occurred while saving purchase details"));
    }
  }

  function* handleSaveTokenDetailsRequest(action: PayloadAction<{ tokenDetails: TokenDetails; assetID: string; _id: string }>) {
    try {
      const { tokenDetails, _id } = action.payload;
      yield call(saveTokenDetailsApi, action.payload);
      yield put(saveTokenDetailsSuccess());
    } catch (error) {
      yield put(saveTokenDetailsFailure("An error occurred while saving token details"));
    }
  }

  function* handleGetTokenDetailsRequest(action: PayloadAction<string>) {
    console.log('handleGetTokenDetailsRequest:', action.payload);
    try {
        const response: ResponseGenerator = yield call(getTokenDetailsApi, action.payload);
        console.log('handleGetTokenDetailsRequest Response:', response);
        yield put(getTokenDetailsSuccess(response.data));
    } catch (error) {
        yield put(getTokenDetailsFailure('An error occurred while saving location details'));
    }
  }
  
  

  function* handleSaveValuationDetailsRequest(action: PayloadAction<{ valuationDetails: ValuationDetails; assetID: string; _id: string }>) {
    try {
      const { valuationDetails, _id } = action.payload;
      console.log('valuationDetails:', valuationDetails);
      yield call(saveValuationDetailsApi, action.payload);
      yield put(saveValuationDetailsSuccess());
    } catch (error) {
      yield put(saveValuationDetailsFailure("An error occurred while saving valuation details"));
    }
  }

  function* handlePublishAsset(action: any) {
    try {
      yield call(publishAssetApi, action.payload);
      yield put(publishAssetSuccess());
    } catch (error) {
      yield put(publishAssetFailure("An error occurred while saving publish status"));
    }
  }
  
  function* handleUnpublishAsset(action: PayloadAction<{ assetID: string; }>) {
    try {
      //yield call(unpublishAssetApi, action.payload);
      yield put(unpublishAssetSuccess());
    } catch (error) {
      yield put(unpublishAssetFailure("An error occurred while saving unpublish status"));
    }
  }

  function* handleUploadImage(action: any) {
    try {
      console.log('Uploading image:', action.payload);
      yield call(uploadImageApi, action.payload);
      yield put(uploadImageSuccess());
    } catch (error) {
      yield put(uploadImageFailure("An error occurred while saving uploading asset image"));
    }
  }

  function* handleUploadValuationDocument(action: PayloadAction<{ document: File, assetID: string, _id: string}>) {
    try {
      console.log('Uploading Valuation document:', action.payload);
      yield call(uploadValuationDocumentApi, action.payload);
      yield put(uploadValuationDocumentSuccess());
    } catch (error) {
      yield put(uploadValuationDocumentFailure("An error occurred while saving uploading valuation docs"));
    }
  }

  function* handleSaveFinancialDetailsRequest(action: PayloadAction<{ financialDetails: FinancialDetails; assetID: string; _id: string }>) {
    try {
      const { financialDetails, _id } = action.payload;
      console.log('financialDetails:', financialDetails);
      yield call(saveFinancialDetailsApi, action.payload);
      yield put(saveFinancialDetailsSuccess());
    } catch (error) {
      yield put(saveFinancialDetailsFailure("An error occurred while saving financial details"));
    }
  }

  function* handleSaveFeaturesDetailsRequest(action: PayloadAction<{ assetFeatures: AssetFeatures; assetID: string; _id: string }>) {
    try {
      const { assetFeatures, assetID } = action.payload;
      console.log('assetFeatures:', assetFeatures);
      yield call(saveAssetFeaturesApi, action.payload);
      yield put(saveFeaturesSuccess());
    } catch (error) {
      yield put(saveFeaturesFailure("An error occurred while saving features details"));
    }
  }


  function* handleGetExternalStructureDetailsRequest(action: PayloadAction<string>) {
    try {
        const response: ResponseGenerator = yield call(getExternalStructureDetailsApi, action.payload);
        yield put(getExternalStructureSuccess(response.data));
    } catch (error) {
        yield put(getExternalStructureFailure('An error occurred while saving location details'));
    }
  }
  
  function* handleGetInternalStructureDetailsRequest(action: PayloadAction<string>) {
    try {
        const response: ResponseGenerator = yield call(getInternalStructureDetailsApi, action.payload);
        yield put(getInternalStructureSuccess(response.data));
    } catch (error) {
        yield put(getInternalStructureFailure('An error occurred while saving location details'));
    }
  }

  function* handleGetPurchaseDetailsRequest(action: PayloadAction<string>) {
    try {
        const response: ResponseGenerator = yield call(getPurchaseDetailsApi, action.payload);
        yield put(getPurchaseDetailsSuccess(response.data));
    } catch (error) {
        yield put(getPurchaseDetailsFailure('An error occurred while saving location details'));
    }
  }

  function* handleGetFinancialDetailsRequest(action: PayloadAction<string>) {
    try {
        const response: ResponseGenerator = yield call(getFinancialDetailsApi, action.payload);
        yield put(getFinancialDetailsSuccess(response.data));
    } catch (error) {
        yield put(getFinancialDetailsFailure('An error occurred while fetching financial details'));
    }
  }

  function* handleGetValuationDetailsRequest(action: PayloadAction<string>) {
    try {
        const response: ResponseGenerator = yield call(getValuationDetailsApi, action.payload);
        yield put(getValuationDetailsSuccess(response.data));
    } catch (error) {
        yield put(getValuationDetailsFailure('An error occurred while fetching valuation details'));
    }
  }

  function* handleGetTokenOfferingDetailsRequest(action: PayloadAction<string>) {
    try {
        const response: ResponseGenerator = yield call(getTokenOfferingDetailsApi, action.payload);
        yield put(getTokenOfferingDetailsSuccess(response.data));
    } catch (error) {
        yield put(getTokenOfferingDetailsFailure('An error occurred while fetching token offering details'));
    }
  }

  function* handleGetFeaturesRequest(action: PayloadAction<string>) {
    try {
        const response: ResponseGenerator = yield call(getAssetFeaturesApi, action.payload);
        yield put(getFeaturesSuccess(response.data));
    } catch (error) {
        yield put(getFeaturesFailure('An error occurred while fetching asset features'));
    }
  }

  function* handleGetPurchasePriceRequest(action: PayloadAction<string>) {
    try {
        const response: ResponseGenerator = yield call(getPurchasePriceApi, action.payload);
        console.log('getPurchasePriceRequest Response:', response);
        yield put(getPurchasePriceSuccess(response.data.purchasePrice));
    } catch (error) {
        yield put(getPurchasePriceFailure('An error occurred while fetching asset purchase price'));
    }
  }

  function* updateMonthlyRentDetailsSaga(action: PayloadAction<{ monthlyRentDetails: MonthlyRentDetails; assetID: string; _id: string }>) {
    try {
      const { monthlyRentDetails, assetID } = action.payload;
      console.log('monthlyRentDetails:', monthlyRentDetails);
        const response: ResponseGenerator = yield call(updateMonthlyRentDetailsApi, action.payload);
        yield put(updateMonthlyRentDetailsSuccess(response.data));
    } catch (error) {
        yield put(updateMonthlyRentDetailsFailure('An error occurred while fetching asset features'));
    }
  }

  function* getAllMonthlyRentDetailsSaga(action: PayloadAction<string>) {
    try {
        const response: ResponseGenerator = yield call(getAllMonthlyRentDetailsApi, action.payload);
        console.log('getAllMonthlyRentDetailsSaga Response:', response);
        yield put(getAllMonthlyRentDetailsSuccess(response));
    } catch (error) {
        yield put(getAllMonthlyRentDetailsFailure('An error occurred while fetching asset features'));
    }
  }

  function* getMonthlyRentDetailsByYearMonthSaga(action: PayloadAction<{ assetID: string; year: number; month: string}>) {
    try {
      console.log('getMonthlyRentDetailsByYearMonthSaga Response:', action.payload);

        const response: ResponseGenerator = yield call(getMonthlyRentDetailsByYearMonthApi, action.payload);
        console.log('getMonthlyRentDetailsByYearMonthSaga Response:', response);
        yield put(getMonthlyRentDetailsByYearMonthSuccess(response));
    } catch (error) {
        yield put(getMonthlyRentDetailsByYearMonthFailure('An error occurred while fetching asset features'));
    }
  }

  function* saveAssetUpdateSaga(action: PayloadAction<{ assetUpdate: AssetUpdate; assetID: string; }>) {
    console.log('saveAssetUpdateSaga:', action.payload);
    try {
      const { assetUpdate, assetID } = action.payload;

      console.log('assetUpdate:', assetUpdate);
      console.log('assetID:', assetID);
      const response: ResponseGenerator = yield call(saveAssetUpdateApi, action.payload);
      yield put(saveAssetUpdateSuccess(response.data));
    } catch (error) {
        yield put(saveAssetUpdateFailure('An error occurred while fetching asset features'));
    }
  }

  function* getAssetUpdatesRequestSaga(action: PayloadAction<string>) {
    console.log('getAssetUpdatesRequestSaga:', action.payload);
    try {
        const response: ResponseGenerator = yield call(getAssetUpdatesApi, action.payload);
        console.log('getAssetUpdatesRequestSaga Response:', response);
        yield put(getAssetUpdatesSuccess(response.data));
    } catch (error) {
        yield put(getAssetUpdatesFailure('An error occurred while fetching asset features'));
    }
  }

  function* handleGetActiveTokenPriceRequest(action: PayloadAction<string>) {
    try {
      const response: ResponseGenerator = yield call(getActiveTokenPriceApi, action.payload);
      yield put(getActiveTokenPriceSuccess(response.data));
    } catch (error) {
      yield put(getActiveTokenPriceFailure('An error occurred while fetching active token price'));
    }
  }

  function* handleGetInitialTokenPriceRequest(action: PayloadAction<string>) {
    try {
      const response: ResponseGenerator = yield call(getInitialTokenPriceApi, action.payload);
      yield put(getInitialTokenPriceSuccess(response.data));
    } catch (error) {
      yield put(getInitialTokenPriceFailure('An error occurred while fetching active token price'));
    }
  }

  function* handleGetTokenPriceHistoryRequest(action: PayloadAction<string>) {
    try {
      const response: ResponseGenerator = yield call(getTokenPriceHistoryApi, action.payload);
      yield put(getTokenPriceHistorySuccess(response.data));
    } catch (error) {
      yield put(getTokenPriceHistoryFailure('An error occurred while fetching token price history'));
    }
  }
  
  function* handleSetTokenPriceRequest(action: PayloadAction<{ assetID: string; 
    assetTokenDetails: AssetTokenization, purchasePrice: number, initialValuationId: string }>) {
    try {
      const response: ResponseGenerator = yield call(setTokenPriceApi, action.payload);
      yield put(setTokenPriceSuccess(response.data));
    } catch (error) {
      yield put(setTokenPriceFailure('Error occured when setting token price'));
    }
  }

  function* handleUpdateTokenPriceRequest(action: PayloadAction<{ assetID: string; assetValuationId: string }>) {
    try {
      const response: ResponseGenerator = yield call(updateTokenPriceApi, action.payload);
      yield put(updateTokenPriceSuccess(response.data));
    } catch (error) {
      yield put(updateTokenPriceFailure('Error occured when setting token price'));
    }
  }

  function* handleGetTokenAvailabilityRequest(action: PayloadAction<string>) {
    try {
      const response: ResponseGenerator = yield call(getTokenAvailabilityApi, action.payload);
      yield put(getTokenAvailabilitySuccess(response.data));
    } catch (error) {
      yield put(getTokenAvailabilityFailure('An error occurred while fetching token availability details'));
    }
  }

  function* handleSubmitPurchaseRequest(action: ReturnType<typeof submitPurchaseRequest>) {
    try {
      const response: ResponseGenerator = yield call(submitPurchaseRequestApi, action.payload);
      yield put(submitPurchaseRequestSuccess(response));
    } catch (error) {
      yield put(submitPurchaseRequestFailure('An error occurred while submitting purchase request'));
    }
  }
  
  function* handleFetchPurchaseHistoryByAssetID(action: ReturnType<typeof fetchPurchaseHistoryByAssetID>) {
    try {
      const response: ResponseGenerator = yield call(getPurchaseHistoryByAssetIDApi, action.payload);
      yield put(fetchPurchaseHistoryByAssetIDSuccess(response.data));
    } catch (error) {
      yield put(fetchPurchaseHistoryByAssetIDFailure('An error occurred while fetching purchase history'));
    }
  }

  function* handleFetchPurchaseHistoryByAssetIDAndUserID(action: ReturnType<typeof fetchPurchaseHistoryByAssetIDAndUserID>) {
    try {
      console.log('saga fetchPurchaseHistoryByAssetIDAndUserID:', action.payload);
      const { assetID, userID } = action.payload;
      const response: ResponseGenerator = yield call(getPurchaseHistoryByAssetIDAndUserIDApi, assetID, userID);
      console.log('fetchPurchaseHistoryByAssetIDAndUserID response:', response);
      yield put(fetchPurchaseHistoryByAssetIDAndUserIDSuccess(response.data));
    } catch (error) {
      yield put(fetchPurchaseHistoryByAssetIDAndUserIDFailure('An error occurred while fetching purchase history'));
    }
  }

  function* handleFetchTotalTokenPurchaseByAssetID(action: ReturnType<typeof fetchTotalTokenPurchaseByAssetID>) {
    try {
      const response: ResponseGenerator = yield call(getTotalTokenPurchaseByAssetID, action.payload);
      console.log('handleFetchTotalTokenPurchaseByAssetID response:', response);
      yield put(fetchTotalTokenPurchaseByAssetIDSuccess(response.data?.totalTokens));
    } catch (error) {
      yield put(fetchTotalTokenPurchaseByAssetIDFailure('An error occurred while fetching total count of token purchase'));
    }
  }
  

export default function* assetEditorSaga() {
  yield takeLatest(getLocationDetailsRequest.type, getLocationDetailsSaga);
  yield takeLatest(saveLocationDetailsRequest.type, saveLocationDetailsSaga);
  yield takeLatest(saveBasicDetailsRequest.type, handleSaveBasicDetailsRequest);
  yield takeLatest(getBasicDetailsRequest.type, getBasicDetailsSaga);
  yield takeLatest(saveRentDetailsRequest.type, handleSaveRentDetailsRequest);
  yield takeLatest(getRentDetailsRequest.type, handleGetRentDetailsRequest);
  yield takeLatest(saveAssetDescriptionRequest.type, handleSaveAssetDescriptionRequest);
  yield takeLatest(getTokenOfferingDetailsRequest.type, handleGetTokenOfferingDetailsRequest);
  yield takeLatest(saveTokenOfferingDetailsRequest.type, handleSaveTokenOfferingDetailsRequest);
  yield takeLatest(saveInternalStructureRequest.type, handleSaveInternalStructureDetailsRequest);
  yield takeLatest(saveExternalStructureRequest.type, handleSaveExternalStructureDetailsRequest);
  yield takeLatest(savePurchaseDetailsRequest.type, handleSavePurchaseDetailsRequest);
  yield takeLatest(saveTokenDetailsRequest.type, handleSaveTokenDetailsRequest);
  yield takeLatest(saveTokenDetailsRequest.type, handleSaveTokenDetailsRequest);
  yield takeLatest(getTokenDetailsRequest.type, handleGetTokenDetailsRequest);
  yield takeLatest(saveValuationDetailsRequest.type, handleSaveValuationDetailsRequest);
  yield takeLatest(publishAssetRequest.type, handlePublishAsset);
  yield takeLatest(unpublishAssetRequest.type, handleUnpublishAsset);
  yield takeLatest(uploadImageRequest.type, handleUploadImage);
  //yield takeLatest(uploadValuationDocumentRequest.type, handleUploadValuationDocument);
  yield takeLatest(saveFinancialDetailsRequest.type, handleSaveFinancialDetailsRequest);
  yield takeLatest(getExternalStructureRequest.type, handleGetExternalStructureDetailsRequest);
  yield takeLatest(getInternalStructureRequest.type, handleGetInternalStructureDetailsRequest);
  yield takeLatest(getPurchaseDetailsRequest.type, handleGetPurchaseDetailsRequest);
  yield takeLatest(getFinancialDetailsRequest.type, handleGetFinancialDetailsRequest);
  yield takeLatest(getValuationDetailsRequest.type, handleGetValuationDetailsRequest);
  yield takeLatest(saveFeaturesRequest.type, handleSaveFeaturesDetailsRequest);
  yield takeLatest(getFeaturesRequest.type, handleGetFeaturesRequest);
  yield takeLatest(updateMonthlyRentDetailsRequest.type, updateMonthlyRentDetailsSaga);
  yield takeLatest(getAllMonthlyRentDetailsRequest.type, getAllMonthlyRentDetailsSaga);
  yield takeLatest(getMonthlyRentDetailsByYearMonthRequest.type, getMonthlyRentDetailsByYearMonthSaga);
  yield takeLatest(getAssetUpdatesRequest.type, getAssetUpdatesRequestSaga);
  yield takeLatest(saveAssetUpdateRequest.type, saveAssetUpdateSaga);
  yield takeLatest(getPurchasePriceRequest.type, handleGetPurchasePriceRequest);
  yield takeLatest(getActiveTokenPriceRequest.type, handleGetActiveTokenPriceRequest);
  yield takeLatest(getInitialTokenPriceRequest.type, handleGetInitialTokenPriceRequest);
  yield takeLatest(setTokenPriceRequest.type, handleSetTokenPriceRequest);
  yield takeLatest(updateTokenPriceRequest.type, handleUpdateTokenPriceRequest);
  yield takeLatest(getTokenPriceHistoryRequest.type, handleGetTokenPriceHistoryRequest);
  yield takeLatest(getTokenAvailabilityRequest.type, handleGetTokenAvailabilityRequest);
  yield takeLatest(submitPurchaseRequest.type, handleSubmitPurchaseRequest);
  yield takeLatest(fetchPurchaseHistoryByAssetID.type, handleFetchPurchaseHistoryByAssetID);
  yield takeLatest(fetchPurchaseHistoryByAssetIDAndUserID.type, handleFetchPurchaseHistoryByAssetIDAndUserID);
  yield takeLatest(fetchTotalTokenPurchaseByAssetID.type, handleFetchTotalTokenPurchaseByAssetID);

  


}
