import React, { useState } from 'react';
import styles from './PropertyTokenComponent.module.css';
import { FaCopy } from 'react-icons/fa'; // Import the copy icon
import { ethers } from 'ethers'; // Ensure ethers.js is installed

const PropertyTokenComponent: React.FC = () => {
  const [propertyTokenWalletAddress, setPropertyTokenWalletAddress] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const connectToMetamask = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setPropertyTokenWalletAddress(accounts[0]);
      } catch (error) {
        setErrorMessage('Failed to connect to Metamask.');
      }
    } else {
      setErrorMessage('Please install Metamask to connect.');
    }
  };

  const copyAddressToClipboard = () => {
    if (propertyTokenWalletAddress) {
      navigator.clipboard.writeText(propertyTokenWalletAddress);
      alert('Address copied to clipboard!');
    }
  };

  const formatAddress = (address: string) => {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  return (
    <div className={styles.propertyTokenContainer}>
      <h2 className={styles.propertyTokenTitle}>Property Token Wallet</h2>
      <p className={styles.infoText}>
        All the property tokens will be stored in this address.
      </p>
      {!propertyTokenWalletAddress ? (
        <button className={styles.connectButton} onClick={connectToMetamask}>
          Connect Metamask
        </button>
      ) : (
        <>
          <p className={styles.connectedAddressTitle}>Your connected Ethereum address</p>
          <div className={styles.walletDetails}>
            <a
              href={`https://etherscan.io/address/${propertyTokenWalletAddress}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.etherscanLink}
            >
              {formatAddress(propertyTokenWalletAddress)}
            </a>
            <FaCopy className={styles.copyIcon} onClick={copyAddressToClipboard} />
          </div>
        </>
      )}

      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

      {propertyTokenWalletAddress && (
        <p className={styles.changeWalletText}>
          To change the wallet address, contact us via chat.
        </p>
      )}
    </div>
  );
};

export default PropertyTokenComponent;
