// src/features/adminUsers/adminUsersSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateAdminUserDto, LoginAdminUserDto, ChangeAdminTypeDto, ChangePasswordDto } from './adminUsersApi';

interface AdminUserState {
  loading: boolean;
  success: boolean;
  accessToken: string | null;
  successMessage: string | null;
  error: string | null;
}

const initialState: AdminUserState = {
  loading: false,
  success: false,
  accessToken: null,
  successMessage: null,
  error: null,
};

const adminUsersSlice = createSlice({
  name: 'adminUsers',
  initialState,
  reducers: {
    createAdminUserRequest(state, action: PayloadAction<CreateAdminUserDto>) {
      state.loading = true;
      state.success = false;
      state.error = null;
    },
    createAdminUserSuccess(state) {
      state.loading = false;
      state.success = true;
      state.error = null;
    },
    createAdminUserFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    loginAdminUserRequest(state, action: PayloadAction<LoginAdminUserDto>) {
        state.loading = true;
        state.accessToken = null;
        state.error = null;
      },
      loginAdminUserSuccess(state, action: PayloadAction<{ accessToken: string }>) {
        state.loading = false;
        state.accessToken = action.payload.accessToken;
        state.error = null;
      },
      loginAdminUserFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.accessToken = null;
        state.error = action.payload;
      },    
      changeAdminTypeRequest(state, action: PayloadAction<ChangeAdminTypeDto>) {
        state.loading = true;
        state.successMessage = null;
        state.error = null;
      },
      changeAdminTypeSuccess(state, action: PayloadAction<string>) {
        state.loading = false;
        state.successMessage = action.payload; // Success message
        state.error = null;
      },
      changeAdminTypeFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.successMessage = null;
        state.error = action.payload;
      },      
      changePasswordRequest(state, action: PayloadAction<ChangePasswordDto>) {
        state.loading = true;
        state.successMessage = null;
        state.error = null;
      },
      changePasswordSuccess(state, action: PayloadAction<string>) {
        state.loading = false;
        state.successMessage = action.payload; // Success message
        state.error = null;
      },
      changePasswordFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.successMessage = null;
        state.error = action.payload;
      },      
  },
});

export const {
  createAdminUserRequest,
  createAdminUserSuccess,
  createAdminUserFailure,
  loginAdminUserRequest,
  loginAdminUserSuccess,
  loginAdminUserFailure,
  changeAdminTypeRequest,
  changeAdminTypeSuccess,
  changeAdminTypeFailure,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,
} = adminUsersSlice.actions;

export default adminUsersSlice.reducer;
