// src/features/valuation/valuationSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssetValuation } from './types';

interface ValuationState {
  data: any | null; // Define a more specific type for your data if possible
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  assetValuation: AssetValuation | null;
  assetValuationList: AssetValuation[];
  tokenPrice?: number;
  totalTokens?: number;
  assetPrice?: number;
  initialValuation: any | null;
}

const initialState: ValuationState = {
    data: null,
    status: 'idle',
    error: null,
    assetValuation: null,
    assetValuationList: [],
    tokenPrice: 0,
    totalTokens: 0,
    assetPrice: 0,
    initialValuation: null,
};

const valuationSlice = createSlice({
  name: 'valuation',
  initialState,
  reducers: {
    addValuationRequest(state, action: PayloadAction<{ assetValuation: AssetValuation; assetID: string; _id: string }>) {
      console.log('addValuationRequest: ', action.payload);
      state.status = 'loading';
      state.error = null;
    },
    addValuationSuccess(state, action: PayloadAction<any>) {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    addValuationFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },
    getValuationByAssetIDRequest(state, action: PayloadAction<string>) {
      console.log('getValuationByAssetIDRequest: ', action.payload);
      state.status = 'loading';
      state.error = null;
    },
    getValuationByAssetIDSuccess(state, action: PayloadAction<any>) {
      console.log('getValuationByAssetIDSuccess: ', action.payload);
      state.status = 'succeeded';
      state.assetValuationList = action.payload;
    },
    getValuationByAssetIDFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },
    clearValuationState(state) {
      state.data = null;
      state.status = 'idle';
      state.error = null;
    },

    fetchTokenPriceRequest(state, action: PayloadAction<string>) {
      state.status = 'loading';
    },
    fetchTokenPriceSuccess(state, action: PayloadAction<number>) {
      state.status = 'succeeded';
      state.tokenPrice = action.payload;
    },
    fetchTokenPriceFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },
    fetchTotalTokensRequest(state, action: PayloadAction<string>) {
      state.status = 'loading';
    },
    fetchTotalTokensSuccess(state, action: PayloadAction<number>) {
      console.log('fetchTotalTokensSuccess: ', action.payload);
      state.status = 'succeeded';
      state.totalTokens = action.payload;
    },
    fetchTotalTokensFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },
    fetchAssetPriceRequest(state, action: PayloadAction<string>) {
      state.status = 'loading';
    },
    fetchAssetPriceSuccess(state, action: PayloadAction<number>) {
      state.status = 'succeeded';
      state.assetPrice = action.payload;
    },
    fetchAssetPriceFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },
    // Fetch initial valuation by assetID
    fetchInitialValuationRequest(state, action: PayloadAction<string>) {
      state.status = 'loading';
      state.error = null;
    },
    fetchInitialValuationSuccess(state, action: PayloadAction<any>) {
      state.status = 'succeeded';
      state.initialValuation = action.payload;
    },
    fetchInitialValuationFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    },

  },
});

export const { addValuationRequest, addValuationSuccess, addValuationFailure, 
  getValuationByAssetIDRequest, getValuationByAssetIDSuccess, getValuationByAssetIDFailure,
  fetchTokenPriceRequest, fetchTokenPriceSuccess, fetchTokenPriceFailure,
  fetchTotalTokensRequest, fetchTotalTokensSuccess, fetchTotalTokensFailure,
  fetchAssetPriceRequest, fetchAssetPriceSuccess, fetchAssetPriceFailure,
  fetchInitialValuationRequest, fetchInitialValuationSuccess, fetchInitialValuationFailure,
  clearValuationState } = valuationSlice.actions;
export default valuationSlice.reducer;
