import React from 'react';
import PropertyWallet from './PropertyWallet';
import RentWallet from './RentWallet';
import styles from './AddressBook.module.css';  // Import the CSS file
import PropertyTokenComponent from './PropertyTokenComponent';

const AddressBook: React.FC = () => {
    return (
        <div>
            <PropertyWallet />
            <PropertyTokenComponent />
            <RentWallet />
        </div>
    );
}

export default AddressBook;
