import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createVeriffSessionApi,
  getVeriffKycStatusApi,
} from './kycVeriffApi';

import {
  createSessionUrlRequest,
  createSessionUrlSuccess,
  createSessionUrlFailure,
  getKycStatusRequest,
  getKycStatusSuccess,
  getKycStatusFailure,
} from './kycVeriffSlice';

// Worker saga: Create Veriff Session URL
function* createSessionUrlWorker(action: any): Generator<any, void, any> {
  try {
    const dto = action.payload;
    const response = yield call(createVeriffSessionApi, dto);
    yield put(createSessionUrlSuccess(response));
  } catch (error: any) {
    yield put(createSessionUrlFailure(error.message));
  }
}

// Worker saga: Get KYC Status
function* getKycStatusWorker(action: any): Generator<any, void, any> {
  try {
    const { userId } = action.payload;
    const response = yield call(getVeriffKycStatusApi, userId);
    yield put(getKycStatusSuccess(response));
  } catch (error: any) {
    yield put(getKycStatusFailure(error.message));
  }
}

// Watcher saga
export function* watchVeriffSagas() {
  yield takeLatest(createSessionUrlRequest.type, createSessionUrlWorker);
  yield takeLatest(getKycStatusRequest.type, getKycStatusWorker);
}
