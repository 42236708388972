import React from 'react';
import styles from './MetricsCard.module.css';
import { FaBuilding, FaDollarSign, FaShoppingBasket, FaCloud, FaClock } from 'react-icons/fa'; // Example icons from Font Awesome

const MetricsCard = (props:any) => {
  return (
    <div className={styles.metricsContainer}>
      {/* Total Properties Card */}
      <div className={styles.metricCard}>
        <div className={styles.metricHeader}>
          <FaBuilding className={styles.metricIcon} />
          <span>Total Properties</span>
        </div>
        <div className={styles.metricValue}>
          <strong>{props?.data?.totalProperties?.length ?? 0}</strong>
        </div>
        {/* <div className={styles.metricComparison}>
          <span className={styles.comparisonBadge}>= 0.00%</span> vs previous 24 hours
        </div> */}
      </div>

      {/* Properties Invested Card */}
      <div className={styles.metricCard}>
        <div className={styles.metricHeader}>
          <FaShoppingBasket className={styles.metricIcon} />
          <span>Properties Invested</span>
        </div>
        <div className={styles.metricValue}>
          <strong>{props?.data?.totalPropertiesInvested ?? 0}</strong>
        </div>
        {/* <div className={styles.metricComparison}>
          <span className={styles.comparisonBadge}>= 0.00%</span> vs previous 24 hours
        </div> */}
      </div>

      {/* Income Received Card */}
      <div className={styles.metricCard}>
        <div className={styles.metricHeader}>
          <FaDollarSign className={styles.metricIcon} />
          <span>Income Received</span>
        </div>
        <div className={styles.metricValue}>
          <strong>{props?.data?.incomeReceived}</strong>
        </div>
        {/* <div className={styles.metricComparison}>
          <span className={styles.comparisonBadge}>= 0.00%</span> vs previous 24 hours
        </div> */}
      </div>
    </div>
  );
};

export default MetricsCard;
