import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './TopBanner.module.css';
import logo from '../../../../assets/logo.png';
import { logoutRequest } from '../../../../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getDisplayNameRequest } from '../../../../features/userProfile/userProfileSlice';
import { RootState } from '../../../../store/rootReducer';
import { CgProfile } from "react-icons/cg";

const TopBanner: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);
  const [helpMenuVisible, setHelpMenuVisible] = useState(false);
  const userID = localStorage.getItem('userID') ?? '';

  const { displayName } = useSelector((state: RootState) => state.userProfile);

  const toggleProfileMenu = () => {
    setHelpMenuVisible(false);
    setMenuVisible(!menuVisible);
  };

  useEffect(() => {
    dispatch(getDisplayNameRequest(userID));
  }, [dispatch]);

  const toggleHelpMenu = () => {
    setMenuVisible(false);
    setHelpMenuVisible(!helpMenuVisible);
  };

  const handleLogout = async () => {
    try {
      dispatch(logoutRequest());
      // navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div className={styles.banner}>
      <div className={styles.logoContainer}>
      <Link to="/me/dashboard">
        <img src={logo} alt="Assetslices Logo" className={styles.logo} />
      </Link>  
      </div>
      <nav className={styles.navMenu}>
        {/* Trial and Upgrade Button */}
        {/* <button className={styles.upgradeButton}>Connect to Metamask</button> */}
        
        {/* Question Mark Icon */}
        <span className={styles.iconContainer} onClick={toggleHelpMenu}>
          <span className={styles.icon}>?</span>
        </span>

        {/* Profile Icon */}
        <span className={styles.profileIcon} onClick={toggleProfileMenu}>
        {displayName?.displayInitials || <CgProfile />}</span>
        
        {/* Dropdown Menu */}
        {menuVisible && (
          <div className={styles.profileDropdown}>
            <div className={styles.profileDropdownHeader}>
              <strong>{displayName?.displayName}</strong>
            </div>
            {/* <div className={styles.profileDropdownItem}>
              <Link to="/me/account">My Account</Link>
            </div> */}
            <div className={styles.profileDropdownItem}>
              <Link to="/">Terms of Services</Link>
            </div>
            <div className={styles.profileDropdownItem} onClick={handleLogout}>
              Log Out
            </div>
          </div>
        )}

        {/* Dropdown Menu */}
        {helpMenuVisible && (
          <div className={styles.helpDropdown}>
            <div className={styles.helpDropdownHeader}>
            <Link to="/me/account">Learning Hub</Link>
            </div>
            <div className={styles.helpDropdownItem}>
              <Link to="/me/account">Platform Status</Link>
            </div>
            <div className={styles.helpDropdownItem}>
              <Link to="/me/account">Compliance Docs</Link>
            </div>
            <div className={styles.helpDropdownItem}>
              <Link to="/me/tokens">Terms of Service</Link>
            </div>
          </div>
        )}


      </nav>
    </div>
  );
};

export default TopBanner;
