// src/api/distributionApi.ts
import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl + '/distribution';

export const addDistributionApi = async (distributionData: any) => {
    console.log('in addDistributionApi');
    console.log('distributionData', distributionData);
  const response = await axios.post(`${API_BASE_URL}/add`, distributionData);
  return response.data;
};

export const getDistributionsByAssetIDApi = async (assetID: string) => {
    console.log('in getDistributionsByAssetIDApi');
  const response = await axios.get(`${API_BASE_URL}/by-asset/${assetID}`);
  console.log('getDistributionsByAssetIDApi response', response);
  return response;
};

export const updateDistributionStatusApi = async (distributionID: string, status: string) => {
  const response = await axios.patch(`${API_BASE_URL}/${distributionID}/status`, { status });
  return response.data;
};

export const getDistributionByIDApi = async (distributionID: string) => {
  const response = await axios.get(`${API_BASE_URL}/${distributionID}`);
  return response.data;
};
