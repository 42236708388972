import React from 'react';
import { Header } from '../Header/header';
import { Footer } from '../Footer';
import { Outlet } from 'react-router-dom';
import styles from './Policy.module.css';

const Policy = () => {
  return (
    <div>
        <Header />
        <div className={styles.container}>
        <Outlet />
        </div>
        <Footer />
    </div>

  );
};

export default Policy;
