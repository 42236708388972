// src/components/AssetDetailEditor/AssetBasicDetails.tsx
import React, { useState } from 'react';
import styles from './AssetDescription.module.css';

interface AssetDescriptionProps {
  description: string;
}

const AssetDescription: React.FC<AssetDescriptionProps> = ({ description }) => {
  const [isTruncate, setIsTruncate] = useState(true)

  const initialText = Math.ceil(description?.length / 3);
  const truncateText = description.slice(0, initialText);

  const toggleTruncate = () => {
    setIsTruncate(!isTruncate)
  }

  return (
    <div className={styles.basicDetailsContainer}>
      <h2 className={styles.basicDetailsHeading}>About Asset</h2>
      <p className={styles.descriptionText}>{isTruncate ? truncateText : description} {isTruncate ? "..." : ""}</p>
      <span className={styles.truncateHandle} onClick={toggleTruncate}>{isTruncate ? 'more...' : 'less...'}</span>
    </div>
  );
};

export default AssetDescription;
